var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "teros_principal__content interna container" },
    [
      _c("div", { staticClass: "teros_principal__content__line-1 line" }, [
        _c(
          "div",
          { staticClass: "teros_principal__content__line-1__highlights" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__line-1__highlights__subject",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__line-1__highlights__subject__breadcrumb",
                  },
                  [
                    _c(
                      "h3",
                      {
                        staticClass:
                          "teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("views.shared.menu.authorizations")) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "h2",
                      {
                        staticClass:
                          "teros_principal__content__line-1__highlights__subject__breadcrumb__title active",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.authorization.customerName) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "teros_principal__arrow" }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__line-1__highlights__subject__breadcrumb",
                  },
                  [
                    _c(
                      "h3",
                      {
                        staticClass:
                          "teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "views.teros.authorizations.authorization.title"
                              )
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "h2",
                      {
                        staticClass:
                          "teros_principal__content__line-1__highlights__subject__breadcrumb__title active",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.authorization.integrationName) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "teros_principal__content__line-1__information" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__line-1__information__time",
              },
              [
                _c(
                  "p",
                  {
                    staticClass:
                      "teros_principal__content__line-1__information__time__text",
                  },
                  [
                    _c("img", {
                      staticClass:
                        "teros_principal__content__line-1__information__time__text__icon",
                      attrs: { src: "/static/img/icons/relogio.png", alt: "" },
                    }),
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("views.shared.createdDate", {
                            date: _vm.creationDate,
                            time: _vm.creationTime,
                          })
                        ) +
                        "\n          " +
                        _vm._s(
                          _vm.lastUpdateDate
                            ? "| " +
                                _vm.$t("views.shared.changedDate", {
                                  date: _vm.lastUpdateDate,
                                  time: _vm.lastUpdateTime,
                                })
                            : ""
                        ) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "teros_principal__content__line-1__information__time__btn",
                    attrs: { type: "button" },
                    on: { click: _vm.goBack },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "/static/img/icons/seta-esquerda.png",
                        alt: "",
                      },
                    }),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("views.shared.backButton")) +
                        "\n        "
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { id: "accordion" } }, [
        _c(
          "div",
          { staticClass: "teros_principal__content__accordion__card" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__accordion__card__header",
                attrs: { id: "headingOne" },
              },
              [
                _c(
                  "h2",
                  {
                    staticClass:
                      "teros_principal__content__accordion__card__header__title",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "teros_principal__content__accordion__card__header__title__btn",
                        attrs: {
                          type: "button",
                          "data-toggle": "collapse",
                          "data-target": "#collapseOne",
                          "aria-expanded": "false",
                          "aria-controls": "collapseOne",
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "views.teros.authorizations.authorization.content.general.title"
                              )
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__accordion__card__header__collapse",
                attrs: { id: "collapseOne" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__accordion__card__header__collapse__body",
                  },
                  [
                    _c(
                      "form",
                      { staticClass: "teros_principal__content__column__info" },
                      [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            { attrs: { for: "formGroupExampleInput1" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "views.teros.authorizations.authorization.content.general.client"
                                  )
                                ) + "\n                "
                              ),
                              _c("img", {
                                attrs: {
                                  src: "/static/img/icons/tooltip.png",
                                  "data-toggle": "tooltip",
                                  "data-placement": "right",
                                  title: _vm.$t(
                                    "views.teros.authorizations.authorization.toolTips.general.customer"
                                  ),
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.authorization.customerName,
                                expression: "authorization.customerName",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              readonly: "",
                              id: "formGroupExampleInput1",
                            },
                            domProps: { value: _vm.authorization.customerName },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.authorization,
                                  "customerName",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group row" }, [
                          _c("div", { staticClass: "col" }, [
                            _c(
                              "label",
                              { attrs: { for: "formGroupExampleInput2" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "views.teros.authorizations.authorization.content.general.reception"
                                    )
                                  ) + "\n                  "
                                ),
                                _c("img", {
                                  attrs: {
                                    src: "/static/img/icons/tooltip.png",
                                    "data-toggle": "tooltip",
                                    "data-placement": "right",
                                    title: _vm.$t(
                                      "views.teros.authorizations.authorization.toolTips.general.reception"
                                    ),
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.authorization.integrationName,
                                  expression: "authorization.integrationName",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                readonly: "",
                                id: "formGroupExampleInput2",
                              },
                              domProps: {
                                value: _vm.authorization.integrationName,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.authorization,
                                    "integrationName",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col" }, [
                            _c(
                              "label",
                              { attrs: { for: "formGroupExampleInput3" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "views.teros.authorizations.authorization.content.general.services"
                                    )
                                  )
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.authorization.serviceName,
                                  expression: "authorization.serviceName",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                readonly: "",
                                id: "formGroupExampleInput3",
                              },
                              domProps: {
                                value: _vm.authorization.serviceName,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.authorization,
                                    "serviceName",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "teros_principal__content__accordion__card" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__accordion__card__header",
                attrs: { id: "headingOne" },
              },
              [
                _c(
                  "h2",
                  {
                    staticClass:
                      "teros_principal__content__accordion__card__header__title",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "teros_principal__content__accordion__card__header__title__btn",
                        attrs: {
                          type: "button",
                          "data-toggle": "collapse",
                          "data-target": "#collapseTwo",
                          "aria-expanded": "false",
                          "aria-controls": "collapseTwo",
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "views.teros.authorizations.authorization.content.changesReview.title"
                              )
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__accordion__card__header__collapse collapse",
                attrs: { id: "collapseTwo" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__accordion__card__header__collapse__body",
                  },
                  [
                    _c(
                      "form",
                      {
                        staticClass:
                          "teros_principal__content__accordion__card__header__collapse__body__form",
                        attrs: { method: "post" },
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.saveChanges.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "teros_principal__content__table" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "teros_principal__content__table__header",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "teros_principal__content__table__header__filters",
                                  },
                                  [
                                    _c("div", { staticClass: "form-group" }, [
                                      _c(
                                        "div",
                                        { staticClass: "search-group" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.textSearchValue,
                                                expression: "textSearchValue",
                                              },
                                            ],
                                            staticClass: "input-field",
                                            attrs: {
                                              type: "text",
                                              placeholder: _vm.$t(
                                                "views.shared.searchBar.searchLabel"
                                              ),
                                              maxlength: "30",
                                              onkeydown:
                                                "return event.keyCode == 13 ? event.preventDefault() : true",
                                            },
                                            domProps: {
                                              value: _vm.textSearchValue,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.textSearchValue =
                                                  $event.target.value
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("button", {
                                            staticClass: "search-button",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.makeTextSearch()
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("itens-per-page", {
                                      attrs: {
                                        "request-pagination":
                                          _vm.requestPagination,
                                        callback: _vm.makeTextSearch,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass:
                                    "teros_principal__content__table__header__actions",
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "teros_principal__content__table__body",
                              },
                              [
                                _c(
                                  "table",
                                  {
                                    staticClass:
                                      "teros_principal__content__table__body__table",
                                    attrs: {
                                      id: "table",
                                      "data-toggle": "table",
                                      "data-height": "600",
                                      "data-url": "",
                                    },
                                  },
                                  [
                                    _c("thead", [
                                      _c("tr", [
                                        _c(
                                          "th",
                                          {
                                            attrs: {
                                              "data-field": "id",
                                              "data-sortable": "true",
                                            },
                                          },
                                          [_vm._v("ID")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          { attrs: { "data-field": "a" } },
                                          [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "views.teros.authorizations.authorization.content.changesReview.table.header.clause/fine"
                                                  )
                                                ) +
                                                "\n                      "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          { attrs: { "data-field": "k" } },
                                          [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "views.teros.authorizations.authorization.content.changesReview.table.header.clause/fineOrigin"
                                                  )
                                                ) +
                                                "\n                      "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          { attrs: { "data-field": "d" } },
                                          [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "views.teros.authorizations.authorization.content.changesReview.table.header.includeDate"
                                                  )
                                                ) +
                                                "\n                      "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          { attrs: { "data-field": "e" } },
                                          [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "views.teros.authorizations.authorization.content.changesReview.table.header.changedAt"
                                                  )
                                                ) +
                                                "\n                      "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          { attrs: { "data-field": "f" } },
                                          [_vm._v("Status")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticStyle: {
                                              "text-align": "center",
                                            },
                                            attrs: { "data-field": "h" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "views.teros.authorizations.authorization.content.changesReview.table.header.options"
                                                  )
                                                ) +
                                                "\n                      "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "tbody",
                                      _vm._l(
                                        _vm.authorization
                                          .servicePartnershipTerms.items,
                                        function (item, index) {
                                          return _c("tr", { key: item.hash }, [
                                            _c("td", [
                                              _vm._v(_vm._s(index + 1)),
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(_vm._s(item.name)),
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  item.servicePartnershipTermOriginName
                                                )
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("formatDate")(
                                                    item.creationDate
                                                  )
                                                )
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("formatDate")(
                                                    item.lastUpdateDate
                                                  )
                                                )
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                class: _vm._f("formatClass")(
                                                  item.authorizationStatus
                                                ),
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(
                                                      _vm.statusTranslated(
                                                        item.authorizationStatus
                                                      )
                                                    ) +
                                                    "\n                      "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticStyle: {
                                                  "text-align": "center",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "teros_principal__content__table__body__table__options",
                                                  },
                                                  [
                                                    _c(
                                                      "router-link",
                                                      {
                                                        staticClass:
                                                          "teros_principal__content__table__body__table__options__item",
                                                        attrs: {
                                                          to:
                                                            "/authorizationterm?authorizationHash=" +
                                                            _vm.authorization
                                                              .hash +
                                                            "&hash=" +
                                                            item.hash,
                                                          "data-toggle":
                                                            "tooltip",
                                                          "data-placement":
                                                            "top",
                                                          title:
                                                            "Visualizar/Editar",
                                                        },
                                                      },
                                                      [
                                                        _c("div", [
                                                          _c("img", {
                                                            attrs: {
                                                              src: "/static/img/icons/editar.svg",
                                                              alt: "",
                                                            },
                                                          }),
                                                        ]),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ])
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "teros_principal__content__table__footer",
                              },
                              [
                                _vm.loading
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "teros_principal__content__table__footer__loader",
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: "/static/img/icons/ajax-loader.gif",
                                            alt: "",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "views.shared.loadingResults"
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("pagination", {
                                  attrs: {
                                    "request-pagination": _vm.requestPagination,
                                    callback: _vm.makeTextSearch,
                                    "current-pagination-info":
                                      _vm.currentPaginationInfo,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("hr"),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "div",
                            { staticClass: "col" },
                            [
                              _c(
                                "label",
                                { attrs: { for: "formGroupExampleInput" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "views.teros.authorizations.authorization.content.changesReview.viewConsentLabel"
                                      )
                                    ) + "\n                  "
                                  ),
                                  _c("img", {
                                    attrs: {
                                      src: "/static/img/icons/tooltip.png",
                                      "data-toggle": "tooltip",
                                      "data-placement": "right",
                                      title: _vm.$t(
                                        "views.teros.authorizations.authorization.toolTips.changeReview.seeConsent"
                                      ),
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(" "),
                              _c("pdf-view", {
                                attrs: {
                                  "file-name":
                                    _vm.authorization.consentimentFileName,
                                  type: "button",
                                  title: _vm.$t(
                                    "views.teros.authorizations.authorization.content.changesReview.viewConsentButton"
                                  ),
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group row" }, [
                          _c("div", { staticClass: "col" }, [
                            _c("br"),
                            _vm._v(" "),
                            _c(
                              "label",
                              { attrs: { for: "formGroupExampleInput" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "views.teros.authorizations.authorization.content.changesReview.complianceStandard"
                                    )
                                  ) + "\n                  "
                                ),
                                _c("img", {
                                  attrs: {
                                    src: "/static/img/icons/tooltip.png",
                                    "data-toggle": "tooltip",
                                    "data-placement": "right",
                                    title: _vm.$t(
                                      "views.teros.authorizations.authorization.toolTips.changeReview.compliancePattern"
                                    ),
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-group" }, [
                              _vm.enabled
                                ? _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.authorization.standardHash,
                                          expression:
                                            "authorization.standardHash",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        disabled:
                                          _vm.standards == null ||
                                          _vm.standards.length < 1,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.authorization,
                                            "standardHash",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    _vm._l(_vm.standards, function (item) {
                                      return _c(
                                        "option",
                                        {
                                          key: item.hash,
                                          domProps: { value: item.hash },
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(item.name) +
                                              "\n                    "
                                          ),
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                : _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.authorization.standardHash,
                                          expression:
                                            "authorization.standardHash",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: { disabled: "" },
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.authorization,
                                            "standardHash",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    _vm._l(_vm.standards, function (item) {
                                      return _c(
                                        "option",
                                        {
                                          key: item.hash,
                                          domProps: { value: item.hash },
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(item.name) +
                                              "\n                    "
                                          ),
                                        ]
                                      )
                                    }),
                                    0
                                  ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col" }, [
                            _c("br"),
                            _vm._v(" "),
                            _c(
                              "label",
                              { attrs: { for: "formGroupExampleInput" } },
                              [
                                _vm._v("Status\n                  "),
                                _c("img", {
                                  attrs: {
                                    src: "/static/img/icons/tooltip.png",
                                    "data-toggle": "tooltip",
                                    "data-placement": "right",
                                    title: _vm.$t(
                                      "views.teros.authorizations.authorization.toolTips.changeReview.status"
                                    ),
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.enabled
                              ? _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.authorization.authorizationStatus,
                                        expression:
                                          "authorization.authorizationStatus",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.authorization,
                                          "authorizationStatus",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("option", { attrs: { value: "2" } }, [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "views.teros.authorizations.authorization.content.changesReview.status.pending"
                                            )
                                          ) +
                                          "\n                  "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "1" } }, [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "views.teros.authorizations.authorization.content.changesReview.status.compatible"
                                            )
                                          ) +
                                          "\n                  "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "3" } }, [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "views.teros.authorizations.authorization.content.changesReview.status.notCompatible"
                                            )
                                          ) +
                                          "\n                  "
                                      ),
                                    ]),
                                  ]
                                )
                              : _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.authorization.authorizationStatus,
                                        expression:
                                          "authorization.authorizationStatus",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: { disabled: "" },
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.authorization,
                                          "authorizationStatus",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("option", { attrs: { value: "2" } }, [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "views.teros.authorizations.authorization.content.changesReview.status.pending"
                                            )
                                          ) +
                                          "\n                  "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "1" } }, [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "views.teros.authorizations.authorization.content.changesReview.status.compatible"
                                            )
                                          ) +
                                          "\n                  "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "3" } }, [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.$t(
                                              "views.teros.authorizations.authorization.content.changesReview.status.notCompatible"
                                            )
                                          ) +
                                          "\n                  "
                                      ),
                                    ]),
                                  ]
                                ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group row" }, [
                          _c("div", { staticClass: "col" }, [
                            _c(
                              "label",
                              { attrs: { for: "formGroupExampleInput" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "views.teros.authorizations.authorization.content.changesReview.seem"
                                    )
                                  )
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.enabled
                              ? _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.authorization.feedback,
                                      expression: "authorization.feedback",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    id: "formGroupExampleInput",
                                    cols: "30",
                                    rows: "5",
                                  },
                                  domProps: {
                                    value: _vm.authorization.feedback,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.authorization,
                                        "feedback",
                                        $event.target.value
                                      )
                                    },
                                  },
                                })
                              : _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.authorization.feedback,
                                      expression: "authorization.feedback",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    id: "formGroupExampleInput",
                                    cols: "30",
                                    rows: "5",
                                    readonly: "",
                                  },
                                  domProps: {
                                    value: _vm.authorization.feedback,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.authorization,
                                        "feedback",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group align-right" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "teros_principal__content__column__info__form__table__plus mb0",
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "/static/img/icons/disquete.png",
                                  alt: "Salvar",
                                },
                              }),
                              _vm._v(
                                _vm._s(_vm.$t("views.shared.saveButton")) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }