<template></template>

<script>
import Vue from "vue";
import axios from "axios";
import { http, router, cognito, storage } from "@/_helpers";

export default {
  data() {
    return {
      code: null,
      user: {},
    };
  },
  created() {
    let loading = Vue.$loading.show();
    this.code = this.$route.query.code;
    let headers = {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    };
    let body =
      "grant_type=authorization_code" +
      "&client_id=" +
      CLIENTID +
      "&redirect_uri=" +
      window.location.origin +
      "/samlredirect" +
      "&code=" +
      this.code;
    axios
      .post(COGNITO_DOMAIN + "/oauth2/token", body, headers)
      .then((response) => {
        if (response.status == 200) {
          let idToken = response.data.id_token;
          let refreshToken = response.data.refresh_token;
          storage.setItem("idToken", idToken);
          let userInfo = cognito.decodeToken(idToken);
          axios
            .get(APIURL + "/api/user/get/currentuser", http.headers())
            .then((response) => {
              this.user = response.data;
              storage.setItem("currentUser", JSON.stringify(this.user));
              cognito.setCurrentUser(this.user);
              loading.hide();
              router.push("/");
            })
            .catch(() => {
              userInfo.username = userInfo["cognito:username"];
              userInfo.phoneNumber = userInfo.phone_number;
              userInfo.SSOIssuerURN = userInfo.identities[0].providerName;
              http
                .post("/api/user/create/ssouser/", userInfo)
                .then((response) => {
                  this.user = response.data;
                  storage.setItem("currentUser", JSON.stringify(this.user));
                  cognito.setCurrentUser(this.user);
                  let body =
                    "grant_type=refresh_token" +
                    "&client_id=" +
                    CLIENTID +
                    "&refresh_token=" +
                    refreshToken;
                  axios
                    .post(COGNITO_DOMAIN + "/oauth2/token", body, headers)
                    .then((response) => {
                      loading.hide();
                      idToken = response.data.id_token;
                      storage.setItem("idToken", idToken);
                      router.push("/");
                    });
                });
            });
        } else {
          storage.clear();
          Vue.$toast.error(this.$i18n.t("views.login.error.sso"));
          loading.hide();
          router.push("/login");
        }
      })
      .catch(() => {
        storage.clear();
        Vue.$toast.error(this.$i18n.t("views.login.error.sso"));
        loading.hide();
        router.push("/login");
      });
  },
};
</script>