import VueI18n from 'vue-i18n';
import Vue from 'vue';
import axios from "axios";
import pt from '@/_locales/pt-BR.json';

axios.defaults.headers.common["Accept-Language"] = 'pt-BR';

Vue.use(VueI18n);
export const i18n = new VueI18n({
  locale: 'pt-BR',
  fallbackLocale: 'pt-BR',
  silentTranslationWarn: true,
  messages: { pt }
});
