var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "teros_principal" }, [
    _c("div", { staticClass: "teros_principal" }, [
      _c("div", { staticClass: "teros_principal__login" }, [
        _c(
          "div",
          {
            staticClass:
              "teros_principal__login__column teros_principal__login__column--fields",
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "teros_principal__login__column__form__highlights",
              },
              [
                _c(
                  "p",
                  {
                    staticClass:
                      "teros_principal__login__column__form__highlights__text",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("views.shared.titleSystem")) +
                        " "
                    ),
                    _c("br"),
                    _vm._v(" "),
                    _c("span", [_vm._v("Teros Open Banking")]),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("views.newpasswordrequired.title")) +
                        " "
                    ),
                    _c("br"),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.newPassword.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "teros_principal__login__column__form__fields",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("input-password", {
                          attrs: {
                            id: "newPassword1",
                            placeholder: _vm.$t(
                              "views.newpasswordrequired.newPassword"
                            ),
                            password: _vm.newPassword1,
                          },
                          on: {
                            onChange: function ($event) {
                              _vm.newPassword1 = $event
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("input-password", {
                          attrs: {
                            id: "newPassword2",
                            placeholder: _vm.$t(
                              "views.newpasswordrequired.confirmation"
                            ),
                            password: _vm.newPassword2,
                          },
                          on: {
                            onChange: function ($event) {
                              _vm.newPassword2 = $event
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "submit" },
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t("views.newpasswordrequired.changeButton")
                            ) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "center" }, [
      _c("img", {
        staticClass: "teros_principal__login__column__logo",
        attrs: { src: "/static/img/logo_login.png", alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }