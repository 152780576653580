var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "teros_principal__content__accordion__card mx0" },
    [
      _c(
        "div",
        {
          staticClass: "teros_principal__content__accordion__card__header",
          attrs: { id: "headingOne" },
        },
        [
          _c(
            "h2",
            {
              staticClass:
                "teros_principal__content__accordion__card__header__title",
            },
            [
              _c(
                "button",
                {
                  staticClass:
                    "teros_principal__content__accordion__card__header__title__btn",
                  attrs: {
                    type: "button",
                    "data-toggle": "collapse",
                    "data-target": "#collapseThree",
                    "aria-expanded": "true",
                    "aria-controls": "collapseThree",
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("views.authorizationForm.title")) +
                      "\n      "
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "teros_principal__content__accordion__card__header__collapse collapse show",
          attrs: { id: "collapseThree" },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "teros_principal__content__accordion__card__header__collapse__body",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "teros_principal__content__accordion__card__header__collapse__body__form",
                },
                [
                  _vm.showDcrType
                    ? _c("div", { staticClass: "form-group row" }, [
                        _c("div", { staticClass: "col" }, [
                          _c("label", { attrs: { for: "AuthorizationDCR" } }, [
                            _vm._v(
                              "Dynamic Client Registration (DCR)\n              "
                            ),
                            _c("img", {
                              attrs: {
                                src: "/static/img/icons/tooltip.png",
                                "data-toggle": "tooltip",
                                "data-placement": "right",
                                title: _vm.$t(
                                  "views.authorizationForm.toolTip.Dcr"
                                ),
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.number",
                                  value: _vm.dcrType,
                                  expression: "dcrType",
                                  modifiers: { number: true },
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { id: "AuthorizationDCR" },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return _vm._n(val)
                                    })
                                  _vm.dcrType = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                              },
                            },
                            [
                              _c("option", { attrs: { value: "1" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "views.authorizationForm.dcrType.selfManaged"
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "2" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "views.authorizationForm.dcrType.autoCreated"
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "3" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "views.authorizationForm.dcrType.openBankingBrazil"
                                    )
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group row" }, [
                    _c("div", { staticClass: "col" }, [
                      _c("label", { attrs: { for: "AuthorizationForm1" } }, [
                        _vm._v("Flow Type\n              "),
                        _c("img", {
                          attrs: {
                            src: "/static/img/icons/tooltip.png",
                            "data-toggle": "tooltip",
                            "data-placement": "right",
                            title: _vm.$t(
                              "views.authorizationForm.toolTip.FlowType"
                            ),
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.flowType,
                              expression: "flowType",
                              modifiers: { number: true },
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "AuthorizationForm1",
                            disabled: _vm.isDisabled,
                          },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return _vm._n(val)
                                })
                              _vm.flowType = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                          },
                        },
                        [
                          _vm.apiAuthentication.dynamicClientRegistrationType ==
                          1
                            ? _c("option", { attrs: { value: "1" } }, [
                                _vm._v("No Auth"),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.apiAuthentication.dynamicClientRegistrationType !=
                          3
                            ? _c("option", { attrs: { value: "2" } }, [
                                _vm._v("Client Credentials"),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.apiAuthentication.dynamicClientRegistrationType !=
                          3
                            ? _c("option", { attrs: { value: "3" } }, [
                                _vm._v("Authorization Code"),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.apiAuthentication.dynamicClientRegistrationType ==
                          1
                            ? _c("option", { attrs: { value: "5" } }, [
                                _vm._v("Password Grant"),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.apiAuthentication.dynamicClientRegistrationType ==
                          1
                            ? _c("option", { attrs: { value: "6" } }, [
                                _vm._v("Basic Auth"),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.apiAuthentication.dynamicClientRegistrationType ==
                          1
                            ? _c("option", { attrs: { value: "7" } }, [
                                _vm._v("Api Key"),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.apiAuthentication.dynamicClientRegistrationType ==
                          1
                            ? _c("option", { attrs: { value: "8" } }, [
                                _vm._v("Bearer Token"),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "9" } }, [
                            _vm._v("Financial-Grade API"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group row" }, [
                    _vm.apiAuthentication.authenticationFlowType == 2 ||
                    _vm.apiAuthentication.authenticationFlowType == 3 ||
                    _vm.apiAuthentication.authenticationFlowType == 9
                      ? _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c(
                              "label",
                              { attrs: { for: "AuthorizationForm2" } },
                              [
                                _vm._v(
                                  "Authorization Server URI*\n              "
                                ),
                                _c("img", {
                                  attrs: {
                                    src: "/static/img/icons/tooltip.png",
                                    "data-toggle": "tooltip",
                                    "data-placement": "right",
                                    title: _vm.$t(
                                      "views.authorizationForm.toolTip.AuthorizationServerURI"
                                    ),
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c("custom-input", {
                              attrs: {
                                id: "AuthorizationForm2",
                                regex: ".*",
                                maxlength: "200",
                                readonly: _vm.isDisabled,
                              },
                              model: {
                                value: _vm.apiAuthentication.serverUri,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.apiAuthentication,
                                    "serverUri",
                                    $$v
                                  )
                                },
                                expression: "apiAuthentication.serverUri",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group row" }, [
                    _vm.apiAuthentication.authenticationFlowType == 3 ||
                    _vm.apiAuthentication.authenticationFlowType == 4
                      ? _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c(
                              "label",
                              { attrs: { for: "AuthorizationForm3" } },
                              [
                                _vm._v(
                                  "Authorization Code Endpoint*\n              "
                                ),
                                _c("img", {
                                  attrs: {
                                    src: "/static/img/icons/tooltip.png",
                                    "data-toggle": "tooltip",
                                    "data-placement": "right",
                                    title: _vm.$t(
                                      "views.authorizationForm.toolTip.AuthorizationCodeEndpoint"
                                    ),
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c("custom-input", {
                              attrs: {
                                id: "AuthorizationForm3",
                                regex: ".*",
                                maxlength: "500",
                                readonly: _vm.isDisabled,
                              },
                              model: {
                                value:
                                  _vm.apiAuthentication.authorizationEndpoint,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.apiAuthentication,
                                    "authorizationEndpoint",
                                    $$v
                                  )
                                },
                                expression:
                                  "apiAuthentication.authorizationEndpoint",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.apiAuthentication.authenticationFlowType == 2 ||
                    _vm.apiAuthentication.authenticationFlowType == 3 ||
                    _vm.apiAuthentication.authenticationFlowType == 4 ||
                    _vm.apiAuthentication.authenticationFlowType == 5
                      ? _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c(
                              "label",
                              { attrs: { for: "AuthorizationForm4" } },
                              [
                                _vm._v("Token Endpoint*\n              "),
                                _c("img", {
                                  attrs: {
                                    src: "/static/img/icons/tooltip.png",
                                    "data-toggle": "tooltip",
                                    "data-placement": "right",
                                    title: _vm.$t(
                                      "views.authorizationForm.toolTip.TokenEndpoint"
                                    ),
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c("custom-input", {
                              attrs: {
                                id: "AuthorizationForm4",
                                regex: ".*",
                                maxlength: "500",
                                readonly: _vm.isDisabled,
                              },
                              model: {
                                value: _vm.apiAuthentication.tokenEndpoint,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.apiAuthentication,
                                    "tokenEndpoint",
                                    $$v
                                  )
                                },
                                expression: "apiAuthentication.tokenEndpoint",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group row" }, [
                    _vm.apiAuthentication.authenticationFlowType == 2 ||
                    _vm.apiAuthentication.authenticationFlowType == 3 ||
                    _vm.apiAuthentication.authenticationFlowType == 4 ||
                    _vm.apiAuthentication.authenticationFlowType == 5
                      ? _c("div", { staticClass: "col" }, [
                          _c(
                            "label",
                            { attrs: { for: "AuthorizationForm5" } },
                            [
                              _vm._v(
                                "Authentication Parameter Type*\n              "
                              ),
                              _c("img", {
                                attrs: {
                                  src: "/static/img/icons/tooltip.png",
                                  "data-toggle": "tooltip",
                                  "data-placement": "right",
                                  title: _vm.$t(
                                    "views.authorizationForm.toolTip.AuthenticationParameterType"
                                  ),
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.number",
                                  value:
                                    _vm.apiAuthentication
                                      .authenticationParameterType,
                                  expression:
                                    "apiAuthentication.authenticationParameterType",
                                  modifiers: { number: true },
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "AuthorizationForm5",
                                disabled: _vm.isDisabled,
                              },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return _vm._n(val)
                                    })
                                  _vm.$set(
                                    _vm.apiAuthentication,
                                    "authenticationParameterType",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            [
                              _c("option", { attrs: { value: "1" } }, [
                                _vm._v("Header"),
                              ]),
                              _vm._v(" "),
                              _vm.apiAuthentication.authenticationFlowType !=
                                6 &&
                              _vm.apiAuthentication.authenticationFlowType !=
                                7 &&
                              _vm.apiAuthentication.authenticationFlowType != 8
                                ? _c("option", { attrs: { value: "2" } }, [
                                    _vm._v(
                                      "\n                Body\n              "
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group row" }, [
                    _vm.apiAuthentication.authenticationFlowType == 2 ||
                    _vm.apiAuthentication.authenticationFlowType == 3 ||
                    _vm.apiAuthentication.authenticationFlowType == 4 ||
                    _vm.apiAuthentication.authenticationFlowType == 5 ||
                    _vm.apiAuthentication.authenticationFlowType == 9
                      ? _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c(
                              "label",
                              { attrs: { for: "AuthorizationForm6" } },
                              [
                                _vm._v("Client Id*\n              "),
                                _c("img", {
                                  attrs: {
                                    src: "/static/img/icons/tooltip.png",
                                    "data-toggle": "tooltip",
                                    "data-placement": "right",
                                    title: _vm.$t(
                                      "views.authorizationForm.toolTip.ClientId"
                                    ),
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c("custom-input", {
                              attrs: {
                                id: "AuthorizationForm6",
                                regex: ".*",
                                maxlength: "500",
                                readonly: _vm.isDisabled,
                              },
                              model: {
                                value: _vm.apiAuthentication.clientId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.apiAuthentication,
                                    "clientId",
                                    $$v
                                  )
                                },
                                expression: "apiAuthentication.clientId",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.apiAuthentication.authenticationFlowType == 2 ||
                    _vm.apiAuthentication.authenticationFlowType == 3 ||
                    _vm.apiAuthentication.authenticationFlowType == 5
                      ? _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c(
                              "label",
                              { attrs: { for: "AuthorizationForm7" } },
                              [
                                _vm._v("Client Secret*\n              "),
                                _c("img", {
                                  attrs: {
                                    src: "/static/img/icons/tooltip.png",
                                    "data-toggle": "tooltip",
                                    "data-placement": "right",
                                    title: _vm.$t(
                                      "views.authorizationForm.toolTip.ClientSecret"
                                    ),
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c("custom-input", {
                              attrs: {
                                id: "AuthorizationForm7",
                                regex: ".*",
                                maxlength: "1000",
                                readonly: _vm.isDisabled,
                              },
                              model: {
                                value: _vm.apiAuthentication.clientSecret,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.apiAuthentication,
                                    "clientSecret",
                                    $$v
                                  )
                                },
                                expression: "apiAuthentication.clientSecret",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _vm.apiAuthentication.authenticationFlowType == 5 ||
                  _vm.apiAuthentication.authenticationFlowType == 6
                    ? _c("div", { staticClass: "form-group row" }, [
                        _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c(
                              "label",
                              { attrs: { for: "AuthorizationForm8" } },
                              [
                                _vm._v("Username*\n              "),
                                _c("img", {
                                  attrs: {
                                    src: "/static/img/icons/tooltip.png",
                                    "data-toggle": "tooltip",
                                    "data-placement": "right",
                                    title: _vm.$t(
                                      "views.authorizationForm.toolTip.Username"
                                    ),
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c("custom-input", {
                              attrs: {
                                id: "AuthorizationForm8",
                                regex: ".*",
                                maxlength: "1000",
                              },
                              model: {
                                value: _vm.apiAuthentication.username,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.apiAuthentication,
                                    "username",
                                    $$v
                                  )
                                },
                                expression: "apiAuthentication.username",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c(
                              "label",
                              { attrs: { for: "AuthorizationForm9" } },
                              [
                                _vm._v("Password*\n              "),
                                _c("img", {
                                  attrs: {
                                    src: "/static/img/icons/tooltip.png",
                                    "data-toggle": "tooltip",
                                    "data-placement": "right",
                                    title: _vm.$t(
                                      "views.authorizationForm.toolTip.Password"
                                    ),
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c("custom-input", {
                              attrs: {
                                id: "AuthorizationForm9",
                                regex: ".*",
                                maxlength: "500",
                              },
                              model: {
                                value: _vm.apiAuthentication.password,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.apiAuthentication,
                                    "password",
                                    $$v
                                  )
                                },
                                expression: "apiAuthentication.password",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group row" }, [
                    _vm.apiAuthentication.authenticationFlowType != undefined &&
                    _vm.apiAuthentication.authenticationFlowType != 1 &&
                    _vm.apiAuthentication.authenticationFlowType != 9
                      ? _c("div", { staticClass: "col" }, [
                          _c(
                            "label",
                            { attrs: { for: "AuthorizationForm10" } },
                            [
                              _vm._v(
                                "Authorization Parameter Type*\n              "
                              ),
                              _c("img", {
                                attrs: {
                                  src: "/static/img/icons/tooltip.png",
                                  "data-toggle": "tooltip",
                                  "data-placement": "right",
                                  title: _vm.$t(
                                    "views.authorizationForm.toolTip.AuthorizationParameterType"
                                  ),
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.number",
                                  value:
                                    _vm.apiAuthentication
                                      .authorizationParameterType,
                                  expression:
                                    "apiAuthentication.authorizationParameterType",
                                  modifiers: { number: true },
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "AuthorizationForm10",
                                disabled: _vm.isDisabled,
                              },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return _vm._n(val)
                                    })
                                  _vm.$set(
                                    _vm.apiAuthentication,
                                    "authorizationParameterType",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            [
                              _c("option", { attrs: { value: "1" } }, [
                                _vm._v("Header"),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.apiAuthentication.authenticationFlowType == 8
                      ? _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c(
                              "label",
                              { attrs: { for: "AuthorizationForm11" } },
                              [
                                _vm._v("Bearer Token*\n              "),
                                _c("img", {
                                  attrs: {
                                    src: "/static/img/icons/tooltip.png",
                                    "data-toggle": "tooltip",
                                    "data-placement": "right",
                                    title: _vm.$t(
                                      "views.authorizationForm.toolTip.BearerToken"
                                    ),
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c("custom-input", {
                              attrs: {
                                id: "AuthorizationForm11",
                                regex: ".*",
                                maxlength: "2500",
                                readonly: _vm.isDisabled,
                              },
                              model: {
                                value: _vm.apiAuthentication.bearerToken,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.apiAuthentication,
                                    "bearerToken",
                                    $$v
                                  )
                                },
                                expression: "apiAuthentication.bearerToken",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.apiAuthentication.authenticationFlowType == 7
                      ? _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c(
                              "label",
                              { attrs: { for: "AuthorizationForm12" } },
                              [
                                _vm._v("APi Key Value*\n              "),
                                _c("img", {
                                  attrs: {
                                    src: "/static/img/icons/tooltip.png",
                                    "data-toggle": "tooltip",
                                    "data-placement": "right",
                                    title: _vm.$t(
                                      "views.authorizationForm.toolTip.APiKeyValue"
                                    ),
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c("custom-input", {
                              attrs: {
                                id: "AuthorizationForm12",
                                regex: ".*",
                                maxlength: "2500",
                                readonly: _vm.isDisabled,
                              },
                              model: {
                                value: _vm.apiAuthentication.apiKey,
                                callback: function ($$v) {
                                  _vm.$set(_vm.apiAuthentication, "apiKey", $$v)
                                },
                                expression: "apiAuthentication.apiKey",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group row" }, [
                    _vm.apiAuthentication.authenticationFlowType == 2 ||
                    _vm.apiAuthentication.authenticationFlowType == 3 ||
                    _vm.apiAuthentication.authenticationFlowType == 5
                      ? _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c(
                              "label",
                              { attrs: { for: "AuthorizationForm13" } },
                              [
                                _vm._v("Scope\n              "),
                                _c("img", {
                                  attrs: {
                                    src: "/static/img/icons/tooltip.png",
                                    "data-toggle": "tooltip",
                                    "data-placement": "right",
                                    title: _vm.$t(
                                      "views.authorizationForm.toolTip.Scope"
                                    ),
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c("custom-input", {
                              attrs: {
                                id: "AuthorizationForm13",
                                regex: ".*",
                                readonly: _vm.isDisabled,
                              },
                              model: {
                                value: _vm.apiAuthentication.scope,
                                callback: function ($$v) {
                                  _vm.$set(_vm.apiAuthentication, "scope", $$v)
                                },
                                expression: "apiAuthentication.scope",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.apiAuthentication.authenticationFlowType == 3 ||
                    _vm.apiAuthentication.authenticationFlowType == 4 ||
                    _vm.apiAuthentication.authenticationFlowType == 9
                      ? _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c(
                              "label",
                              { attrs: { for: "AuthorizationForm14" } },
                              [
                                _vm._v("Redirect Uri*\n              "),
                                _c("img", {
                                  attrs: {
                                    src: "/static/img/icons/tooltip.png",
                                    "data-toggle": "tooltip",
                                    "data-placement": "right",
                                    title: _vm.$t(
                                      "views.authorizationForm.toolTip.RedirectUri"
                                    ),
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c("custom-input", {
                              attrs: {
                                id: "AuthorizationForm14",
                                regex: ".*",
                                maxlength: "500",
                              },
                              model: {
                                value: _vm.apiAuthentication.redirectUri,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.apiAuthentication,
                                    "redirectUri",
                                    $$v
                                  )
                                },
                                expression: "apiAuthentication.redirectUri",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group row" }, [
                    _vm.apiAuthentication.authenticationFlowType != undefined &&
                    _vm.apiAuthentication.authenticationFlowType != 1 &&
                    _vm.apiAuthentication.authenticationFlowType != 5 &&
                    _vm.apiAuthentication.authenticationFlowType != 9
                      ? _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c(
                              "label",
                              { attrs: { for: "CustomHeaderField" } },
                              [
                                _vm._v(
                                  "\n              Custom Authorization Header\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm._l(_vm.customHeaders, function (item, counter) {
                              return _c("div", { key: counter }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: item.field,
                                      expression: "item.field",
                                    },
                                  ],
                                  staticClass:
                                    "form-control teros_principal__content__column__info__form__input",
                                  attrs: {
                                    id: "CustomHeaderField",
                                    type: "text",
                                    readonly: _vm.isDisabled,
                                  },
                                  domProps: { value: item.field },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        item,
                                        "field",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                counter == _vm.customHeaders.length - 1 &&
                                !_vm.isDisabled
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "action-fields",
                                        attrs: {
                                          href: "javascript:void(null)",
                                        },
                                        on: { click: _vm.addCustomHeader },
                                      },
                                      [_vm._v("+ Add Field\n              ")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.customHeaders.length > 1 && !_vm.isDisabled
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "action-fields",
                                        attrs: {
                                          href: "javascript:void(null)",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeCustomHeader(
                                              counter
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("- Remove Field\n              ")]
                                    )
                                  : _vm._e(),
                              ])
                            }),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.apiAuthentication.authenticationFlowType != undefined &&
                    _vm.apiAuthentication.authenticationFlowType != 1 &&
                    _vm.apiAuthentication.authenticationFlowType != 5 &&
                    _vm.apiAuthentication.authenticationFlowType != 9
                      ? _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c(
                              "label",
                              { attrs: { for: "CustomHeaderValue" } },
                              [_vm._v(" Value ")]
                            ),
                            _vm._v(" "),
                            _vm._l(_vm.customHeaders, function (item, counter) {
                              return _c("div", { key: counter }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: item.value,
                                      expression: "item.value",
                                    },
                                  ],
                                  staticClass:
                                    "form-control teros_principal__content__column__info__form__input margin-bottom-thirty-eight-pixels",
                                  attrs: {
                                    id: "CustomHeaderValue",
                                    type: "text",
                                    readonly: _vm.isDisabled,
                                  },
                                  domProps: { value: item.value },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        item,
                                        "value",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ])
                            }),
                          ],
                          2
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _vm.apiAuthentication.authenticationFlowType == 9
                    ? _c("div", { staticClass: "form-group row" }, [
                        _c("div", { staticClass: "col" }, [
                          _c(
                            "label",
                            { attrs: { for: "AuthorizationForm15" } },
                            [
                              _vm._v(
                                "Client Authentication Method*\n              "
                              ),
                              _c("img", {
                                attrs: {
                                  src: "/static/img/icons/tooltip.png",
                                  "data-toggle": "tooltip",
                                  "data-placement": "right",
                                  title: _vm.$t(
                                    "views.authorizationForm.toolTip.ClientAuthenticationMethod"
                                  ),
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.number",
                                  value:
                                    _vm.apiAuthentication
                                      .clientAuthenticationMethod,
                                  expression:
                                    "apiAuthentication.clientAuthenticationMethod",
                                  modifiers: { number: true },
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "AuthorizationForm15",
                                disabled: _vm.isDisabled,
                              },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return _vm._n(val)
                                    })
                                  _vm.$set(
                                    _vm.apiAuthentication,
                                    "clientAuthenticationMethod",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            [
                              _c("option", { attrs: { value: "1" } }, [
                                _vm._v("private_key_jwt"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "2" } }, [
                                _vm._v("tls_client_auth"),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col" }, [
                          _c(
                            "label",
                            { attrs: { for: "AuthorizationForm16" } },
                            [
                              _vm._v(
                                "Authorization Response Mode*\n              "
                              ),
                              _c("img", {
                                attrs: {
                                  src: "/static/img/icons/tooltip.png",
                                  "data-toggle": "tooltip",
                                  "data-placement": "right",
                                  title: _vm.$t(
                                    "views.authorizationForm.toolTip.AuthorizationResponseMode"
                                  ),
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.number",
                                  value:
                                    _vm.apiAuthentication
                                      .authorizationResponseMode,
                                  expression:
                                    "apiAuthentication.authorizationResponseMode",
                                  modifiers: { number: true },
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "AuthorizationForm16",
                                disabled: _vm.isDisabled,
                              },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return _vm._n(val)
                                    })
                                  _vm.$set(
                                    _vm.apiAuthentication,
                                    "authorizationResponseMode",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            [
                              _c("option", { attrs: { value: "2" } }, [
                                _vm._v("PLAIN / fragment"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "6" } }, [
                                _vm._v("JARM / fragment.jwt"),
                              ]),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.apiAuthentication.authenticationFlowType == 9
                    ? _c("div", { staticClass: "form-group row" }, [
                        _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c(
                              "label",
                              { attrs: { for: "AuthorizationForm17" } },
                              [
                                _vm._v(
                                  "Authentication Context Reference\n              "
                                ),
                                _c("img", {
                                  attrs: {
                                    src: "/static/img/icons/tooltip.png",
                                    "data-toggle": "tooltip",
                                    "data-placement": "right",
                                    title: _vm.$t(
                                      "views.authorizationForm.toolTip.AuthenticationContextReference"
                                    ),
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c("custom-input", {
                              attrs: {
                                id: "AuthorizationForm17",
                                regex: ".*",
                                maxlength: "200",
                                readonly: _vm.isDisabled,
                              },
                              model: {
                                value:
                                  _vm.apiAuthentication
                                    .authenticationContextReference,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.apiAuthentication,
                                    "authenticationContextReference",
                                    $$v
                                  )
                                },
                                expression:
                                  "apiAuthentication.authenticationContextReference",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.apiAuthentication.authenticationFlowType == 9
                    ? _c("div", { staticClass: "form-group row" })
                    : _vm._e(),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }