<template>
  <div class="teros_principal__content interna container">
    <div class="teros_principal__content__line-1 line">
      <div class="teros_principal__content__line-1__highlights">
        <div class="teros_principal__content__line-1__highlights__subject">
          <div
            class="teros_principal__content__line-1__highlights__subject__breadcrumb"
          >
            <h3
              class="teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle"
            >
              {{ $t("views.shared.menu.authorizations") }}
            </h3>
            <h2
              class="teros_principal__content__line-1__highlights__subject__breadcrumb__title active"
            >
              {{ authorization.customerName }}
            </h2>
          </div>
          <div class="teros_principal__arrow"></div>
          <div
            class="teros_principal__content__line-1__highlights__subject__breadcrumb"
          >
            <h3
              class="teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle"
            >
              {{ $t("views.teros.authorizations.authorization.title") }}
            </h3>
            <h2
              class="teros_principal__content__line-1__highlights__subject__breadcrumb__title active"
            >
              {{ authorization.integrationName }}
            </h2>
          </div>
        </div>
      </div>
      <div class="teros_principal__content__line-1__information">
        <div class="teros_principal__content__line-1__information__time">
          <p class="teros_principal__content__line-1__information__time__text">
            <img
              class="teros_principal__content__line-1__information__time__text__icon"
              src="/static/img/icons/relogio.png"
              alt=""
            />
            {{
              $t("views.shared.createdDate", {
                date: creationDate,
                time: creationTime,
              })
            }}
            {{
              lastUpdateDate
                ? "| " +
                  $t("views.shared.changedDate", {
                    date: lastUpdateDate,
                    time: lastUpdateTime,
                  })
                : ""
            }}
          </p>

          <button
            type="button"
            @click="goBack"
            class="teros_principal__content__line-1__information__time__btn"
          >
            <img src="/static/img/icons/seta-esquerda.png" alt="" />
            {{ $t("views.shared.backButton") }}
          </button>
        </div>
      </div>
    </div>

    <div id="accordion">
      <div class="teros_principal__content__accordion__card">
        <div
          class="teros_principal__content__accordion__card__header"
          id="headingOne"
        >
          <h2 class="teros_principal__content__accordion__card__header__title">
            <button
              class="teros_principal__content__accordion__card__header__title__btn"
              type="button"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              {{
                $t(
                  "views.teros.authorizations.authorization.content.general.title"
                )
              }}
            </button>
          </h2>
        </div>

        <div
          id="collapseOne"
          class="teros_principal__content__accordion__card__header__collapse"
        >
          <div
            class="teros_principal__content__accordion__card__header__collapse__body"
          >
            <form class="teros_principal__content__column__info">
              <div class="form-group">
                <label for="formGroupExampleInput1"
                  >{{
                    $t(
                      "views.teros.authorizations.authorization.content.general.client"
                    )
                  }}
                  <img
                    src="/static/img/icons/tooltip.png"
                    data-toggle="tooltip"
                    data-placement="right"
                    :title="
                      $t(
                        'views.teros.authorizations.authorization.toolTips.general.customer'
                      )
                    "
                /></label>
                <input
                  type="text"
                  readonly
                  class="form-control"
                  id="formGroupExampleInput1"
                  v-model="authorization.customerName"
                />
              </div>

              <div class="form-group row">
                <div class="col">
                  <label for="formGroupExampleInput2"
                    >{{
                      $t(
                        "views.teros.authorizations.authorization.content.general.reception"
                      )
                    }}
                    <img
                      src="/static/img/icons/tooltip.png"
                      data-toggle="tooltip"
                      data-placement="right"
                      :title="
                        $t(
                          'views.teros.authorizations.authorization.toolTips.general.reception'
                        )
                      "
                  /></label>
                  <input
                    type="text"
                    readonly
                    class="form-control"
                    id="formGroupExampleInput2"
                    v-model="authorization.integrationName"
                  />
                </div>

                <div class="col">
                  <label for="formGroupExampleInput3">{{
                    $t(
                      "views.teros.authorizations.authorization.content.general.services"
                    )
                  }}</label>
                  <input
                    type="text"
                    readonly
                    class="form-control"
                    id="formGroupExampleInput3"
                    v-model="authorization.serviceName"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="teros_principal__content__accordion__card">
        <div
          class="teros_principal__content__accordion__card__header"
          id="headingOne"
        >
          <h2 class="teros_principal__content__accordion__card__header__title">
            <button
              class="teros_principal__content__accordion__card__header__title__btn"
              type="button"
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              {{
                $t(
                  "views.teros.authorizations.authorization.content.changesReview.title"
                )
              }}
            </button>
          </h2>
        </div>

        <div
          id="collapseTwo"
          class="teros_principal__content__accordion__card__header__collapse collapse"
        >
          <div
            class="teros_principal__content__accordion__card__header__collapse__body"
          >
            <form
              @submit.prevent="saveChanges"
              class="teros_principal__content__accordion__card__header__collapse__body__form"
              method="post"
            >
              <div class="teros_principal__content__table">
                <div class="teros_principal__content__table__header">
                  <div class="teros_principal__content__table__header__filters">
                    <div class="form-group">
                      <div class="search-group">
                        <input
                          type="text"
                          class="input-field"
                          :placeholder="
                            $t('views.shared.searchBar.searchLabel')
                          "
                          maxlength="30"
                          onkeydown="return event.keyCode == 13 ? event.preventDefault() : true"
                          v-model="textSearchValue"
                        />
                        <button
                          @click="makeTextSearch()"
                          type="button"
                          class="search-button"
                        />
                      </div>
                    </div>

                    <itens-per-page
                      :request-pagination="requestPagination"
                      :callback="makeTextSearch"
                    ></itens-per-page>
                  </div>

                  <div class="teros_principal__content__table__header__actions">
                    <!--<a href="#" title="Download"
                      ><img src="/static/img/icons/download.png" alt="Download"
                    /></a>
                    <a href="#" title="Imprimir"
                      ><img src="/static/img/icons/printer.png" alt="Imprimir"
                    /></a> -->
                  </div>
                </div>

                <div class="teros_principal__content__table__body">
                  <table
                    id="table"
                    class="teros_principal__content__table__body__table"
                    data-toggle="table"
                    data-height="600"
                    data-url=""
                  >
                    <thead>
                      <tr>
                        <th data-field="id" data-sortable="true">ID</th>
                        <th data-field="a">
                          {{
                            $t(
                              "views.teros.authorizations.authorization.content.changesReview.table.header.clause/fine"
                            )
                          }}
                        </th>
                        <th data-field="k">
                          {{
                            $t(
                              "views.teros.authorizations.authorization.content.changesReview.table.header.clause/fineOrigin"
                            )
                          }}
                        </th>
                        <th data-field="d">
                          {{
                            $t(
                              "views.teros.authorizations.authorization.content.changesReview.table.header.includeDate"
                            )
                          }}
                        </th>
                        <th data-field="e">
                          {{
                            $t(
                              "views.teros.authorizations.authorization.content.changesReview.table.header.changedAt"
                            )
                          }}
                        </th>
                        <th data-field="f">Status</th>
                        <th data-field="h" style="text-align: center">
                          {{
                            $t(
                              "views.teros.authorizations.authorization.content.changesReview.table.header.options"
                            )
                          }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in authorization
                          .servicePartnershipTerms.items"
                        :key="item.hash"
                      >
                        <td>{{ index + 1 }}</td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.servicePartnershipTermOriginName }}</td>
                        <td>{{ item.creationDate | formatDate }}</td>
                        <td>{{ item.lastUpdateDate | formatDate }}</td>
                        <td :class="item.authorizationStatus | formatClass">
                          {{ statusTranslated(item.authorizationStatus) }}
                        </td>

                        <td style="text-align: center">
                          <div
                            class="teros_principal__content__table__body__table__options"
                          >
                            <router-link
                              :to="
                                '/authorizationterm?authorizationHash=' +
                                authorization.hash +
                                '&hash=' +
                                item.hash
                              "
                              class="teros_principal__content__table__body__table__options__item"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Visualizar/Editar"
                            >
                              <div>
                                <img
                                  src="/static/img/icons/editar.svg"
                                  alt=""
                                />
                              </div>
                            </router-link>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="teros_principal__content__table__footer">
                  <div
                    v-if="loading"
                    class="teros_principal__content__table__footer__loader"
                  >
                    <img src="/static/img/icons/ajax-loader.gif" alt="" />
                    <span>{{ $t("views.shared.loadingResults") }}</span>
                  </div>

                  <pagination
                    :request-pagination="requestPagination"
                    :callback="makeTextSearch"
                    :current-pagination-info="currentPaginationInfo"
                  ></pagination>
                </div>
              </div>

              <br />
              <hr />
              <br />

              <div class="form-group row">
                <div class="col">
                  <label for="formGroupExampleInput"
                    >{{
                      $t(
                        "views.teros.authorizations.authorization.content.changesReview.viewConsentLabel"
                      )
                    }}
                    <img
                      src="/static/img/icons/tooltip.png"
                      data-toggle="tooltip"
                      data-placement="right"
                      :title="
                        $t(
                          'views.teros.authorizations.authorization.toolTips.changeReview.seeConsent'
                        )
                      "
                  /></label>
                  <br />
                  <pdf-view
                    :file-name="authorization.consentimentFileName"
                    :type="'button'"
                    :title="
                      $t(
                        'views.teros.authorizations.authorization.content.changesReview.viewConsentButton'
                      )
                    "
                  ></pdf-view>
                </div>
              </div>
              <br />
              <div class="form-group row">
                <div class="col">
                  <br />
                  <label for="formGroupExampleInput"
                    >{{
                      $t(
                        "views.teros.authorizations.authorization.content.changesReview.complianceStandard"
                      )
                    }}
                    <img
                      src="/static/img/icons/tooltip.png"
                      data-toggle="tooltip"
                      data-placement="right"
                      :title="
                        $t(
                          'views.teros.authorizations.authorization.toolTips.changeReview.compliancePattern'
                        )
                      "
                  /></label>
                  <div class="form-group">
                    <select
                      v-if="enabled"
                      class="form-control"
                      v-model="authorization.standardHash"
                      :disabled="standards == null || standards.length < 1"
                    >
                      <option
                        v-for="item in standards"
                        :value="item.hash"
                        :key="item.hash"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                    <select
                      v-else
                      class="form-control"
                      disabled
                      v-model="authorization.standardHash"
                    >
                      <option
                        v-for="item in standards"
                        :value="item.hash"
                        :key="item.hash"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col">
                  <br />
                  <label for="formGroupExampleInput"
                    >Status
                    <img
                      src="/static/img/icons/tooltip.png"
                      data-toggle="tooltip"
                      data-placement="right"
                      :title="
                        $t(
                          'views.teros.authorizations.authorization.toolTips.changeReview.status'
                        )
                      "
                  /></label>
                  <select
                    v-if="enabled"
                    class="form-control"
                    v-model="authorization.authorizationStatus"
                  >
                    <option value="2">
                      {{
                        $t(
                          "views.teros.authorizations.authorization.content.changesReview.status.pending"
                        )
                      }}
                    </option>
                    <option value="1">
                      {{
                        $t(
                          "views.teros.authorizations.authorization.content.changesReview.status.compatible"
                        )
                      }}
                    </option>
                    <option value="3">
                      {{
                        $t(
                          "views.teros.authorizations.authorization.content.changesReview.status.notCompatible"
                        )
                      }}
                    </option>
                  </select>
                  <select
                    v-else
                    class="form-control"
                    disabled
                    v-model="authorization.authorizationStatus"
                  >
                    <option value="2">
                      {{
                        $t(
                          "views.teros.authorizations.authorization.content.changesReview.status.pending"
                        )
                      }}
                    </option>
                    <option value="1">
                      {{
                        $t(
                          "views.teros.authorizations.authorization.content.changesReview.status.compatible"
                        )
                      }}
                    </option>
                    <option value="3">
                      {{
                        $t(
                          "views.teros.authorizations.authorization.content.changesReview.status.notCompatible"
                        )
                      }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group row">
                <div class="col">
                  <label for="formGroupExampleInput">{{
                    $t(
                      "views.teros.authorizations.authorization.content.changesReview.seem"
                    )
                  }}</label>
                  <textarea
                    v-if="enabled"
                    id="formGroupExampleInput"
                    cols="30"
                    rows="5"
                    class="form-control"
                    v-model="authorization.feedback"
                  ></textarea>
                  <textarea
                    v-else
                    id="formGroupExampleInput"
                    cols="30"
                    rows="5"
                    class="form-control"
                    readonly
                    v-model="authorization.feedback"
                  ></textarea>
                </div>
              </div>

              <div class="form-group align-right">
                <button
                  class="teros_principal__content__column__info__form__table__plus mb0"
                >
                  <img src="/static/img/icons/disquete.png" alt="Salvar" />{{
                $t("views.shared.saveButton")
              }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http, router } from "@/_helpers";
import PdfView from "@/_components/PdfView.vue";
import Pagination from "@/_components/Pagination.vue";
import ItensPerPage from "@/_components/ItensPerPage.vue";
import moment from 'moment';

export default {
  data() {
    return {
      authorization: { servicePartnershipTerms: {}, status: 0 },
      enabled: true,
      standards: [],
      loading: true,
      textSearchValue: "",
      oldTextSearchValue: "",
      currentPaginationInfo: null,
      requestPagination: { page: 1, take: 10 },
    };
  },
  components: {
    PdfView,
    Pagination,
    ItensPerPage,
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
    formatClass(value) {
      if (value == 1) {
        return "compativel";
      } else if (value == 2) {
        return "pendente";
      } else if (value == 3) {
        return "nao-compativel";
      }
      return "";
    },
  },
  computed: {
    creationDate() {
      if (this.authorization.creationDate) {
        return moment(String(this.authorization.creationDate)).format(
          "DD/MM/YYYY"
        );
      }
      return "";
    },
    creationTime() {
      if (this.authorization.creationDate) {
        return moment(String(this.authorization.creationDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("HH:mm");
      }
      return "";
    },
    lastUpdateDate() {
      if (this.authorization.lastUpdateDate) {
        return moment(String(this.authorization.lastUpdateDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
    lastUpdateTime() {
      if (this.authorization.lastUpdateDate) {
        return moment(String(this.authorization.lastUpdateDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("HH:mm");
      }
      return "";
    },
  },
  mounted() {
    this.$emit("message", this.$route);
    const hash = this.$route.query.hash;
    http
      .get("/api/authorization/get/" + hash)
      .then((response) => {
        this.authorization = response.data;
        if (this.authorization.authorizationStatus != 2) {
          this.enabled = false;
        }
        this.currentPaginationInfo = response.data.servicePartnershipTerms;

        http
          .get(
            "/api/service/list/standards/" + this.authorization.serviceHash,
            false
          )
          .then((response) => {
            this.standards = response.data;
          });
      })
      .finally(() => (this.loading = false));
  },
  methods: {
    goBack() {
      router.push({ name: "authorizationspage" });
    },
    saveChanges() {
      if (this.authorization.authorizationStatus == 2) {
        http.put("/api/authorization/update/", this.authorization).then(() => {
          this.goBack();
          this.$emit("success");
        });
      } else {
        if (this.authorization.authorizationStatus == 1) {
          this.authorization.authorize = true;
        } else {
          this.authorization.authorize = false;
        }
        http
          .put("/api/authorization/authorize/", this.authorization)
          .then(() => {
            this.goBack();
            this.$emit("success");
          });
      }
    },
    statusTranslated(value) {
      if (value == 1) {
        return this.$i18n.t(
          "views.teros.authorizations.authorization.content.changesReview.status.compatible"
        );
      } else if (value == 2) {
        return this.$i18n.t(
          "views.teros.authorizations.authorization.content.changesReview.status.pending"
        );
      } else if (value == 3) {
        return this.$i18n.t(
          "views.teros.authorizations.authorization.content.changesReview.status.notCompatible"
        );
      } else {
        return "";
      }
    },
    makeTextSearch(event, isChangePage = false) {
      this.CheckValuesChanged(isChangePage);

      if (this.textSearchValue.trim()) {
        http
          .get(
            `/api/authorization/get/${
              this.authorization.hash
            }?FilterProperty=Name&FilterValue=${this.textSearchValue.trim()}&Page=${
              this.requestPagination.page
            }&Take=${this.requestPagination.take}`
          )
          .then((response) => {
            this.authorization = response.data;
            this.currentPaginationInfo = response.data.servicePartnershipTerms;
          })
          .finally(() => (this.loading = false));
      } else {
        http
          .get(
            `/api/authorization/get/${this.authorization.hash}?Page=${this.requestPagination.page}&Take=${this.requestPagination.take}`
          )
          .then((response) => {
            this.authorization = response.data;
            this.currentPaginationInfo = response.data.servicePartnershipTerms;
          })
          .finally(() => (this.loading = false));
      }
    },
    CheckValuesChanged(isChangePage = false) {
      if (this.textSearchValue != this.oldTextSearchValue) {
        if (isChangePage) {
          this.textSearchValue = this.oldTextSearchValue;
        } else {
          this.requestPagination.page = 1;
          this.oldTextSearchValue = this.textSearchValue;
        }
      }
    },
  },
};
</script>