var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "teros_principal__content interna container" },
    [
      _c("div", { staticClass: "teros_principal__content__line-1" }, [
        _c(
          "div",
          { staticClass: "teros_principal__content__line-1__highlights" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__line-1__highlights__subject",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__line-1__highlights__subject__breadcrumb",
                  },
                  [
                    _c("router-link", { attrs: { to: "/transmission" } }, [
                      _c(
                        "h3",
                        {
                          staticClass:
                            "teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t(
                                  "views.client.transmission.general.client"
                                )
                              ) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "h2",
                        {
                          staticClass:
                            "teros_principal__content__line-1__highlights__subject__breadcrumb__title",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.currentUser.customerName) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "teros_principal__content__line-1__information" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__line-1__information__time",
              },
              [
                _c(
                  "p",
                  {
                    staticClass:
                      "teros_principal__content__line-1__information__time__text",
                  },
                  [
                    _c("img", {
                      staticClass:
                        "teros_principal__content__line-1__information__time__text__icon",
                      attrs: { src: "/static/img/icons/relogio.png", alt: "" },
                    }),
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("views.shared.createdDate", {
                            date: _vm.creationDate,
                            time: _vm.creationTime,
                          })
                        ) +
                        "\n          " +
                        _vm._s(
                          _vm.lastUpdateDate
                            ? "| " +
                                _vm.$t("views.shared.changedDate", {
                                  date: _vm.lastUpdateDate,
                                  time: _vm.lastUpdateTime,
                                })
                            : ""
                        ) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "teros_principal__content__line-1__information__time__btn",
                    attrs: { type: "button" },
                    on: { click: _vm.goBack },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "/static/img/icons/seta-esquerda.png",
                        alt: "",
                      },
                    }),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("views.shared.backButton")) +
                        "\n        "
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "teros_principal__content__column" }, [
        _c(
          "div",
          { staticClass: "teros_principal__content__column__menu-vertical" },
          [
            _c(
              "ul",
              {
                staticClass:
                  "teros_principal__content__column__menu-vertical__list",
              },
              [
                _c(
                  "li",
                  {
                    staticClass:
                      "teros_principal__content__column__menu-vertical__list__item",
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--lupa",
                        attrs: { to: "/transmission" },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("views.client.transmission.general.tabTitle")
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass:
                      "teros_principal__content__column__menu-vertical__list__item",
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--servicos",
                        attrs: { to: "/transmissionservices" },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "views.client.transmission.transmissionservices.tabTitle"
                            )
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass:
                      "teros_principal__content__column__menu-vertical__list__item",
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--compliance",
                        attrs: { to: "/transmissionconsentiments" },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "views.client.transmission.transmissionconsentiments.tabTitle"
                            )
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass:
                      "teros_principal__content__column__menu-vertical__list__item",
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass:
                          "teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--servicos active",
                        attrs: { href: "#" },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "views.client.transmission.transmissionapis.tabTitle"
                            )
                          )
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass:
                      "teros_principal__content__column__menu-vertical__list__item",
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--registros",
                        attrs: { to: "/transmissionregistries" },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "views.client.transmission.transmissionregistries.tabTitle"
                            )
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass:
                      "teros_principal__content__column__menu-vertical__list__item",
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--gestao",
                        attrs: { to: "/transmissionlogs" },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "views.client.transmission.transmissionlogs.tabTitle"
                            )
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "teros_principal__content__column__info" }, [
          _c(
            "div",
            { attrs: { id: "accordion" } },
            [
              _vm._l(_vm.apis, function (item, index) {
                return _c(
                  "div",
                  {
                    key: item.hash,
                    staticClass:
                      "teros_principal__content__accordion__card mx0",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "teros_principal__content__accordion__card__header",
                        attrs: { id: "headingOne" },
                      },
                      [
                        _c(
                          "h2",
                          {
                            staticClass:
                              "teros_principal__content__accordion__card__header__title",
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "teros_principal__content__accordion__card__header__title__btn",
                                attrs: {
                                  type: "button",
                                  "data-toggle": "collapse",
                                  "data-target": "#collapse" + index,
                                  "aria-expanded": "false",
                                  "aria-controls": "collapse" + index,
                                },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(item.apiName) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "teros_principal__content__accordion__card__header__collapse collapse",
                        attrs: { id: "collapse" + index },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "teros_principal__content__accordion__card__header__collapse__body",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "teros_principal__content__accordion__card__header__collapse__body__form",
                              },
                              [
                                _c(
                                  "form",
                                  {
                                    attrs: { method: "post" },
                                    on: {
                                      submit: function ($event) {
                                        $event.preventDefault()
                                        return _vm.update(index)
                                      },
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "form-group" }, [
                                      _c(
                                        "label",
                                        {
                                          attrs: {
                                            for: "healthCheckURL" + item.hash,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "views.client.transmission.transmissionapis.content.healthCheckUrl"
                                              )
                                            ) + "\n                      "
                                          ),
                                          _c("img", {
                                            attrs: {
                                              src: "/static/img/icons/tooltip.png",
                                              "data-toggle": "tooltip",
                                              "data-placement": "right",
                                              title: _vm.$t(
                                                "views.client.transmission.transmissionapis.toolTip.healthCheckUrl"
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: item.healthCheckURL,
                                            expression: "item.healthCheckURL",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          id: "healthCheckURL" + item.hash,
                                        },
                                        domProps: {
                                          value: item.healthCheckURL,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              item,
                                              "healthCheckURL",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "teros_principal__content__table__body",
                                      },
                                      [
                                        _c(
                                          "table",
                                          {
                                            staticClass:
                                              "teros_principal__content__table__body__table",
                                            attrs: {
                                              id: "table",
                                              "data-toggle": "table",
                                              "data-height": "600",
                                              "data-url": "",
                                            },
                                          },
                                          [
                                            _c("thead", [
                                              _c("tr", [
                                                _c(
                                                  "th",
                                                  {
                                                    attrs: {
                                                      "data-field": "id",
                                                      "data-sortable": "true",
                                                    },
                                                  },
                                                  [_vm._v("ID")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "th",
                                                  {
                                                    attrs: {
                                                      "data-field": "a",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                            " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "views.client.transmission.transmissionapis.content.table.header.serviceName"
                                                          )
                                                        ) +
                                                        "\n                          "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "th",
                                                  {
                                                    attrs: {
                                                      "data-field": "b",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                            " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "views.client.transmission.transmissionapis.content.table.header.endpointUrl"
                                                          )
                                                        ) +
                                                        "\n                          "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "th",
                                                  {
                                                    attrs: {
                                                      "data-field": "c",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                            " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "views.client.transmission.transmissionapis.content.table.header.isExternal"
                                                          )
                                                        ) +
                                                        "\n                          "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "th",
                                                  {
                                                    attrs: {
                                                      "data-field": "d",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                            " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "views.client.transmission.transmissionapis.content.table.header.isInternal"
                                                          )
                                                        ) +
                                                        "\n                          "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "th",
                                                  {
                                                    attrs: {
                                                      "data-field": "e",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                            " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "views.client.transmission.transmissionapis.content.hasOutage"
                                                          )
                                                        ) +
                                                        "\n                          "
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "tbody",
                                              _vm._l(
                                                item.endpoints,
                                                function (endpoint, index) {
                                                  return _c(
                                                    "tr",
                                                    { key: endpoint.hash },
                                                    [
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(index + 1)
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            endpoint.serviceName
                                                          )
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            endpoint.endpointUrl
                                                          )
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            "text-align":
                                                              "center",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                            " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "formatBool"
                                                                )(
                                                                  endpoint.isExternal
                                                                )
                                                              ) +
                                                              "\n                          "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            "text-align":
                                                              "center",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                            " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "formatBool"
                                                                )(
                                                                  endpoint.isInternal
                                                                )
                                                              ) +
                                                              "\n                          "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      item.hasOutage
                                                        ? _c(
                                                            "td",
                                                            {
                                                              staticStyle: {
                                                                "text-align":
                                                                  "center",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "custom-control custom-switch",
                                                                  attrs: {
                                                                    "data-toggle":
                                                                      "tooltip",
                                                                    "data-placement":
                                                                      "top",
                                                                    title:
                                                                      "Desativar/Ativar",
                                                                  },
                                                                },
                                                                [
                                                                  _c("input", {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            endpoint.hasOutage,
                                                                          expression:
                                                                            "endpoint.hasOutage",
                                                                        },
                                                                      ],
                                                                    staticClass:
                                                                      "custom-control-input",
                                                                    attrs: {
                                                                      type: "checkbox",
                                                                      id:
                                                                        "customSwitch" +
                                                                        index +
                                                                        item.hash,
                                                                      disabled:
                                                                        !item
                                                                          .apiOutage
                                                                          .isPartial,
                                                                    },
                                                                    domProps: {
                                                                      checked:
                                                                        Array.isArray(
                                                                          endpoint.hasOutage
                                                                        )
                                                                          ? _vm._i(
                                                                              endpoint.hasOutage,
                                                                              null
                                                                            ) >
                                                                            -1
                                                                          : endpoint.hasOutage,
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          var $$a =
                                                                              endpoint.hasOutage,
                                                                            $$el =
                                                                              $event.target,
                                                                            $$c =
                                                                              $$el.checked
                                                                                ? true
                                                                                : false
                                                                          if (
                                                                            Array.isArray(
                                                                              $$a
                                                                            )
                                                                          ) {
                                                                            var $$v =
                                                                                null,
                                                                              $$i =
                                                                                _vm._i(
                                                                                  $$a,
                                                                                  $$v
                                                                                )
                                                                            if (
                                                                              $$el.checked
                                                                            ) {
                                                                              $$i <
                                                                                0 &&
                                                                                _vm.$set(
                                                                                  endpoint,
                                                                                  "hasOutage",
                                                                                  $$a.concat(
                                                                                    [
                                                                                      $$v,
                                                                                    ]
                                                                                  )
                                                                                )
                                                                            } else {
                                                                              $$i >
                                                                                -1 &&
                                                                                _vm.$set(
                                                                                  endpoint,
                                                                                  "hasOutage",
                                                                                  $$a
                                                                                    .slice(
                                                                                      0,
                                                                                      $$i
                                                                                    )
                                                                                    .concat(
                                                                                      $$a.slice(
                                                                                        $$i +
                                                                                          1
                                                                                      )
                                                                                    )
                                                                                )
                                                                            }
                                                                          } else {
                                                                            _vm.$set(
                                                                              endpoint,
                                                                              "hasOutage",
                                                                              $$c
                                                                            )
                                                                          }
                                                                        },
                                                                    },
                                                                  }),
                                                                  _vm._v(" "),
                                                                  _c("label", {
                                                                    staticClass:
                                                                      "custom-control-label",
                                                                    attrs: {
                                                                      for:
                                                                        "customSwitch" +
                                                                        index +
                                                                        item.hash,
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "teros_principal__content__accordion__card",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "teros_principal__content__accordion__card__header",
                                            attrs: { id: "headingSecond" },
                                          },
                                          [
                                            _c(
                                              "h2",
                                              {
                                                staticClass:
                                                  "teros_principal__content__accordion__card__header__title",
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "teros_principal__content__accordion__card__header__title__btn",
                                                    attrs: {
                                                      type: "button",
                                                      "data-toggle": "collapse",
                                                      "data-target":
                                                        "#outage" + index,
                                                      "aria-expanded": "false",
                                                      "aria-controls":
                                                        "outage" + index,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                          " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "views.client.transmission.transmissionapis.content.outage"
                                                          )
                                                        ) +
                                                        "\n                        "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("hr"),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "teros_principal__content__accordion__card__header__collapse",
                                            attrs: { id: "outage" + index },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "teros_principal__content__accordion__card__header__collapse__body pt0",
                                                staticStyle: {
                                                  padding: "0 40px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "teros_principal__content__table",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group row",
                                                        staticStyle: {
                                                          "padding-top": "40px",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "col",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "custom-control custom-switch",
                                                              },
                                                              [
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        item.hasOutage,
                                                                      expression:
                                                                        "item.hasOutage",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "custom-control-input",
                                                                  attrs: {
                                                                    type: "checkbox",
                                                                    id:
                                                                      "hasOutage" +
                                                                      index,
                                                                  },
                                                                  domProps: {
                                                                    checked:
                                                                      Array.isArray(
                                                                        item.hasOutage
                                                                      )
                                                                        ? _vm._i(
                                                                            item.hasOutage,
                                                                            null
                                                                          ) > -1
                                                                        : item.hasOutage,
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        var $$a =
                                                                            item.hasOutage,
                                                                          $$el =
                                                                            $event.target,
                                                                          $$c =
                                                                            $$el.checked
                                                                              ? true
                                                                              : false
                                                                        if (
                                                                          Array.isArray(
                                                                            $$a
                                                                          )
                                                                        ) {
                                                                          var $$v =
                                                                              null,
                                                                            $$i =
                                                                              _vm._i(
                                                                                $$a,
                                                                                $$v
                                                                              )
                                                                          if (
                                                                            $$el.checked
                                                                          ) {
                                                                            $$i <
                                                                              0 &&
                                                                              _vm.$set(
                                                                                item,
                                                                                "hasOutage",
                                                                                $$a.concat(
                                                                                  [
                                                                                    $$v,
                                                                                  ]
                                                                                )
                                                                              )
                                                                          } else {
                                                                            $$i >
                                                                              -1 &&
                                                                              _vm.$set(
                                                                                item,
                                                                                "hasOutage",
                                                                                $$a
                                                                                  .slice(
                                                                                    0,
                                                                                    $$i
                                                                                  )
                                                                                  .concat(
                                                                                    $$a.slice(
                                                                                      $$i +
                                                                                        1
                                                                                    )
                                                                                  )
                                                                              )
                                                                          }
                                                                        } else {
                                                                          _vm.$set(
                                                                            item,
                                                                            "hasOutage",
                                                                            $$c
                                                                          )
                                                                        }
                                                                      },
                                                                  },
                                                                }),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    staticClass:
                                                                      "custom-control-label",
                                                                    attrs: {
                                                                      for:
                                                                        "hasOutage" +
                                                                        index,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "views.client.transmission.transmissionapis.content.outage"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    item.hasOutage
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-group row",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col",
                                                              },
                                                              [
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    attrs: {
                                                                      for: "explanation",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "views.client.transmission.transmissionapis.content.explanation"
                                                                        )
                                                                      ) +
                                                                        "\n                                "
                                                                    ),
                                                                    _c("img", {
                                                                      attrs: {
                                                                        src: "/static/img/icons/tooltip.png",
                                                                        "data-toggle":
                                                                          "tooltip",
                                                                        "data-placement":
                                                                          "right",
                                                                        title:
                                                                          _vm.$t(
                                                                            "views.client.transmission.transmissionapis.toolTip.explanation"
                                                                          ),
                                                                      },
                                                                    }),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "custom-input",
                                                                  {
                                                                    attrs: {
                                                                      id:
                                                                        "explanation" +
                                                                        index,
                                                                      regex:
                                                                        ".*",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        item
                                                                          .apiOutage
                                                                          .explanation,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            item.apiOutage,
                                                                            "explanation",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "item.apiOutage.explanation",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    item.hasOutage
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-group row",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col",
                                                              },
                                                              [
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    attrs: {
                                                                      for: "startDate",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "views.client.transmission.transmissionapis.content.startDate"
                                                                        )
                                                                      ) +
                                                                        "\n                                "
                                                                    ),
                                                                    _c("img", {
                                                                      attrs: {
                                                                        src: "/static/img/icons/tooltip.png",
                                                                        "data-toggle":
                                                                          "tooltip",
                                                                        "data-placement":
                                                                          "right",
                                                                        title:
                                                                          _vm.$t(
                                                                            "views.client.transmission.transmissionapis.toolTip.startDate"
                                                                          ),
                                                                      },
                                                                    }),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        item
                                                                          .apiOutage
                                                                          .startDate,
                                                                      expression:
                                                                        "item.apiOutage.startDate",
                                                                    },
                                                                  ],
                                                                  attrs: {
                                                                    id:
                                                                      "startDate" +
                                                                      index,
                                                                    type: "datetime-local",
                                                                  },
                                                                  domProps: {
                                                                    value:
                                                                      item
                                                                        .apiOutage
                                                                        .startDate,
                                                                  },
                                                                  on: {
                                                                    input:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          $event
                                                                            .target
                                                                            .composing
                                                                        )
                                                                          return
                                                                        _vm.$set(
                                                                          item.apiOutage,
                                                                          "startDate",
                                                                          $event
                                                                            .target
                                                                            .value
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "label",
                                                                  {
                                                                    attrs: {
                                                                      for: "endDate",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "views.client.transmission.transmissionapis.content.endDate"
                                                                        )
                                                                      ) +
                                                                        "\n                                "
                                                                    ),
                                                                    _c("img", {
                                                                      attrs: {
                                                                        src: "/static/img/icons/tooltip.png",
                                                                        "data-toggle":
                                                                          "tooltip",
                                                                        "data-placement":
                                                                          "right",
                                                                        title:
                                                                          _vm.$t(
                                                                            "views.client.transmission.transmissionapis.toolTip.endDate"
                                                                          ),
                                                                      },
                                                                    }),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        item
                                                                          .apiOutage
                                                                          .endDate,
                                                                      expression:
                                                                        "item.apiOutage.endDate",
                                                                    },
                                                                  ],
                                                                  attrs: {
                                                                    id:
                                                                      "endDate" +
                                                                      index,
                                                                    type: "datetime-local",
                                                                    min: item
                                                                      .apiOutage
                                                                      .startDate,
                                                                  },
                                                                  domProps: {
                                                                    value:
                                                                      item
                                                                        .apiOutage
                                                                        .endDate,
                                                                  },
                                                                  on: {
                                                                    input:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          $event
                                                                            .target
                                                                            .composing
                                                                        )
                                                                          return
                                                                        _vm.$set(
                                                                          item.apiOutage,
                                                                          "endDate",
                                                                          $event
                                                                            .target
                                                                            .value
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    item.hasOutage
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-group row",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "custom-control custom-switch",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "input",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "model",
                                                                              rawName:
                                                                                "v-model",
                                                                              value:
                                                                                item
                                                                                  .apiOutage
                                                                                  .isPartial,
                                                                              expression:
                                                                                "item.apiOutage.isPartial",
                                                                            },
                                                                          ],
                                                                        staticClass:
                                                                          "custom-control-input",
                                                                        attrs: {
                                                                          type: "checkbox",
                                                                          id:
                                                                            "isPartial" +
                                                                            index,
                                                                        },
                                                                        domProps:
                                                                          {
                                                                            checked:
                                                                              Array.isArray(
                                                                                item
                                                                                  .apiOutage
                                                                                  .isPartial
                                                                              )
                                                                                ? _vm._i(
                                                                                    item
                                                                                      .apiOutage
                                                                                      .isPartial,
                                                                                    null
                                                                                  ) >
                                                                                  -1
                                                                                : item
                                                                                    .apiOutage
                                                                                    .isPartial,
                                                                          },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              var $$a =
                                                                                  item
                                                                                    .apiOutage
                                                                                    .isPartial,
                                                                                $$el =
                                                                                  $event.target,
                                                                                $$c =
                                                                                  $$el.checked
                                                                                    ? true
                                                                                    : false
                                                                              if (
                                                                                Array.isArray(
                                                                                  $$a
                                                                                )
                                                                              ) {
                                                                                var $$v =
                                                                                    null,
                                                                                  $$i =
                                                                                    _vm._i(
                                                                                      $$a,
                                                                                      $$v
                                                                                    )
                                                                                if (
                                                                                  $$el.checked
                                                                                ) {
                                                                                  $$i <
                                                                                    0 &&
                                                                                    _vm.$set(
                                                                                      item.apiOutage,
                                                                                      "isPartial",
                                                                                      $$a.concat(
                                                                                        [
                                                                                          $$v,
                                                                                        ]
                                                                                      )
                                                                                    )
                                                                                } else {
                                                                                  $$i >
                                                                                    -1 &&
                                                                                    _vm.$set(
                                                                                      item.apiOutage,
                                                                                      "isPartial",
                                                                                      $$a
                                                                                        .slice(
                                                                                          0,
                                                                                          $$i
                                                                                        )
                                                                                        .concat(
                                                                                          $$a.slice(
                                                                                            $$i +
                                                                                              1
                                                                                          )
                                                                                        )
                                                                                    )
                                                                                }
                                                                              } else {
                                                                                _vm.$set(
                                                                                  item.apiOutage,
                                                                                  "isPartial",
                                                                                  $$c
                                                                                )
                                                                              }
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        staticClass:
                                                                          "custom-control-label",
                                                                        attrs: {
                                                                          for:
                                                                            "isPartial" +
                                                                            index,
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "views.client.transmission.transmissionapis.content.isPartial"
                                                                            )
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "font-size":
                                                                          "13px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "views.client.transmission.transmissionapis.content.yourTimeZoneIs"
                                                                          )
                                                                        ) +
                                                                        ": "
                                                                    ),
                                                                    _c("b", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.localTimeZone
                                                                        )
                                                                      ),
                                                                    ]),
                                                                    _vm._v(
                                                                      ".\n                                " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "views.client.transmission.transmissionapis.content.outageTimeZoneMessage"
                                                                          )
                                                                        ) +
                                                                        ".\n                              "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "form-group align-right" },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "teros_principal__content__column__info__form__table__plus mb0",
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: "/static/img/icons/disquete.png",
                                                alt: "Salvar",
                                              },
                                            }),
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "views.shared.saveButton"
                                                )
                                              ) + "\n                    "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                )
              }),
              _vm._v(" "),
              _vm.loading
                ? _c(
                    "div",
                    {
                      staticClass:
                        "teros_principal__content__table__footer__loader",
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: "/static/img/icons/ajax-loader.gif",
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("views.shared.loadingResults"))),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            2
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }