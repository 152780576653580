<template>
  <div class="teros_principal__content interna container">
    <div class="teros_principal__content__line-1 line">
      <div class="teros_principal__content__line-1__highlights">
        <div class="teros_principal__content__line-1__highlights__subject">
          <router-link :to="'/services?hash=' + service.integrationHash">
            <div
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb
              "
            >
              <h3
                class="
                  teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle
                "
              >
                {{ $t("views.shared.menu.integrations") }}
              </h3>
              <h2
                class="
                  teros_principal__content__line-1__highlights__subject__breadcrumb__title
                "
              >
                {{ service.integrationName }}
              </h2>
            </div>
          </router-link>
          <div class="teros_principal__arrow"></div>
          <router-link :to="'/service?hash=' + service.hash">
            <div
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb
              "
            >
              <h3
                class="
                  teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle
                "
              >
                {{ $t("views.teros.integrations.services.tabTitle") }}
              </h3>
              <h2
                class="
                  teros_principal__content__line-1__highlights__subject__breadcrumb__title
                "
              >
                {{ service.name }}
              </h2>
            </div>
          </router-link>
          <div class="teros_principal__arrow"></div>
          <div
            class="
              teros_principal__content__line-1__highlights__subject__breadcrumb
            "
          >
            <h3
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle
              "
            >
              {{ $t("views.teros.integrations.compliance.title") }}
            </h3>
            <h2
              v-if="newObj"
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__title
                active
              "
            >
              {{ $t("views.teros.integrations.compliance.newObject") }}
            </h2>
            <h2
              v-else
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__title
                active
              "
            >
              {{ standard.name }}
            </h2>
          </div>
        </div>
      </div>
      <div class="teros_principal__content__line-1__information">
        <div class="teros_principal__content__line-1__information__time">
          <button
            type="button"
            @click="goBack"
            class="teros_principal__content__line-1__information__time__btn"
          >
            <img src="/static/img/icons/seta-esquerda.png" alt="" />
            {{ $t("views.shared.backButton") }}
          </button>
        </div>
      </div>
    </div>

    <div class="teros_principal__content__accordion__description">
      <p
        v-if="!newObj"
        class="teros_principal__content__line-1__information__time__text"
      >
        <img
          class="
            teros_principal__content__line-1__information__time__text__icon
          "
          src="/static/img/icons/relogio.png"
          alt=""
        />
        {{
          $t("views.shared.createdDate", {
            date: creationDate,
            time: creationTime,
          })
        }}
        {{
          lastUpdateDate
            ? "| " +
              $t("views.shared.changedDate", {
                date: lastUpdateDate,
                time: lastUpdateTime,
              })
            : ""
        }}
      </p>
    </div>

    <div id="accordion">
      <div class="teros_principal__content__accordion__card">
        <div
          class="teros_principal__content__accordion__card__header"
          id="headingOne"
        >
          <h2 class="teros_principal__content__accordion__card__header__title">
            <button
              class="
                teros_principal__content__accordion__card__header__title__btn
              "
              type="button"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              {{
                $t("views.teros.integrations.compliance.content.general.title")
              }}
            </button>
            <hr />
          </h2>
        </div>

        <div
          id="collapseOne"
          class="teros_principal__content__accordion__card__header__collapse"
        >
          <div
            class="
              teros_principal__content__accordion__card__header__collapse__body
            "
          >
            <form
              @submit.prevent="createOrUpdate"
              class="teros_principal__content__column__info"
              method="post"
            >
              <div v-if="!newObj" class="form-group">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="customSwitch"
                    v-model="standard.serviceStandardActive"
                  />
                  <label class="custom-control-label" for="customSwitch">{{
                    $t(
                      "views.shared.activeSwitch"
                    )
                  }}</label>
                </div>
              </div>

              <div class="form-group row">
                <div class="col">
                  <label for="formGroupExampleInput"
                    >{{
                      $t(
                        "views.teros.integrations.compliance.content.general.complianceStandard"
                      )
                    }}*</label
                  >
                  <input
                    type="text"
                    required
                    class="form-control"
                    id="formGroupExampleInput"
                    v-model="standard.name"
                  />
                </div>
              </div>

              <div class="form-group row">
                <div class="col">
                  <label for="formGroupExampleInput3">{{
                    $t(
                      "views.teros.integrations.compliance.content.general.description"
                    )
                  }}</label>
                  <textarea
                    id="formGroupExampleInput3"
                    cols="30"
                    rows="5"
                    v-model="standard.description"
                    class="form-control"
                  ></textarea>
                </div>
              </div>

              <div class="form-group row" v-if="!newObj">
                <div class="col">
                  <a
                    href="javascript:void(null)"
                    @click="showOrHideTiny"
                    style="width: 270px"
                    class="
                      show_tiny
                      teros_principal__content__column__info__form__combo__btn-contrato
                    "
                  >
                    {{
                      $t(
                        "views.teros.integrations.compliance.content.general.editLayoutButton"
                      )
                    }}
                  </a>
                </div>
              </div>
              <div v-if="showTiny && !newObj" class="form-group row">
                <div class="col">
                  <editor
                    api-key="no-api-key"
                    v-model="standard.consentimentLayout"
                  />
                </div>
              </div>

              <div class="form-group align-right">
                <button
                  class="
                    teros_principal__content__column__info__form__table__plus
                    mb0
                  "
                >
                  <img src="/static/img/icons/disquete.png" alt="Salvar" />{{
                    $t("views.shared.saveButton")
                  }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div v-if="!newObj" class="teros_principal__content__accordion__card">
        <div
          class="teros_principal__content__accordion__card__header"
          id="headingTwo"
        >
          <h2 class="teros_principal__content__accordion__card__header__title">
            <button
              class="
                teros_principal__content__accordion__card__header__title__btn
              "
              type="button"
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              {{
                $t("views.teros.integrations.compliance.content.terms.title")
              }}
            </button>
            <hr />
          </h2>
        </div>

        <div
          id="collapseTwo"
          class="teros_principal__content__accordion__card__header__collapse"
        >
          <div
            class="
              teros_principal__content__accordion__card__header__collapse__body
              pt0
            "
            style="padding: 0 40px"
          >
            <div class="teros_principal__content__table">
              <div class="teros_principal__content__table__header">
                <div class="teros_principal__content__table__header__filters">
                  <div class="form-group">
                    <form @submit.prevent="makeTextSearch">
                      <div class="search-group">
                        <input
                          type="text"
                          class="input-field"
                          placeholder="Buscar"
                          maxlength="30"
                          v-model="textSearchValue"
                        />
                        <button class="search-button" />
                      </div>
                    </form>
                  </div>

                  <itens-per-page
                    :request-pagination="requestPagination"
                    :callback="makeTextSearch"
                  ></itens-per-page>
                </div>

                <div class="teros_principal__content__table__header__actions">
                  <!--<a href="#" title="Download"
                    ><img src="/static/img/icons/download.png" alt="Download"
                  /></a>
                  <a href="#" title="Imprimir"
                    ><img src="/static/img/icons/printer.png" alt="Imprimir"
                  /></a> -->
                </div>
              </div>

              <div class="teros_principal__content__table__body">
                <table
                  id="table"
                  class="teros_principal__content__table__body__table"
                  data-toggle="table"
                  data-height="600"
                  data-url=""
                >
                  <thead>
                    <tr>
                      <th data-field="id" data-sortable="true">
                        {{
                          $t(
                            "views.teros.integrations.compliance.content.terms.header.ordering"
                          )
                        }}
                      </th>
                      <th data-field="a">
                        {{
                          $t(
                            "views.teros.integrations.compliance.content.terms.header.name"
                          )
                        }}
                      </th>
                      <th data-field="b">
                        {{
                          $t(
                            "views.teros.integrations.compliance.content.terms.header.serviceType"
                          )
                        }}
                      </th>
                      <th data-field="d">
                        {{
                          $t(
                            "views.shared.table.header.createdAt"
                          )
                        }}
                      </th>
                      <th data-field="e">
                        {{
                          $t(
                            "views.shared.table.header.changedAt"
                          )
                        }}
                      </th>
                      <th data-field="f" style="text-align: center">
                        {{
                          $t(
                            "views.shared.table.header.options"
                          )
                        }}
                      </th>
                      <th data-field="g" style="text-align: center">
                        {{
                          $t(
                            "views.shared.table.header.deactivateActivate"
                          )
                        }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in terms" :key="item.hash">
                      <td>{{ item.orderNumber }}</td>
                      <td>{{ item.name }}</td>
                      <td>{{ service.name }}</td>
                      <td>{{ item.creationDate | formatDate }}</td>
                      <td>{{ item.lastUpdateDate | formatDate }}</td>

                      <td style="width: 100px; text-align: center">
                        <div
                          class="
                            teros_principal__content__table__body__table__options
                          "
                        >
                          <router-link
                            :to="
                              '/term?serviceHash=' +
                              service.hash +
                              '&standardHash=' +
                              standard.hash +
                              '&hash=' +
                              item.hash
                            "
                            class="
                              teros_principal__content__table__body__table__options__item
                            "
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Visualizar/Editar"
                          >
                            <div>
                              <img src="/static/img/icons/editar.svg" alt="" />
                            </div>
                          </router-link>

                          <a
                            @click="prepareRemove(item.hash, index)"
                            class="
                              teros_principal__content__table__body__table__options__item
                            "
                            data-toggle="modal"
                            data-target="#deleteModal"
                            data-placement="top"
                            title="Deletar"
                          >
                            <div>
                              <img src="/static/img/icons/deletar.svg" alt="" />
                            </div>
                          </a>
                        </div>
                      </td>
                      <td style="text-align: center">
                        <div class="custom-control custom-switch">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            :id="'customSwitch' + index"
                            v-model="item.serviceStandardTermActive"
                            @change="changeStatus(item.hash)"
                          />
                          <label
                            class="custom-control-label"
                            :for="'customSwitch' + index"
                          ></label>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="teros_principal__content__table__footer">
                <div
                  v-if="loading"
                  class="teros_principal__content__table__footer__loader"
                >
                  <img src="/static/img/icons/ajax-loader.gif" alt="" />
                  <span>{{ $t("views.shared.loadingResults") }}</span>
                </div>

                <pagination
                  :request-pagination="requestPagination"
                  :callback="makeTextSearch"
                  :current-pagination-info="currentPaginationInfo"
                ></pagination>
                <div
                  class="teros_principal__content__table__footer__pagination"
                >
                  <router-link
                    :to="
                      '/term?serviceHash=' +
                      service.hash +
                      '&standardHash=' +
                      standard.hash
                    "
                    title="Novo Termo"
                    class="teros_principal__content__table__footer__add-item"
                  >
                    <img src="/static/img/icons/plus.png" alt="" />{{
                      $t("views.shared.includeButton")
                    }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="block-out" v-if="!newObj">
        <div class="form-group row">
          <div>
            <div class="col">
              <button
                type="button"
                class="
                  teros_principal__content__column__info__form__combo__btn-contrato
                "
                style="width: 270px"
                @click="getfileUrl"
              >
                {{
                  $t(
                    "views.client.reception.receptionservice.content.compliance.consentButtons.viewConsent"
                  )
                }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade teros-modal"
      id="deleteModal"
      tabindex="-1"
      aria-labelledby="deleteModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteModalLabel">
              <img src="/static/img/icons/atencao-modal-red.png" alt="" />
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            {{ $t("views.shared.modal.delete.confirmMessage") }}
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              {{ $t("views.shared.modal.delete.cancelButton") }}
            </button>
            <button
              type="button"
              class="btn btn-primary btn-primary--red"
              data-dismiss="modal"
              @click="remove"
            >
              {{ $t("views.shared.modal.delete.deleteButton") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { http, router } from "@/_helpers";
import Pagination from "@/_components/Pagination.vue";
import ItensPerPage from "@/_components/ItensPerPage.vue";
import Editor from "@tinymce/tinymce-vue";
import moment from 'moment';

export default {
  data() {
    return {
      newObj: true,
      service: {},
      standard: {},
      serviceStandard: {},
      terms: [],
      termHash: null,
      index: 0,
      loading: true,
      textSearchValue: "",
      oldTextSearchValue: "",
      currentPaginationInfo: null,
      requestPagination: { page: 1, take: 10 },
      showTiny: false,
    };
  },
  components: {
    Pagination,
    ItensPerPage,
    Editor,
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
  },
  computed: {
    creationDate() {
      if (this.standard.creationDate) {
        return moment(String(this.standard.creationDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
    creationTime() {
      if (this.standard.creationDate) {
        return moment(String(this.standard.creationDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("HH:mm");
      }
      return "";
    },
    lastUpdateDate() {
      if (this.standard.lastUpdateDate) {
        return moment(String(this.standard.lastUpdateDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
    lastUpdateTime() {
      if (this.standard.lastUpdateDate) {
        return moment(String(this.standard.lastUpdateDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("HH:mm");
      }
      return "";
    },
  },
  mounted() {
    this.$emit("message", this.$route);
    const hash = this.$route.query.hash;
    const serviceHash = this.$route.query.serviceHash;
    http.get("/api/service/get/" + serviceHash).then((response) => {
      this.service = response.data;
    });
    if (hash) {
      this.newObj = false;
      http
        .get(
          "/api/service/get/standard?serviceHash=" +
            serviceHash +
            "&standardHash=" +
            hash
        )
        .then((response) => {
          this.standard = response.data;
        });
      http
        .get(
          "/api/service/search/terms?serviceHash=" +
            serviceHash +
            "&standardHash=" +
            hash +
            "&OrderByProperty=OrderNumber",
          false
        )
        .then((response) => {
          this.terms = response.data.items;
          this.currentPaginationInfo = response.data;
        })
        .finally(() => (this.loading = false));
    }
  },
  methods: {
    goBack() {
      router.push({
        name: "servicepage",
        query: { hash: this.service.hash },
      });
    },
    createOrUpdate() {
      if (this.newObj) {
        http.post("/api/standard/create/", this.standard).then((response) => {
          this.standard = response.data;
          this.newObj = false;
          this.loading = false;
          this.serviceStandard.servicehash = this.service.hash;
          this.serviceStandard.standardhash = this.standard.hash;
          this.serviceStandard.consentimentlayout =
            "<p>{{Terms}}</p><p>{{Purposes}}</p><p>{{ExpirationDate}}</p>";
          this.serviceStandard.active = true;
          http
            .post("/api/service/add/standard", this.serviceStandard)
            .then((response) => {
              this.standard.serviceStandardActive = response.data.active;
              this.standard.consentimentLayout =
                response.data.consentimentLayout;
              router.push({
                name: "compliancepage",
                query: {
                  hash: this.standard.hash,
                  serviceHash: this.service.hash,
                },
              });
              this.$emit("success");
            });
        });
      } else {
        http.put("/api/standard/update/", this.standard).then(() => {
          this.serviceStandard.servicehash = this.service.hash;
          this.serviceStandard.standardhash = this.standard.hash;
          this.serviceStandard.consentimentlayout =
            this.standard.consentimentLayout;
          this.serviceStandard.active = this.standard.serviceStandardActive;
          http
            .put("/api/service/update/standard", this.serviceStandard)
            .then(() => {
              this.goBack();
              this.$emit("success");
            });
        });
      }
    },
    changeStatus(hash) {
      let serviceHash = this.service.hash;
      let standardHash = this.standard.hash;
      http
        .get(
          "/api/service/get/term?serviceHash=" +
            serviceHash +
            "&standardHash=" +
            standardHash +
            "&termHash=" +
            hash
        )
        .then((response) => {
          let term = response.data;
          let active = !term.serviceStandardTermActive;
          http
            .put(
              "/api/service/update/term?serviceHash=" +
                serviceHash +
                "&standardHash=" +
                standardHash +
                "&termHash=" +
                hash +
                "&active=" +
                active +
                "&orderNumber=" +
                term.serviceStandardTermOrderNumber
            )
            .then(() => {
              if (active) {
                Vue.$toast.success(
                  this.$i18n.t(
                    "views.teros.integrations.compliance.content.terms.modal.activatedMessage"
                  )
                );
              } else {
                Vue.$toast.success(
                  this.$i18n.t(
                    "views.teros.integrations.compliance.content.terms.modal.deactivatedMessage"
                  )
                );
              }
            });
        });
    },
    prepareRemove(hash, index) {
      this.termHash = hash;
      this.index = index;
    },
    remove() {
      http
        .remove(
          "/api/service/delete/term?serviceHash=" +
            this.service.hash +
            "&standardHash=" +
            this.standard.hash +
            "&termHash=" +
            this.termHash
        )
        .then(() => {
          Vue.$toast.success(
            this.$i18n.t(
              "views.teros.integrations.compliance.content.terms.modal.deletedMessage"
            )
          );
          Vue.delete(this.terms, this.index);
        });
    },
    makeTextSearch(event, isChangePage = false) {
      this.CheckValuesChanged(isChangePage);

      if (this.textSearchValue.trim()) {
        http
          .get(
            `/api/service/search/terms?serviceHash=${
              this.service.hash
            }&standardHash=${
              this.standard.hash
            }&FilterProperty=Name&FilterValue=${this.textSearchValue.trim()}&Page=${
              this.requestPagination.page
            }&Take=${this.requestPagination.take}`
          )
          .then((response) => {
            this.terms = response.data.items;
            this.currentPaginationInfo = response.data;
          })
          .finally(() => (this.loading = false));
      } else {
        http
          .get(
            `/api/service/search/terms?serviceHash=${this.service.hash}&standardHash=${this.standard.hash}&Page=${this.requestPagination.page}&Take=${this.requestPagination.take}`
          )
          .then((response) => {
            this.terms = response.data.items;
            this.currentPaginationInfo = response.data;
          })
          .finally(() => (this.loading = false));
      }
    },
    CheckValuesChanged(isChangePage = false) {
      if (this.textSearchValue != this.oldTextSearchValue) {
        if (isChangePage) {
          this.textSearchValue = this.oldTextSearchValue;
        } else {
          this.requestPagination.page = 1;
          this.oldTextSearchValue = this.textSearchValue;
        }
      }
    },
    showOrHideTiny() {
      this.showTiny = !this.showTiny;
    },
    getfileUrl() {
      http
        .get(
          `/api/service/get/consentiment/service/${this.service.hash}/standard/${this.standard.hash}`
        )
        .then((response) => {
          if (response.data) {
            router.push({
              name: "pdfviewpage",
              params: { fileUrl: response.data },
            });
          } else {
            Vue.$toast.info(
              this.$i18n.t("views.pdfView.component.noPdfMessage")
            );
          }
        });
    },
  },
};
</script>
