<template>
  <div class="form-group">
    <select @change="changeTake()" class="form-control" v-model="pageSize">
      <option v-for="item in pageSizeValues" :value="item" :key="item">
        {{ item }}
      </option>
    </select>
    <span class="select-result">{{
      $t("views.shared.searchBar.resultsLabel")
    }}</span>
  </div>
</template>

<script>
export default {
  name: "itensPerPage",
  props: {
    requestPagination: Object,
    callback: Function,
  },
  data() {
    return {
      pageSizeValues: [10, 20, 30, 40, 50],
      pageSize: 10,
    };
  },
  methods: {
    changeTake() {
      this.requestPagination.take = this.pageSize;
      this.requestPagination.page = 1;
      this.callback(undefined, true);
    },
  },
};
</script>