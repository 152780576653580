var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "teros_principal__content interna container" },
    [
      _c("div", { staticClass: "teros_principal__content__line-1 line" }, [
        _c(
          "div",
          { staticClass: "teros_principal__content__line-1__highlights" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__line-1__highlights__subject",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__line-1__highlights__subject__breadcrumb",
                  },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/receptionlistconsentiments" } },
                      [
                        _c(
                          "h3",
                          {
                            staticClass:
                              "teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t("views.client.reception.list.client")
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "h2",
                          {
                            staticClass:
                              "teros_principal__content__line-1__highlights__subject__breadcrumb__title",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.currentUser.customerName) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "teros_principal__arrow" }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__line-1__highlights__subject__breadcrumb",
                  },
                  [
                    _c(
                      "h3",
                      {
                        staticClass:
                          "teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "views.client.reception.receptionlistgenericterms.title"
                              )
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.newObj
                      ? _c(
                          "h2",
                          {
                            staticClass:
                              "teros_principal__content__line-1__highlights__subject__breadcrumb__title active",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "views.client.reception.receptionlistgenericterms.newObject"
                                  )
                                ) +
                                "\n          "
                            ),
                          ]
                        )
                      : _c(
                          "h2",
                          {
                            staticClass:
                              "teros_principal__content__line-1__highlights__subject__breadcrumb__title active",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.receptionlistconsentiment.name) +
                                "\n          "
                            ),
                          ]
                        ),
                  ]
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "teros_principal__content__line-1__information" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__line-1__information__time",
              },
              [
                _c(
                  "button",
                  {
                    staticClass:
                      "teros_principal__content__line-1__information__time__btn",
                    attrs: { type: "button" },
                    on: { click: _vm.goBack },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "/static/img/icons/seta-esquerda.png",
                        alt: "",
                      },
                    }),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("views.shared.backButton")) +
                        "\n        "
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "teros_principal__content__column" }, [
        _c("div", { staticClass: "teros_principal__content__column__info" }, [
          _c("div", { attrs: { id: "accordion" } }, [
            _c(
              "form",
              {
                attrs: { method: "post" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.createOrUpdate.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "teros_principal__content__accordion__card" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "teros_principal__content__accordion__card__header",
                        attrs: { id: "headingTwo" },
                      },
                      [
                        _c(
                          "h2",
                          {
                            staticClass:
                              "teros_principal__content__accordion__card__header__title",
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "teros_principal__content__accordion__card__header__title__btn",
                                attrs: {
                                  type: "button",
                                  "data-toggle": "collapse",
                                  "data-target": "#collapseTwo",
                                  "aria-expanded": "false",
                                  "aria-controls": "collapseTwo",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t(
                                        "views.client.reception.receptionlistgenericterms.general.title"
                                      )
                                    ) +
                                    "\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("hr"),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "teros_principal__content__accordion__card__header__collapse",
                        attrs: { id: "collapseTwo" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "teros_principal__content__accordion__card__header__collapse__body pt0",
                            staticStyle: { padding: "0 40px" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "teros_principal__content__table",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "form-group",
                                    staticStyle: { "padding-top": "40px" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "custom-control custom-switch",
                                      },
                                      [
                                        _c("input", {
                                          staticClass: "custom-control-input",
                                          attrs: {
                                            type: "checkbox",
                                            checked: "",
                                            id: "customSwitch1",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          {
                                            staticClass: "custom-control-label",
                                            attrs: { for: "customSwitch1" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "views.shared.activeSwitch"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "form-group row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          attrs: {
                                            for: "formGroupExampleInput3",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "views.client.reception.receptionlistgenericterms.general.content.termName"
                                              )
                                            ) + "*"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("custom-input", {
                                        attrs: { id: "formGroupExampleInput3" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  !_vm.newObj
                                    ? _c("div", { staticClass: "col" }, [
                                        _c(
                                          "label",
                                          {
                                            attrs: {
                                              for: "formGroupExampleInput4",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "ReceptionConsentimentHash\n                    "
                                            ),
                                            _c("img", {
                                              attrs: {
                                                src: "/static/img/icons/tooltip.png",
                                                "data-toggle": "tooltip",
                                                "data-placement": "right",
                                                title: _vm.$t(
                                                  "views.client.reception.receptionconsentiment.toolTip.ReceptionConsentHash"
                                                ),
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("input", {
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            id: "formGroupExampleInput4",
                                            readonly: "",
                                          },
                                        }),
                                      ])
                                    : _vm._e(),
                                ]),
                                _vm._v(" "),
                                _c("br"),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "teros_principal__content__accordion__card" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "teros_principal__content__accordion__card__header",
                        attrs: { id: "headingThree" },
                      },
                      [
                        _c(
                          "h2",
                          {
                            staticClass:
                              "teros_principal__content__accordion__card__header__title",
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "teros_principal__content__accordion__card__header__title__btn",
                                attrs: {
                                  type: "button",
                                  "data-toggle": "collapse",
                                  "data-target": "#collapseThree",
                                  "aria-expanded": "false",
                                  "aria-controls": "collapseThree",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t(
                                        "views.client.reception.receptionlistconsentiment.consent.title"
                                      )
                                    ) +
                                    "\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("hr"),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "teros_principal__content__accordion__card__header__collapse",
                        attrs: { id: "collapseThree" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "teros_principal__content__accordion__card__header__collapse__body pt0",
                            staticStyle: { padding: "0 40px" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "teros_principal__content__table",
                              },
                              [
                                _vm._m(0),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "form-group row",
                                    staticStyle: { "padding-top": "40px" },
                                  },
                                  [
                                    _c("div", { staticClass: "col" }, [
                                      _c(
                                        "label",
                                        {
                                          attrs: {
                                            for: "formGroupExampleInput5",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                          " +
                                              _vm._s(
                                                _vm.$t(
                                                  "views.client.reception.receptionlistconsentiment.consent.content.dataEscrowLimit"
                                                )
                                              ) +
                                              "*\n                          "
                                          ),
                                          _c("img", {
                                            attrs: {
                                              src: "/static/img/icons/tooltip.png",
                                              "data-toggle": "tooltip",
                                              "data-placement": "right",
                                              title: _vm.$t(
                                                "views.client.reception.receptionlistconsentiment.toolTip.dataEscrowLimit"
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "input-group" },
                                        [
                                          _c("custom-input", {
                                            staticStyle: {
                                              width: "60%",
                                              "margin-right": "5px",
                                            },
                                            attrs: {
                                              id: "formGroupExampleInput5",
                                              type: "number",
                                              regex: "[0-9]",
                                            },
                                            model: {
                                              value:
                                                _vm.receptionlistconsentiment
                                                  .dataEscrowLimit,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.receptionlistconsentiment,
                                                  "dataEscrowLimit",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "receptionlistconsentiment.dataEscrowLimit",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.unit,
                                                  expression: "unit",
                                                },
                                              ],
                                              staticClass: "custom-select",
                                              staticStyle: {
                                                width: "30%",
                                                "border-radius": "3px",
                                              },
                                              on: {
                                                change: [
                                                  function ($event) {
                                                    var $$selectedVal =
                                                      Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function (o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function (o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                    _vm.unit = $event.target
                                                      .multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  },
                                                  _vm.handleDurationChange,
                                                ],
                                              },
                                            },
                                            [
                                              _c(
                                                "option",
                                                { attrs: { value: "days" } },
                                                [_vm._v("Dias")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "option",
                                                { attrs: { value: "months" } },
                                                [_vm._v("Meses")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "option",
                                                { attrs: { value: "years" } },
                                                [_vm._v("Anos")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col" },
                                      [
                                        _c(
                                          "label",
                                          {
                                            attrs: {
                                              for: "formGroupExampleInput6",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "views.client.reception.receptionlistconsentiment.consent.content.expiration"
                                                )
                                              ) +
                                                "*\n                          "
                                            ),
                                            _c("img", {
                                              attrs: {
                                                src: "/static/img/icons/tooltip.png",
                                                "data-toggle": "tooltip",
                                                "data-placement": "right",
                                                title: _vm.$t(
                                                  "views.client.reception.receptionlistconsentiment.toolTip.Expiration"
                                                ),
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("custom-input", {
                                          attrs: {
                                            id: "formGroupExampleInput5",
                                            type: "number",
                                            regex: "[0-9]",
                                          },
                                          model: {
                                            value:
                                              _vm.receptionlistconsentiment
                                                .expiration,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.receptionlistconsentiment,
                                                "expiration",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "receptionlistconsentiment.expiration",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "form-group row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          attrs: {
                                            for: "formGroupExampleInput7",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "views.client.reception.receptionlistconsentiment.consent.content.purposes"
                                              )
                                            ) + "* "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "action-fields",
                                          attrs: {
                                            href: "javascript:void(null)",
                                          },
                                          on: { click: _vm.addPurpose },
                                        },
                                        [
                                          _vm._v(
                                            "+ " +
                                              _vm._s(_vm.$t("views.shared.add"))
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.purposes,
                                        function (item, counter) {
                                          return _c("div", { key: counter }, [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: item.name,
                                                  expression: "item.name",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: { type: "text" },
                                              domProps: { value: item.name },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    item,
                                                    "name",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                staticClass: "action-fields",
                                                attrs: {
                                                  href: "javascript:void(null)",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removePurpose(
                                                      counter
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "- " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "views.shared.remove"
                                                      )
                                                    )
                                                ),
                                              ]
                                            ),
                                          ])
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(" "),
                                _c("div", { staticClass: "form-group row" }, [
                                  _c("div", { staticClass: "col" }, [
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "show_tiny teros_principal__content__column__info__form__combo__btn-contrato",
                                        staticStyle: { width: "270px" },
                                        attrs: {
                                          href: "javascript:void(null)",
                                        },
                                        on: { click: _vm.showOrHideTiny },
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "views.client.reception.receptionlistconsentiment.consent.content.consentButtons.editConsentLayout"
                                              )
                                            ) +
                                            "\n                      "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _vm.showTiny
                                  ? _c(
                                      "div",
                                      { staticClass: "form-group row" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "col" },
                                          [
                                            _c("editor", {
                                              attrs: {
                                                "api-key": "no-api-key",
                                              },
                                              model: {
                                                value:
                                                  _vm.receptionlistconsentiment
                                                    .layout,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.receptionlistconsentiment,
                                                    "layout",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "receptionlistconsentiment.layout",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.newObj
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "teros_principal__content__table__body",
                                      },
                                      [
                                        _c(
                                          "table",
                                          {
                                            staticClass:
                                              "teros_principal__content__table__body__table",
                                            attrs: {
                                              id: "table",
                                              "data-toggle": "table",
                                              "data-height": "600",
                                              "data-url": "",
                                            },
                                          },
                                          [
                                            _c("thead", [
                                              _c("tr", [
                                                _c(
                                                  "th",
                                                  {
                                                    attrs: {
                                                      "data-field": "id",
                                                      "data-sortable": "true",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                            " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "views.client.reception.receptionlistconsentiment.consent.content.table.header.orderNumber"
                                                          )
                                                        ) +
                                                        "\n                          "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "th",
                                                  {
                                                    attrs: {
                                                      "data-field": "a",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                            " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "views.client.reception.receptionlistconsentiment.consent.content.table.header.terms"
                                                          )
                                                        ) +
                                                        "\n                          "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "th",
                                                  {
                                                    attrs: {
                                                      "data-field": "d",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                            " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "views.shared.table.header.createdAt"
                                                          )
                                                        ) +
                                                        "\n                          "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "th",
                                                  {
                                                    attrs: {
                                                      "data-field": "e",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                            " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "views.shared.table.header.changedAt"
                                                          )
                                                        ) +
                                                        "\n                          "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "th",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "center",
                                                    },
                                                    attrs: {
                                                      "data-field": "f",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                            " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "views.shared.table.header.options"
                                                          )
                                                        ) +
                                                        "\n                          "
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "tbody",
                                              _vm._l(
                                                _vm.receptionlistconsentiment
                                                  .termsList,
                                                function (item) {
                                                  return _c(
                                                    "tr",
                                                    { key: item.hash },
                                                    [
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.orderNumber
                                                          )
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(item.name)
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f(
                                                              "formatDate"
                                                            )(item.creationDate)
                                                          )
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f(
                                                              "formatDate"
                                                            )(
                                                              item.lastUpdateDate
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            "text-align":
                                                              "center",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "teros_principal__content__table__body__table__options mult-opt",
                                                            },
                                                            [
                                                              _c(
                                                                "router-link",
                                                                {
                                                                  staticClass:
                                                                    "teros_principal__content__table__body__table__options__item",
                                                                  attrs: {
                                                                    to:
                                                                      "/receptionconsentimentterm?receptionconsentimentHash=" +
                                                                      _vm
                                                                        .receptionlistconsentiment
                                                                        .hash +
                                                                      "&hash=" +
                                                                      item.hash,
                                                                    "data-toggle":
                                                                      "tooltip",
                                                                    "data-placement":
                                                                      "top",
                                                                    title:
                                                                      "Visualizar/Editar",
                                                                  },
                                                                },
                                                                [
                                                                  _c("div", [
                                                                    _c("img", {
                                                                      attrs: {
                                                                        src: "/static/img/icons/editar.svg",
                                                                        alt: "",
                                                                      },
                                                                    }),
                                                                  ]),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "a",
                                                                {
                                                                  staticClass:
                                                                    "teros_principal__content__table__body__table__options__item",
                                                                  attrs: {
                                                                    "data-toggle":
                                                                      "modal",
                                                                    "data-target":
                                                                      "#deleteModal",
                                                                    "data-placement":
                                                                      "top",
                                                                    title:
                                                                      "Deletar",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.prepareRemove(
                                                                          item.hash,
                                                                          _vm.index
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._m(
                                                                    1,
                                                                    true
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.newObj
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "teros_principal__content__table__footer",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "teros_principal__content__table__footer__pagination",
                                          },
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                staticClass:
                                                  "teros_principal__content__table__footer__add-item",
                                                attrs: {
                                                  to:
                                                    "/receptionconsentimentterm?receptionconsentimentHash=" +
                                                    _vm
                                                      .receptionlistconsentiment
                                                      .hash,
                                                  title: "Novo Termo",
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: "/static/img/icons/plus.png",
                                                    alt: "",
                                                  },
                                                }),
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "views.shared.includeButton"
                                                    )
                                                  ) + "\n                      "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "form-group row",
                    staticStyle: { padding: "0px 40px 40px" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "col",
                        staticStyle: { "text-align": "left" },
                      },
                      [
                        _c("div", [
                          _c(
                            "div",
                            [
                              _c("pdf-view", {
                                attrs: {
                                  "file-name":
                                    _vm.receptionlistconsentiment.fileName,
                                  type: "button",
                                  title: _vm.$t(
                                    "views.client.reception.receptionlistconsentiment.consent.content.consentButtons.viewConsent"
                                  ),
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "col",
                        staticStyle: { "text-align": "right" },
                      },
                      [
                        _c("div", [
                          _c("div", [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "teros_principal__content__table__footer__add-item",
                                class: { disabled: _vm.isDisabled },
                                staticStyle: { margin: "0" },
                                attrs: { disabled: _vm.isDisabled },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: "/static/img/icons/disquete.png",
                                    alt: "",
                                  },
                                }),
                                _vm._v(
                                  _vm._s(_vm.$t("views.shared.saveButton")) +
                                    "\n                  "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticStyle: { clear: "both" } }),
              ]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal fade teros-modal",
          attrs: {
            id: "deleteModal",
            tabindex: "-1",
            "aria-labelledby": "deleteModalLabel",
            "aria-hidden": "true",
          },
        },
        [
          _c("div", { staticClass: "modal-dialog" }, [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(2),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("views.shared.modal.delete.confirmMessage")) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button", "data-dismiss": "modal" },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t("views.shared.modal.delete.cancelButton")
                        ) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-primary--red",
                    attrs: { type: "button", "data-dismiss": "modal" },
                    on: { click: _vm.remove },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t("views.shared.modal.delete.deleteButton")
                        ) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "form-group row", staticStyle: { "padding-top": "40px" } },
      [
        _c("div", { staticClass: "col" }, [
          _vm._v(
            "\n                      Placeholder: Select\n                    "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col" }, [
          _vm._v(
            "\n                      Placeholder: Select \n                    "
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", { attrs: { src: "/static/img/icons/deletar.svg", alt: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        { staticClass: "modal-title", attrs: { id: "deleteModalLabel" } },
        [
          _c("img", {
            attrs: { src: "/static/img/icons/atencao-modal-red.png", alt: "" },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }