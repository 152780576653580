<template>
  <div class="teros_principal__content interna container" id="form">
    <div class="teros_principal__content__line-1">
      <div class="teros_principal__content__line-1__highlights">
        <div class="teros_principal__content__line-1__highlights__subject">
          <div
            class="
              teros_principal__content__line-1__highlights__subject__breadcrumb
            "
          >
            <h3
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle
              "
            >
              {{ $t("views.teros.integrations.integration.title") }}
            </h3>

            <h2
              v-if="newObj"
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__title
                active
              "
            >
              {{ $t("views.teros.integrations.integration.newObject") }}
            </h2>
            <h2
              v-else
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__title
                active
              "
            >
              {{ integration.name }}
            </h2>
          </div>
        </div>
      </div>

      <div class="teros_principal__content__line-1__information">
        <div class="teros_principal__content__line-1__information__time">
          <p
            v-if="!newObj"
            class="teros_principal__content__line-1__information__time__text"
          >
            <img
              class="
                teros_principal__content__line-1__information__time__text__icon
              "
              src="/static/img/icons/relogio.png"
              alt=""
            />
            {{
              $t("views.shared.createdDate", {
                date: creationDate,
                time: creationTime,
              })
            }}
            {{
              lastUpdateDate
                ? "| " +
                  $t("views.shared.changedDate", {
                    date: lastUpdateDate,
                    time: lastUpdateTime,
                  })
                : ""
            }}
          </p>

          <button
            type="button"
            @click="goBack"
            class="teros_principal__content__line-1__information__time__btn"
          >
            <img src="/static/img/icons/seta-esquerda.png" alt="" />
            {{ $t("views.shared.backButton") }}
          </button>
        </div>
      </div>
    </div>

    <div class="teros_principal__content__column">
      <div class="teros_principal__content__column__menu-vertical">
        <ul class="teros_principal__content__column__menu-vertical__list">
          <li
            class="teros_principal__content__column__menu-vertical__list__item"
          >
            <a
              href="#"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--lupa
                active
              "
              >{{ $t("views.teros.integrations.integration.tabTitle") }}</a
            >
          </li>

          <li
            class="teros_principal__content__column__menu-vertical__list__item"
          >
            <a
              v-if="newObj"
              href="#"
              @click="showAlert"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--servicos
              "
              >{{ $t("views.teros.integrations.services.tabTitle") }}</a
            >
            <router-link
              v-else
              :to="'/services?hash=' + integration.hash"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--servicos
              "
              >{{
                $t("views.teros.integrations.services.tabTitle")
              }}</router-link
            >
          </li>

          <li
            class="teros_principal__content__column__menu-vertical__list__item"
          >
            <a
              v-if="newObj"
              href="#"
              @click="showAlert"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--gestao
              "
              >{{ $t("views.teros.integrations.integrationlogs.tabTitle") }}</a
            >
            <router-link
              v-else
              :to="'/integrationlogs?hash=' + integration.hash"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--gestao
              "
              >{{
                $t("views.teros.integrations.integrationlogs.tabTitle")
              }}</router-link
            >
          </li>
        </ul>
      </div>
      <div class="teros_principal__content__column__info">
        <form
          @submit.prevent="createOrUpdate"
          enctype="multipart/form-data"
          method="post"
        >
          <div id="accordion">
            <div class="teros_principal__content__accordion__card mx0">
              <div
                class="teros_principal__content__accordion__card__header"
                id="headingOne"
              >
                <h2
                  class="
                    teros_principal__content__accordion__card__header__title
                  "
                >
                  <button
                    class="
                      teros_principal__content__accordion__card__header__title__btn
                    "
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    {{
                      $t(
                        "views.teros.integrations.integration.content.editTab.title"
                      )
                    }}
                  </button>
                </h2>
              </div>

              <div
                id="collapseOne"
                class="
                  teros_principal__content__accordion__card__header__collapse
                  collapse
                  show
                "
              >
                <div
                  class="
                    teros_principal__content__accordion__card__header__collapse__body
                  "
                >
                  <div
                    class="
                      teros_principal__content__accordion__card__header__collapse__body__form
                    "
                  >
                    <div class="form-group">
                      <div class="custom-control custom-switch">
                        <input
                          type="checkbox"
                          checked
                          class="custom-control-input"
                          id="customSwitch1"
                          v-model="integration.active"
                        />
                        <label
                          class="custom-control-label"
                          for="customSwitch1"
                          >{{ $t("views.shared.activeSwitch") }}</label
                        >
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="col">
                        <label for="IntegrationPage1"
                          >{{
                            $t(
                              "views.teros.integrations.integration.content.editTab.name"
                            )
                          }}*</label
                        >
                        <custom-input
                          :id="'IntegrationPage1'"
                          :regex="'[a-zA-Z0-9\u00C0-\u00ff]'"
                          v-model="integration.name"
                          :required="true"
                        ></custom-input>
                      </div>
                      <div class="col">
                        <live-select
                          :title="
                            $t(
                              'views.teros.integrations.integration.content.editTab.category'
                            ) + '*'
                          "
                          :options="categories"
                          :hash="integration.categoryHash"
                          @onChange="integration.categoryHash = $event"
                        ></live-select>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col">
                        <label for="IntegrationLogo">
                          Logo URI
                        </label>
                        <custom-input
                          :id="'IntegrationLogo'"
                          :regex="'.*'"
                          v-model="integration.logoUri"
                          :maxlength="'500'"
                        ></custom-input>
                      </div>
                      <div class="col">
                        <label for="IntegrationPage4">
                          {{
                            $t(
                              "views.teros.integrations.integration.consentURL"
                            )
                          }}
                          <img
                            src="/static/img/icons/tooltip.png"
                            data-toggle="tooltip"
                            data-placement="right"
                            :title="
                              $t(
                                'views.teros.integrations.integration.toolTips.consentURL'
                              )
                            "
                          />
                        </label>
                        <custom-input
                          :id="'IntegrationPage4'"
                          :regex="'.*'"
                          v-model="integration.consentCreateEndpoint"
                          :maxlength="'500'"
                        ></custom-input>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col">
                        <label for="IntegrationPage5">
                          {{
                            $t(
                              "views.teros.integrations.integration.paymentConsentCreateEndpoint"
                            )
                          }}
                          <img
                            src="/static/img/icons/tooltip.png"
                            data-toggle="tooltip"
                            data-placement="right"
                            :title="
                              $t(
                                'views.teros.integrations.integration.toolTips.paymentConsentCreateEndpoint'
                              )
                            "
                          />
                        </label>
                        <custom-input
                          :id="'IntegrationPage5'"
                          :regex="'.*'"
                          v-model="integration.paymentConsentCreateEndpoint"
                          :maxlength="'500'"
                        ></custom-input>
                      </div>
                      <div class="col">
                        <label for="IntegrationPage4">
                          {{
                            $t(
                              "views.teros.integrations.integration.pixPaymentCreateEndpoint"
                            )
                          }}
                          <img
                            src="/static/img/icons/tooltip.png"
                            data-toggle="tooltip"
                            data-placement="right"
                            :title="
                              $t(
                                'views.teros.integrations.integration.toolTips.pixPaymentCreateEndpoint'
                              )
                            "
                          />
                        </label>
                        <custom-input
                          :id="'IntegrationPage6'"
                          :regex="'.*'"
                          v-model="integration.pixPaymentCreateEndpoint"
                          :maxlength="'500'"
                        ></custom-input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="teros_principal__content__accordion__card mx0">
              <div
                class="teros_principal__content__accordion__card__header"
                id="headingOne"
              >
                <h2
                  class="
                    teros_principal__content__accordion__card__header__title
                  "
                >
                  <button
                    class="
                      teros_principal__content__accordion__card__header__title__btn
                    "
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="true"
                    aria-controls="collapseTwo"
                  >
                    {{
                      $t(
                        "views.teros.integrations.integration.content.responsibilitiesTab.title"
                      )
                    }}
                  </button>
                </h2>
              </div>

              <div
                id="collapseTwo"
                class="
                  teros_principal__content__accordion__card__header__collapse
                  collapse
                  show
                "
              >
                <div
                  class="
                    teros_principal__content__accordion__card__header__collapse__body
                  "
                >
                  <div
                    class="
                      teros_principal__content__accordion__card__header__collapse__body__form
                    "
                  >
                    <div class="form-group">
                      <label for="">
                        {{
                          $t(
                            "views.teros.integrations.integration.content.responsibilitiesTab.contract"
                          )
                        }}
                      </label>
                      <file-upload
                        :file-name="integration.contractFileName"
                      ></file-upload>
                    </div>

                    <div
                      class="
                        form-group
                        teros_principal__content__column__info__form__combo
                      "
                    >
                      <pdf-view
                        :file-name="integration.contractFileName"
                        :type="'button'"
                        :title="
                          $t(
                            'views.teros.integrations.integration.content.responsibilitiesTab.viewContractButton'
                          )
                        "
                      ></pdf-view>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <authorization-form
              :apiAuthentication="integration.apiAuthentication"
              :showDcrType="showDcrType"
              :customHeaders="customHeaders"
              @onChangeFlow="onChangeFlowType"
            />
          </div>

          <div
            class="
              form-group
              teros_principal__content__column__info__form__combo
            "
            style="margin-top: 0; margin-bottom: 40px; text-align: right"
          >
            <button
              class="
                teros_principal__content__column__info__form__combo__salvar
              "
              title="Salvar"
            >
              <img src="/static/img/icons/disquete.png" alt="Salvar" />{{
                $t("views.shared.saveButton")
              }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { http, router } from "@/_helpers";
import FileUpload from "@/_components/FileUpload.vue";
import PdfView from "@/_components/PdfView.vue";
import LiveSelect from "@/_components/LiveSelect.vue";
import AuthorizationForm from "@/_components/AuthorizationForm.vue";
import CustomInput from "@/_components/CustomInput.vue";
import moment from "moment";

export default {
  data() {
    return {
      newObj: true,
      integration: {
        active: true,
        apiAuthentication: {
          dynamicClientRegistrationType: 1,
          authenticationFlowType: 1,
          authorizationParameterType: 1,
          authenticationParameterType: 1,
          tokenType: 1,
          codeChallengeMethod: 1,
        },
      },
      categories: [],
      customHeaders: [],
      showDcrType: false
    };
  },
  components: {
    FileUpload,
    PdfView,
    LiveSelect,
    AuthorizationForm,
    CustomInput,
  },
  computed: {
    creationDate() {
      if (this.integration.creationDate) {
        return moment(String(this.integration.creationDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
    creationTime() {
      if (this.integration.creationDate) {
        return moment(String(this.integration.creationDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("HH:mm");
      }
      return "";
    },
    lastUpdateDate() {
      if (this.integration.lastUpdateDate) {
        return moment(String(this.integration.lastUpdateDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
    lastUpdateTime() {
      if (this.integration.lastUpdateDate) {
        return moment(String(this.integration.lastUpdateDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("HH:mm");
      }
      return "";
    },
  },
  mounted() {
    this.$emit("message", this.$route);
    const hash = this.$route.query.hash;
    if (hash) {
      this.newObj = false;
      http.get("/api/integration/get/" + hash).then((response) => {
        this.integration = response.data;
        if (this.integration.apiAuthentication == null) {
          this.integration.apiAuthentication = {
            dynamicClientRegistrationType: 1,
            authenticationFlowType: 1,
            authorizationParameterType: 1,
            authenticationParameterType: 1,
            tokenType: 1,
            codeChallengeMethod: 1,
          };
        }
        if (this.integration.apiAuthentication.customHeaderFields != null) {
          this.customHeaders = this.customHeaders.concat(
            JSON.parse(this.integration.apiAuthentication.customHeaderFields)
          );
        }
        this.customHeaders.push({ field: "", value: "" });
      });
    }
    http.get("/api/category/list/").then((response) => {
      this.categories = response.data;
    });
  },
  methods: {
    goBack() {
      router.push({ name: "integrationspage" });
    },
    showAlert() {
      Vue.$toast.info(
        this.$i18n.t("views.teros.integrations.integration.alert.saveBefore")
      );
    },
    refreshData(value) {
      this.integration.categoryHash = value;
    },
    onChangeFlowType(value) {
      this.integration.apiAuthentication = { dynamicClientRegistrationType: this.integration.apiAuthentication.dynamicClientRegistrationType };
      this.integration.apiAuthentication.authenticationFlowType = value;
      this.customHeaders = [];
      this.customHeaders.push({ field: "", value: "" });
    },
    createOrUpdate() {
      let uploadFile = new Promise((resolve, reject) => {
        this.bus.$emit(
          "make-file-upload",
          {
            url: "/api/util/upload/base64",
            name: `integration/${this.integration.name}/${this.integration.hash}`,
          },
          resolve,
          reject
        );
      });

      let invalidCustomHeaders = this.customHeaders.filter(
        (x) =>
          (x.field == "" && x.value != "") || (x.field != "" && x.value == "")
      );
      if (invalidCustomHeaders.length > 0) {
        Vue.$toast.error(
          this.$i18n.t("views.authorizationForm.error.customHeaderInvalid")
        );
        return;
      }

      let customHeadersCleaned = this.customHeaders.filter(
        (x) => x.field != "" && x.value != ""
      );

      this.integration.apiAuthentication.customHeaderFields =
        JSON.stringify(customHeadersCleaned);

      uploadFile.then((response) => {
        this.integration.contractFileName = response.data;
        if (this.newObj) {
          http
            .post("/api/integration/create/", this.integration)
            .then((response) => {
              this.integration = response.data;
              this.newObj = false;
              router.push("/integration?hash=" + this.integration.hash);
              this.$emit("success");
            });
        } else {
          http.put("/api/integration/update/", this.integration).then(() => {
            this.goBack();
            this.$emit("success");
          });
        }
      });
    },
  },
};
</script>
