<template>
  <div>
    <input
      :id="id"
      :type="showPassword ? 'text' : 'password'"
      :placeholder="placeholder"
      v-model="passwordValue"
      class="form-control teros_principal__login__column__form__fields__input"
    />
    <a
      :for="id"
      href="javascript:void(null)"
      :class="
        showPassword
          ? 'teros_principal__login__column__form__fields__view'
          : 'teros_principal__login__column__form__fields__slash__view'
      "
      @click="showPasswordFunction"
    ></a>
  </div>
</template>

<script>
export default {
  name: "input-password",
  data() {
    return {
      showPassword: false,
    };
  },
  props: {
    id: {
      type: String,
      default: "passwordId",
    },
    password: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Password",
    },
  },
  computed: {
    passwordValue: {
      get: function () {
        return this.password;
      },
      set: function (value) {
        this.$emit("onChange", value);
      },
    },
  },
  methods: {
    showPasswordFunction() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>