<template>
  <div class="teros_principal__content interna container">
    <div class="teros_principal__content__line-1 line">
      <div class="teros_principal__content__line-1__highlights">
        <div class="teros_principal__content__line-1__highlights__subject">
          <div
            class="teros_principal__content__line-1__highlights__subject__breadcrumb"
          >
            <router-link to="/authorizations">
              <h3
                class="teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle"
              >
                {{ $t("views.shared.menu.authorizations") }}
              </h3>
              <h2
                class="teros_principal__content__line-1__highlights__subject__breadcrumb__title"
              >
                {{ authorization.customerName }}
              </h2>
            </router-link>
          </div>
          <div class="teros_principal__arrow"></div>
          <div
            class="teros_principal__content__line-1__highlights__subject__breadcrumb"
          >
            <router-link :to="'/authorization?hash=' + authorization.hash">
              <h3
                class="teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle"
              >
                {{ $t("views.teros.authorizations.authorization.title") }}
              </h3>
              <h2
                class="teros_principal__content__line-1__highlights__subject__breadcrumb__title"
              >
                {{ authorization.integrationName }}
              </h2>
            </router-link>
          </div>
          <div class="teros_principal__arrow"></div>
          <div
            class="teros_principal__content__line-1__highlights__subject__breadcrumb"
          >
            <h3
              class="teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle"
            >
              {{ $t("views.teros.authorizations.authorizationterm.title") }}
            </h3>
            <h2
              class="teros_principal__content__line-1__highlights__subject__breadcrumb__title active"
            >
              {{ servicepartnershipterm.name }}
            </h2>
          </div>
        </div>
      </div>
      <div class="teros_principal__content__line-1__information">
        <div class="teros_principal__content__line-1__information__time">
          <button
            type="button"
            @click="goBack"
            class="teros_principal__content__line-1__information__time__btn"
          >
            <img src="/static/img/icons/seta-esquerda.png" alt="" />
            {{ $t("views.shared.backButton") }}
          </button>
        </div>
      </div>
    </div>

    <div class="teros_principal__content__accordion__description">
      <p class="teros_principal__content__line-1__information__time__text">
        <img
          class="teros_principal__content__line-1__information__time__text__icon"
          src="/static/img/icons/relogio.png"
          alt=""
        />
        {{
          $t("views.shared.createdDate", {
            date: creationDate,
            time: creationTime,
          })
        }}
        {{
          lastUpdateDate
            ? "| " +
              $t("views.shared.changedDate", {
                date: lastUpdateDate,
                time: lastUpdateTime,
              })
            : ""
        }}
      </p>
    </div>

    <div id="accordion">
      <div class="teros_principal__content__accordion__card">
        <div
          class="teros_principal__content__accordion__card__header accordion-off"
          id="headingOne"
        >
          <h2 class="teros_principal__content__accordion__card__header__title">
            <button
              class="teros_principal__content__accordion__card__header__title__btn"
              type="button"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              {{
                $t("views.teros.authorizations.authorizationterm.content.title")
              }}
            </button>
            <hr />
          </h2>
        </div>

        <div
          id="collapseOne"
          class="teros_principal__content__accordion__card__header__collapse"
        >
          <div
            class="teros_principal__content__accordion__card__header__collapse__body"
          >
            <div
              class="teros_principal__content__accordion__card__header__collapse__body__form"
            >
              <div
                v-if="
                  servicepartnershipterm.authorizationStatus == 2 &&
                  servicepartnershiptermorigin.exists
                "
              >
                <div class="form-group">
                  <div class="custom-control custom-switch">
                    <input
                      type="checkbox"
                      checked
                      class="custom-control-input"
                      id="customSwitch1"
                      v-model="servicepartnershiptermorigin.active"
                      disabled
                    />
                    <label class="custom-control-label" for="customSwitch1">{{
                      $t(
                        "views.teros.authorizations.authorizationterm.content.originalStatus"
                      )
                    }}</label>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col">
                    <label for="formGroupExampleInput1">{{
                      $t(
                        "views.teros.authorizations.authorizationterm.content.originalOrdering"
                      )
                    }}</label>
                    <input
                      type="number"
                      class="form-control"
                      id="formGroupExampleInput1"
                      v-model.number="servicepartnershiptermorigin.orderNumber"
                      readonly
                    />
                  </div>

                  <div class="col">
                    <label for="formGroupExampleInput2">{{
                      $t(
                        "views.teros.authorizations.authorizationterm.content.originalName"
                      )
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="formGroupExampleInput2"
                      v-model="servicepartnershiptermorigin.name"
                      readonly
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col">
                    <label for="formGroupExampleInput3">{{
                      $t(
                        "views.teros.authorizations.authorizationterm.content.originalTerm"
                      )
                    }}</label>
                    <editor
                      id="originalTerm"
                      api-key="no-api-key"
                      v-model="servicepartnershiptermorigin.description"
                      toolbar="false"
                      disabled
                    />
                  </div>
                </div>

                <hr style="margin: 40px 0" />
              </div>

              <div>
                <div class="form-group">
                  <div class="custom-control custom-switch">
                    <input
                      type="checkbox"
                      checked
                      class="custom-control-input"
                      id="customSwitch1"
                      v-model="servicepartnershipterm.active"
                      disabled
                    />
                    <label class="custom-control-label" for="customSwitch1">{{
                      $t(
                        "views.teros.authorizations.authorizationterm.content.changedStatus"
                      )
                    }}</label>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col">
                    <label for="formGroupExampleInput1"
                      >{{
                        $t(
                          "views.teros.authorizations.authorizationterm.content.changedOrdering"
                        )
                      }}
                      <img
                        src="/static/img/icons/tooltip.png"
                        data-toggle="tooltip"
                        data-placement="right"
                        :title="
                          $t(
                            'views.teros.authorizations.authorizationterm.toolTips.changedOrdering'
                          )
                        "
                    /></label>
                    <input
                      type="number"
                      class="form-control"
                      id="formGroupExampleInput1"
                      v-model.number="servicepartnershipterm.orderNumber"
                      readonly
                    />
                  </div>

                  <div class="col">
                    <label for="formGroupExampleInput2"
                      >{{
                        $t(
                          "views.teros.authorizations.authorizationterm.content.changedName"
                        )
                      }}
                      <img
                        src="/static/img/icons/tooltip.png"
                        data-toggle="tooltip"
                        data-placement="right"
                        :title="
                          $t(
                            'views.teros.authorizations.authorizationterm.toolTips.changedName'
                          )
                        "
                    /></label>
                    <input
                      type="text"
                      class="form-control"
                      id="formGroupExampleInput2"
                      v-model="servicepartnershipterm.name"
                      readonly
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col">
                    <label for="formGroupExampleInput3">{{
                      $t(
                        "views.teros.authorizations.authorizationterm.content.changedTerm"
                      )
                    }}</label>
                    <editor
                      id="alteredTerm"
                      api-key="no-api-key"
                      v-model="servicepartnershipterm.description"
                      toolbar="false"
                      disabled
                    />
                  </div>
                </div>
              </div>

              <hr style="margin: 40px 0" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http, router } from "@/_helpers";
import Editor from "@tinymce/tinymce-vue";
import moment from 'moment';

export default {
  data() {
    return {
      authorization: {},
      servicepartnershipterm: {},
      servicepartnershiptermorigin: { exists: false },
    };
  },
  components: {
    Editor,
  },
  computed: {
    creationDate() {
      if (this.servicepartnershipterm.creationDate) {
        return moment(String(this.servicepartnershipterm.creationDate)).format(
          "DD/MM/YYYY"
        );
      }
      return "";
    },
    creationTime() {
      if (this.servicepartnershipterm.creationDate) {
        return moment(String(this.servicepartnershipterm.creationDate)).format(
          "HH:mm"
        );
      }
      return "";
    },
    lastUpdateDate() {
      if (this.servicepartnershipterm.lastUpdateDate) {
        return moment(
          String(this.servicepartnershipterm.lastUpdateDate)
        ).format("DD/MM/YYYY");
      }
      return "";
    },
    lastUpdateTime() {
      if (this.servicepartnershipterm.lastUpdateDate) {
        return moment(
          String(this.servicepartnershipterm.lastUpdateDate)
        ).format("HH:mm");
      }
      return "";
    },
  },
  mounted() {
    this.$emit("message", this.$route);
    const hash = this.$route.query.hash;
    const authorizationHash = this.$route.query.authorizationHash;
    http.get("/api/authorization/get/" + authorizationHash).then((response) => {
      this.authorization = response.data;
    });
    http.get("/api/servicepartnershipterm/get/" + hash).then((response) => {
      this.servicepartnershipterm = response.data;
      if (
        this.servicepartnershipterm.servicePartnershipTermOriginHash != null &&
        this.servicepartnershipterm.authorizationStatus == 2
      ) {
        http
          .get(
            "/api/servicepartnershipterm/get/" +
              this.servicepartnershipterm.servicePartnershipTermOriginHash
          )
          .then((response) => {
            this.servicepartnershiptermorigin = response.data;
            this.servicepartnershiptermorigin.exists = true;
          });
      }
    });
  },
  methods: {
    goBack() {
      router.push({
        name: "authorizationpage",
        query: { hash: this.authorization.hash },
      });
    },
  },
};
</script>