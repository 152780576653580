var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.pageIdentifier }, [
    _c(
      "div",
      { staticClass: "teros_principal clientes" },
      [
        _vm.currentUser
          ? _c(
              "header",
              { staticClass: "teros_principal__header container-fluid" },
              [
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "teros_principal__header__menu" }, [
                  _c(
                    "div",
                    { staticClass: "teros_principal__header__menu__languages" },
                    [_c("language-select")],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "teros_principal__header__menu__list" },
                    [
                      _c(
                        "li",
                        {
                          staticClass:
                            "teros_principal__header__menu__list__item",
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.currentUser.name))])]
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        {
                          staticClass:
                            "teros_principal__header__menu__list__item",
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass:
                                "teros_principal__header__menu__list__item__link",
                              attrs: { href: "#" },
                              on: { click: _vm.logout },
                            },
                            [_vm._v(_vm._s(_vm.$t("views.shared.exit")))]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.currentUser
          ? _c("div", { staticClass: "teros_principal__menu container" }, [
              _c("ul", { staticClass: "teros_principal__menu__list" }, [
                _c(
                  "li",
                  { staticClass: "teros_principal__menu__list__item" },
                  [
                    _vm.menuActive == "dashboard"
                      ? _c(
                          "router-link",
                          {
                            staticClass:
                              "teros_principal__menu__list__item__link teros_principal__menu__list__item__link--active-dashboard",
                            attrs: { to: "/" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "teros_principal__menu__list__item__link__circle",
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: "/static/img/icons/speedometer-active.png",
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("views.shared.menu.dashboard")) +
                                "\n          "
                            ),
                          ]
                        )
                      : _c(
                          "router-link",
                          {
                            staticClass:
                              "teros_principal__menu__list__item__link teros_principal__menu__list__item__link-dashboard",
                            attrs: { to: "/" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "teros_principal__menu__list__item__link__circle",
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: "/static/img/icons/speedometer.png",
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("views.shared.menu.dashboard")) +
                                "\n          "
                            ),
                          ]
                        ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.currentUser.isAdmin
                  ? _c(
                      "li",
                      { staticClass: "teros_principal__menu__list__item" },
                      [
                        _vm.menuActive == "integrations"
                          ? _c(
                              "router-link",
                              {
                                staticClass:
                                  "teros_principal__menu__list__item__link teros_principal__menu__list__item__link--active-integracoes",
                                attrs: { to: "/integrations" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "teros_principal__menu__list__item__link__circle",
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "/static/img/icons/intersect-active.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t("views.shared.menu.integrations")
                                    ) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _c(
                              "router-link",
                              {
                                staticClass:
                                  "teros_principal__menu__list__item__link teros_principal__menu__list__item__link-integracoes",
                                attrs: { to: "/integrations" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "teros_principal__menu__list__item__link__circle",
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "/static/img/icons/intersect.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t("views.shared.menu.integrations")
                                    ) +
                                    "\n          "
                                ),
                              ]
                            ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.currentUser.isAdmin
                  ? _c(
                      "li",
                      { staticClass: "teros_principal__menu__list__item" },
                      [
                        _vm.menuActive == "customers"
                          ? _c(
                              "router-link",
                              {
                                staticClass:
                                  "teros_principal__menu__list__item__link teros_principal__menu__list__item__link--active-clientes",
                                attrs: { to: "/customers" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "teros_principal__menu__list__item__link__circle",
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "/static/img/icons/customer-active.png",
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t("views.shared.menu.customers")
                                    ) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _c(
                              "router-link",
                              {
                                staticClass:
                                  "teros_principal__menu__list__item__link teros_principal__menu__list__item__link-clientes",
                                attrs: { to: "/customers" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "teros_principal__menu__list__item__link__circle",
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "/static/img/icons/customer.png",
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t("views.shared.menu.customers")
                                    ) +
                                    "\n          "
                                ),
                              ]
                            ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.currentUser.isAdmin &&
                _vm.currentUser.groups.includes("CustomerAdmin")
                  ? _c(
                      "li",
                      { staticClass: "teros_principal__menu__list__item" },
                      [
                        _vm.menuActive == "reception"
                          ? _c(
                              "router-link",
                              {
                                staticClass:
                                  "teros_principal__menu__list__item__link teros_principal__menu__list__item__link--active-clientes",
                                attrs: { to: "/receptions" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "teros_principal__menu__list__item__link__circle",
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "/static/img/icons/customer-active.png",
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t("views.shared.menu.reception")
                                    ) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _c(
                              "router-link",
                              {
                                staticClass:
                                  "teros_principal__menu__list__item__link teros_principal__menu__list__item__link-clientes",
                                attrs: { to: "/receptions" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "teros_principal__menu__list__item__link__circle",
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "/static/img/icons/customer.png",
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t("views.shared.menu.reception")
                                    ) +
                                    "\n          "
                                ),
                              ]
                            ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.currentUser.isAdmin &&
                _vm.currentUser.groups.includes("CustomerAdmin")
                  ? _c(
                      "li",
                      { staticClass: "teros_principal__menu__list__item" },
                      [
                        _vm.menuActive == "transmission"
                          ? _c(
                              "router-link",
                              {
                                staticClass:
                                  "teros_principal__menu__list__item__link teros_principal__menu__list__item__link--active-transmissao",
                                attrs: { to: "/transmission" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "teros_principal__menu__list__item__link__circle",
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "/static/img/icons/transmission-active.png",
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t("views.shared.menu.transmission")
                                    ) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _c(
                              "router-link",
                              {
                                staticClass:
                                  "teros_principal__menu__list__item__link teros_principal__menu__list__item__link-transmissao",
                                attrs: { to: "/transmission" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "teros_principal__menu__list__item__link__circle",
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "/static/img/icons/transmission.png",
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t("views.shared.menu.transmission")
                                    ) +
                                    "\n          "
                                ),
                              ]
                            ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.currentUser.isAdmin
                  ? _c(
                      "li",
                      { staticClass: "teros_principal__menu__list__item" },
                      [
                        _vm.menuActive == "authorizations"
                          ? _c(
                              "router-link",
                              {
                                staticClass:
                                  "teros_principal__menu__list__item__link teros_principal__menu__list__item__link--active-autorizacoes",
                                attrs: { to: "/authorizations" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "teros_principal__menu__list__item__link__circle",
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "/static/img/icons/paper-active.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t("views.shared.menu.authorizations")
                                    ) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _c(
                              "router-link",
                              {
                                staticClass:
                                  "teros_principal__menu__list__item__link teros_principal__menu__list__item__link-autorizacoes",
                                attrs: { to: "/authorizations" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "teros_principal__menu__list__item__link__circle",
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "/static/img/icons/paper.png",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t("views.shared.menu.authorizations")
                                    ) +
                                    "\n          "
                                ),
                              ]
                            ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.currentUser.isAdmin &&
                _vm.currentUser.groups.includes("CustomerAdmin")
                  ? _c(
                      "li",
                      { staticClass: "teros_principal__menu__list__item" },
                      [
                        _vm.menuActive == "access"
                          ? _c(
                              "router-link",
                              {
                                staticClass:
                                  "teros_principal__menu__list__item__link teros_principal__menu__list__item__link--active-integracoes",
                                attrs: { to: "/access" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "teros_principal__menu__list__item__link__circle",
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "/static/img/icons/acesso.png",
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("views.shared.menu.access")) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _c(
                              "router-link",
                              {
                                staticClass:
                                  "teros_principal__menu__list__item__link teros_principal__menu__list__item__link-integracoes",
                                attrs: { to: "/access" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "teros_principal__menu__list__item__link__circle",
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "/static/img/icons/acesso.png",
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("views.shared.menu.access")) +
                                    "\n          "
                                ),
                              ]
                            ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.currentUser.isAdmin
                  ? _c(
                      "li",
                      { staticClass: "teros_principal__menu__list__item" },
                      [
                        _vm.menuActive == "config"
                          ? _c(
                              "router-link",
                              {
                                staticClass:
                                  "teros_principal__menu__list__item__link teros_principal__menu__list__item__link--active-autorizacoes",
                                attrs: { to: "/categories" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "teros_principal__menu__list__item__link__circle",
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "/static/img/icons/settings-active.svg",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("views.shared.menu.config")) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _c(
                              "router-link",
                              {
                                staticClass:
                                  "teros_principal__menu__list__item__link teros_principal__menu__list__item__link-autorizacoes",
                                attrs: { to: "/categories" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "teros_principal__menu__list__item__link__circle",
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "/static/img/icons/settings.svg",
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("views.shared.menu.config")) +
                                    "\n          "
                                ),
                              ]
                            ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("router-view", {
          on: { message: _vm.refreshPage, success: _vm.showMessage },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("footer", { staticClass: "teros_principal__footer container" }, [
      _c("p", [
        _vm._v(
          "Teros Open Banking | " +
            _vm._s(_vm.$t("views.shared.titleSystem")) +
            " - 2021"
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "teros_principal__header__logo" }, [
      _c("img", { attrs: { src: "/static/img/logo_teros.png", alt: "" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }