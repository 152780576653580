<template>
  <div class="teros_principal__content interna container">
    <div class="teros_principal__content__line-1">
      <div class="teros_principal__content__line-1__highlights">
        <div class="teros_principal__content__line-1__highlights__subject">
          <div class="teros_principal__content__line-1__highlights__subject__breadcrumb">
            <h3 class="teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle">
              {{ $t("views.client.reception.list.client") }}
            </h3>
            <h2 class="teros_principal__content__line-1__highlights__subject__breadcrumb__title active">
              {{ currentUser.customerName }}
            </h2>
          </div>
        </div>
      </div>
    </div>

    <div class="teros_principal__content__column">
      <!--Drawer lateral-->
      <div class="teros_principal__content__column__menu-vertical">
        <ul class="teros_principal__content__column__menu-vertical__list">
          <li class="teros_principal__content__column__menu-vertical__list__item">
            <a
              href="/receptions"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--lupa
                active
              "
              >{{ $t("views.client.reception.reception.tabTitle") }}</a
            >
          </li>

          <!-- <li class="teros_principal__content__column__menu-vertical__list__item">
            <a
              v-if="newObj"
              href="#"
              @click="showAlert"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--compliance
                active
              "
              >{{ $t("views.client.reception.receptionlistconsentiment.tabTitle") }}</a
            >
            <router-link
              v-else
              to="/receptionlistconsentiments"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--compliance
              "
              >{{ $t("views.client.reception.receptionlistconsentiment.tabTitle") }}</router-link
            >
          </li>

          <li class="teros_principal__content__column__menu-vertical__list__item">
            <router-link to="/receptionlistgenericterms" class="teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--registros">{{
              $t("views.client.reception.receptionlistgenericterms.tabTitle")
            }}</router-link>
          </li> -->

        </ul>
      </div>
      <!--Content-->
      <div class="teros_principal__content__column__info">
        <form @submit.prevent="makeTextSearch">
          <div class="teros_principal__content__table">
            <div class="teros_principal__content__table__header pt0">
              <div class="teros_principal__content__table__header__filters">
                <div class="form-group">
                  <div class="search-group">
                    <input type="text" class="input-field" :placeholder="$t('views.shared.searchBar.searchLabel')" maxlength="30" v-model="textSearchValue" />
                    <button class="search-button" />
                  </div>
                </div>

                <itens-per-page :request-pagination="requestPagination" :callback="makeTextSearch"></itens-per-page>
              </div>

              <div class="teros_principal__content__table__header__actions">
                <!--<a href="#" title="Download"
                ><img src="/static/img/icons/download.png" alt="Download"
              /></a>
              <a href="#" title="Imprimir"
                ><img src="/static/img/icons/printer.png" alt="Imprimir"
              /></a> -->
              </div>
            </div>

            <div class="teros_principal__content__table__body">
              <table id="table" class="teros_principal__content__table__body__table" data-toggle="table" data-height="600" data-url="">
                <thead>
                  <tr>
                    <th data-field="id" data-sortable="true">ID</th>
                    <th data-field="a">
                      {{ $t("views.client.reception.list.table.header.name") }}
                    </th>
                    <th data-field="c">
                      {{ $t("views.client.reception.list.table.header.type") }}
                    </th>
                    <th data-field="e">
                      {{ $t("views.shared.table.header.createdAt") }}
                    </th>
                    <th data-field="f">
                      {{ $t("views.shared.table.header.changedAt") }}
                    </th>
                    <!--<th data-field="g">Contrato</th>-->
                    <th data-field="h" style="text-align: center">
                      {{ $t("views.shared.table.header.options") }}
                    </th>
                    <th data-field="i" style="text-align: center">
                      {{ $t("views.shared.table.header.deactivateActivate") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in receptions" :key="item.hash">
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.category }}</td>
                    <td>{{ item.creationDate | formatDate }}</td>
                    <td>{{ item.lastUpdateDate | formatDate }}</td>
                    <!--<td>
                    <pdf-view
                      :file-name="item.contractFileName"
                      :type="'link'"
                      :title="'Visualizar'"
                    ></pdf-view>
                  </td>-->

                    <td style="width: 102px; padding-left: 0; padding-right: 0">
                      <div class="teros_principal__content__table__body__table__options">
                        <router-link
                          :to="'/reception?hash=' + item.hash"
                          class="teros_principal__content__table__body__table__options__item"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Visualizar/Editar"
                        >
                          <div>
                            <img src="/static/img/icons/editar.svg" alt="" />
                          </div>
                        </router-link>
                        <a
                          @click="prepareRemove(item.hash, index)"
                          class="teros_principal__content__table__body__table__options__item"
                          data-toggle="modal"
                          data-target="#deleteModal"
                          data-placement="top"
                          title="Deletar"
                        >
                          <div>
                            <img src="/static/img/icons/deletar.svg" alt="" />
                          </div>
                        </a>
                      </div>
                    </td>
                    <td style="text-align: center">
                      <div class="custom-control custom-switch" data-toggle="tooltip" data-placement="top" title="Desativar/Ativar">
                        <input type="checkbox" class="custom-control-input" :id="'customSwitch' + index" v-model="item.active" @change="changeStatus(item.hash)" />
                        <label class="custom-control-label" :for="'customSwitch' + index"></label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="teros_principal__content__table__footer">
              <div v-if="loading" class="teros_principal__content__table__footer__loader">
                <img src="/static/img/icons/ajax-loader.gif" alt="" />
                <span>{{ $t("views.shared.loadingResults") }}</span>
              </div>

              <pagination :request-pagination="requestPagination" :callback="makeTextSearch" :current-pagination-info="currentPaginationInfo"></pagination>
              <div class="teros_principal__content__table__footer__pagination">
                <router-link to="/reception" title="Nova Recepção" class="teros_principal__content__table__footer__add-item">
                  <img src="/static/img/icons/plus.png" alt="" />{{ $t("views.shared.includeButton") }}
                </router-link>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="modal fade teros-modal" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteModalLabel">
              <img src="/static/img/icons/atencao-modal-red.png" alt="" />
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            {{ $t("views.shared.modal.delete.confirmMessage") }}
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              {{ $t("views.shared.modal.delete.cancelButton") }}
            </button>
            <button type="button" class="btn btn-primary btn-primary--red" data-dismiss="modal" @click="remove">
              {{ $t("views.shared.modal.delete.deleteButton") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { cognito, http } from "@/_helpers";
import PdfView from "@/_components/PdfView.vue";
import Pagination from "@/_components/Pagination.vue";
import ItensPerPage from "@/_components/ItensPerPage.vue";
import moment from "moment";

export default {
  data() {
    return {
      currentUser: cognito.currentUserValue,
      receptions: [],
      receptionHash: null,
      index: 0,
      loading: true,
      textSearchValue: "",
      oldTextSearchValue: "",
      currentPaginationInfo: null,
      requestPagination: { page: 1, take: 10 },
    };
  },
  components: {
    PdfView,
    Pagination,
    ItensPerPage,
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
  },
  mounted() {
    this.$emit("message", this.$route);
    http
      .get("/api/reception/search/" + this.currentUser.customerHash, false)
      .then((response) => {
        this.receptions = response.data.items;
        this.currentPaginationInfo = response.data;
        console.log("response", response);
      })
      .finally(() => (this.loading = false));
  },
  methods: {
    changeStatus(hash) {
      http.get("/api/reception/get/" + hash).then((response) => {
        let receptionEdit = response.data;
        receptionEdit.active = !response.data.active;
        http.put("/api/reception/update/", receptionEdit).then(() => {
          if (receptionEdit.active) {
            Vue.$toast.success(this.$i18n.t("views.client.reception.list.modal.activatedMessage"));
          } else {
            Vue.$toast.success(this.$i18n.t("views.client.reception.list.modal.deactivatedMessage"));
          }
        });
      });
    },
    prepareRemove(hash, index) {
      this.receptionHash = hash;
      this.index = index;
    },
    remove() {
      http.remove("/api/reception/delete/" + this.receptionHash).then(() => {
        Vue.$toast.success(this.$i18n.t("views.client.reception.list.modal.deletedMessage"));
        Vue.delete(this.receptions, this.index);
      });
    },
    makeTextSearch(event, isChangePage = false) {
      this.CheckValuesChanged(isChangePage);

      if (this.textSearchValue.trim()) {
        http
          .get(
            `/api/reception/search/${this.currentUser.customerHash}?FilterProperty=Name&FilterValue=${this.textSearchValue.trim()}&Page=${this.requestPagination.page}&Take=${
              this.requestPagination.take
            }`
          )
          .then((response) => {
            this.receptions = response.data.items;
            this.currentPaginationInfo = response.data;
          })
          .finally(() => (this.loading = false));
      } else {
        http
          .get(`/api/reception/search/${this.currentUser.customerHash}?Page=${this.requestPagination.page}&Take=${this.requestPagination.take}`)
          .then((response) => {
            this.receptions = response.data.items;
            this.currentPaginationInfo = response.data;
          })
          .finally(() => (this.loading = false));
      }
    },
    CheckValuesChanged(isChangePage = false) {
      if (this.textSearchValue != this.oldTextSearchValue) {
        if (isChangePage) {
          this.textSearchValue = this.oldTextSearchValue;
        } else {
          this.requestPagination.page = 1;
          this.oldTextSearchValue = this.textSearchValue;
        }
      }
    },
  },
};
</script>
