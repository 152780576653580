<template>
  <div
    v-if="currentPaginationInfo && currentPaginationInfo.totalPages"
    class="teros_principal__content__table__footer__pagination"
  >
    <a
      @click="previousPage()"
      :class="{
        'bt-left-disabled': currentPaginationInfo.currentPage == 1,
      }"
      href="javascript:void(0);"
      class="bt-left"
    ></a>
    <ul>
      <li v-for="n in rangeToShow" :key="n">
        <a
          :style="n == '...' ? 'pointer-events: none;' : ''"
          @click="changePage(n)"
          href="javascript:void(0);"
          ><span
            :style="
              n == currentPaginationInfo.currentPage ? 'font-weight: bold;' : ''
            "
            >{{ n }}&ensp;</span
          ></a
        >
      </li>
    </ul>
    <a
      @click="nextPage()"
      :class="{
        'bt-right-disabled':
          currentPaginationInfo.currentPage == currentPaginationInfo.totalPages,
      }"
      href="javascript:void(0);"
      class="bt-right"
    ></a>
  </div>
</template>

<script>
export default {
  name: "pagination",
  props: {
    currentPaginationInfo: Object,
    requestPagination: Object,
    callback: Function,
  },
  computed: {
    rangeToShow: function () {
      let currentPage = this.requestPagination.page;
      let nrOfPages = this.currentPaginationInfo.totalPages;
      var delta = 2,
        range = [],
        rangeWithDots = [],
        l;

      if (nrOfPages <= 10) {
        return nrOfPages;
      }

      range.push(1);
      for (let i = currentPage - delta; i <= currentPage + delta; i++) {
        if (i < nrOfPages && i > 1) {
          range.push(i);
        }
      }
      range.push(nrOfPages);

      for (let i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          } else if (i - l !== 1) {
            rangeWithDots.push("...");
          }
        }
        rangeWithDots.push(i);
        l = i;
      }

      return rangeWithDots;
    },
  },
  methods: {
    changePage(page) {
      if (page < 1) {
        this.requestPagination.page = 1;
      } else if (page > this.currentPaginationInfo.totalPages) {
        this.requestPagination.page = this.currentPaginationInfo.totalPages;
      } else {
        this.requestPagination.page = page;
      }
      this.callback(undefined, true);
    },
    nextPage() {
      if (
        this.currentPaginationInfo.currentPage <
        this.currentPaginationInfo.totalPages
      ) {
        this.requestPagination.page++;
        this.callback(undefined, true);
      }
    },
    previousPage() {
      if (this.currentPaginationInfo.currentPage > 1) {
        this.requestPagination.page--;
        this.callback(undefined, true);
      }
    },
  },
};
</script>>