<template>
  <div class="teros_principal__content interna container">
    <div class="teros_principal__content__line-1">
      <div class="teros_principal__content__line-1__highlights">
        <div class="teros_principal__content__line-1__highlights__subject">
          <div
            class="
              teros_principal__content__line-1__highlights__subject__breadcrumb
            "
          >
            <a href="#">
              <h3
                class="
                  teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle
                "
              >
                {{ $t("views.client.transmission.general.client") }}
              </h3>
              <h2
                class="
                  teros_principal__content__line-1__highlights__subject__breadcrumb__title
                "
              >
                {{ currentUser.customerName }}
              </h2>
            </a>
          </div>
        </div>
      </div>

      <div class="teros_principal__content__line-1__information">
        <div class="teros_principal__content__line-1__information__time">
          <p v-if="!newObj" class="teros_principal__content__line-1__information__time__text">
            <img
              class="
                teros_principal__content__line-1__information__time__text__icon
              "
              src="/static/img/icons/relogio.png"
              alt=""
            />
            {{
              $t("views.shared.createdDate", {
                date: creationDate,
                time: creationTime,
              })
            }}
            {{
              lastUpdateDate
                ? "| " +
                  $t("views.shared.changedDate", {
                    date: lastUpdateDate,
                    time: lastUpdateTime,
                  })
                : ""
            }}
          </p>
        </div>
      </div>
    </div>
    <!-- Barra Lateral -->
    <div class="teros_principal__content__column">
      <div class="teros_principal__content__column__menu-vertical">
        <ul class="teros_principal__content__column__menu-vertical__list">
          <li class="teros_principal__content__column__menu-vertical__list__item">
            <a
              href="#"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--lupa
                active
              "
              >{{ $t("views.client.transmission.general.tabTitle") }}</a
            >
          </li>

          <li class="teros_principal__content__column__menu-vertical__list__item">
            <a
              v-if="newObj"
              href="#"
              @click="showAlert"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--servicos
              "
              >{{ $t("views.client.transmission.transmissionservices.tabTitle") }}</a
            >
            <router-link
              v-else
              to="/transmissionservices"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--servicos
              "
              >{{ $t("views.client.transmission.transmissionservices.tabTitle") }}</router-link
            >
          </li>

          <li class="teros_principal__content__column__menu-vertical__list__item">
            <a
              v-if="newObj"
              href="#"
              @click="showAlert"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--compliance
              "
              >{{ $t("views.client.transmission.transmissionconsentiments.tabTitle") }}</a
            >
            <router-link
              v-else
              to="/transmissionconsentiments"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--compliance
              "
              >{{ $t("views.client.transmission.transmissionconsentiments.tabTitle") }}</router-link
            >
          </li>

          <li class="teros_principal__content__column__menu-vertical__list__item">
            <a
              v-if="newObj"
              href="#"
              @click="showAlert"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--servicos
              "
              >{{ $t("views.client.transmission.transmissionapis.tabTitle") }}</a
            >
            <router-link
              v-else
              to="/transmissionapis"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--servicos
              "
              >{{ $t("views.client.transmission.transmissionapis.tabTitle") }}</router-link
            >
          </li>

          <li class="teros_principal__content__column__menu-vertical__list__item">
            <a
              v-if="newObj"
              href="#"
              @click="showAlert"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--registros
              "
              >{{ $t("views.client.transmission.transmissionregistries.tabTitle") }}</a
            >
            <router-link
              v-else
              to="/transmissionregistries"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--registros
              "
              >{{ $t("views.client.transmission.transmissionregistries.tabTitle") }}</router-link
            >
          </li>

          <li class="teros_principal__content__column__menu-vertical__list__item">
            <a
              v-if="newObj"
              href="#"
              @click="showAlert"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--gestao
              "
              >{{ $t("views.client.transmission.transmissionlogs.tabTitle") }}</a
            >
            <router-link
              v-else
              to="/transmissionlogs"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--gestao
              "
              >{{ $t("views.client.transmission.transmissionlogs.tabTitle") }}</router-link
            >
          </li>
        </ul>
      </div>

      <div class="teros_principal__content__column__info">
        <div id="accordion">
          <form @submit.prevent="createOrUpdate" method="post">
            <div class="teros_principal__content__accordion__card mx0">
              <div class="teros_principal__content__accordion__card__header" id="headingOne">
                <h2
                  class="
                    teros_principal__content__accordion__card__header__title
                  "
                >
                  <button
                    class="
                      teros_principal__content__accordion__card__header__title__btn
                    "
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    {{ $t("views.client.transmission.general.tabTitle") }}
                  </button>
                </h2>
              </div>

              <div
                id="collapseOne"
                class="
                  teros_principal__content__accordion__card__header__collapse
                "
              >
                <div
                  class="
                    teros_principal__content__accordion__card__header__collapse__body
                  "
                >
                  <div
                    class="
                      teros_principal__content__accordion__card__header__collapse__body__form
                    "
                  >
                    <div class="form-group">
                      <div class="custom-control custom-switch">
                        <input type="checkbox" checked class="custom-control-input" id="customSwitch1" v-model="transmission.active" />
                        <label class="custom-control-label" for="customSwitch1">{{ $t("views.shared.activeSwitch") }}</label>
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="col">
                        <label for="formGroupExampleInput2"
                          >{{ $t("views.client.transmission.general.content.brand") }}*
                          <img src="/static/img/icons/tooltip.png" data-toggle="tooltip" data-placement="right" :title="$t('views.client.transmission.general.toolTips.brand')"
                        /></label>
                        <custom-input :id="'formGroupExampleInput2'" v-model="transmission.brand"></custom-input>
                      </div>
                    </div>

                    <div class="teros_principal__content__accordion__card">
                      <div
                        class="
                          teros_principal__content__accordion__card__header
                        "
                        id="headingSecond2"
                      >
                        <h2
                          class="
                            teros_principal__content__accordion__card__header__title
                          "
                        >
                          <button
                            class="
                              teros_principal__content__accordion__card__header__title__btn
                            "
                            type="button"
                            data-toggle="collapse"
                            data-target="#fase2"
                            aria-expanded="true"
                            aria-controls="fase2"
                          >
                            {{ $t("views.client.transmission.general.phase2Config") }}
                          </button>
                          <hr />
                        </h2>
                      </div>
                      <div
                        id="fase2"
                        class="
                          teros_principal__content__accordion__card__header__collapse
                        "
                      >
                        <div
                          class="
                            teros_principal__content__accordion__card__header__collapse__body
                            pt0
                          "
                          style="padding: 0 40px"
                        >
                          <div class="form-group row">
                            <div class="col">
                              <label for="integrationBaseURL"
                                >{{ $t("views.client.transmission.general.integrationBaseURL") }}*
                                <img
                                  src="/static/img/icons/tooltip.png"
                                  data-toggle="tooltip"
                                  data-placement="right"
                                  :title="$t('views.client.transmission.general.toolTips.integrationBaseURL')"
                                  alt=""
                              /></label>
                              <custom-input :id="'integrationBaseURL'" :regex="'.*'" :maxlength="'250'" v-model="transmission.integrationBaseURL"></custom-input>
                            </div>
                          </div>
                          <div class="form-group row">
                            <div class="col">
                              <label for="webhook"
                                >{{ $t("views.client.transmission.general.webhook") }}*
                                <img src="/static/img/icons/tooltip.png" data-toggle="tooltip" data-placement="right" :title="$t('views.client.transmission.general.toolTips.webhook')" alt=""
                              /></label>
                              <custom-input :id="'webhook'" :regex="'.*'" :maxlength="'250'" v-model="transmission.webhook"></custom-input>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="teros_principal__content__accordion__card">
                      <div
                        class="
                          teros_principal__content__accordion__card__header
                        "
                        id="headingSecond"
                      >
                        <h2
                          class="
                            teros_principal__content__accordion__card__header__title
                          "
                        >
                          <button
                            class="
                              teros_principal__content__accordion__card__header__title__btn
                            "
                            type="button"
                            data-toggle="collapse"
                            data-target="#fase3"
                            aria-expanded="true"
                            aria-controls="fase3"
                          >
                            {{ $t("views.client.transmission.general.phase3Config") }}
                          </button>
                          <hr />
                        </h2>
                      </div>
                      <div
                        id="fase3"
                        class="
                          teros_principal__content__accordion__card__header__collapse
                        "
                      >
                        <div
                          class="
                            teros_principal__content__accordion__card__header__collapse__body
                            pt0
                          "
                          style="padding: 0 40px"
                        >
                          <div class="form-group row">
                            <div class="col">
                              <label for="paymentInitiationURL"
                                >{{ $t("views.client.transmission.general.paymentInitiationURL") }}*
                                <img src="/static/img/icons/tooltip.png" data-toggle="tooltip" data-placement="right" :title="$t('views.client.transmission.general.toolTips.paymentInitiationURL')"
                              /></label>
                              <custom-input :id="'paymentInitiationURL'" :regex="'.*'" :maxlength="'250'" v-model="transmission.paymentInitiationURL"></custom-input>
                            </div>
                          </div>

                          <div class="form-group row">
                            <div class="col">
                              <label for="paymentInitiationConsultURL"
                                >{{ $t("views.client.transmission.general.paymentInitiationConsultURL") }}*
                                <img
                                  src="/static/img/icons/tooltip.png"
                                  data-toggle="tooltip"
                                  data-placement="right"
                                  :title="$t('views.client.transmission.general.toolTips.paymentInitiationQueryURL')"
                                  alt=""
                              /></label>
                              <custom-input :id="'paymentInitiationConsultURL'" :regex="'.*'" :maxlength="'250'" v-model="transmission.paymentInitiationConsultURL"></custom-input>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <authorization-form :apiAuthentication="transmission.apiAuthentication" :showDcrType="showDcrType" :customHeaders="customHeaders" @onChangeFlow="onChangeFlowType" />

            <div
              class="
                form-group
                teros_principal__content__column__info__form__combo
              "
              style="margin-top: 0; margin-bottom: 40px; text-align: right"
            >
              <button
                class="
                  teros_principal__content__column__info__form__combo__salvar
                "
                title="Salvar"
              >
                <img src="/static/img/icons/disquete.png" alt="Salvar" />{{ $t("views.shared.saveButton") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { cognito, http } from "@/_helpers";
import CustomInput from "@/_components/CustomInput.vue";
import AuthorizationForm from "@/_components/AuthorizationForm.vue";
import moment from "moment";

export default {
  data() {
    return {
      currentUser: cognito.currentUserValue,
      newObj: true,
      transmission: {
        active: true,
        apiAuthentication: {
          dynamicClientRegistrationType: 1,
          authenticationFlowType: 1,
        },
      },
      customHeaders: [],
      showDcrType: false,
    };
  },
  components: {
    CustomInput,
    AuthorizationForm,
  },
  computed: {
    creationDate() {
      if (this.transmission.creationDate) {
        return moment(String(this.transmission.creationDate)).format("DD/MM/YYYY");
      }
      return "";
    },
    creationTime() {
      if (this.transmission.creationDate) {
        return moment(String(this.transmission.creationDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("HH:mm");
      }
      return "";
    },
    lastUpdateDate() {
      if (this.transmission.lastUpdateDate) {
        return moment(String(this.transmission.lastUpdateDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
    lastUpdateTime() {
      if (this.transmission.lastUpdateDate) {
        return moment(String(this.transmission.lastUpdateDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("HH:mm");
      }
      return "";
    },
  },
  mounted() {
    this.$emit("message", this.$route);
    http.get("/api/transmission/get/" + this.currentUser.customerHash).then((response) => {
      if (response.data) {
        this.transmission = response.data;
        this.newObj = false;
        if (this.transmission.apiAuthentication == null) {
          this.transmission.apiAuthentication = {
            dynamicClientRegistrationType: 1,
            authenticationFlowType: 1,
            authorizationParameterType: 1,
            authenticationParameterType: 1,
            tokenType: 1,
            codeChallengeMethod: 1,
          };
        }
        if (this.transmission.apiAuthentication.customHeaderFields != null) {
          this.customHeaders = this.customHeaders.concat(JSON.parse(this.transmission.apiAuthentication.customHeaderFields));
        }
        this.customHeaders.push({ field: "", value: "" });
      } else {
        this.transmission.customerHash = this.currentUser.customerHash;
        this.transmission.customerName = this.currentUser.customerName;
      }
    });
  },
  methods: {
    goBack() {
      router.go(-1);
    },
    showAlert() {
      Vue.$toast.info(this.$i18n.t("views.client.transmission.general.alert.saveBefore"));
    },
    createOrUpdate() {
      let invalidCustomHeaders = this.customHeaders.filter((x) => (x.field == "" && x.value != "") || (x.field != "" && x.value == ""));
      if (invalidCustomHeaders.length > 0) {
        Vue.$toast.error(this.$i18n.t("views.authorizationForm.error.customHeaderInvalid"));
        return;
      }
      let customHeadersCleaned = this.customHeaders.filter((x) => x.field != "" && x.value != "");
      this.transmission.apiAuthentication.customHeaderFields = JSON.stringify(customHeadersCleaned);

      if (this.newObj) {
        http.post("/api/transmission/create/", this.transmission).then((response) => {
          this.transmission = response.data;
          this.newObj = false;
          this.$emit("success");
        });
      } else {
        http.put("/api/transmission/update/", this.transmission).then(() => {
          this.$emit("success");
        });
      }
    },
    onChangeFlowType(value) {
      this.transmission.apiAuthentication = { dynamicClientRegistrationType: this.transmission.apiAuthentication.dynamicClientRegistrationType };
      this.transmission.apiAuthentication.authenticationFlowType = value;
      this.customHeaders = [];
      this.customHeaders.push({ field: "", value: "" });
    },
  },
};
</script>
