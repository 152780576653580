<template>
  <div>
    <label :for="id">{{ title }}</label>
    <div>
      <select
        v-if="multiple"
        multiple
        data-live-search="true"
        title=""
        class="selectpicker"
        :id="id"
        v-model="hashValues"
      >
        <option v-for="item in options" :value="item.hash" :key="item.hash" :disabled="disabled">
          {{ item.name }}
        </option>
      </select>
      <select
        v-else
        data-live-search="true"
        title=""
        class="selectpicker"
        :id="id"
        v-model="hashValue"
      >
        <option selected disabled value></option>
        <option v-for="item in options" :value="item.hash" :key="item.hash" :disabled="disabled">
          {{ item.name }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "live-select",
  props: {
    id: {
      type: String,
      default: "selectList",
    },
    title: {
      type: String,
      default: "",
    },
    multiple: Boolean,
    disabled: Boolean,
    hash: {
      type: String,
      default: null,
    },
    hashs: {
      type: Array,
      default() {
        return [];
      },
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  updated: function () {
    $("#" + this.id).selectpicker("refresh");
  },
  computed: {
    hashValue: {
      get: function () {
        return this.hash;
      },
      set: function (value) {
        this.$emit("onChange", value);
      },
    },
    hashValues: {
      get: function () {
        return this.hashs;
      },
      set: function (value) {
        this.$emit("onChange", value);
      },
    },
  },
};
</script>