<template>
  <div>
    <button v-if="isButton" type="button" class="teros_principal__content__column__info__form__combo__btn-contrato" style="width: 270px" @click="getfileUrl">
      {{ title }}
    </button>

    <a v-if="!isButton" href="#" @click="getfileUrl">{{ title }}</a>
  </div>
</template>

<script>
import { http, router, i18n } from "@/_helpers";
import Vue from "vue";

export default {
  name: "pdf-view",
  data() {
    return {
      isButton: true,
    };
  },
  props: {
    fileName: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "button",
    },
    title: {
      type: String,
      default: i18n.t("views.pdfView.component.title"),
    },
  },
  mounted() {
    if (this.type === "link") {
      this.isButton = false;
    }
  },
  methods: {
    getfileUrl() {
      if (this.fileName) {
        http.get(`/api/util/get/asseturl?filename=${this.fileName}`).then((response) => {
          router.push({
            name: "pdfviewpage",
            params: { fileUrl: response.data },
          });
        });
      } else {
        console.log("n caiu");

        Vue.$toast.info(i18n.t("views.pdfView.component.noPdfMessage"));
      }
    },
  },
};
</script>
