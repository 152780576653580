<template>
    <div class="teros_principal__content interna container">
        <div class="teros_principal__content__line-1 line">
            <div class="teros_principal__content__line-1__highlights">
                <div class="teros_principal__content__line-1__highlights__subject">
                    <router-link to="/categories">
                        <div
                        class="
                            teros_principal__content__line-1__highlights__subject__breadcrumb
                        "
                        >
                        <h3
                            class="
                            teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle
                            "
                        >
                            Teros Openbanking
                        </h3>
                        <h2
                            class="
                            teros_principal__content__line-1__highlights__subject__breadcrumb__title
                            "
                        >
                            {{ $t("views.shared.menu.config") }}
                        </h2>
                        </div>
                    </router-link>
                <div class="teros_principal__arrow"></div>
                <router-link :to="'/resourcetype?hash=' + resourceType.hash">
                    <div
                    class="
                        teros_principal__content__line-1__highlights__subject__breadcrumb
                    "
                    >
                    <h3
                        class="
                        teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle
                        "
                    >
                        {{
                        $t(
                            "views.teros.integrations.service.content.general.resourceType"
                        )
                        }}
                    </h3>
                    <h2
                        class="
                        teros_principal__content__line-1__highlights__subject__breadcrumb__title
                        "
                    >
                        {{ resourceType.name }}
                    </h2>
                    </div>
                </router-link>
                <div class="teros_principal__arrow"></div>
                <div
                    class="
                    teros_principal__content__line-1__highlights__subject__breadcrumb
                    "
                >
                    <h3
                    class="
                        teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle
                    "
                    >
                    {{
                        $t("views.teros.config.groupings.content.title")
                    }}
                    </h3>
                    <h2
                    v-if="newObj"
                    class="
                        teros_principal__content__line-1__highlights__subject__breadcrumb__title
                        active
                    "
                    >
                    {{ $t("views.teros.config.groupings.newObject") }}
                    </h2>
                    <h2
                    v-else
                    class="
                        teros_principal__content__line-1__highlights__subject__breadcrumb__title
                        active
                    "
                    >
                    {{ grouping.name }}
                    </h2>
                </div>
                </div>
            </div>

            <div class="teros_principal__content__line-1__information">
                <div class="teros_principal__content__line-1__information__time">
                    <p
                        v-if="!newObj"
                        class="teros_principal__content__line-1__information__time__text"
                    >
                        <img
                        class="
                            teros_principal__content__line-1__information__time__text__icon
                        "
                        src="/static/img/icons/relogio.png"
                        alt=""
                        />
                        {{
                        $t("views.shared.createdDate", {
                            date: creationDate,
                            time: creationTime,
                        })
                        }}
                        {{
                        lastUpdateDate
                            ? "| " +
                            $t("views.shared.changedDate", {
                                date: lastUpdateDate,
                                time: lastUpdateTime,
                            })
                            : ""
                        }}
                    </p>

                    <button
                        type="button"
                        @click="goBack"
                        class="teros_principal__content__line-1__information__time__btn"
                    >
                        <img src="/static/img/icons/seta-esquerda.png" alt="" />
                        {{ $t("views.shared.backButton") }}
                    </button>
                </div>
            </div>
        </div>
        
        <div id="accordion">
            <div class="teros_principal__content__accordion__card">
                <div
                class="
                    teros_principal__content__accordion__card__header
                    accordion-off
                "
                id="headingOne"
                >
                <h2 class="teros_principal__content__accordion__card__header__title">
                    <button
                    class="
                        teros_principal__content__accordion__card__header__title__btn
                    "
                    type="button"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                    >
                    {{ $t("views.teros.config.resourceType.content.edit.title") }}
                    </button>
                    <hr />
                </h2>
                </div>

                <div
                id="collapseOne"
                class="teros_principal__content__accordion__card__header__collapse"
                >
                    <div
                        class="
                        teros_principal__content__accordion__card__header__collapse__body
                        "
                    >
                        <form
                        @submit.prevent="createOrUpdate"
                        class="
                            teros_principal__content__accordion__card__header__collapse__body__form
                        "
                        method="post"
                        >
                        <div class="form-group row">
                            <div class="col">
                            <label for="formGroupExampleInput"
                                >{{
                                $t("views.teros.config.resourceType.content.edit.name")
                                }}*
                                <img
                                src="/static/img/icons/tooltip.png"
                                data-toggle="tooltip"
                                data-placement="right"
                                :title="
                                    $t(
                                    'views.teros.config.resourceType.toolTip.resourceType'
                                    )
                                "
                                />
                            </label>
                            <custom-input
                                :id="'formGroupExampleInput'"
                                :regex="'[a-zA-Z0-9\u00C0-\u00ff - _]'"
                                v-model="grouping.name"
                            ></custom-input>
                            </div>
                            <div class="col">
                            <label for="formGroupExampleInput2"
                                >{{
                                $t("views.teros.config.groupings.content.edit.description")
                                }}*
                                <img
                                src="/static/img/icons/tooltip.png"
                                data-toggle="tooltip"
                                data-placement="right"                     
                                :title="
                                    $t(
                                    'views.teros.config.groupings.toolTip.description'
                                    )
                                "
                                />
                            </label>
                            <custom-input
                                :id="'formGroupExampleInput2'"
                                :regex="'[a-zA-Z0-9\u00C0-\u00ff -]'"
                                v-model="grouping.description"
                            ></custom-input>
                            </div>
                        </div>

                        <div
                            v-if="!newObj"
                            class="
                            teros_principal__content__accordion__card__header__collapse__body
                            pt0
                            "
                            style="padding: 0 40px"
                        >
                            <div class="teros_principal__content__table">
                            <div class="teros_principal__content__table__header">
                                <div
                                class="teros_principal__content__table__header__filters"
                                >
                                <div class="form-group">
                                    <div class="search-group">
                                    <input
                                        type="text"
                                        class="input-field"
                                        :placeholder="
                                        $t('views.shared.searchBar.searchLabel')
                                        "
                                        maxlength="30"
                                        onkeydown="return event.keyCode == 13 ? event.preventDefault() : true"
                                        v-model="textSearchValue"
                                    />
                                    <button
                                        @click="makeTextSearch()"
                                        type="button"
                                        class="search-button"
                                    />
                                    </div>
                                </div>

                                <itens-per-page
                                    :request-pagination="requestPagination"
                                    :callback="makeTextSearch"
                                ></itens-per-page>
                                </div>
                            </div>

                            <div class="teros_principal__content__table__body">
                                <table
                                id="table"
                                class="teros_principal__content__table__body__table"
                                data-toggle="table"
                                data-height="600"
                                data-url=""
                                >
                                <thead>
                                    <tr>
                                    <th data-field="id" data-sortable="true">ID</th>
                                    <th data-field="a">
                                        {{
                                        $t(
                                            "views.teros.config.resourceType.content.permissions.header.name"
                                        )
                                        }}
                                    </th>
                                    <th data-field="d">
                                        {{ $t("views.shared.table.header.createdAt") }}
                                    </th>
                                    <th data-field="e">
                                        {{ $t("views.shared.table.header.changedAt") }}
                                    </th>
                                    <th data-field="f" style="text-align: center">
                                        {{ $t("views.shared.table.header.options") }}
                                    </th>
                                    <th data-field="g" style="text-align: center">
                                        {{
                                        $t("views.shared.table.header.deactivateActivate")
                                        }}
                                    </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                    v-for="(item, index) in permissions"
                                    :key="item.hash"
                                    >
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ item.name }}</td>
                                    <td>{{ item.creationDate | formatDate }}</td>
                                    <td>{{ item.lastUpdateDate | formatDate }}</td>

                                    <td
                                        style="
                                        width: 100px;
                                        padding-left: 0;
                                        padding-right: 0;
                                        text-align: center;
                                        "
                                    >
                                        <div
                                        class="
                                            teros_principal__content__table__body__table__options
                                        "
                                        >
                                        <router-link
                                            :to="
                                            '/permission?resourceTypeHash=' +
                                            resourceType.hash +
                                            '&groupingHash=' +
                                            grouping.hash +
                                            '&hash=' +
                                            item.hash
                                            "
                                            class="
                                            teros_principal__content__table__body__table__options__item
                                            "
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Visualizar/Editar"
                                        >
                                            <div>
                                            <img
                                                src="/static/img/icons/editar.svg"
                                                alt=""
                                            />
                                            </div>
                                        </router-link>

                                        <a
                                            @click="prepareRemove(item.hash, index)"
                                            class="
                                            teros_principal__content__table__body__table__options__item
                                            "
                                            data-toggle="modal"
                                            data-target="#deleteModal"
                                            data-placement="top"
                                            title="Deletar"
                                        >
                                            <div>
                                            <img
                                                src="/static/img/icons/deletar.svg"
                                                alt=""
                                            />
                                            </div>
                                        </a>
                                        </div>
                                    </td>
                                    <td style="text-align: center">
                                        <div class="custom-control custom-switch">
                                        <input
                                            type="checkbox"
                                            class="custom-control-input"
                                            :id="'customSwitch' + index"
                                            v-model="item.active"
                                            @change="changeStatus(item.hash)"
                                        />
                                        <label
                                            class="custom-control-label"
                                            :for="'customSwitch' + index"
                                        ></label>
                                        </div>
                                    </td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>

                            <div class="teros_principal__content__table__footer">
                                <div
                                v-if="loading"
                                class="teros_principal__content__table__footer__loader"
                                >
                                <img src="/static/img/icons/ajax-loader.gif" alt="" />
                                <span>{{ $t("views.shared.loadingResults") }}</span>
                                </div>

                                <pagination
                                :request-pagination="requestPagination"
                                :callback="makeTextSearch"
                                :current-pagination-info="currentPaginationInfo"
                                ></pagination>
                                <div
                                class="
                                    teros_principal__content__table__footer__pagination
                                "
                                >
                                <router-link
                                    :to="
                                    '/permission?resourceTypeHash=' + resourceType.hash + '&groupingHash=' + grouping.hash
                                    "
                                    title="Nova Permissão"
                                    class="
                                    teros_principal__content__table__footer__add-item
                                    "
                                >
                                    <img src="/static/img/icons/plus.png" alt="" />{{
                                    $t("views.shared.includeButton")
                                    }}
                                </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group align-right">
                        <button
                        class="
                            teros_principal__content__column__info__form__table__plus
                            mb0
                        "
                        >
                        <img src="/static/img/icons/disquete.png" alt="Salvar" />{{
                            $t("views.shared.saveButton")
                        }}
                        </button>
                    </div>
                    </form>
                </div>
                </div>
            </div>
        </div>

        <div
        class="modal fade teros-modal"
        id="deleteModal"
        tabindex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
        >
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="deleteModalLabel">
                <img src="/static/img/icons/atencao-modal-red.png" alt="" />
                </h5>
                <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                >
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                {{ $t("views.shared.modal.delete.confirmMessage") }}
            </div>
            <div class="modal-footer">
                <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                >
                {{ $t("views.shared.modal.delete.cancelButton") }}
                </button>
                <button
                type="button"
                class="btn btn-primary btn-primary--red"
                data-dismiss="modal"
                v-on:click="remove"
                >
                {{ $t("views.shared.modal.delete.deleteButton") }}
                </button>
            </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import { http, router } from "@/_helpers";

import CustomInput from "@/_components/CustomInput.vue";
import ItensPerPage from "@/_components/ItensPerPage.vue";
import Pagination from "@/_components/Pagination.vue";

import moment from "moment";

export default {
    data() {
        return {
            newObj: true,
            resourceType: {},
            index: null,
            grouping: { active: true },
            permissions: [],
            permissionHash: null,
            loading: true,
            textSearchValue: "",
            oldTextSearchValue: "",
            currentPaginationInfo: null,
            requestPagination: { page: 1, take: 10 },
        };
    },
    components: {
        CustomInput,
        Pagination,
        ItensPerPage,
    },
    filters: {
    formatDate(value) {
        if (value) {
            return moment(String(value))
            .subtract(new Date().getTimezoneOffset(), "m")
            .format("DD/MM/YYYY");
        }
        return "";
        },
    },
    computed: {
        creationDate() {
        if (this.grouping.creationDate) {
            return moment(String(this.grouping.creationDate))
            .subtract(new Date().getTimezoneOffset(), "m")
            .format("DD/MM/YYYY");
        }
        return "";
        },
        creationTime() {
        if (this.grouping.creationDate) {
            return moment(String(this.grouping.creationDate))
            .subtract(new Date().getTimezoneOffset(), "m")
            .format("HH:mm");
        }
        return "";
        },
        lastUpdateDate() {
        if (this.grouping.lastUpdateDate) {
            return moment(String(this.grouping.lastUpdateDate))
            .subtract(new Date().getTimezoneOffset(), "m")
            .format("DD/MM/YYYY");
        }
        return "";
        },
        lastUpdateTime() {
        if (this.grouping.lastUpdateDate) {
            return moment(String(this.grouping.lastUpdateDate))
            .subtract(new Date().getTimezoneOffset(), "m")
            .format("HH:mm");
        }
        return "";
        },
    },
    mounted() {
        this.$emit("message", this.$route);
        const hash = this.$route.query.hash;
        const resourceTypeHash = this.$route.query.resourceTypeHash;
        
        this.getResourceTypeByHash(resourceTypeHash);
        
        if (hash) {
            this.newObj = false;
            this.getGroupingByHash(hash);
            this.getAssociatedPermissions(hash);
        }
        
    },
    methods: {
        getResourceTypeByHash(resourceTypeHash) {
            if (resourceTypeHash) {
                http
                    .get("/api/resourcetype/get/" + resourceTypeHash)
                    .then((response) => {
                        this.resourceType = response.data;
                    })
                    .finally(() => {this.loading = false});
            }
        },
        getGroupingByHash(hash) {
            if (hash) {
                http
                    .get("/api/grouping/get/" + hash)
                    .then((response) => {
                        this.grouping = response.data;
                    })
                    .finally(() => {this.loading = false});
            }
        },
        getAssociatedPermissions(groupingHash) {
            if (groupingHash) {
                http
                    .get("/api/permission/search/" + groupingHash)
                    .then((response) => {
                        if (response.data && response.data.items) {
                            this.permissions = response.data.items;
                        }
                    })
                    .finally(() => {});
            }
        },
        goBack() {
            router.push({
                name: "resourcetypepage",
                query: { hash: this.resourceType.hash },
            });
        },
        createOrUpdate() {
            if (this.newObj) {
                this.grouping.resourceTypeHash = this.resourceType.hash;

                http
                    .post("/api/grouping/create", this.grouping)
                    .then((response) => {
                        if (response.data && response.status === 200 || response.status === 201) {
                            this.grouping = response.data;
                            this.$emit("success");
                            this.goBack();
                        }
                    });
            } else {
                http
                    .put("/api/grouping/update", this.grouping)
                    .then((response) => {
                        if (response.data && response.status === 200 || response.status === 201) {
                            this.grouping = response.data;
                            this.$emit("success");
                            this.goBack();
                        }
                    });
            }
        },
        makeTextSearch(event, isChangePage = false) {

        },
        changeStatus(hash) {
            http
                .get("/api/permission/get/" + hash)
                .then((response) => {
                    this.permission = response.data;
                    this.permission.active = !this.permission.active;
                    
                    http
                        .put("/api/permission/update", this.permission)
                        .then((response) => {
                            if (this.permission.active) {
                                Vue.$toast.success(
                                this.$i18n.t(
                                    "views.teros.config.resourceType.content.permissions.modal.activatedMessage"
                                )
                                );
                            } else {
                                Vue.$toast.success(
                                this.$i18n.t(
                                    "views.teros.config.resourceType.content.permissions.modal.deactivatedMessage"
                                )
                                );
                            }
                        })
                        .catch((error) => {
                            console.log('error => ', error);
                            this.getAssociatedPermissions();
                        })
                        .finally(() => {});
                })
                .finally(() => {});
        },
        prepareRemove(hash, index) {
            this.permissionHash = hash;
            this.index = index;
        },
        remove() {
            http.remove("/api/permission/delete/" + this.permissionHash).then(() => {
                Vue.$toast.success(
                this.$i18n.t(
                    "views.teros.config.resourceType.content.permissions.modal.deletedMessage"
                )
                );
                Vue.delete(this.permissions, this.index);
            });
        },
    },
}
</script>