<template>
  <div class="teros_principal__content interna container">
    <div class="teros_principal__content__line-1">
      <div class="teros_principal__content__line-1__highlights">
        <div class="teros_principal__content__line-1__highlights__subject">
          <div
            class="
              teros_principal__content__line-1__highlights__subject__breadcrumb
            "
          >
            <router-link to="/transmission">
              <h3
                class="
                  teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle
                "
              >
                {{ $t("views.client.transmission.general.client") }}
              </h3>
              <h2
                class="
                  teros_principal__content__line-1__highlights__subject__breadcrumb__title
                "
              >
                {{ currentUser.customerName }}
              </h2>
            </router-link>
          </div>
        </div>
      </div>

      <div class="teros_principal__content__line-1__information">
        <div class="teros_principal__content__line-1__information__time">
          <p class="teros_principal__content__line-1__information__time__text">
            <img
              class="
                teros_principal__content__line-1__information__time__text__icon
              "
              src="/static/img/icons/relogio.png"
              alt=""
            />
            {{
              $t("views.shared.createdDate", {
                date: creationDate,
                time: creationTime,
              })
            }}
            {{
              lastUpdateDate
                ? "| " +
                  $t("views.shared.changedDate", {
                    date: lastUpdateDate,
                    time: lastUpdateTime,
                  })
                : ""
            }}
          </p>

          <button
            type="button"
            @click="goBack"
            class="teros_principal__content__line-1__information__time__btn"
          >
            <img src="/static/img/icons/seta-esquerda.png" alt="" />
            {{ $t("views.shared.backButton") }}
          </button>
        </div>
      </div>
    </div>

    <div class="teros_principal__content__column">
      <div class="teros_principal__content__column__menu-vertical">
        <ul class="teros_principal__content__column__menu-vertical__list">
          <li
            class="teros_principal__content__column__menu-vertical__list__item"
          >
            <router-link
              to="/transmission"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--lupa
              "
              >{{
                $t("views.client.transmission.general.tabTitle")
              }}</router-link
            >
          </li>

          <li
            class="teros_principal__content__column__menu-vertical__list__item"
          >
            <router-link
              to="/transmissionservices"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--servicos
              "
              >{{
                $t("views.client.transmission.transmissionservices.tabTitle")
              }}</router-link
            >
          </li>

          <li
            class="teros_principal__content__column__menu-vertical__list__item"
          >
            <router-link
              to="/transmissionconsentiments"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--compliance
              "
              >{{
                $t(
                  "views.client.transmission.transmissionconsentiments.tabTitle"
                )
              }}</router-link
            >
          </li>

          <li
            class="teros_principal__content__column__menu-vertical__list__item"
          >
            <a
              href="#"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--servicos
                active
              "
              >{{
                $t("views.client.transmission.transmissionapis.tabTitle")
              }}</a
            >
          </li>

          <li
            class="teros_principal__content__column__menu-vertical__list__item"
          >
            <router-link
              to="/transmissionregistries"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--registros
              "
              >{{
                $t("views.client.transmission.transmissionregistries.tabTitle")
              }}</router-link
            >
          </li>

          <li
            class="teros_principal__content__column__menu-vertical__list__item"
          >
            <router-link
              to="/transmissionlogs"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--gestao
              "
              >{{
                $t("views.client.transmission.transmissionlogs.tabTitle")
              }}</router-link
            >
          </li>
        </ul>
      </div>

      <div class="teros_principal__content__column__info">
        <div id="accordion">
          <div
            v-for="(item, index) in apis"
            :key="item.hash"
            class="teros_principal__content__accordion__card mx0"
          >
            <div
              class="teros_principal__content__accordion__card__header"
              id="headingOne"
            >
              <h2
                class="teros_principal__content__accordion__card__header__title"
              >
                <button
                  class="
                    teros_principal__content__accordion__card__header__title__btn
                  "
                  type="button"
                  data-toggle="collapse"
                  :data-target="'#collapse' + index"
                  aria-expanded="false"
                  :aria-controls="'collapse' + index"
                >
                  {{ item.apiName }}
                </button>
              </h2>
            </div>

            <div
              :id="'collapse' + index"
              class="
                teros_principal__content__accordion__card__header__collapse
                collapse
              "
            >
              <div
                class="
                  teros_principal__content__accordion__card__header__collapse__body
                "
              >
                <div
                  class="
                    teros_principal__content__accordion__card__header__collapse__body__form
                  "
                >
                  <form @submit.prevent="update(index)" method="post">
                    <div class="form-group">
                      <label :for="'healthCheckURL' + item.hash"
                        >{{
                          $t(
                            "views.client.transmission.transmissionapis.content.healthCheckUrl"
                          )
                        }}
                        <img
                          src="/static/img/icons/tooltip.png"
                          data-toggle="tooltip"
                          data-placement="right"
                          :title="
                            $t(
                              'views.client.transmission.transmissionapis.toolTip.healthCheckUrl'
                            )
                          "
                        />
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        :id="'healthCheckURL' + item.hash"
                        v-model="item.healthCheckURL"
                      />
                    </div>
                    <div class="teros_principal__content__table__body">
                      <table
                        id="table"
                        class="teros_principal__content__table__body__table"
                        data-toggle="table"
                        data-height="600"
                        data-url=""
                      >
                        <thead>
                          <tr>
                            <th data-field="id" data-sortable="true">ID</th>
                            <th data-field="a">
                              {{
                                $t(
                                  "views.client.transmission.transmissionapis.content.table.header.serviceName"
                                )
                              }}
                            </th>
                            <th data-field="b">
                              {{
                                $t(
                                  "views.client.transmission.transmissionapis.content.table.header.endpointUrl"
                                )
                              }}
                            </th>
                            <th data-field="c">
                              {{
                                $t(
                                  "views.client.transmission.transmissionapis.content.table.header.isExternal"
                                )
                              }}
                            </th>
                            <th data-field="d">
                              {{
                                $t(
                                  "views.client.transmission.transmissionapis.content.table.header.isInternal"
                                )
                              }}
                            </th>
                            <th data-field="e">
                              {{
                                $t(
                                  "views.client.transmission.transmissionapis.content.hasOutage"
                                )
                              }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(endpoint, index) in item.endpoints"
                            :key="endpoint.hash"
                          >
                            <td>{{ index + 1 }}</td>
                            <td>{{ endpoint.serviceName }}</td>
                            <td>{{ endpoint.endpointUrl }}</td>
                            <td style="text-align: center">
                              {{ endpoint.isExternal | formatBool }}
                            </td>
                            <td style="text-align: center">
                              {{ endpoint.isInternal | formatBool }}
                            </td>
                            <td
                              v-if="item.hasOutage"
                              style="text-align: center"
                            >
                              <div
                                class="custom-control custom-switch"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Desativar/Ativar"
                              >
                                <input
                                  type="checkbox"
                                  class="custom-control-input"
                                  :id="'customSwitch' + index + item.hash"
                                  v-model="endpoint.hasOutage"
                                  :disabled="!item.apiOutage.isPartial"
                                />
                                <label
                                  class="custom-control-label"
                                  :for="'customSwitch' + index + item.hash"
                                ></label>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="teros_principal__content__accordion__card">
                      <div
                        class="
                          teros_principal__content__accordion__card__header
                        "
                        id="headingSecond"
                      >
                        <h2
                          class="
                            teros_principal__content__accordion__card__header__title
                          "
                        >
                          <button
                            class="
                              teros_principal__content__accordion__card__header__title__btn
                            "
                            type="button"
                            data-toggle="collapse"
                            :data-target="'#outage' + index"
                            aria-expanded="false"
                            :aria-controls="'outage' + index"
                          >
                            {{
                              $t(
                                "views.client.transmission.transmissionapis.content.outage"
                              )
                            }}
                          </button>
                          <hr />
                        </h2>
                      </div>

                      <div
                        :id="'outage' + index"
                        class="
                          teros_principal__content__accordion__card__header__collapse
                        "
                      >
                        <div
                          class="
                            teros_principal__content__accordion__card__header__collapse__body
                            pt0
                          "
                          style="padding: 0 40px"
                        >
                          <div class="teros_principal__content__table">
                            <div
                              class="form-group row"
                              style="padding-top: 40px"
                            >
                              <div class="col">
                                <div class="custom-control custom-switch">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    :id="'hasOutage' + index"
                                    v-model="item.hasOutage"
                                  />
                                  <label
                                    class="custom-control-label"
                                    :for="'hasOutage' + index"
                                    >{{
                                      $t(
                                        "views.client.transmission.transmissionapis.content.outage"
                                      )
                                    }}</label
                                  >
                                </div>
                              </div>
                            </div>
                            <div class="form-group row" v-if="item.hasOutage">
                              <div class="col">
                                <label for="explanation"
                                  >{{
                                    $t(
                                      "views.client.transmission.transmissionapis.content.explanation"
                                    )
                                  }}
                                  <img
                                    src="/static/img/icons/tooltip.png"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    :title="
                                      $t(
                                        'views.client.transmission.transmissionapis.toolTip.explanation'
                                      )
                                    "
                                /></label>
                                <custom-input
                                  :id="'explanation' + index"
                                  :regex="'.*'"
                                  v-model="item.apiOutage.explanation"
                                ></custom-input>
                              </div>
                            </div>
                            <div class="form-group row" v-if="item.hasOutage">
                              <div class="col">
                                <label for="startDate"
                                  >{{
                                    $t(
                                      "views.client.transmission.transmissionapis.content.startDate"
                                    )
                                  }}
                                  <img
                                    src="/static/img/icons/tooltip.png"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    :title="
                                      $t(
                                        'views.client.transmission.transmissionapis.toolTip.startDate'
                                      )
                                    "
                                /></label>
                                <input
                                  :id="'startDate' + index"
                                  type="datetime-local"
                                  v-model="item.apiOutage.startDate"
                                />

                                <label for="endDate"
                                  >{{
                                    $t(
                                      "views.client.transmission.transmissionapis.content.endDate"
                                    )
                                  }}
                                  <img
                                    src="/static/img/icons/tooltip.png"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    :title="
                                      $t(
                                        'views.client.transmission.transmissionapis.toolTip.endDate'
                                      )
                                    "
                                /></label>
                                <input
                                  :id="'endDate' + index"
                                  type="datetime-local"
                                  :min="item.apiOutage.startDate"
                                  v-model="item.apiOutage.endDate"
                                />
                              </div>
                            </div>
                            <div class="form-group row" v-if="item.hasOutage">
                              <div class="col">
                                <div class="custom-control custom-switch">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    :id="'isPartial' + index"
                                    v-model="item.apiOutage.isPartial"
                                  />
                                  <label
                                    class="custom-control-label"
                                    :for="'isPartial' + index"
                                    >{{
                                      $t(
                                        "views.client.transmission.transmissionapis.content.isPartial"
                                      )
                                    }}</label
                                  >
                                </div>
                                <span style="font-size: 13px">
                                  {{
                                    $t(
                                      "views.client.transmission.transmissionapis.content.yourTimeZoneIs"
                                    )
                                  }}: <b>{{ localTimeZone }}</b
                                  >.
                                  {{
                                    $t(
                                      "views.client.transmission.transmissionapis.content.outageTimeZoneMessage"
                                    )
                                  }}.
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group align-right">
                      <button
                        class="
                          teros_principal__content__column__info__form__table__plus
                          mb0
                        "
                      >
                        <img
                          src="/static/img/icons/disquete.png"
                          alt="Salvar"
                        />{{ $t("views.shared.saveButton") }}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="loading"
            class="teros_principal__content__table__footer__loader"
          >
            <img src="/static/img/icons/ajax-loader.gif" alt="" />
            <span>{{ $t("views.shared.loadingResults") }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cognito, http, router, i18n } from "@/_helpers";
import moment from "moment";
import CustomInput from "@/_components/CustomInput.vue";
import Vue from "vue";

export default {
  data() {
    return {
      currentUser: cognito.currentUserValue,
      transmission: {},
      apis: [],
      index: 0,
      loading: true,
      localTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      apiOutage: {
        hash: "00000000-0000-0000-0000-000000000000",
        HasOutage: false,
        explanation: "",
        isPartial: false,
        startDate: this.todayDate,
        endDate: this.todayDate,
      },
    };
  },
  components: {
    CustomInput,
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
    formatBool(value) {
      if (value) {
        return i18n.t(
          "views.client.transmission.transmissionapis.content.table.header.status.yes"
        );
      }
      return i18n.t(
        "views.client.transmission.transmissionapis.content.table.header.status.no"
      );
    },
  },
  computed: {
    todayDate() {
      return moment()
        .seconds(0)
        .milliseconds(0)
        .toISOString(true)
        .split(".")[0];
    },
    creationDate() {
      if (this.transmission.creationDate) {
        return moment(String(this.transmission.creationDate)).format(
          "DD/MM/YYYY"
        );
      }
      return "";
    },
    creationTime() {
      if (this.transmission.creationDate) {
        return moment(String(this.transmission.creationDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("HH:mm");
      }
      return "";
    },
    lastUpdateDate() {
      if (this.transmission.lastUpdateDate) {
        return moment(String(this.transmission.lastUpdateDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
    lastUpdateTime() {
      if (this.transmission.lastUpdateDate) {
        return moment(String(this.transmission.lastUpdateDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("HH:mm");
      }
      return "";
    },
    lastUpdateDate() {
      if (this.transmission.lastUpdateDate) {
        return moment(String(this.transmission.lastUpdateDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
  },
  mounted() {
    this.$emit("message", this.$route);
    http
      .get(`/api/transmission/get/${this.currentUser.customerHash}`)
      .then((response) => {
        this.transmission = response.data;
        http
          .get(
            `/api/transmissionapi/list/${this.currentUser.customerHash}`,
            false
          )
          .then((response) => {
            this.apis = response.data;
            this.apis.forEach((item) => {
              if (!item.apiOutage) {
                item.apiOutage = {
                  ...this.apiOutage,
                };
                item.apiOutage.startDate = this.startDate;
                item.apiOutage.endDate = this.endDate;
              } else {
                item.apiOutage.startDate = moment
                  .utc(item.apiOutage.startDate)
                  .local()
                  .seconds(0)
                  .milliseconds(0)
                  .toISOString(true)
                  .split(".")[0];
                item.apiOutage.endDate = moment
                  .utc(item.apiOutage.endDate)
                  .local()
                  .seconds(0)
                  .milliseconds(0)
                  .toISOString(true)
                  .split(".")[0];
              }
            });
          })
          .finally(() => (this.loading = false));
      });
  },
  methods: {
    goBack() {
      router.push({ name: "transmissionpage" });
    },
    update(index) {
      let api = this.apis[index];
      api.apiOutageHash = api.apiOutage.hash;
      if (
        api.hasOutage &&
        api.apiOutage.hash == "00000000-0000-0000-0000-000000000000"
      ) {
        api.apiOutageCreateRequest = {
          explanation: api.apiOutage.explanation,
          isPartial: api.apiOutage.isPartial,
          startDate: moment(api.apiOutage.startDate).utc().format(),
          endDate: moment(api.apiOutage.endDate).utc().format(),
        };
      } else if (
        api.hasOutage &&
        api.apiOutage.hash != "00000000-0000-0000-0000-000000000000"
      ) {
        api.apiOutageUpdateRequest = {
          hash: api.apiOutage.hash,
          explanation: api.apiOutage.explanation,
          isPartial: api.apiOutage.isPartial,
          startDate: moment(api.apiOutage.startDate).utc().format(),
          endDate: moment(api.apiOutage.endDate).utc().format(),
        };
      }

      let atLeastOneIsTrue = api.endpoints
        .map((item) => item.hasOutage)
        .reduce((accumulator, currentValue) => {
          return accumulator || currentValue;
        });

      let atLeastOneExternal = api.endpoints
        .map((item) => item.isExternal)
        .reduce((accumulator, currentValue) => {
          return accumulator || currentValue;
        });

      if (api.apiOutage.isPartial && !atLeastOneIsTrue) {
        Vue.$toast.error(
          i18n.t(
            "views.client.transmission.transmissionapis.content.atLeastOneServiceSelected"
          )
        );
      } else if (!api.healthCheckURL && atLeastOneExternal) {
        Vue.$toast.error(
          i18n.t(
            "views.client.transmission.transmissionapis.content.healthCheckUrlMandatory"
          )
        );
      } else {
        http.put("/api/transmissionapi/update/", api).then(() => {
          this.$emit("success");
        });
      }
    },
  },
};
</script>