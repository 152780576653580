<template>
  <div class="teros_principal__content interna container">
    <div class="teros_principal__content__line-1">
      <div class="teros_principal__content__line-1__highlights">
        <div class="teros_principal__content__line-1__highlights__subject">
          <div
            class="
              teros_principal__content__line-1__highlights__subject__breadcrumb
            "
          >
            <router-link to="/transmission">
              <h3
                class="
                  teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle
                "
              >
                {{ $t("views.client.transmission.general.client") }}
              </h3>
              <h2
                class="
                  teros_principal__content__line-1__highlights__subject__breadcrumb__title
                "
              >
                {{ currentUser.customerName }}
              </h2>
            </router-link>
          </div>
        </div>
      </div>

      <div class="teros_principal__content__line-1__information">
        <div class="teros_principal__content__line-1__information__time">
          <p class="teros_principal__content__line-1__information__time__text">
            <img
              class="
                teros_principal__content__line-1__information__time__text__icon
              "
              src="/static/img/icons/relogio.png"
              alt=""
            />
            {{
              $t("views.shared.createdDate", {
                date: creationDate,
                time: creationTime,
              })
            }}
            {{
              lastUpdateDate
                ? "| " +
                  $t("views.shared.changedDate", {
                    date: lastUpdateDate,
                    time: lastUpdateTime,
                  })
                : ""
            }}
          </p>

          <button
            type="button"
            @click="goBack"
            class="teros_principal__content__line-1__information__time__btn"
          >
            <img src="/static/img/icons/seta-esquerda.png" alt="" />
            {{ $t("views.shared.backButton") }}
          </button>
        </div>
      </div>
    </div>

    <div class="teros_principal__content__column">
      <div class="teros_principal__content__column__menu-vertical">
        <ul class="teros_principal__content__column__menu-vertical__list">
          <li
            class="teros_principal__content__column__menu-vertical__list__item"
          >
            <router-link
              to="/transmission"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--lupa
              "
              >{{
                $t("views.client.transmission.general.tabTitle")
              }}</router-link
            >
          </li>

          <li
            class="teros_principal__content__column__menu-vertical__list__item"
          >
            <router-link
              to="/transmissionservices"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--servicos
              "
              >{{
                $t("views.client.transmission.transmissionservices.tabTitle")
              }}</router-link
            >
          </li>

          <li
            class="teros_principal__content__column__menu-vertical__list__item"
          >
            <router-link
              to="/transmissionconsentiments"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--compliance
              "
              >{{
                $t(
                  "views.client.transmission.transmissionconsentiments.tabTitle"
                )
              }}</router-link
            >
          </li>

          <li
            class="teros_principal__content__column__menu-vertical__list__item"
          >
            <router-link
              to="/transmissionapis"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--servicos
              "
              >{{
                $t("views.client.transmission.transmissionapis.tabTitle")
              }}</router-link
            >
          </li>

          <li
            class="teros_principal__content__column__menu-vertical__list__item"
          >
            <a
              href="#"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--registros
                active
              "
              >{{
                $t("views.client.transmission.transmissionregistries.tabTitle")
              }}</a
            >
          </li>

          <li
            class="teros_principal__content__column__menu-vertical__list__item"
          >
            <router-link
              to="/transmissionlogs"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--gestao
              "
              >{{
                $t("views.client.transmission.transmissionlogs.tabTitle")
              }}</router-link
            >
          </li>
        </ul>
      </div>

      <div class="teros_principal__content__column__info">
        <div class="teros_principal__content__table">
          <div class="teros_principal__content__table__header">
            <div class="teros_principal__content__table__header__filters">
              <div class="form-group">
                <div class="search-group">
                  <input
                    type="text"
                    class="input-field"
                    :placeholder="$t('views.shared.searchBar.searchLabel')"
                    maxlength="30"
                    v-model="textSearchValue"
                  />
                </div>
              </div>

              <div class="form-group">
                <input type="date" v-model="startDate" />
                <span class="date-until">{{
                  $t("views.shared.searchBar.untilDrop")
                }}</span>
                <input type="date" v-model="endDate" />
              </div>

              <div class="form-group">
                <div class="search-group">
                  <button
                    @click="makeSearch"
                    style="width: 40px; height: 51px; margin-left: -10px"
                    class="search-button"
                  ></button>
                </div>
              </div>
            </div>

            <div class="teros_principal__content__table__header__actions">
              <a href="javascript:void(0);" title="Download" @click="download()"
                ><img src="/static/img/icons/download.png" alt="Download"
              /></a>
              <!-- <a href="#" title="Imprimir"
                ><img src="/static/img/icons/printer.png" alt="Imprimir"
              /></a> -->
            </div>
          </div>

          <div class="teros_principal__content__table__header">
            <div class="teros_principal__content__table__header__filters">
              <itens-per-page
                :request-pagination="requestPagination"
                :callback="makeSearch"
              ></itens-per-page>
            </div>
          </div>

          <div class="teros_principal__content__table__body">
            <table
              id="table"
              class="teros_principal__content__table__body__table"
              data-toggle="table"
              data-height="600"
              data-url=""
            >
              <thead>
                <tr>
                  <th data-field="id" data-sortable="true">ID</th>
                  <th data-field="a">
                    {{
                      $t(
                        "views.client.transmission.transmissionregistries.table.header.compliance"
                      )
                    }}
                  </th>
                  <th data-field="c">
                    {{
                      $t(
                        "views.client.transmission.transmissionregistries.table.header.date"
                      )
                    }}
                  </th>
                  <th data-field="d">
                    {{
                      $t(
                        "views.client.transmission.transmissionregistries.table.header.appId"
                      )
                    }}
                  </th>
                  <th data-field="g">
                    {{
                      $t(
                        "views.client.transmission.transmissionregistries.table.header.localization"
                      )
                    }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in transmissionregistries"
                  :key="item.hash"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.transmissionOperationName }}</td>
                  <td>{{ item.creationDate | formatDate }}</td>
                  <td>{{ item.gatewayClientId }}</td>
                  <td>{{ item.localizationName }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="teros_principal__content__table__footer">
            <div
              v-if="loading"
              class="teros_principal__content__table__footer__loader"
            >
              <img src="/static/img/icons/ajax-loader.gif" alt="" />
              <span>{{ $t("views.shared.loadingResults") }}</span>
            </div>

            <pagination
              :request-pagination="requestPagination"
              :callback="makeSearch"
              :current-pagination-info="currentPaginationInfo"
            ></pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { cognito, http, router } from "@/_helpers";
import Pagination from "@/_components/Pagination.vue";
import ItensPerPage from "@/_components/ItensPerPage.vue";
import moment from "moment";

export default {
  data() {
    return {
      currentUser: cognito.currentUserValue,
      transmission: {},
      transmissionregistries: [],
      loading: true,
      textSearchValue: "",
      startDate: "",
      endDate: "",
      oldTextSearchValue: "",
      oldStartDate: "",
      oldEndDate: "",
      currentPaginationInfo: null,
      requestPagination: { page: 1, take: 10 },
    };
  },
  components: {
    Pagination,
    ItensPerPage,
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
  },
  computed: {
    creationDate() {
      if (this.transmission.creationDate) {
        return moment(String(this.transmission.creationDate)).format(
          "DD/MM/YYYY"
        );
      }
      return "";
    },
    creationTime() {
      if (this.transmission.creationDate) {
        return moment(String(this.transmission.creationDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("HH:mm");
      }
      return "";
    },
    lastUpdateDate() {
      if (this.transmission.lastUpdateDate) {
        return moment(String(this.transmission.lastUpdateDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
    lastUpdateTime() {
      if (this.transmission.lastUpdateDate) {
        return moment(String(this.transmission.lastUpdateDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("HH:mm");
      }
      return "";
    },
  },
  mounted() {
    this.$emit("message", this.$route);
    http
      .get(`/api/transmission/get/${this.currentUser.customerHash}`)
      .then((response) => {
        this.transmission = response.data;
        http
          .get(
            `/api/transmissionregistry/search/${this.transmission.hash}?OrderByProperty=CreationDate`,
            false
          )
          .then((response) => {
            this.transmissionregistries = response.data.items;
            this.currentPaginationInfo = response.data;
          })
          .finally(() => (this.loading = false));
      });
  },
  methods: {
    goBack() {
      router.push({ name: "transmissionpage" });
    },
    makeSearch(event, isChangePage = false) {
      let startDate = "";
      let endDate = "";

      this.checkValuesChanged(isChangePage);

      if (this.startDate) {
        startDate = this.startDate + " 00:00:00";
      }

      if (this.endDate) {
        endDate = this.endDate + " 23:59:59";
      }

      if (this.startDate > this.endDate) {
        Vue.$toast.error(
          this.$i18n.t("views.shared.searchBar.error.startDateGreaterThanEnd")
        );
        return;
      }

      if (this.textSearchValue.trim()) {
        http
          .get(
            `/api/transmissionregistry/search/${
              this.transmission.hash
            }?FilterProperty=Name&FilterValue=${this.textSearchValue.trim()}&StartDate=${startDate}&EndDate=${endDate}&Page=${
              this.requestPagination.page
            }&Take=${this.requestPagination.take}`
          )
          .then((response) => {
            this.transmissionregistries = response.data.items;
            this.currentPaginationInfo = response.data;
          })
          .finally(() => (this.loading = false));
      } else {
        http
          .get(
            `/api/transmissionregistry/search/${this.transmission.hash}?StartDate=${startDate}&EndDate=${endDate}&Page=${this.requestPagination.page}&Take=${this.requestPagination.take}`
          )
          .then((response) => {
            this.transmissionregistries = response.data.items;
            this.currentPaginationInfo = response.data;
          })
          .finally(() => (this.loading = false));
      }
    },
    checkValuesChanged(isChangePage = false) {
      if (
        this.textSearchValue != this.oldTextSearchValue ||
        this.startDate != this.oldStartDate ||
        this.endDate != this.oldEndDate
      ) {
        if (isChangePage) {
          this.textSearchValue = this.oldTextSearchValue;
          this.startDate = this.oldStartDate;
          this.endDate = this.oldEndDate;
        } else {
          this.requestPagination.page = 1;
          this.oldTextSearchValue = this.textSearchValue;
          this.oldStartDate = this.startDate;
          this.oldEndDate = this.endDate;
        }
      }
    },
    download() {
      let startDate = "";
      let endDate = "";

      this.checkValuesChanged(false);

      if (this.startDate) {
        startDate = this.startDate + " 00:00:00";
      }

      if (this.endDate) {
        endDate = this.endDate + " 23:59:59";
      }

      if (this.startDate > this.endDate) {
        Vue.$toast.error(
          this.$i18n.t("views.shared.searchBar.error.startDateGreaterThanEnd")
        );
        return;
      }

      if (this.textSearchValue.trim()) {
        http
          .get(
            `/api/transmissionregistry/csv/${
              this.transmission.hash
            }?FilterProperty=Name&FilterValue=${this.textSearchValue.trim()}&StartDate=${startDate}&EndDate=${endDate}&Page=${
              this.requestPagination.page
            }&Take=${this.requestPagination.take}`
          )
          .then((response) => {
            const blob = new Blob([response.data], { type: "text/csv" });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = "TransmissionRegistries.csv";
            link.click();
            URL.revokeObjectURL(link.href);
          })
          .finally(() => (this.loading = false));
      } else {
        http
          .get(
            `/api/transmissionregistry/csv/${this.transmission.hash}?StartDate=${startDate}&EndDate=${endDate}&Page=${this.requestPagination.page}&Take=${this.requestPagination.take}`
          )
          .then((response) => {
            const blob = new Blob([response.data], { type: "text/csv" });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = "TransmissionRegistries.csv";
            link.click();
            URL.revokeObjectURL(link.href);
          })
          .finally(() => (this.loading = false));
      }
    },
  },
};
</script>