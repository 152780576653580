var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.isButton
      ? _c(
          "button",
          {
            staticClass:
              "teros_principal__content__column__info__form__combo__btn-contrato",
            staticStyle: { width: "270px" },
            attrs: { type: "button" },
            on: { click: _vm.getfileUrl },
          },
          [_vm._v("\n    " + _vm._s(_vm.title) + "\n  ")]
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.isButton
      ? _c("a", { attrs: { href: "#" }, on: { click: _vm.getfileUrl } }, [
          _vm._v(_vm._s(_vm.title)),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }