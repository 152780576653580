var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "teros_principal__content interna container" },
    [
      _c("div", { staticClass: "teros_principal__content__line-1" }, [
        _c(
          "div",
          { staticClass: "teros_principal__content__line-1__highlights" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__line-1__highlights__subject",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__line-1__highlights__subject__breadcrumb",
                  },
                  [
                    _c("router-link", { attrs: { to: "/transmission" } }, [
                      _c(
                        "h3",
                        {
                          staticClass:
                            "teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t(
                                  "views.client.transmission.general.client"
                                )
                              ) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "h2",
                        {
                          staticClass:
                            "teros_principal__content__line-1__highlights__subject__breadcrumb__title",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.currentUser.customerName) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "teros_principal__content__line-1__information" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__line-1__information__time",
              },
              [
                _c(
                  "p",
                  {
                    staticClass:
                      "teros_principal__content__line-1__information__time__text",
                  },
                  [
                    _c("img", {
                      staticClass:
                        "teros_principal__content__line-1__information__time__text__icon",
                      attrs: { src: "/static/img/icons/relogio.png", alt: "" },
                    }),
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("views.shared.createdDate", {
                            date: _vm.creationDate,
                            time: _vm.creationTime,
                          })
                        ) +
                        "\n          " +
                        _vm._s(
                          _vm.lastUpdateDate
                            ? "| " +
                                _vm.$t("views.shared.changedDate", {
                                  date: _vm.lastUpdateDate,
                                  time: _vm.lastUpdateTime,
                                })
                            : ""
                        ) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "teros_principal__content__line-1__information__time__btn",
                    attrs: { type: "button" },
                    on: { click: _vm.goBack },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "/static/img/icons/seta-esquerda.png",
                        alt: "",
                      },
                    }),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("views.shared.backButton")) +
                        "\n        "
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "teros_principal__content__column" }, [
        _c(
          "div",
          { staticClass: "teros_principal__content__column__menu-vertical" },
          [
            _c(
              "ul",
              {
                staticClass:
                  "teros_principal__content__column__menu-vertical__list",
              },
              [
                _c(
                  "li",
                  {
                    staticClass:
                      "teros_principal__content__column__menu-vertical__list__item",
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--lupa",
                        attrs: { to: "/transmission" },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("views.client.transmission.general.tabTitle")
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass:
                      "teros_principal__content__column__menu-vertical__list__item",
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--servicos",
                        attrs: { to: "/transmissionservices" },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "views.client.transmission.transmissionservices.tabTitle"
                            )
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass:
                      "teros_principal__content__column__menu-vertical__list__item",
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--compliance",
                        attrs: { to: "/transmissionconsentiments" },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "views.client.transmission.transmissionconsentiments.tabTitle"
                            )
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass:
                      "teros_principal__content__column__menu-vertical__list__item",
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--servicos",
                        attrs: { to: "/transmissionapis" },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "views.client.transmission.transmissionapis.tabTitle"
                            )
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass:
                      "teros_principal__content__column__menu-vertical__list__item",
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--registros",
                        attrs: { to: "/transmissionregistries" },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "views.client.transmission.transmissionregistries.tabTitle"
                            )
                          )
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass:
                      "teros_principal__content__column__menu-vertical__list__item",
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass:
                          "teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--gestao active",
                        attrs: { href: "#" },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "views.client.transmission.transmissionlogs.tabTitle"
                            )
                          )
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "teros_principal__content__column__info" }, [
          _c("div", { staticClass: "teros_principal__content__table" }, [
            _c(
              "div",
              { staticClass: "teros_principal__content__table__header" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__table__header__filters",
                  },
                  [
                    _c("div", { staticClass: "form-group" }, [
                      _c("div", { staticClass: "search-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.textSearchValue,
                              expression: "textSearchValue",
                            },
                          ],
                          staticClass: "input-field",
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t(
                              "views.shared.searchBar.searchLabel"
                            ),
                            maxlength: "30",
                          },
                          domProps: { value: _vm.textSearchValue },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.textSearchValue = $event.target.value
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.startDate,
                            expression: "startDate",
                          },
                        ],
                        attrs: { type: "date" },
                        domProps: { value: _vm.startDate },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.startDate = $event.target.value
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "date-until" }, [
                        _vm._v(
                          _vm._s(_vm.$t("views.shared.searchBar.untilDrop"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.endDate,
                            expression: "endDate",
                          },
                        ],
                        attrs: { type: "date" },
                        domProps: { value: _vm.endDate },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.endDate = $event.target.value
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("div", { staticClass: "search-group" }, [
                        _c("button", {
                          staticClass: "search-button",
                          staticStyle: {
                            width: "40px",
                            height: "51px",
                            "margin-left": "-10px",
                          },
                          on: { click: _vm.makeSearch },
                        }),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__table__header__actions",
                  },
                  [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "javascript:void(0);",
                          title: "Download",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.download()
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: "/static/img/icons/download.png",
                            alt: "Download",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "teros_principal__content__table__header" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__table__header__filters",
                  },
                  [
                    _c("itens-per-page", {
                      attrs: {
                        "request-pagination": _vm.requestPagination,
                        callback: _vm.makeSearch,
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "teros_principal__content__table__body" },
              [
                _c(
                  "table",
                  {
                    staticClass: "teros_principal__content__table__body__table",
                    attrs: {
                      id: "table",
                      "data-toggle": "table",
                      "data-height": "600",
                      "data-url": "",
                    },
                  },
                  [
                    _c("thead", [
                      _c("tr", [
                        _c(
                          "th",
                          {
                            attrs: {
                              "data-field": "id",
                              "data-sortable": "true",
                            },
                          },
                          [_vm._v("ID")]
                        ),
                        _vm._v(" "),
                        _c("th", { attrs: { "data-field": "a" } }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(
                                _vm.$t(
                                  "views.client.transmission.transmissionlogs.table.header.action"
                                )
                              ) +
                              "\n                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("th", { attrs: { "data-field": "c" } }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(
                                _vm.$t(
                                  "views.client.transmission.transmissionlogs.table.header.operationDate"
                                )
                              ) +
                              "\n                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("th", { attrs: { "data-field": "d" } }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(
                                _vm.$t(
                                  "views.client.transmission.transmissionlogs.table.header.userId"
                                )
                              ) +
                              "\n                "
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.logs, function (item, index) {
                        return _c("tr", { key: item.hash }, [
                          _c("td", [_vm._v(_vm._s(index + 1))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(item.description))]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm._f("formatDate")(item.creationDate))
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(item.userId))]),
                        ])
                      }),
                      0
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "teros_principal__content__table__footer" },
              [
                _vm.loading
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "teros_principal__content__table__footer__loader",
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: "/static/img/icons/ajax-loader.gif",
                            alt: "",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("views.shared.loadingResults"))),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("pagination", {
                  attrs: {
                    "request-pagination": _vm.requestPagination,
                    callback: _vm.makeSearch,
                    "current-pagination-info": _vm.currentPaginationInfo,
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }