import Vue from "vue";
import Router from "vue-router";
import { cognito, Role } from "@/_helpers";

import LoginPage from "@/authentication/LoginPage";
import ForgotPasswordPage from "@/authentication/ForgotPasswordPage";
import NewPasswordRequiredPage from "@/authentication/NewPasswordRequiredPage";
import SamlRedirect from "@/authentication/SamlRedirect";
import ChangePasswordPage from "@/authentication/ChangePasswordPage";
import HomePage from "@/home/HomePage";
import IntegrationsPage from "@/integration/IntegrationsPage";
import IntegrationPage from "@/integration/IntegrationPage";
import ServicesPage from "@/service/ServicesPage";
import ServicePage from "@/service/ServicePage";
import CompliancePage from "@/compliance/CompliancePage";
import TermPage from "@/term/TermPage";
import IntegrationLogsPage from "@/integrationlog/IntegrationLogsPage";
import CustomersPage from "@/customer/CustomersPage";
import CustomerPage from "@/customer/CustomerPage";
import UsersPage from "@/user/UsersPage";
import UserPage from "@/user/UserPage";
import CustomerLogsPage from "@/customerlog/CustomerLogsPage";
import ReceptionsPage from "@/reception/ReceptionsPage";
import ReceptionPage from "@/reception/ReceptionPage";
import ReceptionServicesPage from "@/receptionservice/ReceptionServicesPage";
import ReceptionServicePage from "@/receptionservice/ReceptionServicePage";
import ServicePartnershipTermPage from "@/servicepartnershipterm/ServicePartnershipTermPage";
import ReceptionConsentimentsPage from "@/receptionconsentiment/ReceptionConsentimentsPage";
import ReceptionListConsentimentsPage from "@/receptionlistconsentiment/ReceptionListConsentimentsPage";
import ReceptionListConsentimentPage from "@/receptionlistconsentiment/ReceptionListConsentimentPage";
import ReceptionListGenericTermsPage from "@/receptionlistgenericterms/ReceptionListGenericTermsPage";
import ReceptionListGenericTermPage from "@/receptionlistgenericterms/ReceptionListGenericTermPage";
import ReceptionConsentimentPage from "@/receptionconsentiment/ReceptionConsentimentPage";
import ReceptionConsentimentTermPage from "@/receptionconsentimentterm/ReceptionConsentimentTermPage";
import ReceptionRegistriesPage from "@/receptionregistry/ReceptionRegistriesPage";
import ReceptionLogsPage from "@/receptionlog/ReceptionLogsPage";
import TransmissionPage from "@/transmission/TransmissionPage";
import TransmissionServicesPage from "@/transmissionservice/TransmissionServicesPage";
import TransmissionServicePage from "@/transmissionservice/TransmissionServicePage";
import TransmissionConsentimentsPage from "@/transmissionconsentiment/TransmissionConsentimentsPage";
import TransmissionConsentimentPage from "@/transmissionconsentiment/TransmissionConsentimentPage";
import TransmissionConsentimentTermPage from "@/transmissionconsentimentterm/TransmissionConsentimentTermPage";
import TransmissionApisPage from "@/transmissionapi/TransmissionApisPage";
import TransmissionRegistriesPage from "@/transmissionregistry/TransmissionRegistriesPage";
import TransmissionLogsPage from "@/transmissionlog/TransmissionLogsPage";
import AuthorizationsPage from "@/authorization/AuthorizationsPage";
import AuthorizationPage from "@/authorization/AuthorizationPage";
import AuthorizationTermPage from "@/authorizationterm/AuthorizationTermPage";
import AccessPage from "@/access/AccessPage";
import PdfViewPage from "@/pdfView/PdfViewPage";
import CategoriesPage from "@/category/CategoriesPage";
import CategoryPage from "@/category/CategoryPage";
import ResourceTypesPage from "@/resourcetype/ResourceTypesPage";
import ResourceTypePage from "@/resourcetype/ResourceTypePage";
import PermissionPage from "@/permission/PermissionPage";
import GroupingPage from "@/grouping/GroupingPage";
import FinancialInstitutionsPage from "@/financialinstitution/FinancialInstitutionsPage";
import FinancialInstitutionPage from "@/financialinstitution/FinancialInstitutionPage";
import ApisPage from "@/api/ApisPage";
import ApiPage from "@/api/ApiPage";
import SwaggerPage from "@/swagger/SwaggerPage";

Vue.use(Router);

export const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/login",
      component: LoginPage,
      meta: { menu: "login" },
      name: "loginpage",
    },
    {
      path: "/forgotpassword",
      component: ForgotPasswordPage,
      meta: { menu: "login" },
      name: "forgotpasswordpage",
    },
    {
      path: "/newpasswordrequired",
      component: NewPasswordRequiredPage,
      meta: { menu: "login" },
      name: "newpasswordrequiredpage",
    },
    {
      path: "/samlredirect",
      component: SamlRedirect,
      meta: { menu: "login" },
      name: "samlredirect",
    },
    {
      path: "/changepassword",
      component: ChangePasswordPage,
      meta: { menu: "login", authorize: Role.All },
      name: "changepasswordpage",
    },
    {
      path: "/",
      component: HomePage,
      meta: { menu: "dashboard", authorize: Role.All },
      name: "homepage",
    },
    {
      path: "/integrations",
      component: IntegrationsPage,
      meta: { menu: "integrations", authorize: Role.Admin },
      name: "integrationspage",
    },
    {
      path: "/integration",
      component: IntegrationPage,
      meta: { menu: "integrations", authorize: Role.Admin },
      name: "integrationpage",
    },
    {
      path: "/services",
      component: ServicesPage,
      meta: { menu: "integrations", authorize: Role.Admin },
      name: "servicespage",
    },
    {
      path: "/service",
      component: ServicePage,
      meta: { menu: "integrations", authorize: Role.Admin },
      name: "servicepage",
    },
    {
      path: "/compliance",
      component: CompliancePage,
      meta: { menu: "integrations", authorize: Role.Admin },
      name: "compliancepage",
    },
    {
      path: "/term",
      component: TermPage,
      meta: { menu: "integrations", authorize: Role.Admin },
      name: "termpage",
    },
    {
      path: "/integrationlogs",
      component: IntegrationLogsPage,
      meta: { menu: "integrations", authorize: Role.Admin },
      name: "integrationlogspage",
    },
    {
      path: "/customers",
      component: CustomersPage,
      meta: { menu: "customers", authorize: Role.Admin },
      name: "customerspage",
    },
    {
      path: "/customer",
      component: CustomerPage,
      meta: { menu: "customers", authorize: Role.Admin },
      name: "customerpage",
    },
    {
      path: "/users",
      component: UsersPage,
      meta: { menu: "customers", authorize: Role.Admin },
      name: "userspage",
    },
    {
      path: "/user",
      component: UserPage,
      meta: { menu: "customers", authorize: Role.Admin },
      name: "userpage",
    },
    {
      path: "/customerlogs",
      component: CustomerLogsPage,
      meta: { menu: "customers", authorize: Role.Admin },
      name: "customerlogspage",
    },
    {
      path: "/receptions",
      component: ReceptionsPage,
      meta: { menu: "reception", authorize: Role.Customer },
      name: "receptionspage",
    },
    {
      path: "/reception",
      component: ReceptionPage,
      meta: { menu: "reception", authorize: Role.Customer },
      name: "receptionpage",
    },
    {
      path: "/receptionservices",
      component: ReceptionServicesPage,
      meta: { menu: "reception", authorize: Role.Customer },
      name: "receptionservicespage",
    },
    {
      path: "/receptionservice",
      component: ReceptionServicePage,
      meta: { menu: "reception", authorize: Role.Customer },
      name: "receptionservicepage",
    },
    {
      path: "/servicepartnershipterm",
      component: ServicePartnershipTermPage,
      meta: { menu: "reception", authorize: Role.Customer },
      name: "servicepartnershiptermpage",
    },
    {
      path: "/receptionconsentiments",
      component: ReceptionConsentimentsPage,
      meta: { menu: "reception", authorize: Role.Customer },
      name: "receptionconsentimentspage",
    },
    {
      path: "/receptionlistconsentiments",
      component: ReceptionListConsentimentsPage,
      meta: { menu: "reception", authorize: Role.Customer },
      name: "receptionlistconsentimentspage",
    },
    {
      path: "/receptionlistconsentiment",
      component: ReceptionListConsentimentPage,
      meta: { menu: "reception", authorize: Role.Customer },
      name: "receptionlistconsentimentpage",
    },

    {
      path: "/receptionlistgenericterms",
      component: ReceptionListGenericTermsPage,
      meta: { menu: "reception", authorize: Role.Customer },
      name: "receptionlistgenerictermspage",
    },

    {
      path: "/receptionlistgenericterm",
      component: ReceptionListGenericTermPage,
      meta: { menu: "reception", authorize: Role.Customer },
      name: "receptionlistgenerictermpage",
    },

    {
      path: "/receptionconsentiment",
      component: ReceptionConsentimentPage,
      meta: { menu: "reception", authorize: Role.Customer },
      name: "receptionconsentimentpage",
    },
    {
      path: "/receptionconsentimentterm",
      component: ReceptionConsentimentTermPage,
      meta: { menu: "reception", authorize: Role.Customer },
      name: "receptionconsentimenttermpage",
    },
    {
      path: "/receptionregistries",
      component: ReceptionRegistriesPage,
      meta: { menu: "reception", authorize: Role.Customer },
      name: "receptionregistriespage",
    },
    {
      path: "/receptionlogs",
      component: ReceptionLogsPage,
      meta: { menu: "reception", authorize: Role.Customer },
      name: "receptionlogspage",
    },
    {
      path: "/transmission",
      component: TransmissionPage,
      meta: { menu: "transmission", authorize: Role.Customer },
      name: "transmissionpage",
    },
    {
      path: "/transmissionservices",
      component: TransmissionServicesPage,
      meta: { menu: "transmission", authorize: Role.Customer },
      name: "transmissionservicespage",
    },
    {
      path: "/transmissionservice",
      component: TransmissionServicePage,
      meta: { menu: "transmission", authorize: Role.Customer },
      name: "transmissionservicepage",
    },
    {
      path: "/transmissionconsentiments",
      component: TransmissionConsentimentsPage,
      meta: { menu: "transmission", authorize: Role.Customer },
      name: "transmissionconsentimentspage",
    },
    {
      path: "/transmissionconsentiment",
      component: TransmissionConsentimentPage,
      meta: { menu: "transmission", authorize: Role.Customer },
      name: "transmissionconsentimentpage",
    },
    {
      path: "/transmissionconsentimentterm",
      component: TransmissionConsentimentTermPage,
      meta: { menu: "transmission", authorize: Role.Customer },
      name: "transmissionconsentimenttermpage",
    },
    {
      path: "/transmissionapis",
      component: TransmissionApisPage,
      meta: { menu: "transmission", authorize: Role.Customer },
      name: "transmissionapispage",
    },
    {
      path: "/transmissionregistries",
      component: TransmissionRegistriesPage,
      meta: { menu: "transmission", authorize: Role.Customer },
      name: "transmissionregistriespage",
    },
    {
      path: "/transmissionlogs",
      component: TransmissionLogsPage,
      meta: { menu: "transmission", authorize: Role.Customer },
      name: "transmissionlogspage",
    },
    {
      path: "/authorizations",
      component: AuthorizationsPage,
      meta: { menu: "authorizations", authorize: Role.Admin },
      name: "authorizationspage",
    },
    {
      path: "/authorization",
      component: AuthorizationPage,
      meta: { menu: "authorizations", authorize: Role.Admin },
      name: "authorizationpage",
    },
    {
      path: "/authorizationterm",
      component: AuthorizationTermPage,
      meta: { menu: "authorizations", authorize: Role.Admin },
      name: "authorizationtermpage",
    },
    {
      path: "/access",
      component: AccessPage,
      meta: { menu: "access", authorize: Role.Customer },
      name: "accesspage",
    },
    {
      path: "/pdfviewpage",
      component: PdfViewPage,
      meta: { menu: "pdfViewPage", authorize: Role.All },
      name: "pdfviewpage",
      props: true,
    },
    {
      path: "/categories",
      component: CategoriesPage,
      meta: { menu: "config", authorize: Role.Admin },
      name: "categoriespage",
    },
    {
      path: "/category",
      component: CategoryPage,
      meta: { menu: "config", authorize: Role.Admin },
      name: "categorypage",
    },
    {
      path: "/resourcetypes",
      component: ResourceTypesPage,
      meta: { menu: "config", authorize: Role.Admin },
      name: "resourcetypespage",
    },
    {
      path: "/resourcetype",
      component: ResourceTypePage,
      meta: { menu: "config", authorize: Role.Admin },
      name: "resourcetypepage",
    },
    {
      path: "/permission",
      component: PermissionPage,
      meta: { menu: "config", authorize: Role.Admin },
      name: "permissionpage",
    },
    {
      path: "/grouping",
      component: GroupingPage,
      meta: { menu: "config", authorize: Role.Admin },
      name: "groupingpage",
    },
    {
      path: "/financialInstitutions",
      component: FinancialInstitutionsPage,
      meta: { menu: "config", authorize: Role.Admin },
      name: "financialInstitutionspage",
    },
    {
      path: "/financialInstitution",
      component: FinancialInstitutionPage,
      meta: { menu: "config", authorize: Role.Admin },
      name: "financialInstitutionpage",
    },
    {
      path: "/api",
      component: ApiPage,
      meta: { menu: "config", authorize: Role.Admin },
      name: "apipage",
    },
    {
      path: "/apis",
      component: ApisPage,
      meta: { menu: "config", authorize: Role.Admin },
      name: "apispage",
    },
    {
      path: "/swagger",
      component: SwaggerPage,
      meta: { menu: "swagger" },
      name: "swaggerpage",
    },
    // otherwise redirect to home
    { path: "*", redirect: "/" },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    } else if (savedPosition) {
      return savedPosition;
    }
  },
});

router.beforeEach((to, from, next) => {
  const { authorize } = to.meta;
  const currentUser = cognito.currentUserValue;
  if (authorize) {
    if (!currentUser && to.path != "/login") {
      return next({ path: "/login", query: { returnUrl: to.path } });
    }
    if (authorize == Role.Admin && !currentUser.isAdmin) {
      return next({ path: "/" });
    }
    if (authorize == Role.Customer && currentUser.isAdmin) {
      return next({ path: "/" });
    }
  } else {
    if (currentUser && to.path == "/login") {
      return next({ path: "/" });
    }
  }
  next();
});
