<template>
  <div class="teros_principal__content interna container">
    <div class="teros_principal__content__line-1">
      <div class="teros_principal__content__line-1__highlights">
        <div class="teros_principal__content__line-1__highlights__subject">
          <div
            class="
              teros_principal__content__line-1__highlights__subject__breadcrumb
            "
          >
            <h3
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle
              "
            >
              {{ $t("views.teros.customers.customer.title") }}
            </h3>

            <h2
              v-if="newObj"
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__title
                active
              "
            >
              {{ $t("views.teros.customers.customer.newObject") }}
            </h2>
            <h2
              v-else
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__title
                active
              "
            >
              {{ customer.name }}
            </h2>
          </div>
        </div>
      </div>

      <div class="teros_principal__content__line-1__information">
        <div class="teros_principal__content__line-1__information__time">
          <p
            v-if="!newObj"
            class="teros_principal__content__line-1__information__time__text"
          >
            <img
              class="
                teros_principal__content__line-1__information__time__text__icon
              "
              src="/static/img/icons/relogio.png"
              alt=""
            />
            {{
              $t("views.shared.createdDate", {
                date: creationDate,
                time: creationTime,
              })
            }}
            {{
              lastUpdateDate
                ? "| " +
                  $t("views.shared.changedDate", {
                    date: lastUpdateDate,
                    time: lastUpdateTime,
                  })
                : ""
            }}
          </p>

          <button
            type="button"
            @click="goBack"
            class="teros_principal__content__line-1__information__time__btn"
          >
            <img src="/static/img/icons/seta-esquerda.png" alt="" />
            {{ $t("views.shared.backButton") }}
          </button>
        </div>
      </div>
    </div>

    <div class="teros_principal__content__column">
      <div class="teros_principal__content__column__menu-vertical">
        <ul class="teros_principal__content__column__menu-vertical__list">
          <li
            class="teros_principal__content__column__menu-vertical__list__item"
          >
            <a
              href="#"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--lupa
                active
              "
              >{{ $t("views.teros.customers.customer.tabTitle") }}</a
            >
          </li>

          <li
            class="teros_principal__content__column__menu-vertical__list__item"
          >
            <a
              v-if="newObj"
              href="#"
              @click="showAlert"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--usuarios
              "
              >{{ $t("views.teros.customers.users.tabTitle") }}</a
            >
            <router-link
              v-else
              :to="'/users?hash=' + customer.hash"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--usuarios
              "
              >{{ $t("views.teros.customers.users.tabTitle") }}</router-link
            >
          </li>

          <li
            class="teros_principal__content__column__menu-vertical__list__item"
          >
            <a
              v-if="newObj"
              href="#"
              @click="showAlert"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--gestao
              "
              >{{ $t("views.teros.customers.customerlogs.tabTitle") }}</a
            >
            <router-link
              v-else
              :to="'/customerlogs?hash=' + customer.hash"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--gestao
              "
              >{{
                $t("views.teros.customers.customerlogs.tabTitle")
              }}</router-link
            >
          </li>
        </ul>
      </div>

      <form
        @submit.prevent="createOrUpdate"
        class="teros_principal__content__column__info"
        method="post"
      >
        <div class="form-group">
          <div class="custom-control custom-switch">
            <input
              type="checkbox"
              checked=""
              class="custom-control-input"
              id="customSwitch1"
              v-model="customer.active"
            />
            <label class="custom-control-label" for="customSwitch1"
              >{{ $t("views.shared.activeSwitch") }}
            </label>
          </div>
        </div>

        <div class="form-group">
          <label for="formGroupExampleInput1"
            >{{ $t("views.teros.customers.customer.content.name") }}*
            <img
              src="/static/img/icons/tooltip.png"
              data-toggle="tooltip"
              data-placement="right"
              :title="$t('views.teros.customers.customer.toolTips.name')"
          /></label>
          <custom-input
            :id="'formGroupExampleInput1'"
            v-model="customer.name"
            :required="true"
          ></custom-input>
        </div>

        <div class="form-group row">
          <div class="col">
            <label for="formGroupExampleInput2">{{
              $t("views.teros.customers.customer.content.companyName")
            }}</label>
            <custom-input
              :id="'formGroupExampleInput2'"
              v-model="customer.companyName"
            ></custom-input>
          </div>
          <div class="col">
            <label for="formGroupExampleInput3">CNPJ*</label>
            <input
              type="text"
              class="form-control"
              id="formGroupExampleInput3"
              v-model="customer.cnpj"
              v-mask="'##.###.###/####-##'"
            />
          </div>
        </div>

        <div v-if="!newObj" class="form-group row">
          <div class="col">
            <label for="formGroupExampleInput4"
              >{{ $t("views.teros.customers.customer.content.standardKey") }}
              <img
                src="/static/img/icons/tooltip.png"
                data-toggle="tooltip"
                data-placement="right"
                :title="
                  $t('views.teros.customers.customer.toolTips.standardKey')
                "
            /></label>
            <input
              type="text"
              class="form-control"
              id="formGroupExampleInput4"
              v-model="customer.gatewayClientId"
              disabled
            />
          </div>

          <div class="col">
            <label for="formGroupExampleInput5">Secret Key </label>
            <input
              type="text"
              class="form-control"
              id="formGroupExampleInput5"
              v-model="customer.gatewaySecret"
              disabled
            />
          </div>
        </div>

        <div class="form-group row">
          <div class="col">
            <live-select
              :title="$t('views.teros.customers.customer.content.categories')"
              multiple
              :options="categories"
              :hashs="customerCategories"
              @onChange="customerCategories = $event"
            ></live-select>
          </div>
          <div class="col">
            <label for="formGroupExampleInput6"
              >{{ $t("views.teros.customers.customer.content.ssoissuer") }}
            </label>
            <custom-input :id="'formGroupExampleInput6'" :regex="'.*'" v-model="customer.ssoIssuerURN" :maxlength="'100'" ></custom-input>
          </div>
        </div>

        <div class="form-group row">
          <div class="col">
            <label for="formGroupExampleInput8"
              >{{ $t("views.teros.customers.customer.content.awsaccount") }}
            </label>
            <custom-input
              :id="'formGroupExampleInput8'"
              v-model="customer.awsAccount"
              :maxlength="'100'"
            ></custom-input>
          </div>
        </div>

        <div class="form-group row">
          <div class="col">
            <label for="formGroupExampleInput8"
              >{{ $t("views.teros.customers.customer.content.defaultWebhookUrl") }}
            </label>
            <input
              type="text"
              class="form-control"
              id="formGroupExampleInput8"
              v-model="customer.defaultWebhookUrl"
            />
          </div>
        </div>

        <div class="form-group row">
          <div class="col">
            <label for="formGroupExampleInput8"
              >BACEN clientId
              <img
                src="/static/img/icons/tooltip.png"
                data-toggle="tooltip"
                data-placement="right"
                :title="
                  $t('views.teros.customers.customer.toolTips.bacenClientId')
                "
            />
            </label>
            <custom-input
              :id="'formGroupExampleInput8'"
              v-model="customer.bacenClientId"
              :maxlength="'100'"
              :regex="'.*'"
            ></custom-input>
          </div>
        </div>

        <div class="form-group">
          <label for="formGroupExampleInput7"
            >{{ $t("views.teros.customers.customer.content.observations") }}
          </label>
          <textarea
            name=""
            class="form-control"
            id="formGroupExampleInput7"
            cols="30"
            rows="5"
            v-model="customer.observations"
            maxlength="2000"
          ></textarea>
        </div>
        <div class="form-group">
          <label for="">{{
            $t("views.teros.customers.customer.content.contract")
          }}</label>
          <file-upload :file-name="customer.contractFileName"></file-upload>
        </div>

        <div
          class="form-group teros_principal__content__column__info__form__combo"
        >
          <pdf-view
            :file-name="customer.contractFileName"
            :type="'button'"
            :title="
              $t('views.teros.customers.customer.content.viewContractButton')
            "
          ></pdf-view>
        </div>

        <div class="form-group align-right">
          <button
            class="teros_principal__content__column__info__form__table__plus"
          >
            <img src="/static/img/icons/disquete.png" alt="Salvar" />{{
              $t("views.shared.saveButton")
            }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { http, router } from "@/_helpers";
import FileUpload from "@/_components/FileUpload.vue";
import PdfView from "@/_components/PdfView.vue";
import LiveSelect from "@/_components/LiveSelect.vue";
import CustomInput from "@/_components/CustomInput.vue";
import moment from 'moment';

export default {
  data() {
    return {
      newObj: true,
      customer: { active: true },
      categories: [],
      customerCategories: [],
      customerCategoriesOld: [],
    };
  },
  components: {
    FileUpload,
    PdfView,
    LiveSelect,
    CustomInput,
  },
  computed: {
    creationDate() {
      if (this.customer.creationDate) {
        return moment(String(this.customer.creationDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
    creationTime() {
      if (this.customer.creationDate) {
        return moment(String(this.customer.creationDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("HH:mm");
      }
      return "";
    },
    lastUpdateDate() {
      if (this.customer.lastUpdateDate) {
        return moment(String(this.customer.lastUpdateDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
    lastUpdateTime() {
      if (this.customer.lastUpdateDate) {
        return moment(String(this.customer.lastUpdateDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("HH:mm");
      }
      return "";
    },
  },
  mounted() {
    this.$emit("message", this.$route);
    const hash = this.$route.query.hash;
    if (hash) {
      this.newObj = false;
      http.get("/api/customer/get/" + hash).then((response) => {
        this.customer = response.data;
      });
    }
    http.get("/api/category/list/").then((response) => {
      this.categories = response.data;
      if (!this.newObj) {
        http.get("/api/customer/get/categories/" + hash).then((response) => {
          let categoriesList = response.data;
          for (let item in categoriesList) {
            this.customerCategories.push(categoriesList[item].categoryHash);
          }
          this.customerCategoriesOld = this.customerCategories;
        });
      }
    });
  },
  methods: {
    goBack() {
      router.push({ name: "customerspage" });
    },
    showAlert() {
      Vue.$toast.info(
        this.$i18n.t("views.teros.customers.customer.alert.saveBefore")
      );
    },
    createOrUpdate() {
      let uploadFile = new Promise((resolve, reject) => {
        this.bus.$emit(
          "make-file-upload",
          {
            url: "/api/util/upload/base64",
            name: `customer/${this.customer.name}/${this.customer.hash}`,
          },
          resolve,
          reject
        );
      });

      uploadFile.then((response) => {
        this.customer.contractFileName = response.data;
        if (this.newObj) {
          http.post("/api/customer/create/", this.customer).then((response) => {
            this.customer = response.data;
            this.newObj = false;
            this.loading = false;
            this.customerCategories.forEach((categoryHash) => {
              http.post(
                "/api/customer/add/category?CustomerHash=" +
                  this.customer.hash +
                  "&CategoryHash=" +
                  categoryHash
              );
            });
            router.push("/customer?hash=" + this.customer.hash);
            this.$emit("success");
          });
        } else {
          http.put("/api/customer/update/", this.customer).then(() => {
            let removedCategories = $(this.customerCategoriesOld)
              .not(this.customerCategories)
              .get();
            removedCategories.forEach((categoryHash) => {
              http.remove(
                "/api/customer/delete/category?CustomerHash=" +
                  this.customer.hash +
                  "&CategoryHash=" +
                  categoryHash
              );
            });
            let addedCategories = $(this.customerCategories)
              .not(this.customerCategoriesOld)
              .get();
            addedCategories.forEach((categoryHash) => {
              http.post(
                "/api/customer/add/category?CustomerHash=" +
                  this.customer.hash +
                  "&CategoryHash=" +
                  categoryHash
              );
            });
            this.goBack();
            this.$emit("success");
          });
        }
      });
    },
  },
};
</script>
