<template>
  <div class="teros_principal__content interna container">
    <div class="teros_principal__content__line-1">
      <div class="teros_principal__content__line-1__highlights">
        <div class="teros_principal__content__line-1__highlights__subject">
          <div
            class="
              teros_principal__content__line-1__highlights__subject__breadcrumb
            "
          >
            <h3
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle
              "
            >
              Teros Openbanking
            </h3>
            <h2
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__title
              "
            >
              {{ $t("views.shared.menu.config") }}
            </h2>
          </div>
        </div>
      </div>
    </div>

    <div class="teros_principal__content__column">
      <div class="teros_principal__content__column__menu-vertical">
        <ul class="teros_principal__content__column__menu-vertical__list">
          <li
            class="teros_principal__content__column__menu-vertical__list__item"
          >
            <a
              href="#"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--lupa
                active
              "
              >{{ $t("views.teros.config.categories.tabTitle") }}</a
            >
          </li>
          <li
            class="teros_principal__content__column__menu-vertical__list__item"
          >
            <router-link
              to="/resourcetypes"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--servicos
              "
              >{{
                $t("views.teros.config.resourceTypes.tabTitle")
              }}</router-link
            >
          </li>
          <li
            class="teros_principal__content__column__menu-vertical__list__item"
          >
            <router-link
              to="/financialInstitutions"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--servicos
              "
              >{{
                $t("views.teros.config.financialInstitutions.tabTitle")
              }}</router-link
            >
          </li>
          <li
            class="teros_principal__content__column__menu-vertical__list__item"
          >
            <router-link
              to="/apis"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--servicos
              "
              >{{ $t("views.teros.config.apis.tabTitle") }}</router-link
            >
          </li>
        </ul>
      </div>

      <div class="teros_principal__content__column__info">
        <div class="teros_principal__content__table">
          <div class="teros_principal__content__table__header">
            <div class="teros_principal__content__table__header__filters">
              <div class="form-group">
                <form @submit.prevent="makeTextSearch">
                  <div class="search-group">
                    <input
                      type="text"
                      class="input-field"
                      :placeholder="$t('views.shared.searchBar.searchLabel')"
                      maxlength="30"
                      v-model="textSearchValue"
                    />
                    <button class="search-button" />
                  </div>
                </form>
              </div>

              <itens-per-page
                :request-pagination="requestPagination"
                :callback="makeTextSearch"
              ></itens-per-page>
            </div>
          </div>

          <div class="teros_principal__content__table__body">
            <table
              id="table"
              class="teros_principal__content__table__body__table"
              data-toggle="table"
              data-height="600"
              data-url=""
            >
              <thead>
                <tr>
                  <th data-field="id" data-sortable="true">ID</th>
                  <th data-field="a">
                    {{ $t("views.teros.config.categories.table.header.name") }}
                  </th>
                  <th data-field="c">
                    {{ $t("views.shared.table.header.createdAt") }}
                  </th>
                  <th data-field="d">
                    {{ $t("views.shared.table.header.changedAt") }}
                  </th>
                  <th data-field="f" style="text-align: center">
                    {{ $t("views.shared.table.header.options") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in categories" :key="item.hash">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.creationDate | formatDate }}</td>
                  <td>{{ item.lastUpdateDate | formatDate }}</td>
                  
                  <td style="text-align: center">
                    <div
                      class="
                        teros_principal__content__table__body__table__options
                      "
                    >
                      <router-link
                        :to="'/category?hash=' + item.hash"
                        class="
                          teros_principal__content__table__body__table__options__item
                        "
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Visualizar/Editar"
                      >
                        <div>
                          <img src="/static/img/icons/editar.svg" alt="" />
                        </div>
                      </router-link>

                      <a
                        @click="prepareRemove(item.hash, index)"
                        class="
                          teros_principal__content__table__body__table__options__item
                        "
                        data-toggle="modal"
                        data-target="#deleteModal"
                        data-placement="top"
                        title="Deletar"
                      >
                        <div>
                          <img src="/static/img/icons/deletar.svg" alt="" />
                        </div>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="teros_principal__content__table__footer">
            <div
              v-if="loading"
              class="teros_principal__content__table__footer__loader"
            >
              <img src="/static/img/icons/ajax-loader.gif" alt="" />
              <span>{{ $t("views.shared.loadingResults") }}</span>
            </div>

            <pagination
              :request-pagination="requestPagination"
              :callback="makeTextSearch"
              :current-pagination-info="currentPaginationInfo"
            ></pagination>

            <div class="teros_principal__content__table__footer__pagination">
              <router-link
                to="/category"
                title="Nova Categoria"
                class="teros_principal__content__table__footer__add-item"
              >
                <img src="/static/img/icons/plus.png" alt="" />{{
                  $t("views.shared.includeButton")
                }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade teros-modal"
      id="deleteModal"
      tabindex="-1"
      aria-labelledby="deleteModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteModalLabel">
              <img src="/static/img/icons/atencao-modal-red.png" alt="" />
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            {{ $t("views.shared.modal.delete.confirmMessage") }}
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              {{ $t("views.shared.modal.delete.cancelButton") }}
            </button>
            <button
              type="button"
              class="btn btn-primary btn-primary--red"
              data-dismiss="modal"
              @click="remove"
            >
              {{ $t("views.shared.modal.delete.deleteButton") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { http } from "@/_helpers";
import Pagination from "@/_components/Pagination.vue";
import ItensPerPage from "@/_components/ItensPerPage.vue";
import moment from "moment";

export default {
  data() {
    return {
      categories: [],
      categoryHash: null,
      index: 0,
      loading: true,
      textSearchValue: "",
      oldTextSearchValue: "",
      currentPaginationInfo: null,
      requestPagination: { page: 1, take: 10 },
    };
  },
  components: {
    Pagination,
    ItensPerPage,
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
  },
  mounted() {
    this.$emit("message", this.$route);
    http
      .get("/api/category/search")
      .then((response) => {
        this.categories = response.data.items;
      })
      .finally(() => (this.loading = false));
  },
  methods: {
    makeTextSearch(event, isChangePage = false) {
      this.checkValuesChanged(isChangePage);

      if (this.textSearchValue.trim()) {
        http
          .get(
            `/api/category/search?FilterProperty=Name&FilterValue=${this.textSearchValue.trim()}&Page=${
              this.requestPagination.page
            }&Take=${this.requestPagination.take}`
          )
          .then((response) => {
            this.categories = response.data.items;
            this.currentPaginationInfo = response.data;
          })
          .finally(() => (this.loading = false));
      } else {
        http
          .get(
            `/api/category/search?Page=${this.requestPagination.page}&Take=${this.requestPagination.take}`
          )
          .then((response) => {
            this.categories = response.data.items;
            this.currentPaginationInfo = response.data;
          })
          .finally(() => (this.loading = false));
      }
    },
    checkValuesChanged(isChangePage = false) {
      if (this.textSearchValue != this.oldTextSearchValue) {
        if (isChangePage) {
          this.textSearchValue = this.oldTextSearchValue;
        } else {
          this.requestPagination.page = 1;
          this.oldTextSearchValue = this.textSearchValue;
        }
      }
    },
    prepareRemove(hash, index) {
      this.categoryHash = hash;
      this.index = index;
    },
    remove() {
      http.remove("/api/category/delete/" + this.categoryHash).then(() => {
        Vue.$toast.success(
          this.$i18n.t("views.teros.config.categories.modal.deletedMessage")
        );
        Vue.delete(this.categories, this.index);
      });
    },
  },
};
</script>