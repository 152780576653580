var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "teros_principal__content interna container" },
    [
      _c("div", { staticClass: "teros_principal__content__line-1 line" }, [
        _c(
          "div",
          { staticClass: "teros_principal__content__line-1__highlights" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__line-1__highlights__subject",
              },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: "/services?hash=" + _vm.service.integrationHash,
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "teros_principal__content__line-1__highlights__subject__breadcrumb",
                      },
                      [
                        _c(
                          "h3",
                          {
                            staticClass:
                              "teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t("views.shared.menu.integrations")
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "h2",
                          {
                            staticClass:
                              "teros_principal__content__line-1__highlights__subject__breadcrumb__title",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.service.integrationName) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "teros_principal__arrow" }),
                _vm._v(" "),
                _c(
                  "router-link",
                  { attrs: { to: "/service?hash=" + _vm.service.hash } },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "teros_principal__content__line-1__highlights__subject__breadcrumb",
                      },
                      [
                        _c(
                          "h3",
                          {
                            staticClass:
                              "teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t(
                                    "views.teros.integrations.services.tabTitle"
                                  )
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "h2",
                          {
                            staticClass:
                              "teros_principal__content__line-1__highlights__subject__breadcrumb__title",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.service.name) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "teros_principal__arrow" }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__line-1__highlights__subject__breadcrumb",
                  },
                  [
                    _c(
                      "h3",
                      {
                        staticClass:
                          "teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "views.teros.integrations.compliance.title"
                              )
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.newObj
                      ? _c(
                          "h2",
                          {
                            staticClass:
                              "teros_principal__content__line-1__highlights__subject__breadcrumb__title active",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "views.teros.integrations.compliance.newObject"
                                  )
                                ) +
                                "\n          "
                            ),
                          ]
                        )
                      : _c(
                          "h2",
                          {
                            staticClass:
                              "teros_principal__content__line-1__highlights__subject__breadcrumb__title active",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.standard.name) +
                                "\n          "
                            ),
                          ]
                        ),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "teros_principal__content__line-1__information" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__line-1__information__time",
              },
              [
                _c(
                  "button",
                  {
                    staticClass:
                      "teros_principal__content__line-1__information__time__btn",
                    attrs: { type: "button" },
                    on: { click: _vm.goBack },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "/static/img/icons/seta-esquerda.png",
                        alt: "",
                      },
                    }),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("views.shared.backButton")) +
                        "\n        "
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "teros_principal__content__accordion__description" },
        [
          !_vm.newObj
            ? _c(
                "p",
                {
                  staticClass:
                    "teros_principal__content__line-1__information__time__text",
                },
                [
                  _c("img", {
                    staticClass:
                      "teros_principal__content__line-1__information__time__text__icon",
                    attrs: { src: "/static/img/icons/relogio.png", alt: "" },
                  }),
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.$t("views.shared.createdDate", {
                          date: _vm.creationDate,
                          time: _vm.creationTime,
                        })
                      ) +
                      "\n      " +
                      _vm._s(
                        _vm.lastUpdateDate
                          ? "| " +
                              _vm.$t("views.shared.changedDate", {
                                date: _vm.lastUpdateDate,
                                time: _vm.lastUpdateTime,
                              })
                          : ""
                      ) +
                      "\n    "
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("div", { attrs: { id: "accordion" } }, [
        _c(
          "div",
          { staticClass: "teros_principal__content__accordion__card" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__accordion__card__header",
                attrs: { id: "headingOne" },
              },
              [
                _c(
                  "h2",
                  {
                    staticClass:
                      "teros_principal__content__accordion__card__header__title",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "teros_principal__content__accordion__card__header__title__btn",
                        attrs: {
                          type: "button",
                          "data-toggle": "collapse",
                          "data-target": "#collapseOne",
                          "aria-expanded": "false",
                          "aria-controls": "collapseOne",
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "views.teros.integrations.compliance.content.general.title"
                              )
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("hr"),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__accordion__card__header__collapse",
                attrs: { id: "collapseOne" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__accordion__card__header__collapse__body",
                  },
                  [
                    _c(
                      "form",
                      {
                        staticClass: "teros_principal__content__column__info",
                        attrs: { method: "post" },
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.createOrUpdate.apply(null, arguments)
                          },
                        },
                      },
                      [
                        !_vm.newObj
                          ? _c("div", { staticClass: "form-group" }, [
                              _c(
                                "div",
                                { staticClass: "custom-control custom-switch" },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.standard.serviceStandardActive,
                                        expression:
                                          "standard.serviceStandardActive",
                                      },
                                    ],
                                    staticClass: "custom-control-input",
                                    attrs: {
                                      type: "checkbox",
                                      id: "customSwitch",
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.standard.serviceStandardActive
                                      )
                                        ? _vm._i(
                                            _vm.standard.serviceStandardActive,
                                            null
                                          ) > -1
                                        : _vm.standard.serviceStandardActive,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a =
                                            _vm.standard.serviceStandardActive,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.standard,
                                                "serviceStandardActive",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.standard,
                                                "serviceStandardActive",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.standard,
                                            "serviceStandardActive",
                                            $$c
                                          )
                                        }
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    {
                                      staticClass: "custom-control-label",
                                      attrs: { for: "customSwitch" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("views.shared.activeSwitch")
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group row" }, [
                          _c("div", { staticClass: "col" }, [
                            _c(
                              "label",
                              { attrs: { for: "formGroupExampleInput" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "views.teros.integrations.compliance.content.general.complianceStandard"
                                    )
                                  ) + "*"
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.standard.name,
                                  expression: "standard.name",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                required: "",
                                id: "formGroupExampleInput",
                              },
                              domProps: { value: _vm.standard.name },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.standard,
                                    "name",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group row" }, [
                          _c("div", { staticClass: "col" }, [
                            _c(
                              "label",
                              { attrs: { for: "formGroupExampleInput3" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "views.teros.integrations.compliance.content.general.description"
                                    )
                                  )
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.standard.description,
                                  expression: "standard.description",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "formGroupExampleInput3",
                                cols: "30",
                                rows: "5",
                              },
                              domProps: { value: _vm.standard.description },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.standard,
                                    "description",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _vm._v(" "),
                        !_vm.newObj
                          ? _c("div", { staticClass: "form-group row" }, [
                              _c("div", { staticClass: "col" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "show_tiny teros_principal__content__column__info__form__combo__btn-contrato",
                                    staticStyle: { width: "270px" },
                                    attrs: { href: "javascript:void(null)" },
                                    on: { click: _vm.showOrHideTiny },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t(
                                            "views.teros.integrations.compliance.content.general.editLayoutButton"
                                          )
                                        ) +
                                        "\n                "
                                    ),
                                  ]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.showTiny && !_vm.newObj
                          ? _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "div",
                                { staticClass: "col" },
                                [
                                  _c("editor", {
                                    attrs: { "api-key": "no-api-key" },
                                    model: {
                                      value: _vm.standard.consentimentLayout,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.standard,
                                          "consentimentLayout",
                                          $$v
                                        )
                                      },
                                      expression: "standard.consentimentLayout",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group align-right" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "teros_principal__content__column__info__form__table__plus mb0",
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "/static/img/icons/disquete.png",
                                  alt: "Salvar",
                                },
                              }),
                              _vm._v(
                                _vm._s(_vm.$t("views.shared.saveButton")) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        !_vm.newObj
          ? _c(
              "div",
              { staticClass: "teros_principal__content__accordion__card" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__accordion__card__header",
                    attrs: { id: "headingTwo" },
                  },
                  [
                    _c(
                      "h2",
                      {
                        staticClass:
                          "teros_principal__content__accordion__card__header__title",
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "teros_principal__content__accordion__card__header__title__btn",
                            attrs: {
                              type: "button",
                              "data-toggle": "collapse",
                              "data-target": "#collapseTwo",
                              "aria-expanded": "false",
                              "aria-controls": "collapseTwo",
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "views.teros.integrations.compliance.content.terms.title"
                                  )
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("hr"),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__accordion__card__header__collapse",
                    attrs: { id: "collapseTwo" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "teros_principal__content__accordion__card__header__collapse__body pt0",
                        staticStyle: { padding: "0 40px" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "teros_principal__content__table" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "teros_principal__content__table__header",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "teros_principal__content__table__header__filters",
                                  },
                                  [
                                    _c("div", { staticClass: "form-group" }, [
                                      _c(
                                        "form",
                                        {
                                          on: {
                                            submit: function ($event) {
                                              $event.preventDefault()
                                              return _vm.makeTextSearch.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "search-group" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.textSearchValue,
                                                    expression:
                                                      "textSearchValue",
                                                  },
                                                ],
                                                staticClass: "input-field",
                                                attrs: {
                                                  type: "text",
                                                  placeholder: "Buscar",
                                                  maxlength: "30",
                                                },
                                                domProps: {
                                                  value: _vm.textSearchValue,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.textSearchValue =
                                                      $event.target.value
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("button", {
                                                staticClass: "search-button",
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("itens-per-page", {
                                      attrs: {
                                        "request-pagination":
                                          _vm.requestPagination,
                                        callback: _vm.makeTextSearch,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass:
                                    "teros_principal__content__table__header__actions",
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "teros_principal__content__table__body",
                              },
                              [
                                _c(
                                  "table",
                                  {
                                    staticClass:
                                      "teros_principal__content__table__body__table",
                                    attrs: {
                                      id: "table",
                                      "data-toggle": "table",
                                      "data-height": "600",
                                      "data-url": "",
                                    },
                                  },
                                  [
                                    _c("thead", [
                                      _c("tr", [
                                        _c(
                                          "th",
                                          {
                                            attrs: {
                                              "data-field": "id",
                                              "data-sortable": "true",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "views.teros.integrations.compliance.content.terms.header.ordering"
                                                  )
                                                ) +
                                                "\n                    "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          { attrs: { "data-field": "a" } },
                                          [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "views.teros.integrations.compliance.content.terms.header.name"
                                                  )
                                                ) +
                                                "\n                    "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          { attrs: { "data-field": "b" } },
                                          [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "views.teros.integrations.compliance.content.terms.header.serviceType"
                                                  )
                                                ) +
                                                "\n                    "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          { attrs: { "data-field": "d" } },
                                          [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "views.shared.table.header.createdAt"
                                                  )
                                                ) +
                                                "\n                    "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          { attrs: { "data-field": "e" } },
                                          [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "views.shared.table.header.changedAt"
                                                  )
                                                ) +
                                                "\n                    "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticStyle: {
                                              "text-align": "center",
                                            },
                                            attrs: { "data-field": "f" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "views.shared.table.header.options"
                                                  )
                                                ) +
                                                "\n                    "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticStyle: {
                                              "text-align": "center",
                                            },
                                            attrs: { "data-field": "g" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "views.shared.table.header.deactivateActivate"
                                                  )
                                                ) +
                                                "\n                    "
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "tbody",
                                      _vm._l(_vm.terms, function (item, index) {
                                        return _c("tr", { key: item.hash }, [
                                          _c("td", [
                                            _vm._v(_vm._s(item.orderNumber)),
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [_vm._v(_vm._s(item.name))]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(_vm._s(_vm.service.name)),
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("formatDate")(
                                                  item.creationDate
                                                )
                                              )
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("formatDate")(
                                                  item.lastUpdateDate
                                                )
                                              )
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                width: "100px",
                                                "text-align": "center",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "teros_principal__content__table__body__table__options",
                                                },
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      staticClass:
                                                        "teros_principal__content__table__body__table__options__item",
                                                      attrs: {
                                                        to:
                                                          "/term?serviceHash=" +
                                                          _vm.service.hash +
                                                          "&standardHash=" +
                                                          _vm.standard.hash +
                                                          "&hash=" +
                                                          item.hash,
                                                        "data-toggle":
                                                          "tooltip",
                                                        "data-placement": "top",
                                                        title:
                                                          "Visualizar/Editar",
                                                      },
                                                    },
                                                    [
                                                      _c("div", [
                                                        _c("img", {
                                                          attrs: {
                                                            src: "/static/img/icons/editar.svg",
                                                            alt: "",
                                                          },
                                                        }),
                                                      ]),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "teros_principal__content__table__body__table__options__item",
                                                      attrs: {
                                                        "data-toggle": "modal",
                                                        "data-target":
                                                          "#deleteModal",
                                                        "data-placement": "top",
                                                        title: "Deletar",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.prepareRemove(
                                                            item.hash,
                                                            index
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._m(0, true)]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "text-align": "center",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "custom-control custom-switch",
                                                },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          item.serviceStandardTermActive,
                                                        expression:
                                                          "item.serviceStandardTermActive",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "custom-control-input",
                                                    attrs: {
                                                      type: "checkbox",
                                                      id:
                                                        "customSwitch" + index,
                                                    },
                                                    domProps: {
                                                      checked: Array.isArray(
                                                        item.serviceStandardTermActive
                                                      )
                                                        ? _vm._i(
                                                            item.serviceStandardTermActive,
                                                            null
                                                          ) > -1
                                                        : item.serviceStandardTermActive,
                                                    },
                                                    on: {
                                                      change: [
                                                        function ($event) {
                                                          var $$a =
                                                              item.serviceStandardTermActive,
                                                            $$el =
                                                              $event.target,
                                                            $$c = $$el.checked
                                                              ? true
                                                              : false
                                                          if (
                                                            Array.isArray($$a)
                                                          ) {
                                                            var $$v = null,
                                                              $$i = _vm._i(
                                                                $$a,
                                                                $$v
                                                              )
                                                            if ($$el.checked) {
                                                              $$i < 0 &&
                                                                _vm.$set(
                                                                  item,
                                                                  "serviceStandardTermActive",
                                                                  $$a.concat([
                                                                    $$v,
                                                                  ])
                                                                )
                                                            } else {
                                                              $$i > -1 &&
                                                                _vm.$set(
                                                                  item,
                                                                  "serviceStandardTermActive",
                                                                  $$a
                                                                    .slice(
                                                                      0,
                                                                      $$i
                                                                    )
                                                                    .concat(
                                                                      $$a.slice(
                                                                        $$i + 1
                                                                      )
                                                                    )
                                                                )
                                                            }
                                                          } else {
                                                            _vm.$set(
                                                              item,
                                                              "serviceStandardTermActive",
                                                              $$c
                                                            )
                                                          }
                                                        },
                                                        function ($event) {
                                                          return _vm.changeStatus(
                                                            item.hash
                                                          )
                                                        },
                                                      ],
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("label", {
                                                    staticClass:
                                                      "custom-control-label",
                                                    attrs: {
                                                      for:
                                                        "customSwitch" + index,
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ])
                                      }),
                                      0
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "teros_principal__content__table__footer",
                              },
                              [
                                _vm.loading
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "teros_principal__content__table__footer__loader",
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: "/static/img/icons/ajax-loader.gif",
                                            alt: "",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "views.shared.loadingResults"
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("pagination", {
                                  attrs: {
                                    "request-pagination": _vm.requestPagination,
                                    callback: _vm.makeTextSearch,
                                    "current-pagination-info":
                                      _vm.currentPaginationInfo,
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "teros_principal__content__table__footer__pagination",
                                  },
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass:
                                          "teros_principal__content__table__footer__add-item",
                                        attrs: {
                                          to:
                                            "/term?serviceHash=" +
                                            _vm.service.hash +
                                            "&standardHash=" +
                                            _vm.standard.hash,
                                          title: "Novo Termo",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: "/static/img/icons/plus.png",
                                            alt: "",
                                          },
                                        }),
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("views.shared.includeButton")
                                          ) + "\n                "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.newObj
          ? _c("div", { staticClass: "block-out" }, [
              _c("div", { staticClass: "form-group row" }, [
                _c("div", [
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "teros_principal__content__column__info__form__combo__btn-contrato",
                        staticStyle: { width: "270px" },
                        attrs: { type: "button" },
                        on: { click: _vm.getfileUrl },
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t(
                                "views.client.reception.receptionservice.content.compliance.consentButtons.viewConsent"
                              )
                            ) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal fade teros-modal",
          attrs: {
            id: "deleteModal",
            tabindex: "-1",
            "aria-labelledby": "deleteModalLabel",
            "aria-hidden": "true",
          },
        },
        [
          _c("div", { staticClass: "modal-dialog" }, [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(1),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("views.shared.modal.delete.confirmMessage")) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button", "data-dismiss": "modal" },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t("views.shared.modal.delete.cancelButton")
                        ) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-primary--red",
                    attrs: { type: "button", "data-dismiss": "modal" },
                    on: { click: _vm.remove },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t("views.shared.modal.delete.deleteButton")
                        ) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", { attrs: { src: "/static/img/icons/deletar.svg", alt: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        { staticClass: "modal-title", attrs: { id: "deleteModalLabel" } },
        [
          _c("img", {
            attrs: { src: "/static/img/icons/atencao-modal-red.png", alt: "" },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }