var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "teros_principal__content interna container" },
    [
      _c("div", { staticClass: "teros_principal__content__line-1 line" }, [
        _c(
          "div",
          { staticClass: "teros_principal__content__line-1__highlights" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__line-1__highlights__subject",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__line-1__highlights__subject__breadcrumb",
                  },
                  [
                    _c("router-link", { attrs: { to: "/receptions" } }, [
                      _c(
                        "h3",
                        {
                          staticClass:
                            "teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t("views.client.reception.list.client")
                              ) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "h2",
                        {
                          staticClass:
                            "teros_principal__content__line-1__highlights__subject__breadcrumb__title",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.reception.customerName) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "teros_principal__arrow" }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__line-1__highlights__subject__breadcrumb",
                  },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: { to: "/reception?hash=" + _vm.reception.hash },
                      },
                      [
                        _c(
                          "h3",
                          {
                            staticClass:
                              "teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t(
                                    "views.client.reception.reception.title"
                                  )
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "h2",
                          {
                            staticClass:
                              "teros_principal__content__line-1__highlights__subject__breadcrumb__title",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.reception.integrationName) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "teros_principal__arrow" }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__line-1__highlights__subject__breadcrumb",
                  },
                  [
                    _c(
                      "h3",
                      {
                        staticClass:
                          "teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "views.client.reception.receptionservice.title"
                              )
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.newObj
                      ? _c(
                          "h2",
                          {
                            staticClass:
                              "teros_principal__content__line-1__highlights__subject__breadcrumb__title active",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "views.client.reception.receptionservice.newObject"
                                  )
                                ) +
                                "\n          "
                            ),
                          ]
                        )
                      : _c(
                          "h2",
                          {
                            staticClass:
                              "teros_principal__content__line-1__highlights__subject__breadcrumb__title active",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.receptionservice.name) +
                                "\n          "
                            ),
                          ]
                        ),
                  ]
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "teros_principal__content__line-1__information" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__line-1__information__time",
              },
              [
                !_vm.newObj
                  ? _c(
                      "p",
                      {
                        staticClass:
                          "teros_principal__content__line-1__information__time__text",
                      },
                      [
                        _c("img", {
                          staticClass:
                            "teros_principal__content__line-1__information__time__text__icon",
                          attrs: {
                            src: "/static/img/icons/relogio.png",
                            alt: "",
                          },
                        }),
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t("views.shared.createdDate", {
                                date: _vm.creationDate,
                                time: _vm.creationTime,
                              })
                            ) +
                            "\n          " +
                            _vm._s(
                              _vm.lastUpdateDate
                                ? "| " +
                                    _vm.$t("views.shared.changedDate", {
                                      date: _vm.lastUpdateDate,
                                      time: _vm.lastUpdateTime,
                                    })
                                : ""
                            ) +
                            "\n        "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "teros_principal__content__line-1__information__time__btn",
                    attrs: { type: "button" },
                    on: { click: _vm.goBack },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "/static/img/icons/seta-esquerda.png",
                        alt: "",
                      },
                    }),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("views.shared.backButton")) +
                        "\n        "
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { id: "accordion" } }, [
        _c(
          "form",
          {
            staticClass:
              "teros_principal__content__accordion__card__header__collapse__body__form",
            attrs: { method: "post" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.createOrUpdate.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "teros_principal__content__accordion__card" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__accordion__card__header",
                    attrs: { id: "headingOne" },
                  },
                  [
                    _c(
                      "h2",
                      {
                        staticClass:
                          "teros_principal__content__accordion__card__header__title",
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "teros_principal__content__accordion__card__header__title__btn",
                            attrs: {
                              type: "button",
                              "data-toggle": "collapse",
                              "data-target": "#collapseOne",
                              "aria-expanded": "false",
                              "aria-controls": "collapseOne",
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t(
                                    "views.client.reception.receptionservice.content.general.title"
                                  )
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("hr"),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__accordion__card__header__collapse",
                    attrs: { id: "collapseOne" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "teros_principal__content__accordion__card__header__collapse__body",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "teros_principal__content__accordion__card__header__collapse__body__form",
                          },
                          [
                            _c("div", { staticClass: "form-group row" }, [
                              _c("div", { staticClass: "col" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "custom-control custom-switch",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.receptionservice.active,
                                          expression: "receptionservice.active",
                                        },
                                      ],
                                      staticClass: "custom-control-input",
                                      attrs: {
                                        type: "checkbox",
                                        id: "customSwitch1",
                                      },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.receptionservice.active
                                        )
                                          ? _vm._i(
                                              _vm.receptionservice.active,
                                              null
                                            ) > -1
                                          : _vm.receptionservice.active,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.receptionservice.active,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.receptionservice,
                                                  "active",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.receptionservice,
                                                  "active",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              _vm.receptionservice,
                                              "active",
                                              $$c
                                            )
                                          }
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "custom-control-label",
                                        attrs: { for: "customSwitch1" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("views.shared.activeSwitch")
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "teros_principal__content__accordion__card__header__collapse__body__form__column__fields",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "form-group" },
                                      [
                                        !_vm.newObj
                                          ? _c(
                                              "label",
                                              {
                                                attrs: {
                                                  for: "formGroupExampleInput",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "views.client.reception.receptionservice.content.general.service"
                                                    )
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !_vm.newObj
                                          ? _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.receptionservice.name,
                                                  expression:
                                                    "receptionservice.name",
                                                },
                                              ],
                                              staticClass:
                                                "form-control teros_principal__content__column__info__form__input",
                                              attrs: {
                                                type: "text",
                                                id: "formGroupExampleInput",
                                                readonly: "",
                                              },
                                              domProps: {
                                                value:
                                                  _vm.receptionservice.name,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm.receptionservice,
                                                    "name",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.newObj
                                          ? _c("live-select", {
                                              attrs: {
                                                id: "selectServices",
                                                title:
                                                  _vm.$t(
                                                    "views.client.reception.receptionservice.content.general.service"
                                                  ) + "*",
                                                options: _vm.services,
                                                hash: _vm.receptionservice
                                                  .serviceHash,
                                              },
                                              on: {
                                                onChange: _vm.onChangeService,
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "form-group" }, [
                                      _c(
                                        "label",
                                        {
                                          attrs: {
                                            for: "formGroupExampleInput5",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "views.client.reception.receptionservice.content.general.commercialName"
                                              )
                                            ) + "\n                      "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.receptionservice
                                                .commercialName,
                                            expression:
                                              "receptionservice.commercialName",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          id: "formGroupExampleInput5",
                                          readonly: "",
                                        },
                                        domProps: {
                                          value:
                                            _vm.receptionservice.commercialName,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.receptionservice,
                                              "commercialName",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "form-group" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "custom-control custom-switch",
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.receptionservice.store,
                                                expression:
                                                  "receptionservice.store",
                                              },
                                            ],
                                            staticClass: "custom-control-input",
                                            attrs: {
                                              type: "checkbox",
                                              id: "customSwitch2",
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.receptionservice.store
                                              )
                                                ? _vm._i(
                                                    _vm.receptionservice.store,
                                                    null
                                                  ) > -1
                                                : _vm.receptionservice.store,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$a =
                                                    _vm.receptionservice.store,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.receptionservice,
                                                        "store",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.receptionservice,
                                                        "store",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.receptionservice,
                                                    "store",
                                                    $$c
                                                  )
                                                }
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "custom-control-label",
                                              attrs: { for: "customSwitch2" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "views.client.reception.receptionservice.content.general.storeSwitch"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _vm.receptionservice.store
                                      ? _c(
                                          "div",
                                          { staticClass: "form-group" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                attrs: {
                                                  for: "formGroupExampleInput1",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "views.client.reception.receptionservice.content.general.storeTime"
                                                    )
                                                  ) +
                                                    "\n                        "
                                                ),
                                                _c("img", {
                                                  attrs: {
                                                    src: "/static/img/icons/tooltip.png",
                                                    "data-toggle": "tooltip",
                                                    "data-placement": "right",
                                                    title: _vm.$t(
                                                      "views.client.reception.receptionservice.toolTips.general.storageTimeLimit"
                                                    ),
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("custom-input", {
                                              attrs: {
                                                id: "formGroupExampleInput1",
                                                type: "number",
                                                regex: "[0-9]",
                                              },
                                              model: {
                                                value:
                                                  _vm.receptionservice
                                                    .timetoStore,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.receptionservice,
                                                    "timetoStore",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "receptionservice.timetoStore",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.receptionservice.store
                                      ? _c(
                                          "div",
                                          { staticClass: "form-group" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "custom-control custom-switch",
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.receptionservice
                                                          .anonymize,
                                                      expression:
                                                        "receptionservice.anonymize",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "custom-control-input",
                                                  attrs: {
                                                    type: "checkbox",
                                                    id: "customSwitch3",
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm.receptionservice
                                                        .anonymize
                                                    )
                                                      ? _vm._i(
                                                          _vm.receptionservice
                                                            .anonymize,
                                                          null
                                                        ) > -1
                                                      : _vm.receptionservice
                                                          .anonymize,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a =
                                                          _vm.receptionservice
                                                            .anonymize,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.receptionservice,
                                                              "anonymize",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.receptionservice,
                                                              "anonymize",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.receptionservice,
                                                          "anonymize",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "custom-control-label",
                                                    attrs: {
                                                      for: "customSwitch3",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "views.client.reception.receptionservice.content.general.anonymizationSwitch"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.receptionservice.store &&
                                    _vm.receptionservice.anonymize
                                      ? _c(
                                          "div",
                                          { staticClass: "form-group" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                attrs: {
                                                  for: "formGroupExampleInput2",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "views.client.reception.receptionservice.content.general.anonymizationType"
                                                    )
                                                  ) +
                                                    "\n                        "
                                                ),
                                                _c("img", {
                                                  attrs: {
                                                    src: "/static/img/icons/tooltip.png",
                                                    "data-toggle": "tooltip",
                                                    "data-placement": "right",
                                                    title: _vm.$t(
                                                      "views.client.reception.receptionservice.toolTips.general.anonType"
                                                    ),
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "select",
                                              {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model.number",
                                                    value:
                                                      _vm.receptionservice
                                                        .anonymizeType,
                                                    expression:
                                                      "receptionservice.anonymizeType",
                                                    modifiers: { number: true },
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  id: "exampleFormControlSelect1",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$selectedVal =
                                                      Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function (o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function (o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return _vm._n(val)
                                                        })
                                                    _vm.$set(
                                                      _vm.receptionservice,
                                                      "anonymizeType",
                                                      $event.target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "option",
                                                  { attrs: { value: "1" } },
                                                  [
                                                    _vm._v(
                                                      "\n                          " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "views.client.reception.receptionservice.content.general.anonymizationTypesDropbox.immediate"
                                                          )
                                                        ) +
                                                        "\n                        "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  { attrs: { value: "2" } },
                                                  [
                                                    _vm._v(
                                                      "\n                          " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "views.client.reception.receptionservice.content.general.anonymizationTypesDropbox.obfuscation"
                                                          )
                                                        ) +
                                                        "\n                        "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  { attrs: { value: "3" } },
                                                  [
                                                    _vm._v(
                                                      "\n                          " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "views.client.reception.receptionservice.content.general.anonymizationTypesDropbox.scheduled"
                                                          )
                                                        ) +
                                                        "\n                        "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.receptionservice.store &&
                                    _vm.receptionservice.anonymize &&
                                    _vm.receptionservice.anonymizeType == 3
                                      ? _c(
                                          "div",
                                          { staticClass: "form-group" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                attrs: {
                                                  for: "formGroupExampleInput3",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "views.client.reception.receptionservice.content.general.anonymizationTime"
                                                    )
                                                  ) +
                                                    "\n                        "
                                                ),
                                                _c("img", {
                                                  attrs: {
                                                    src: "/static/img/icons/tooltip.png",
                                                    "data-toggle": "tooltip",
                                                    "data-placement": "right",
                                                    title: _vm.$t(
                                                      "views.client.reception.receptionservice.toolTips.general.anonTime"
                                                    ),
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("custom-input", {
                                              attrs: {
                                                id: "formGroupExampleInput3",
                                                type: "number",
                                                regex: "[0-9]",
                                              },
                                              model: {
                                                value:
                                                  _vm.receptionservice
                                                    .timeToAnonymize,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.receptionservice,
                                                    "timeToAnonymize",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "receptionservice.timeToAnonymize",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "teros_principal__content__accordion__card__header__collapse__body__form__column__fields",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "form-group" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                attrs: {
                                                  for: "formGroupExampleInput4",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "URL/Endpoint\n                          "
                                                ),
                                                _c("img", {
                                                  attrs: {
                                                    src: "/static/img/icons/tooltip.png",
                                                    "data-toggle": "tooltip",
                                                    "data-placement": "right",
                                                    title: _vm.$t(
                                                      "views.client.reception.receptionservice.toolTips.general.urlEndpoint"
                                                    ),
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.receptionservice
                                                      .endpointUrl,
                                                  expression:
                                                    "receptionservice.endpointUrl",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                id: "formGroupExampleInput4",
                                                readonly: "",
                                              },
                                              domProps: {
                                                value:
                                                  _vm.receptionservice
                                                    .endpointUrl,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm.receptionservice,
                                                    "endpointUrl",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col" }, [
                                _vm._m(0),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "tab-content",
                                    attrs: { id: "myTabContent" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "tab-pane fade show active",
                                        attrs: {
                                          id: "home",
                                          role: "tabpanel",
                                          "aria-labelledby": "home-tab",
                                        },
                                      },
                                      [
                                        _c("ace-editor", {
                                          ref: "bodyRequestEditor",
                                          staticStyle: { fontsize: "15px" },
                                          attrs: {
                                            lang: "json",
                                            theme: "iplastic",
                                            height: "450px",
                                          },
                                          model: {
                                            value: _vm.bodyRequest,
                                            callback: function ($$v) {
                                              _vm.bodyRequest = $$v
                                            },
                                            expression: "bodyRequest",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "a",
                                          {
                                            staticClass: "copy-code",
                                            attrs: {
                                              href: "javascript:void(null)",
                                            },
                                            on: { click: _vm.copyBodyRequest },
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: "/static/img/icons/copy.png",
                                                alt: "",
                                              },
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "tab-pane fade",
                                        attrs: {
                                          id: "profile",
                                          role: "tabpanel",
                                          "aria-labelledby": "profile-tab",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "teros_principal__tags",
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "teros_principal__tags__tag teros_principal__tags__tag--green active",
                                                attrs: {
                                                  href: "javascript:void(null)",
                                                },
                                                on: {
                                                  click:
                                                    _vm.showResponseSuccess,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "views.client.reception.receptionservice.content.general.jsonSchemaMsgs.success"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "teros_principal__tags__tag teros_principal__tags__tag--red",
                                                attrs: {
                                                  href: "javascript:void(null)",
                                                },
                                                on: {
                                                  click: _vm.showResponseError,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "views.client.reception.receptionservice.content.general.jsonSchemaMsgs.error"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.responseSuccessActive
                                          ? _c("ace-editor", {
                                              ref: "jsonSchemaResponseEditor",
                                              staticStyle: { fontsize: "15px" },
                                              attrs: {
                                                lang: "json",
                                                theme: "dawn",
                                                height: "600px",
                                              },
                                              model: {
                                                value:
                                                  _vm.receptionservice
                                                    .jsonSchemaResponseSuccess,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.receptionservice,
                                                    "jsonSchemaResponseSuccess",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "receptionservice.jsonSchemaResponseSuccess",
                                              },
                                            })
                                          : _c("ace-editor", {
                                              ref: "jsonSchemaResponseEditor",
                                              staticStyle: { fontsize: "15px" },
                                              attrs: {
                                                lang: "json",
                                                theme: "iplastic",
                                                height: "600px",
                                              },
                                              model: {
                                                value:
                                                  _vm.receptionservice
                                                    .jsonSchemaResponseError,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.receptionservice,
                                                    "jsonSchemaResponseError",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "receptionservice.jsonSchemaResponseError",
                                              },
                                            }),
                                        _vm._v(" "),
                                        _c(
                                          "a",
                                          {
                                            staticClass: "copy-code",
                                            attrs: {
                                              href: "javascript:void(null)",
                                            },
                                            on: { click: _vm.copyJsonResponse },
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: "/static/img/icons/copy.png",
                                                alt: "",
                                              },
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                { attrs: { for: "formGroupExampleInput9" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "views.client.reception.receptionservice.content.general.serviceData"
                                      )
                                    ) + "\n                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.serviceData,
                                    expression: "serviceData",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id: "formGroupExampleInput9",
                                  readonly: "",
                                },
                                domProps: { value: _vm.serviceData },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.serviceData = $event.target.value
                                  },
                                },
                              }),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _vm.receptionservice.serviceConsentiment
              ? _c(
                  "div",
                  { staticClass: "teros_principal__content__accordion__card" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "teros_principal__content__accordion__card__header",
                        attrs: { id: "headingTwo" },
                      },
                      [
                        _c(
                          "h2",
                          {
                            staticClass:
                              "teros_principal__content__accordion__card__header__title",
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "teros_principal__content__accordion__card__header__title__btn",
                                attrs: {
                                  type: "button",
                                  "data-toggle": "collapse",
                                  "data-target": "#collapseTwo",
                                  "aria-expanded": "false",
                                  "aria-controls": "collapseTwo",
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "views.client.reception.receptionservice.content.compliance.title"
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("hr"),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "teros_principal__content__accordion__card__header__collapse",
                        attrs: { id: "collapseTwo" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "teros_principal__content__accordion__card__header__collapse__body pt0",
                            staticStyle: { padding: "0 40px" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "teros_principal__content__table",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "form-group row",
                                    staticStyle: { "padding-top": "40px" },
                                  },
                                  [
                                    _c("div", { staticClass: "col" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "custom-control custom-switch",
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.receptionservice
                                                    .consentiment,
                                                expression:
                                                  "receptionservice.consentiment",
                                              },
                                            ],
                                            staticClass: "custom-control-input",
                                            attrs: {
                                              type: "checkbox",
                                              id: "customSwitch4",
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.receptionservice
                                                  .consentiment
                                              )
                                                ? _vm._i(
                                                    _vm.receptionservice
                                                      .consentiment,
                                                    null
                                                  ) > -1
                                                : _vm.receptionservice
                                                    .consentiment,
                                            },
                                            on: {
                                              click: _vm.onChangeConsentiment,
                                              change: function ($event) {
                                                var $$a =
                                                    _vm.receptionservice
                                                      .consentiment,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.receptionservice,
                                                        "consentiment",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.receptionservice,
                                                        "consentiment",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.receptionservice,
                                                    "consentiment",
                                                    $$c
                                                  )
                                                }
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "custom-control-label",
                                              attrs: { for: "customSwitch4" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "views.client.reception.receptionservice.content.compliance.consentSwitch"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.receptionservice.consentiment
                                  ? _c(
                                      "div",
                                      { staticClass: "form-group row" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "col" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                attrs: {
                                                  for: "formGroupExampleInput6",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "views.client.reception.receptionservice.content.compliance.validityConsent"
                                                    )
                                                  ) + "\n                    "
                                                ),
                                                _c("img", {
                                                  attrs: {
                                                    src: "/static/img/icons/tooltip.png",
                                                    "data-toggle": "tooltip",
                                                    "data-placement": "right",
                                                    title: _vm.$t(
                                                      "views.client.reception.receptionservice.toolTips.compliance.consentValidity"
                                                    ),
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("custom-input", {
                                              attrs: {
                                                id: "formGroupExampleInput6",
                                                type: "number",
                                                regex: "[0-9]",
                                              },
                                              model: {
                                                value:
                                                  _vm.receptionservice
                                                    .consentimentExpiration,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.receptionservice,
                                                    "consentimentExpiration",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "receptionservice.consentimentExpiration",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                attrs: {
                                                  for: "formGroupExampleInput7",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "views.client.reception.receptionservice.content.compliance.purposes"
                                                    )
                                                  ) + "\n                  "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                staticClass: "action-fields",
                                                attrs: {
                                                  href: "javascript:void(null)",
                                                },
                                                on: { click: _vm.addPurpose },
                                              },
                                              [
                                                _vm._v(
                                                  "+ " +
                                                    _vm._s(
                                                      _vm.$t("views.shared.add")
                                                    )
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm._l(
                                              _vm.purposes,
                                              function (item, counter) {
                                                return _c(
                                                  "div",
                                                  { key: counter },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value: item.name,
                                                          expression:
                                                            "item.name",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      attrs: { type: "text" },
                                                      domProps: {
                                                        value: item.name,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            item,
                                                            "name",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "action-fields",
                                                        attrs: {
                                                          href: "javascript:void(null)",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.removePurpose(
                                                              counter
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "- " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "views.shared.remove"
                                                              )
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.receptionservice.consentiment
                                  ? _c(
                                      "div",
                                      { staticClass: "form-group row" },
                                      [
                                        _c("div", { staticClass: "col" }, [
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                for: "formGroupExampleInput8",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "views.client.reception.receptionservice.content.compliance.complianceStandards"
                                                  )
                                                ) + "\n                    "
                                              ),
                                              _c("img", {
                                                attrs: {
                                                  src: "/static/img/icons/tooltip.png",
                                                  "data-toggle": "tooltip",
                                                  "data-placement": "right",
                                                  title: _vm.$t(
                                                    "views.client.reception.receptionservice.toolTips.compliance.compliancePattern"
                                                  ),
                                                },
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.receptionservice
                                                      .standardHash,
                                                  expression:
                                                    "receptionservice.standardHash",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                disabled:
                                                  _vm.standards == null ||
                                                  _vm.standards.length < 1,
                                              },
                                              on: {
                                                change: [
                                                  function ($event) {
                                                    var $$selectedVal =
                                                      Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function (o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function (o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                    _vm.$set(
                                                      _vm.receptionservice,
                                                      "standardHash",
                                                      $event.target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    )
                                                  },
                                                  function ($event) {
                                                    return _vm.onChangeStandard(
                                                      $event
                                                    )
                                                  },
                                                ],
                                              },
                                            },
                                            _vm._l(
                                              _vm.standards,
                                              function (item) {
                                                return _c(
                                                  "option",
                                                  {
                                                    key: item.hash,
                                                    domProps: {
                                                      value: item.hash,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(item.name) +
                                                        "\n                    "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.newObj && _vm.receptionservice.consentiment
                                  ? _c("div", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "teros_principal__content__table__header",
                                        },
                                        [
                                          _vm.showTerms
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "teros_principal__content__table__header__filters",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "form-group",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "search-group",
                                                        },
                                                        [
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm.textSearchValue,
                                                                expression:
                                                                  "textSearchValue",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "input-field",
                                                            attrs: {
                                                              type: "text",
                                                              placeholder:
                                                                _vm.$t(
                                                                  "views.shared.searchBar.searchLabel"
                                                                ),
                                                              maxlength: "30",
                                                              onkeydown:
                                                                "return event.keyCode == 13 ? event.preventDefault() : true",
                                                            },
                                                            domProps: {
                                                              value:
                                                                _vm.textSearchValue,
                                                            },
                                                            on: {
                                                              input: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                )
                                                                  return
                                                                _vm.textSearchValue =
                                                                  $event.target.value
                                                              },
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          _c("button", {
                                                            staticClass:
                                                              "search-button",
                                                            attrs: {
                                                              type: "button",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.makeTextSearch()
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("itens-per-page", {
                                                    attrs: {
                                                      "request-pagination":
                                                        _vm.requestPagination,
                                                      callback:
                                                        _vm.makeTextSearch,
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("div", {
                                            staticClass:
                                              "teros_principal__content__table__header__actions",
                                          }),
                                          _vm._v(" "),
                                          !_vm.newObj
                                            ? _c(
                                                "div",
                                                { staticClass: "col" },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "show_tiny teros_principal__content__column__info__form__combo__btn-contrato",
                                                      staticStyle: {
                                                        width: "300px",
                                                        "margin-left": "auto",
                                                      },
                                                      attrs: {
                                                        href: "javascript:void(null)",
                                                        "data-toggle": "modal",
                                                        "data-target":
                                                          "#resetModal",
                                                        "data-placement": "top",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "views.client.reception.receptionservice.content.compliance.consentButtons.restoreRecommendedCompliance"
                                                            )
                                                          ) +
                                                          "\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.showTerms
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "teros_principal__content__table__body",
                                            },
                                            [
                                              _c(
                                                "table",
                                                {
                                                  staticClass:
                                                    "teros_principal__content__table__body__table",
                                                  attrs: {
                                                    id: "table",
                                                    "data-toggle": "table",
                                                    "data-height": "600",
                                                    "data-url": "",
                                                  },
                                                },
                                                [
                                                  _c("thead", [
                                                    _c("tr", [
                                                      _c(
                                                        "th",
                                                        {
                                                          attrs: {
                                                            "data-field": "id",
                                                            "data-sortable":
                                                              "true",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "views.client.reception.receptionservice.content.compliance.table.header.ordering"
                                                                )
                                                              ) +
                                                              "\n                        "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "th",
                                                        {
                                                          attrs: {
                                                            "data-field": "a",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "views.client.reception.receptionservice.content.compliance.table.header.standard"
                                                                )
                                                              ) +
                                                              "\n                        "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "th",
                                                        {
                                                          attrs: {
                                                            "data-field": "d",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "views.shared.table.header.createdAt"
                                                                )
                                                              ) +
                                                              "\n                        "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "th",
                                                        {
                                                          attrs: {
                                                            "data-field": "e",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "views.shared.table.header.changedAt"
                                                                )
                                                              ) +
                                                              "\n                        "
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "th",
                                                        {
                                                          attrs: {
                                                            "data-field": "f",
                                                          },
                                                        },
                                                        [_vm._v("Status")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "th",
                                                        {
                                                          staticStyle: {
                                                            "text-align":
                                                              "center",
                                                          },
                                                          attrs: {
                                                            "data-field": "g",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "views.shared.table.header.options"
                                                                )
                                                              ) +
                                                              "\n                        "
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "tbody",
                                                    _vm._l(
                                                      _vm.terms,
                                                      function (item) {
                                                        return _c(
                                                          "tr",
                                                          { key: item.hash },
                                                          [
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.orderNumber
                                                                )
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.name
                                                                )
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "formatDate"
                                                                  )(
                                                                    item.creationDate
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "formatDate"
                                                                  )(
                                                                    item.lastUpdateDate
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                            _vm._v(" "),
                                                            _c(
                                                              "td",
                                                              {
                                                                class: _vm._f(
                                                                  "formatClass"
                                                                )(
                                                                  item.authorizationStatus
                                                                ),
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          " +
                                                                    _vm._s(
                                                                      _vm.statusTranslated(
                                                                        item.authorizationStatus
                                                                      )
                                                                    ) +
                                                                    "\n                        "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "td",
                                                              {
                                                                staticStyle: {
                                                                  "text-align":
                                                                    "center",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "teros_principal__content__table__body__table__options mult-opt",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "router-link",
                                                                      {
                                                                        staticClass:
                                                                          "teros_principal__content__table__body__table__options__item",
                                                                        attrs: {
                                                                          to:
                                                                            "/servicepartnershipterm?receptionServiceHash=" +
                                                                            _vm
                                                                              .receptionservice
                                                                              .hash +
                                                                            "&hash=" +
                                                                            item.hash,
                                                                          "data-toggle":
                                                                            "tooltip",
                                                                          "data-placement":
                                                                            "top",
                                                                          title:
                                                                            "Visualizar/Editar",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          [
                                                                            _c(
                                                                              "img",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    src: "/static/img/icons/editar.svg",
                                                                                    alt: "",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.showTerms
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "teros_principal__content__table__footer",
                                            },
                                            [
                                              _vm.loading
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "teros_principal__content__table__footer__loader",
                                                    },
                                                    [
                                                      _c("img", {
                                                        attrs: {
                                                          src: "/static/img/icons/ajax-loader.gif",
                                                          alt: "",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "views.shared.loadingResults"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c("pagination", {
                                                attrs: {
                                                  "request-pagination":
                                                    _vm.requestPagination,
                                                  callback: _vm.makeTextSearch,
                                                  "current-pagination-info":
                                                    _vm.currentPaginationInfo,
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "teros_principal__content__table__footer__pagination",
                                                },
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      staticClass:
                                                        "teros_principal__content__table__footer__add-item",
                                                      attrs: {
                                                        to:
                                                          "/servicepartnershipterm?receptionServiceHash=" +
                                                          _vm.receptionservice
                                                            .hash,
                                                        title: "Novo Termo",
                                                      },
                                                    },
                                                    [
                                                      _c("img", {
                                                        attrs: {
                                                          src: "/static/img/icons/plus.png",
                                                          alt: "",
                                                        },
                                                      }),
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "views.shared.includeButton"
                                                          )
                                                        ) +
                                                          "\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.receptionservice.standardHash != null &&
                                _vm.receptionservice.consentiment
                                  ? _c(
                                      "div",
                                      { staticClass: "group-form-group" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "form-group row" },
                                          [
                                            _c("div", { staticClass: "col" }, [
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "show_tiny teros_principal__content__column__info__form__combo__btn-contrato",
                                                  staticStyle: {
                                                    width: "270px",
                                                  },
                                                  attrs: {
                                                    href: "javascript:void(null)",
                                                  },
                                                  on: {
                                                    click: _vm.showOrHideTiny,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "views.client.reception.receptionservice.content.compliance.consentButtons.editConsentLayout"
                                                        )
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("div", [
                                              _vm.receptionservice
                                                .consentimentFileName
                                                ? _c(
                                                    "div",
                                                    { staticClass: "col" },
                                                    [
                                                      _c("pdf-view", {
                                                        attrs: {
                                                          "file-name":
                                                            _vm.receptionservice
                                                              .consentimentFileName,
                                                          type: "button",
                                                          title: _vm.$t(
                                                            "views.client.reception.receptionservice.content.compliance.consentButtons.viewConsent"
                                                          ),
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm.receptionservice.hash
                                                ? _c(
                                                    "div",
                                                    { staticClass: "col" },
                                                    [
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "teros_principal__content__column__info__form__combo__btn-contrato",
                                                          staticStyle: {
                                                            width: "270px",
                                                          },
                                                          attrs: {
                                                            type: "button",
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.getfileUrl,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "views.client.reception.receptionservice.content.compliance.consentButtons.viewConsent"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.showTiny
                                          ? _c(
                                              "div",
                                              { staticClass: "form-group row" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "col" },
                                                  [
                                                    _c("editor", {
                                                      attrs: {
                                                        "api-key": "no-api-key",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.receptionservice
                                                            .consentimentLayout,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.receptionservice,
                                                            "consentimentLayout",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "receptionservice.consentimentLayout",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "form-group align-right block-out" }, [
              _c(
                "button",
                {
                  staticClass:
                    "teros_principal__content__column__info__form__table__plus mb0",
                },
                [
                  _c("img", {
                    attrs: {
                      src: "/static/img/icons/disquete.png",
                      alt: "Salvar",
                    },
                  }),
                  _vm._v(
                    _vm._s(_vm.$t("views.shared.saveButton")) + "\n        "
                  ),
                ]
              ),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal fade teros-modal",
          attrs: {
            id: "resetModal",
            tabindex: "-1",
            "aria-labelledby": "resetModalLabel",
            "aria-hidden": "true",
          },
        },
        [
          _c("div", { staticClass: "modal-dialog" }, [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(1),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.$t(
                        "views.client.reception.receptionservice.modal.restore.confirmMessage"
                      )
                    ) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button", "data-dismiss": "modal" },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t("views.shared.modal.delete.cancelButton")
                        ) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button", "data-dismiss": "modal" },
                    on: { click: _vm.termsReset },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t(
                            "views.client.reception.receptionservice.modal.restore.restoreButton"
                          )
                        ) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal fade teros-modal",
          attrs: {
            id: "groupingsModal",
            tabindex: "-1",
            "aria-labelledby": "groupingModalLabel",
            "aria-hidden": "true",
            "data-backdrop": "static",
          },
        },
        [
          _c("div", { staticClass: "modal-dialog modal-dialog-scrollable" }, [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(2),
              _vm._v(" "),
              _vm.permissions.length > 0
                ? _c(
                    "div",
                    {
                      staticClass: "modal-body",
                      staticStyle: { "text-align": "start !important" },
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          _vm._l(
                            _vm.receptionServicePermissions,
                            function (rsPermission, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "form-group" },
                                [
                                  rsPermission[0]
                                    ? _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getPermissionNameByPermissionHash(
                                              rsPermission[0].permissionHash
                                            )
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.receptionServicePermissions[index],
                                    function (item, counter) {
                                      return _c(
                                        "div",
                                        {
                                          key: counter,
                                          staticClass: "row mt-3",
                                        },
                                        [
                                          _c("div", { staticClass: "col-4" }, [
                                            _vm._m(3, true),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm
                                                      .receptionServicePermissions[
                                                      index
                                                    ][counter].version,
                                                  expression:
                                                    "receptionServicePermissions[index][counter].version",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                id: "formGroupPermission",
                                                placeholder: "Versão",
                                                width: "250px",
                                                disabled:
                                                  _vm
                                                    .receptionServicePermissions[
                                                    index
                                                  ][counter].disabled,
                                              },
                                              domProps: {
                                                value:
                                                  _vm
                                                    .receptionServicePermissions[
                                                    index
                                                  ][counter].version,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm
                                                      .receptionServicePermissions[
                                                      index
                                                    ][counter],
                                                    "version",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "col-8" }, [
                                            _vm._m(4, true),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm
                                                      .receptionServicePermissions[
                                                      index
                                                    ][counter].path,
                                                  expression:
                                                    "receptionServicePermissions[index][counter].path",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                id: "formGroupPermission",
                                                width: "250px",
                                                placeholder: "Path",
                                              },
                                              domProps: {
                                                value:
                                                  _vm
                                                    .receptionServicePermissions[
                                                    index
                                                  ][counter].path,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm
                                                      .receptionServicePermissions[
                                                      index
                                                    ][counter],
                                                    "path",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]),
                                        ]
                                      )
                                    }
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-12" }, [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "action-fields",
                                          attrs: {
                                            href: "javascript:void(null)",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.addReceptionServicePermission(
                                                rsPermission[0].permissionHash,
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "+ " +
                                              _vm._s(_vm.$t("views.shared.add"))
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "action-fields",
                                          attrs: {
                                            href: "javascript:void(null)",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeReceptionServicePermission(
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "- " +
                                              _vm._s(
                                                _vm.$t("views.shared.remove")
                                              )
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("hr"),
                                ],
                                2
                              )
                            }
                          ),
                          0
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button", "data-dismiss": "modal" },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t("views.shared.modal.delete.cancelButton")
                        ) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button", "data-dismiss": "modal" },
                    on: { click: _vm.saveReceptionServicePermissions },
                  },
                  [_vm._v("\n            Salvar\n          ")]
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal fade teros-modal",
          attrs: {
            id: "endpointsModal",
            tabindex: "-1",
            "aria-labelledby": "endpointsModalLabel",
            "aria-hidden": "true",
            "data-backdrop": "static",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-dialog modal-dialog-scrollable",
              staticStyle: { "min-width": "700px !important" },
            },
            [
              _c("div", { staticClass: "modal-content" }, [
                _vm._m(5),
                _vm._v(" "),
                _vm.endpointPermissionsList.length > 0
                  ? _c(
                      "div",
                      {
                        staticClass: "modal-body",
                        staticStyle: { "text-align": "start !important" },
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            _vm._l(
                              _vm.endpointPermissionsList,
                              function (endpointPermission, index) {
                                return _c("div", [
                                  _c("p", [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "action-fields",
                                        attrs: {
                                          "data-toggle": "collapse",
                                          href:
                                            "#multiCollapseExample1" + index,
                                          role: "button",
                                          "aria-expanded": "true",
                                          "aria-controls":
                                            "multiCollapseExample1" + index,
                                        },
                                      },
                                      [
                                        _c("h5", [
                                          _c(
                                            "strong",
                                            {
                                              staticStyle: {
                                                "text-transform": "lowercase",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(
                                                    _vm.getPermissionNameByPermissionHash(
                                                      endpointPermission.permissionHash
                                                    )
                                                  )
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-12" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "collapse",
                                          attrs: {
                                            id: "multiCollapseExample1" + index,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "card card-body" },
                                            [
                                              endpointPermission.endpoints &&
                                              endpointPermission.endpoints
                                                .length > 0
                                                ? _c(
                                                    "div",
                                                    _vm._l(
                                                      endpointPermission.endpoints,
                                                      function (
                                                        endpoint,
                                                        indexPermission
                                                      ) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-group row",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-9",
                                                              },
                                                              [
                                                                _c("label", [
                                                                  _vm._v(
                                                                    "\n                                Endpoint\n                              "
                                                                  ),
                                                                ]),
                                                                _vm._v(" "),
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        endpoint.endpoint,
                                                                      expression:
                                                                        "endpoint.endpoint",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "form-control col-11 mb-3",
                                                                  attrs: {
                                                                    type: "text",
                                                                    required:
                                                                      "",
                                                                    id: "formGroupEndpontInput",
                                                                    disabled:
                                                                      "",
                                                                  },
                                                                  domProps: {
                                                                    value:
                                                                      endpoint.endpoint,
                                                                  },
                                                                  on: {
                                                                    input:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          $event
                                                                            .target
                                                                            .composing
                                                                        )
                                                                          return
                                                                        _vm.$set(
                                                                          endpoint,
                                                                          "endpoint",
                                                                          $event
                                                                            .target
                                                                            .value
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-3",
                                                              },
                                                              [
                                                                _c("label", [
                                                                  _vm._v(
                                                                    "\n                                Versão\n                              "
                                                                  ),
                                                                ]),
                                                                _vm._v(" "),
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        endpoint.version,
                                                                      expression:
                                                                        "endpoint.version",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "form-control col-11 mb-3",
                                                                  attrs: {
                                                                    type: "text",
                                                                    required:
                                                                      "",
                                                                    id: "formGroupEndpontInput",
                                                                    disabled:
                                                                      "",
                                                                  },
                                                                  domProps: {
                                                                    value:
                                                                      endpoint.version,
                                                                  },
                                                                  on: {
                                                                    input:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          $event
                                                                            .target
                                                                            .composing
                                                                        )
                                                                          return
                                                                        _vm.$set(
                                                                          endpoint,
                                                                          "version",
                                                                          $event
                                                                            .target
                                                                            .value
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                : _c("div", [
                                                    _c("h5", [
                                                      _vm._v(
                                                        "Não existem endpoints configurados para essa permissão"
                                                      ),
                                                    ]),
                                                  ]),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ])
                              }
                            ),
                            0
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "modal-footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button", "data-dismiss": "modal" },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t("views.shared.modal.delete.cancelButton")
                          ) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button", "data-dismiss": "modal" },
                      on: { click: function ($event) {} },
                    },
                    [_vm._v("\n            Ok\n          ")]
                  ),
                ]),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "ul",
      { staticClass: "nav nav-tabs", attrs: { id: "myTab", role: "tablist" } },
      [
        _c("li", { staticClass: "nav-item" }, [
          _c(
            "a",
            {
              staticClass: "nav-link active",
              attrs: {
                id: "home-tab",
                "data-toggle": "tab",
                href: "#home",
                role: "tab",
                "aria-controls": "home",
                "aria-selected": "true",
              },
            },
            [_vm._v("Body Request")]
          ),
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "nav-item" }, [
          _c(
            "a",
            {
              staticClass: "nav-link",
              attrs: {
                id: "profile-tab",
                "data-toggle": "tab",
                href: "#profile",
                role: "tab",
                "aria-controls": "profile",
                "aria-selected": "false",
              },
            },
            [_vm._v("JSON Schema Response")]
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        { staticClass: "modal-title", attrs: { id: "resetModalLabel" } },
        [
          _c("img", {
            attrs: { src: "/static/img/icons/atencao-modal.png", alt: "" },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "modal-header", attrs: { id: "groupingModalLabel" } },
      [
        _c("h5", { staticClass: "modal-title" }, [
          _vm._v("Configurações de Permissões"),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "close",
            attrs: {
              type: "button",
              "data-dismiss": "modal",
              "aria-label": "Close",
            },
          },
          [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "formGroupPermission" } }, [
      _vm._v("\n                      Versão\n\n                      "),
      _c("img", {
        attrs: {
          src: "/static/img/icons/tooltip.png",
          "data-toggle": "tooltip",
          "data-placement": "right",
          title: "Title",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "formGroupPermission" } }, [
      _vm._v("\n                      Endpoint Path\n                      "),
      _c("img", {
        attrs: {
          src: "/static/img/icons/tooltip.png",
          "data-toggle": "tooltip",
          "data-placement": "right",
          title: "Title",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "modal-header", attrs: { id: "endpointsModalLabel" } },
      [
        _c("h5", { staticClass: "modal-title" }, [
          _vm._v("Configurações de Endpoints"),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "close",
            attrs: {
              type: "button",
              "data-dismiss": "modal",
              "aria-label": "Close",
            },
          },
          [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }