<template>
  <div class="teros_principal">
    <div class="teros_principal__login">
      <div class="teros_principal__login__column">
        <img
          src="/static/img/logo_login.png"
          class="teros_principal__login__column__logo"
          alt=""
        />
      </div>

      <div
        class="teros_principal__login__column teros_principal__login__column--fields"
      >
        <div class="teros_principal__login__column__title">
          <h1 class="teros_principal__login__column__title__text">
            TROCA DE SENHA
          </h1>
        </div>

        <div class="teros_principal__login__column__form__highlights">
          <p class="teros_principal__login__column__form__highlights__text">
            Sistema de Governança <br />
            <span>Teros Open Banking</span>
          </p>
        </div>

        <form @submit.prevent="changePassword">
          <div class="teros_principal__login__column__form__fields">
            <div class="form-group">
              <input
                type="password"
                class="form-control teros_principal__login__column__form__fields__input"
                v-model="oldPassword"
                placeholder="Senha atual"
              />
            </div>

            <div class="form-group">
              <input
                type="password"
                class="form-control teros_principal__login__column__form__fields__input"
                v-model="newPassword1"
                placeholder="Nova senha"
              />
            </div>

            <div class="form-group">
              <input
                type="password"
                class="form-control teros_principal__login__column__form__fields__input"
                v-model="newPassword2"
                placeholder="Confirmação"
              />
            </div>

            <button type="submit" class="btn btn-primary">ALTERAR</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { router, cognito, storage } from "@/_helpers";
import { CognitoUserPool, AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';

export default {
  data() {
    return {
      username: null,
      oldPassword: "",
      newPassword1: "",
      newPassword2: "",
    };
  },
  created() {
    var data = {
      UserPoolId: USERPOOLID,
      ClientId: CLIENTID,
    };
    var userPool = new CognitoUserPool(data);
    var cognitoUser = userPool.getCurrentUser();
    if (cognitoUser == null) {
      storage.clear();
      router.push("/login");
    }
    this.username = cognitoUser.username;
  },
  methods: {
    changePassword() {
      var authenticationData = {
        Username: this.username,
        Password: this.oldPassword,
      };
      var authenticationDetails = new AuthenticationDetails(
        authenticationData
      );
      var poolData = {
        UserPoolId: USERPOOLID,
        ClientId: CLIENTID,
      };
      var userPool = new CognitoUserPool(poolData);
      var userData = {
        Username: authenticationData.Username,
        Pool: userPool,
      };
      var cognitoUser = new CognitoUser(userData);
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: () => {
          if (this.newPassword1 == this.newPassword2) {
            cognitoUser.changePassword(
              this.oldPassword,
              this.newPassword1,
              (err) => {
                if (err) {
                  Vue.$toast.error(err.message);
                } else {
                  cognito.authenticate(
                    this.username,
                    this.newPassword1,
                    USERPOOLID,
                    CLIENTID
                  );
                }
              }
            );
          } else {
            Vue.$toast.error("As entradas para a nova senha devem ser iguais.");
          }
        },
        onFailure: () => {
          Vue.$toast.error("Senha atual incorreta.");
        },
      });
    },
  },
};
</script>