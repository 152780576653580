<template>
  <div class="teros_principal__content interna container">
    <div id="accordion" class="dashboard">
      <div class="teros_principal__content__accordion__card mx0">
        <div
          id="headingOne"
          class="teros_principal__content__accordion__card__header"
        >
          <h2 class="teros_principal__content__accordion__card__header__title">
            <button
              type="button"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
              class="
                teros_principal__content__accordion__card__header__title__btn
              "
              @click="loadPanel(0)"
            >
              {{ $t("views.shared.dashboard.lateralTab.KPIs") }}
            </button>
          </h2>
        </div>
        <div
          id="collapseOne"
          class="
            teros_principal__content__accordion__card__header__collapse
            collapse
          "
          style=""
        >
          <div
            class="
              teros_principal__content__accordion__card__header__collapse__body
            "
          >
            <div class="dashboard-page">
              <iframe
                :src="generalPanelUrl"
                width="1200"
                height="300"
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      <div class="teros_principal__content__accordion__card mx0">
        <div
          id="headingOne"
          class="teros_principal__content__accordion__card__header"
        >
          <h2 class="teros_principal__content__accordion__card__header__title">
            <button
              type="button"
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
              class="
                teros_principal__content__accordion__card__header__title__btn
                collapsed
              "
              @click="loadPanel(1)"
            >
              {{ $t("views.shared.dashboard.lateralTab.transactions") }}
            </button>
          </h2>
        </div>
        <div
          id="collapseTwo"
          class="
            teros_principal__content__accordion__card__header__collapse
            collapse
          "
          style=""
        >
          <div
            class="
              teros_principal__content__accordion__card__header__collapse__body
            "
          >
            <div class="dashboard-page">
              <iframe
                :src="servicesPanelUrl"
                width="1200"
                height="1900"
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      <div class="teros_principal__content__accordion__card mx0">
        <div
          id="headingOne"
          class="teros_principal__content__accordion__card__header"
        >
          <h2 class="teros_principal__content__accordion__card__header__title">
            <button
              type="button"
              data-toggle="collapse"
              data-target="#collapseTwo2"
              aria-expanded="false"
              aria-controls="collapseTwo2"
              class="
                teros_principal__content__accordion__card__header__title__btn
                collapsed
              "
              @click="loadPanel(2)"
            >
              {{ $t("views.shared.dashboard.lateralTab.consents") }}
            </button>
          </h2>
        </div>
        <div
          id="collapseTwo2"
          class="
            teros_principal__content__accordion__card__header__collapse
            collapse
          "
          style=""
        >
          <div
            class="
              teros_principal__content__accordion__card__header__collapse__body
            "
          >
            <div class="dashboard-page">
              <iframe
                :src="receptionPanelUrl"
                width="1200"
                height="3000"
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      <div class="teros_principal__content__accordion__card mx0">
        <div
          id="headingOne"
          class="teros_principal__content__accordion__card__header"
        >
          <h2 class="teros_principal__content__accordion__card__header__title">
            <button
              type="button"
              data-toggle="collapse"
              data-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
              class="
                teros_principal__content__accordion__card__header__title__btn
                collapsed
              "
              @click="loadPanel(3)"
            >
            
              {{
                $t("views.shared.dashboard.lateralTab.techIndicators")
              }}
            </button>
          </h2>
        </div>
        <div
          id="collapseThree"
          class="
            teros_principal__content__accordion__card__header__collapse
            collapse
          "
          style=""
        >
          <div
            class="
              teros_principal__content__accordion__card__header__collapse__body
            "
          >
            <div class="dashboard-page">
              <iframe
                :src="responsibilityManagementPanelUrl"
                width="1200"
                height="1300"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    

    <!--
      <div class="teros_principal__content__accordion__card mx0">
        <div
          id="headingOne"
          class="teros_principal__content__accordion__card__header"
        >
          <h2 class="teros_principal__content__accordion__card__header__title">
            <button
              type="button"
              data-toggle="collapse"
              data-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
              class="
                teros_principal__content__accordion__card__header__title__btn
                collapsed
              "
              @click="loadPanel(4)"
            >
              {{ $t("views.shared.dashboard.lateralTab.usersChannels") }}
            </button>
          </h2>
        </div>
        <div
          id="collapseFour"
          class="
            teros_principal__content__accordion__card__header__collapse
            collapse
          "
          style=""
        >
          <div
            class="
              teros_principal__content__accordion__card__header__collapse__body
            "
          >
            <div class="dashboard-page">
              <iframe
                :src="usersChannelsPanelUrl"
                width="1200"
                height="2400"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
-->

    </div>
  </div>
</template>

<script>
import { http } from "@/_helpers";

export default {
  data() {
    return {
      generalPanelUrl: "",
      servicesPanelUrl: "",
      receptionPanelUrl: "",
      responsibilityManagementPanelUrl: "",
      usersChannelsPanelUrl: "",
      expanded: [false, false, false, false, false],
    };
  },
  mounted() {
    this.$emit("message", this.$route);
  },
  methods: {
    loadPanel(id) {
      if (this.expanded[id]) {
        this.expanded[id] = false;
      } else {
        http.get("/api/util/get/tableau/ticket/").then((response) => {
          let ticket = response.data;
          switch (id) {
            case 0:
              this.generalPanelUrl =
                TABLEAUURL +
                "trusted/" +
                ticket +
                "/views/TerosDashboard/KPIs?:embed=yes&:tabs=no&:toolbar=no";
              break;
            case 1:
              this.servicesPanelUrl =
                TABLEAUURL +
                "trusted/" +
                ticket +
                "/views/TerosDashboard/TransactionsAnalysis?:embed=yes&:tabs=no&:toolbar=no";
              break;
            case 2:
              this.receptionPanelUrl =
                TABLEAUURL +
                "trusted/" +
                ticket +
                "/views/TerosDashboard/ConsentAnalysis?:embed=yes&:tabs=no&:toolbar=no";
              break;
            case 3:
              this.responsibilityManagementPanelUrl =
                TABLEAUURL +
                "trusted/" +
                ticket +
                "/views/Dash_OB_atual/PainelGesto?:embed=yes&:tabs=no&:toolbar=no";
              break;
            case 4:
              this.usersChannelsPanelUrl =
                TABLEAUURL +
                "trusted/" +
                ticket +
                "/views/Dash_OB_atual/PainelCanais?:embed=yes&:tabs=no&:toolbar=no";
              break;
          }
          this.expanded[id] = true;
        });
      }
    },
  },
};
</script>