var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "teros_principal" }, [
    _c("div", { staticClass: "teros_principal" }, [
      _c("div", { staticClass: "teros_principal__login" }, [
        _c(
          "div",
          {
            staticClass:
              "teros_principal__login__column teros_principal__login__column--fields",
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "teros_principal__login__column__form__highlights",
              },
              [
                _c(
                  "p",
                  {
                    staticClass:
                      "teros_principal__login__column__form__highlights__text",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("views.shared.titleSystem")) +
                        " "
                    ),
                    _c("br"),
                    _vm._v(" "),
                    _c("span", [_vm._v("Teros Open Banking")]),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.login.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "teros_principal__login__column__form__fields",
                  },
                  [
                    _c("div", { staticClass: "form-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.username,
                            expression: "username",
                          },
                        ],
                        staticClass:
                          "form-control teros_principal__login__column__form__fields__input text-field",
                        attrs: { type: "text", placeholder: "Login" },
                        domProps: { value: _vm.username },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.username = $event.target.value
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("input-password", {
                          attrs: {
                            placeholder: _vm.$t("views.login.password"),
                            password: _vm.password,
                          },
                          on: {
                            onChange: function ($event) {
                              _vm.password = $event
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("div", { staticClass: "form-check" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.keepConected,
                              expression: "keepConected",
                            },
                          ],
                          staticClass: "form-check-input",
                          attrs: { type: "checkbox", id: "gridCheck" },
                          domProps: {
                            checked: Array.isArray(_vm.keepConected)
                              ? _vm._i(_vm.keepConected, null) > -1
                              : _vm.keepConected,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.keepConected,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.keepConected = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.keepConected = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.keepConected = $$c
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "form-check-label",
                            attrs: { for: "gridCheck" },
                          },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(
                                  _vm.$t("views.login.checkboxStayConnected")
                                ) +
                                "\n                "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "submit" },
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("views.login.sendButton")) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.ssoIssuer
                      ? _c("hr", { staticStyle: { margin: "25px 0" } })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.ssoIssuer
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            staticStyle: {
                              width: "300px",
                              "margin-top": "0px",
                            },
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.SSOsignin()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n              Single Sign-On Login\n            "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "teros_principal__login__column__form__obs",
                attrs: { to: "/forgotpassword" },
              },
              [_vm._v(_vm._s(_vm.$t("views.login.forgotPwdLink")))]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "center" }, [
      _c("img", {
        staticClass: "teros_principal__login__column__logo",
        attrs: { src: "/static/img/logo_login.png", alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }