<template>
  <div class="teros_principal__content interna container">
    <div class="teros_principal__content__line-1">
      <div class="teros_principal__content__line-1__highlights">
        <div class="teros_principal__content__line-1__highlights__subject">
          <div
            class="teros_principal__content__line-1__highlights__subject__breadcrumb"
          >
            <h3
              class="teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle"
            >
              {{ $t("views.teros.customers.customer.title") }}
            </h3>

            <h2
              class="teros_principal__content__line-1__highlights__subject__breadcrumb__title active"
            >
              {{ customer.name }}
            </h2>
          </div>
        </div>
      </div>

      <div class="teros_principal__content__line-1__information">
        <div class="teros_principal__content__line-1__information__time">
          <p class="teros_principal__content__line-1__information__time__text">
            <img
              class="teros_principal__content__line-1__information__time__text__icon"
              src="/static/img/icons/relogio.png"
              alt=""
            />
            {{
              $t("views.shared.createdDate", {
                date: creationDate,
                time: creationTime,
              })
            }}
            {{
              lastUpdateDate
                ? "| " +
                  $t("views.shared.changedDate", {
                    date: lastUpdateDate,
                    time: lastUpdateTime,
                  })
                : ""
            }}
          </p>

          <button
            type="button"
            @click="goBack"
            class="teros_principal__content__line-1__information__time__btn"
          >
            <img src="/static/img/icons/seta-esquerda.png" alt="" />
            {{ $t("views.shared.backButton") }}
          </button>
        </div>
      </div>
    </div>

    <div class="teros_principal__content__column">
      <div class="teros_principal__content__column__menu-vertical">
        <ul class="teros_principal__content__column__menu-vertical__list">
          <li
            class="teros_principal__content__column__menu-vertical__list__item"
          >
            <router-link
              :to="'/customer?hash=' + customer.hash"
              class="teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--lupa"
              >{{ $t("views.teros.customers.customer.tabTitle") }}</router-link
            >
          </li>

          <li
            class="teros_principal__content__column__menu-vertical__list__item"
          >
            <a
              href="#"
              class="teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--usuarios active"
              >{{ $t("views.teros.customers.users.tabTitle") }}</a
            >
          </li>

          <li
            class="teros_principal__content__column__menu-vertical__list__item"
          >
            <router-link
              :to="'/customerlogs?hash=' + customer.hash"
              class="teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--gestao"
              >{{
                $t("views.teros.customers.customerlogs.tabTitle")
              }}</router-link
            >
          </li>
        </ul>
      </div>

      <div class="teros_principal__content__column__info">
        <div class="teros_principal__content__table">
          <div class="teros_principal__content__table__header">
            <div class="teros_principal__content__table__header__filters">
              <div class="form-group">
                <form @submit.prevent="makeTextSearch">
                  <div class="search-group">
                    <input
                      type="text"
                      class="input-field"
                      :placeholder="$t('views.shared.searchBar.searchLabel')"
                      maxlength="30"
                      v-model="textSearchValue"
                    />
                    <button class="search-button" />
                  </div>
                </form>
              </div>

              <itens-per-page
                :request-pagination="requestPagination"
                :callback="makeTextSearch"
              ></itens-per-page>
            </div>
          </div>

          <div class="teros_principal__content__table__body">
            <table
              id="table"
              class="teros_principal__content__table__body__table"
              data-toggle="table"
              data-height="600"
              data-url=""
            >
              <thead>
                <tr>
                  <th data-field="id" data-sortable="true">ID</th>
                  <th data-field="b">
                    {{
                      $t("views.teros.customers.users.table.header.userName")
                    }}
                  </th>
                  <th data-field="a">
                    {{
                      $t("views.teros.customers.users.table.header.fullName")
                    }}
                  </th>
                  <!--<th data-field="c">
                    {{
                      $t("views.teros.customers.users.table.header.accessGroup")
                    }}
                  </th>-->
                  <th data-field="d">
                    {{ $t("views.teros.customers.users.table.header.mail") }}
                  </th>
                  <th data-field="e">
                    {{
                      $t(
                        "views.teros.customers.users.table.header.contactPhone"
                      )
                    }}
                  </th>
                  <th data-field="f" style="text-align: center">
                    {{ $t("views.shared.table.header.options") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in users" :key="item.hash">
                  <td>{{ index + 1 }}</td>
                  <td style="word-wrap: break-word; max-width: 160px">
                    {{ item.username }}
                  </td>
                  <td style="word-wrap: break-word; max-width: 160px">
                    {{ item.name }}
                  </td>
                  <!--<td>...</td>-->
                  <td>{{ item.email }}</td>
                  <td>{{ item.phoneNumber }}</td>

                  <td
                    style="
                      width: 100px;
                      padding-left: 0;
                      padding-right: 0;
                      text-align: center;
                    "
                  >
                    <div
                      class="teros_principal__content__table__body__table__options"
                    >
                      <router-link
                        :to="'/user?hash=' + item.hash"
                        class="teros_principal__content__table__body__table__options__item"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Visualizar/Editar"
                      >
                        <div>
                          <img src="/static/img/icons/editar.svg" alt="" />
                        </div>
                      </router-link>

                      <a
                        @click="prepareRemove(item.hash, index)"
                        class="teros_principal__content__table__body__table__options__item"
                        data-toggle="modal"
                        data-target="#deleteModal"
                        data-placement="top"
                        title="Deletar"
                      >
                        <div>
                          <img src="/static/img/icons/deletar.svg" alt="" />
                        </div>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="teros_principal__content__table__footer">
            <div
              v-if="loading"
              class="teros_principal__content__table__footer__loader"
            >
              <img src="/static/img/icons/ajax-loader.gif" alt="" />
              <span>{{ $t("views.shared.loadingResults") }}</span>
            </div>
            <pagination
              :request-pagination="requestPagination"
              :callback="makeTextSearch"
              :current-pagination-info="currentPaginationInfo"
            ></pagination>

            <div class="teros_principal__content__table__footer__pagination">
              <router-link
                :to="'/user?customerHash=' + customer.hash"
                title="Novo Usuário"
                class="teros_principal__content__table__footer__add-item"
              >
                <img src="/static/img/icons/plus.png" alt="" />{{
                $t("views.shared.includeButton")
              }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade teros-modal"
      id="deleteModal"
      tabindex="-1"
      aria-labelledby="deleteModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteModalLabel">
              <img src="/static/img/icons/atencao-modal-red.png" alt="" />
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            {{ $t("views.shared.modal.delete.confirmMessage") }}
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              {{ $t("views.shared.modal.delete.cancelButton") }}
            </button>
            <button
              type="button"
              class="btn btn-primary btn-primary--red"
              data-dismiss="modal"
              @click="remove"
            >
              {{ $t("views.shared.modal.delete.deleteButton") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { http, router } from "@/_helpers";
import Pagination from "@/_components/Pagination.vue";
import ItensPerPage from "@/_components/ItensPerPage.vue";
import moment from 'moment';

export default {
  data() {
    return {
      customer: {},
      loading: true,
      users: [],
      userHash: null,
      index: 0,
      textSearchValue: "",
      oldTextSearchValue: "",
      currentPaginationInfo: null,
      requestPagination: { page: 1, take: 10 },
    };
  },
  components: {
    Pagination,
    ItensPerPage,
  },
  computed: {
    creationDate() {
      if (this.customer.creationDate) {
        return moment(String(this.customer.creationDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
    creationTime() {
      if (this.customer.creationDate) {
        return moment(String(this.customer.creationDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("HH:mm");
      }
      return "";
    },
    lastUpdateDate() {
      if (this.customer.lastUpdateDate) {
        return moment(String(this.customer.lastUpdateDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
    lastUpdateTime() {
      if (this.customer.lastUpdateDate) {
        return moment(String(this.customer.lastUpdateDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("HH:mm");
      }
      return "";
    },
  },
  mounted() {
    this.$emit("message", this.$route);
    const hash = this.$route.query.hash;
    http.get("/api/customer/get/" + hash).then((response) => {
      this.customer = response.data;
      http
        .get("/api/user/search/" + hash, false)
        .then((response) => {
          this.users = response.data.items;
          this.currentPaginationInfo = response.data;
        })
        .finally(() => (this.loading = false));
    });
  },
  methods: {
    goBack() {
      router.push({ name: "customerspage" });
    },
    prepareRemove(hash, index) {
      this.userHash = hash;
      this.index = index;
    },
    remove() {
      http.remove("/api/user/delete/" + this.userHash).then(() => {
        Vue.$toast.success(
          this.$i18n.t("views.teros.customers.users.modal.deletedMessage")
        );
        Vue.delete(this.users, this.index);
      });
    },
    makeTextSearch(event, isChangePage = false) {
      this.CheckValuesChanged(isChangePage);

      if (this.textSearchValue.trim()) {
        http
          .get(
            `/api/user/search/${
              this.customer.hash
            }?FilterProperty=Name&FilterValue=${this.textSearchValue.trim()}&Page=${
              this.requestPagination.page
            }&Take=${this.requestPagination.take}`
          )
          .then((response) => {
            this.users = response.data.items;
            this.currentPaginationInfo = response.data;
          })
          .finally(() => (this.loading = false));
      } else {
        http
          .get(
            `/api/user/search/${this.customer.hash}?Page=${this.requestPagination.page}&Take=${this.requestPagination.take}`
          )
          .then((response) => {
            this.users = response.data.items;
            this.currentPaginationInfo = response.data;
          })
          .finally(() => (this.loading = false));
      }
    },
    CheckValuesChanged(isChangePage = false) {
      if (this.textSearchValue != this.oldTextSearchValue) {
        if (isChangePage) {
          this.textSearchValue = this.oldTextSearchValue;
        } else {
          this.requestPagination.page = 1;
          this.oldTextSearchValue = this.textSearchValue;
        }
      }
    },
  },
};
</script>