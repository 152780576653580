var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.currentUser
      ? _c("div", { staticClass: "teros_principal__header__logo" }, [
          _c("img", { attrs: { src: "/static/img/logo_teros.png", alt: "" } }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _c("div", { attrs: { id: "swagger" } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }