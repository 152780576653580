import Vue from 'vue';
import { BehaviorSubject } from 'rxjs';
import { storage, router, http, i18n } from '@/_helpers';
import { AuthenticationDetails, CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';

const currentUserSubject = new BehaviorSubject(JSON.parse(storage.getItem('currentUser')));

export const cognito = {
    authenticate,
    logout,
    refreshToken,
    decodeToken,
    setCurrentUser,
    ssoAuthenticationRedirect,
    isPasswordValid,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() { return currentUserSubject.value }
};

function authenticate(username, password, userPoolId, cognitoClientId, returnUrl = '/') {
    let authenticationData = {
        Username: username,
        Password: password,
    };
    let authenticationDetails = new AuthenticationDetails(authenticationData);
    let poolData = {
        UserPoolId: userPoolId,
        ClientId: cognitoClientId,
    };
    let userPool = new CognitoUserPool(poolData);
    let userData = {
        Username: username,
        Pool: userPool,
    };
    let cognitoUser = new CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: () => {
            let idTokenName = "CognitoIdentityServiceProvider." + cognitoClientId + "." + cognitoUser.username + ".idToken";
            let accessTokenName = "CognitoIdentityServiceProvider." + cognitoClientId + "." + cognitoUser.username + ".accessToken";
            let refreshTokenName = "CognitoIdentityServiceProvider." + cognitoClientId + "." + cognitoUser.username + ".refreshToken";
            let idToken = localStorage.getItem(idTokenName);
            let accessToken = localStorage.getItem(accessTokenName);
            let refreshToken = localStorage.getItem(refreshTokenName);
            storage.setItem('idToken', idToken);
            storage.setItem('accessToken', accessToken);
            storage.setItem('refreshToken', refreshToken);
            http.
                get('/api/user/get/currentuser')
                .then(response => {
                    let user = response.data;
                    storage.setItem('currentUser', JSON.stringify(user));
                    currentUserSubject.next(user);
                    router.push(returnUrl);
                })
        },
        newPasswordRequired: result => {
            router.push('/newPasswordRequired?username=' + cognitoUser.username + '&session=' + cognitoUser.Session);
        },
        onFailure: err => {
            Vue.$toast.error(i18n.t('views.shared.modal.error.authUser'))
        }
    });
}

function logout() {
    let poolData = {
        UserPoolId: USERPOOLID,
        ClientId: CLIENTID
    }
    let userPool = new CognitoUserPool(poolData);
    let cognitoUser = userPool.getCurrentUser();
    currentUserSubject.next(null);
    if (cognitoUser != null) {
        cognitoUser.getSession(function (err, session) {
            cognitoUser.globalSignOut({
                onSuccess: function (result) {
                },
                onFailure: function (err) {
                    console.log("Error from cognito promise: ", err);
                }
            });
        });
        cognitoUser.signOut();
        storage.clear();
    }
    router.push('/login');
}

function refreshToken(callback) {
    let poolData = {
        UserPoolId: USERPOOLID,
        ClientId: CLIENTID
    }
    let userPool = new CognitoUserPool(poolData);
    let cognitoUser = userPool.getCurrentUser();
    if (cognitoUser != null) {
        cognitoUser.getSession(callback);
    }
    else {
        logout();
    }
}

function decodeToken(token) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
}

function ssoAuthenticationRedirect(identity_provider) {
    let query =
        'identity_provider=' + identity_provider +
        '&client_id=' + CLIENTID +
        '&redirect_uri=' + window.location.origin + "/samlredirect" +
        '&response_type=CODE&scope=aws.cognito.signin.user.admin email openid phone profile'
    window.location.href = COGNITO_DOMAIN + "/oauth2/authorize?" + query
}

function setCurrentUser(user) {
    currentUserSubject.next(user);
}

function isPasswordValid(password, passConfirm) {
    if (password.length < 8) {
        return false;
    } else if (!/\d/.test(password)) {
        return false;
    } else if (!/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password)) {
        return false;
    } else if (!/[a-z]/.test(password)) {
        return false;
    } else if (!/[A-Z]/.test(password)) {
        return false;
    }
    return true;
}