var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.currentPaginationInfo && _vm.currentPaginationInfo.totalPages
    ? _c(
        "div",
        { staticClass: "teros_principal__content__table__footer__pagination" },
        [
          _c("a", {
            staticClass: "bt-left",
            class: {
              "bt-left-disabled": _vm.currentPaginationInfo.currentPage == 1,
            },
            attrs: { href: "javascript:void(0);" },
            on: {
              click: function ($event) {
                return _vm.previousPage()
              },
            },
          }),
          _vm._v(" "),
          _c(
            "ul",
            _vm._l(_vm.rangeToShow, function (n) {
              return _c("li", { key: n }, [
                _c(
                  "a",
                  {
                    style: n == "..." ? "pointer-events: none;" : "",
                    attrs: { href: "javascript:void(0);" },
                    on: {
                      click: function ($event) {
                        return _vm.changePage(n)
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        style:
                          n == _vm.currentPaginationInfo.currentPage
                            ? "font-weight: bold;"
                            : "",
                      },
                      [_vm._v(_vm._s(n) + " ")]
                    ),
                  ]
                ),
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c("a", {
            staticClass: "bt-right",
            class: {
              "bt-right-disabled":
                _vm.currentPaginationInfo.currentPage ==
                _vm.currentPaginationInfo.totalPages,
            },
            attrs: { href: "javascript:void(0);" },
            on: {
              click: function ($event) {
                return _vm.nextPage()
              },
            },
          }),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }