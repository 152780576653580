var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "teros_principal__content container" }, [
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.makeTextSearch.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "teros_principal__content__table" }, [
          _c(
            "div",
            { staticClass: "teros_principal__content__table__header" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "teros_principal__content__table__header__filters",
                },
                [
                  _c("div", { staticClass: "form-group" }, [
                    _c("div", { staticClass: "search-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.textSearchValue,
                            expression: "textSearchValue",
                          },
                        ],
                        staticClass: "input-field",
                        attrs: {
                          type: "text",
                          placeholder: _vm.$t(
                            "views.shared.searchBar.searchLabel"
                          ),
                          maxlength: "30",
                        },
                        domProps: { value: _vm.textSearchValue },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.textSearchValue = $event.target.value
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("button", { staticClass: "search-button" }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("itens-per-page", {
                    attrs: {
                      "request-pagination": _vm.requestPagination,
                      callback: _vm.makeTextSearch,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", {
                staticClass: "teros_principal__content__table__header__actions",
              }),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "teros_principal__content__table__body" }, [
            _c(
              "table",
              {
                staticClass: "teros_principal__content__table__body__table",
                attrs: {
                  id: "table",
                  "data-toggle": "table",
                  "data-height": "600",
                  "data-url": "",
                },
              },
              [
                _c("thead", [
                  _c("tr", [
                    _c(
                      "th",
                      {
                        attrs: { "data-field": "id", "data-sortable": "true" },
                      },
                      [_vm._v("ID")]
                    ),
                    _vm._v(" "),
                    _c("th", { attrs: { "data-field": "a" } }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.$t(
                              "views.teros.authorizations.list.table.header.client"
                            )
                          ) +
                          "\n              "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("th", { attrs: { "data-field": "b" } }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.$t(
                              "views.teros.authorizations.list.table.header.reception"
                            )
                          ) +
                          "\n              "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("th", { attrs: { "data-field": "c" } }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.$t(
                              "views.teros.authorizations.list.table.header.service"
                            )
                          ) +
                          "\n              "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("th", { attrs: { "data-field": "d" } }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.$t(
                              "views.teros.authorizations.list.table.header.authDate"
                            )
                          ) +
                          "\n              "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("th", { attrs: { "data-field": "e" } }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.$t(
                              "views.teros.authorizations.list.table.header.changeDate"
                            )
                          ) +
                          "\n              "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("th", { attrs: { "data-field": "g" } }, [
                      _vm._v("Status"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticStyle: { "text-align": "center" },
                        attrs: { "data-field": "h" },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.$t("views.shared.table.header.options")
                            ) +
                            "\n              "
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.authorizations, function (item, index) {
                    return _c("tr", { key: item.hash }, [
                      _c("td", [_vm._v(_vm._s(index + 1))]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(item.customerName))]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(item.integrationName))]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(item.serviceName))]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(_vm._s(_vm._f("formatDate")(item.responseDate))),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm._f("formatDate")(item.lastUpdateDate))
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { class: _vm._f("formatClass")(item.status) }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.statusTranslated(item.status)) +
                            "\n              "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            "text-align": "center",
                            width: "100px",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "teros_principal__content__table__body__table__options",
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "teros_principal__content__table__body__table__options__item",
                                  attrs: {
                                    to: "/authorization?hash=" + item.hash,
                                    "data-toggle": "tooltip",
                                    "data-placement": "top",
                                    title: "Visualizar/Editar",
                                  },
                                },
                                [
                                  _c("div", [
                                    _c("img", {
                                      attrs: {
                                        src: "/static/img/icons/editar.svg",
                                        alt: "",
                                      },
                                    }),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ])
                  }),
                  0
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "teros_principal__content__table__footer" },
            [
              _vm.loading
                ? _c(
                    "div",
                    {
                      staticClass:
                        "teros_principal__content__table__footer__loader",
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: "/static/img/icons/ajax-loader.gif",
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("views.shared.loadingResults"))),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("pagination", {
                attrs: {
                  "request-pagination": _vm.requestPagination,
                  callback: _vm.makeTextSearch,
                  "current-pagination-info": _vm.currentPaginationInfo,
                },
              }),
            ],
            1
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }