var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("label", { attrs: { for: _vm.id } }, [_vm._v(_vm._s(_vm.title))]),
    _vm._v(" "),
    _c("div", [
      _vm.multiple
        ? _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.hashValues,
                  expression: "hashValues",
                },
              ],
              staticClass: "selectpicker",
              attrs: {
                multiple: "",
                "data-live-search": "true",
                title: "",
                id: _vm.id,
              },
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.hashValues = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
              },
            },
            _vm._l(_vm.options, function (item) {
              return _c(
                "option",
                {
                  key: item.hash,
                  attrs: { disabled: _vm.disabled },
                  domProps: { value: item.hash },
                },
                [_vm._v("\n        " + _vm._s(item.name) + "\n      ")]
              )
            }),
            0
          )
        : _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.hashValue,
                  expression: "hashValue",
                },
              ],
              staticClass: "selectpicker",
              attrs: { "data-live-search": "true", title: "", id: _vm.id },
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.hashValue = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
              },
            },
            [
              _c("option", {
                attrs: { selected: "", disabled: "", value: "" },
              }),
              _vm._v(" "),
              _vm._l(_vm.options, function (item) {
                return _c(
                  "option",
                  {
                    key: item.hash,
                    attrs: { disabled: _vm.disabled },
                    domProps: { value: item.hash },
                  },
                  [_vm._v("\n        " + _vm._s(item.name) + "\n      ")]
                )
              }),
            ],
            2
          ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }