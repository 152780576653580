var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "teros_principal__content interna container" },
    [
      _c("div", { staticClass: "teros_principal__content__line-1" }, [
        _c(
          "div",
          { staticClass: "teros_principal__content__line-1__highlights" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__line-1__highlights__subject",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__line-1__highlights__subject__breadcrumb",
                  },
                  [
                    _c("router-link", { attrs: { to: "/receptions" } }, [
                      _c(
                        "h3",
                        {
                          staticClass:
                            "teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t("views.client.reception.list.client")
                              ) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.newObj
                        ? _c(
                            "h2",
                            {
                              staticClass:
                                "teros_principal__content__line-1__highlights__subject__breadcrumb__title",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.currentUser.customerName) +
                                  "\n            "
                              ),
                            ]
                          )
                        : _c(
                            "h2",
                            {
                              staticClass:
                                "teros_principal__content__line-1__highlights__subject__breadcrumb__title",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.reception.customerName) +
                                  "\n            "
                              ),
                            ]
                          ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "teros_principal__arrow" }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__line-1__highlights__subject__breadcrumb",
                  },
                  [
                    _c(
                      "h3",
                      {
                        staticClass:
                          "teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t("views.client.reception.reception.title")
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.newObj
                      ? _c(
                          "h2",
                          {
                            staticClass:
                              "teros_principal__content__line-1__highlights__subject__breadcrumb__title active",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "views.client.reception.reception.newObject"
                                  )
                                ) +
                                "\n          "
                            ),
                          ]
                        )
                      : _c(
                          "h2",
                          {
                            staticClass:
                              "teros_principal__content__line-1__highlights__subject__breadcrumb__title active",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.reception.integrationName) +
                                "\n          "
                            ),
                          ]
                        ),
                  ]
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "teros_principal__content__line-1__information" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__line-1__information__time",
              },
              [
                !_vm.newObj
                  ? _c(
                      "p",
                      {
                        staticClass:
                          "teros_principal__content__line-1__information__time__text",
                      },
                      [
                        _c("img", {
                          staticClass:
                            "teros_principal__content__line-1__information__time__text__icon",
                          attrs: {
                            src: "/static/img/icons/relogio.png",
                            alt: "",
                          },
                        }),
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t("views.shared.createdDate", {
                                date: _vm.creationDate,
                                time: _vm.creationTime,
                              })
                            ) +
                            "\n          " +
                            _vm._s(
                              _vm.lastUpdateDate
                                ? "| " +
                                    _vm.$t("views.shared.changedDate", {
                                      date: _vm.lastUpdateDate,
                                      time: _vm.lastUpdateTime,
                                    })
                                : ""
                            ) +
                            "\n        "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "teros_principal__content__line-1__information__time__btn",
                    attrs: { type: "button" },
                    on: { click: _vm.goBack },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "/static/img/icons/seta-esquerda.png",
                        alt: "",
                      },
                    }),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("views.shared.backButton")) +
                        "\n        "
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "teros_principal__content__column" }, [
        _c(
          "div",
          { staticClass: "teros_principal__content__column__menu-vertical" },
          [
            _c(
              "ul",
              {
                staticClass:
                  "teros_principal__content__column__menu-vertical__list",
              },
              [
                _c(
                  "li",
                  {
                    staticClass:
                      "teros_principal__content__column__menu-vertical__list__item",
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass:
                          "teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--lupa active",
                        attrs: { href: "#" },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("views.client.reception.reception.tabTitle")
                          )
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass:
                      "teros_principal__content__column__menu-vertical__list__item",
                  },
                  [
                    _vm.newObj
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--servicos",
                            attrs: { href: "#" },
                            on: { click: _vm.showAlert },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "views.client.reception.receptionservices.tabTitle"
                                )
                              )
                            ),
                          ]
                        )
                      : _c(
                          "router-link",
                          {
                            staticClass:
                              "teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--servicos",
                            attrs: {
                              to:
                                "/receptionservices?hash=" + _vm.reception.hash,
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "views.client.reception.receptionservices.tabTitle"
                                )
                              )
                            ),
                          ]
                        ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass:
                      "teros_principal__content__column__menu-vertical__list__item",
                  },
                  [
                    _vm.newObj
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--compliance",
                            attrs: { href: "#" },
                            on: { click: _vm.showAlert },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "views.client.reception.receptionconsentiments.tabTitle"
                                )
                              )
                            ),
                          ]
                        )
                      : _c(
                          "router-link",
                          {
                            staticClass:
                              "teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--compliance",
                            attrs: {
                              to:
                                "/receptionconsentiments?hash=" +
                                _vm.reception.hash,
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "views.client.reception.receptionconsentiments.tabTitle"
                                )
                              )
                            ),
                          ]
                        ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass:
                      "teros_principal__content__column__menu-vertical__list__item",
                  },
                  [
                    _vm.newObj
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--registros",
                            attrs: { href: "#" },
                            on: { click: _vm.showAlert },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "views.client.reception.registries.tabTitle"
                                )
                              )
                            ),
                          ]
                        )
                      : _c(
                          "router-link",
                          {
                            staticClass:
                              "teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--registros",
                            attrs: {
                              to:
                                "/receptionregistries?hash=" +
                                _vm.reception.hash,
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "views.client.reception.registries.tabTitle"
                                )
                              )
                            ),
                          ]
                        ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass:
                      "teros_principal__content__column__menu-vertical__list__item",
                  },
                  [
                    _vm.newObj
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--gestao",
                            attrs: { href: "#" },
                            on: { click: _vm.showAlert },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "views.client.reception.receptionlogs.tabTitle"
                                )
                              )
                            ),
                          ]
                        )
                      : _c(
                          "router-link",
                          {
                            staticClass:
                              "teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--gestao",
                            attrs: {
                              to: "/receptionlogs?hash=" + _vm.reception.hash,
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "views.client.reception.receptionlogs.tabTitle"
                                )
                              )
                            ),
                          ]
                        ),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "teros_principal__content__column__info" }, [
          _c("div", { attrs: { id: "accordion" } }, [
            _c(
              "form",
              {
                attrs: { enctype: "multipart/form-data", method: "post" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.createOrUpdate.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__accordion__card mx0",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "teros_principal__content__accordion__card__header",
                        attrs: { id: "headingOne" },
                      },
                      [
                        _c(
                          "h2",
                          {
                            staticClass:
                              "teros_principal__content__accordion__card__header__title",
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "teros_principal__content__accordion__card__header__title__btn",
                                attrs: {
                                  type: "button",
                                  "data-toggle": "collapse",
                                  "data-target": "#collapseOne",
                                  "aria-expanded": "true",
                                  "aria-controls": "collapseOne",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t(
                                        "views.client.reception.reception.content.editTab.title"
                                      )
                                    ) +
                                    "\n                "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "teros_principal__content__accordion__card__header__collapse collapse show",
                        attrs: { id: "collapseOne" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "teros_principal__content__accordion__card__header__collapse__body",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "teros_principal__content__accordion__card__header__collapse__body__form",
                              },
                              [
                                _c("div", { staticClass: "form-group" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "custom-control custom-switch",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.reception.active,
                                            expression: "reception.active",
                                          },
                                        ],
                                        staticClass: "custom-control-input",
                                        attrs: {
                                          type: "checkbox",
                                          checked: "",
                                          id: "customSwitch1",
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.reception.active
                                          )
                                            ? _vm._i(
                                                _vm.reception.active,
                                                null
                                              ) > -1
                                            : _vm.reception.active,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.reception.active,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.reception,
                                                    "active",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.reception,
                                                    "active",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.reception,
                                                "active",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        {
                                          staticClass: "custom-control-label",
                                          attrs: { for: "customSwitch1" },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "views.client.reception.reception.content.editTab.activate"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "form-group" },
                                  [
                                    !_vm.newObj
                                      ? _c(
                                          "label",
                                          {
                                            attrs: {
                                              for: "formGroupExampleInput1",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "views.client.reception.reception.content.editTab.integration"
                                                )
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.newObj
                                      ? _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.reception.integrationName,
                                              expression:
                                                "reception.integrationName",
                                            },
                                          ],
                                          staticClass:
                                            "form-control teros_principal__content__column__info__form__input",
                                          attrs: {
                                            type: "text",
                                            id: "formGroupExampleInput1",
                                            readonly: "",
                                          },
                                          domProps: {
                                            value:
                                              _vm.reception.integrationName,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.reception,
                                                "integrationName",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.newObj
                                      ? _c("live-select", {
                                          attrs: {
                                            title:
                                              _vm.$t(
                                                "views.client.reception.reception.content.editTab.integration"
                                              ) + "*",
                                            options: _vm.integrations,
                                            hash: _vm.reception.integrationHash,
                                          },
                                          on: {
                                            onChange: _vm.onChangeIntegration,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "form-group row" }, [
                                  _c("div", { staticClass: "col" }, [
                                    _c(
                                      "label",
                                      {
                                        attrs: {
                                          for: "formGroupExampleInput3",
                                        },
                                      },
                                      [_vm._v("Webhook Url")]
                                    ),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.reception.webhookUrl,
                                          expression: "reception.webhookUrl",
                                        },
                                      ],
                                      staticClass:
                                        "form-control teros_principal__content__column__info__form__input",
                                      attrs: {
                                        type: "text",
                                        id: "formGroupExampleInput3",
                                      },
                                      domProps: {
                                        value: _vm.reception.webhookUrl,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.reception,
                                            "webhookUrl",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  !_vm.newObj
                                    ? _c("div", { staticClass: "col" }, [
                                        _c(
                                          "label",
                                          {
                                            attrs: {
                                              for: "formGroupExampleInput4",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "ReceptionHash\n                        "
                                            ),
                                            _c("img", {
                                              attrs: {
                                                src: "/static/img/icons/tooltip.png",
                                                "data-toggle": "tooltip",
                                                "data-placement": "right",
                                                title: _vm.$t(
                                                  "views.client.reception.toolTip.ReceptionHash"
                                                ),
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.reception.hash,
                                              expression: "reception.hash",
                                            },
                                          ],
                                          staticClass:
                                            "form-control teros_principal__content__column__info__form__input",
                                          attrs: {
                                            type: "text",
                                            id: "formGroupExampleInput4",
                                            readonly: "",
                                          },
                                          domProps: {
                                            value: _vm.reception.hash,
                                          },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                _vm.reception,
                                                "hash",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ])
                                    : _vm._e(),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__accordion__card mx0",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "teros_principal__content__accordion__card__header",
                        attrs: { id: "headingOne" },
                      },
                      [
                        _c(
                          "h2",
                          {
                            staticClass:
                              "teros_principal__content__accordion__card__header__title",
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "teros_principal__content__accordion__card__header__title__btn",
                                attrs: {
                                  type: "button",
                                  "data-toggle": "collapse",
                                  "data-target": "#collapseTwo",
                                  "aria-expanded": "true",
                                  "aria-controls": "collapseTwo",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t(
                                        "views.client.reception.reception.content.responsibilitiesTab.title"
                                      )
                                    ) +
                                    "\n                "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "teros_principal__content__accordion__card__header__collapse collapse show",
                        attrs: { id: "collapseTwo" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "teros_principal__content__accordion__card__header__collapse__body",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "teros_principal__content__accordion__card__header__collapse__body__form",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "form-group" },
                                  [
                                    _c("label", { attrs: { for: "" } }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "views.client.reception.reception.content.responsibilitiesTab.contract"
                                          )
                                        )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("file-upload", {
                                      attrs: {
                                        "file-name":
                                          _vm.reception.contractFileName,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "form-group teros_principal__content__column__info__form__combo",
                                  },
                                  [
                                    !_vm.newObj
                                      ? _c("pdf-view", {
                                          attrs: {
                                            "file-name":
                                              _vm.reception.contractFileName,
                                            type: "button",
                                            title: _vm.$t(
                                              "views.client.reception.reception.content.responsibilitiesTab.seeContractButton"
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("authorization-form", {
                  attrs: {
                    apiAuthentication: _vm.reception.apiAuthentication,
                    showDcrType: _vm.showDcrType,
                    customHeaders: _vm.customHeaders,
                  },
                  on: {
                    onChangeFlow: _vm.onChangeFlow,
                    onChangeDcr: _vm.onChangeDcr,
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "form-group teros_principal__content__column__info__form__combo",
                    staticStyle: {
                      "margin-top": "0",
                      "margin-bottom": "40px",
                      "text-align": "right",
                    },
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "teros_principal__content__column__info__form__combo__salvar",
                        attrs: { title: "Salvar" },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: "/static/img/icons/disquete.png",
                            alt: "Salvar",
                          },
                        }),
                        _vm._v(
                          _vm._s(_vm.$t("views.shared.saveButton")) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }