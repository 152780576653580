var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group" }, [
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.pageSize,
            expression: "pageSize",
          },
        ],
        staticClass: "form-control",
        on: {
          change: [
            function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.pageSize = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
            function ($event) {
              return _vm.changeTake()
            },
          ],
        },
      },
      _vm._l(_vm.pageSizeValues, function (item) {
        return _c("option", { key: item, domProps: { value: item } }, [
          _vm._v("\n      " + _vm._s(item) + "\n    "),
        ])
      }),
      0
    ),
    _vm._v(" "),
    _c("span", { staticClass: "select-result" }, [
      _vm._v(_vm._s(_vm.$t("views.shared.searchBar.resultsLabel"))),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }