<template>
  <div class="teros_principal__content interna container">
    <div class="teros_principal__content__line-1 line">
      <div class="teros_principal__content__line-1__highlights">
        <div class="teros_principal__content__line-1__highlights__subject">
          <router-link :to="'/customer?hash=' + customer.hash">
            <div
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb
              "
            >
              <h3
                class="
                  teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle
                "
              >
                {{ $t("views.teros.customers.customer.title") }}
              </h3>
              <h2
                class="
                  teros_principal__content__line-1__highlights__subject__breadcrumb__title
                "
              >
                {{ customer.name }}
              </h2>
            </div>
          </router-link>
          <div class="teros_principal__arrow"></div>
          <div
            class="
              teros_principal__content__line-1__highlights__subject__breadcrumb
            "
          >
            <h3
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle
              "
            >
              {{ $t("views.teros.customers.user.title") }}
            </h3>
            <h2
              v-if="newObj"
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__title
                active
              "
            >
              {{ $t("views.teros.customers.user.newObject") }}
            </h2>
            <h2
              v-else
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__title
                active
              "
            >
              {{ user.username }}
            </h2>
          </div>
        </div>
      </div>

      <div class="teros_principal__content__line-1__information">
        <div class="teros_principal__content__line-1__information__time">
          <p
            v-if="!newObj"
            class="teros_principal__content__line-1__information__time__text"
          >
            <img
              class="
                teros_principal__content__line-1__information__time__text__icon
              "
              src="/static/img/icons/relogio.png"
              alt=""
            />
            {{
              $t("views.shared.createdDate", {
                date: creationDate,
                time: creationTime,
              })
            }}
            {{
              lastUpdateDate
                ? "| " +
                  $t("views.shared.changedDate", {
                    date: lastUpdateDate,
                    time: lastUpdateTime,
                  })
                : ""
            }}
          </p>

          <button
            type="button"
            @click="goBack"
            class="teros_principal__content__line-1__information__time__btn"
          >
            <img src="/static/img/icons/seta-esquerda.png" alt="" />
            {{ $t("views.shared.backButton") }}
          </button>
        </div>
      </div>
    </div>

    <div id="accordion">
      <div class="teros_principal__content__accordion__card">
        <div
          class="teros_principal__content__accordion__card__header"
          id="headingOne"
        >
          <h2 class="teros_principal__content__accordion__card__header__title">
            <button
              class="
                teros_principal__content__accordion__card__header__title__btn
              "
              type="button"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              {{ $t("views.teros.customers.user.content.title") }}
            </button>
          </h2>
        </div>

        <div
          id="collapseOne"
          class="teros_principal__content__accordion__card__header__collapse"
        >
          <div
            class="
              teros_principal__content__accordion__card__header__collapse__body
            "
          >
            <form
              @submit.prevent="createOrUpdate"
              class="
                teros_principal__content__accordion__card__header__collapse__body__form
              "
              method="post"
            >
              <div class="form-group row">
                <div class="col">
                  <label for="formGroupExampleInput1"
                    >{{ $t("views.teros.customers.user.content.userName") }}*
                    <img
                      src="/static/img/icons/tooltip.png"
                      data-toggle="tooltip"
                      data-placement="right"
                      :title="
                        $t('views.teros.customers.user.toolTips.username')
                      "
                  /></label>
                  <custom-input
                    v-if="newObj"
                    :id="'formGroupExampleInput1'"
                    :regex="'[-a-zA-Z0-9\.\?\$@&!%_]'"
                    v-model="user.username"
                  ></custom-input>
                  <custom-input
                    v-else
                    :readonly="true"
                    :id="'formGroupExampleInput1'"
                    :regex="'[-a-zA-Z0-9\.\?\$@&!%_]'"
                    v-model="user.username"
                  ></custom-input>
                </div>

                <div class="col">
                  <label for="formGroupExampleInput2"
                    >{{ $t("views.teros.customers.user.content.fullName") }}*
                    <img
                      src="/static/img/icons/tooltip.png"
                      data-toggle="tooltip"
                      data-placement="right"
                      :title="
                        $t('views.teros.customers.user.toolTips.fullname')
                      "
                  /></label>
                  <custom-input
                    :id="'formGroupExampleInput2'"
                    v-model="user.name"
                  ></custom-input>
                </div>
              </div>

              <div class="form-group row">
                <div class="col">
                  <live-select
                    :title="
                      $t('views.teros.customers.user.content.accessGroup') + '*'
                    "
                    multiple
                    :options="cognitoGroups"
                    :hashs="user.groups"
                    @onChange="user.groups = $event"
                  ></live-select>
                </div>

                <div class="col">
                  <label for="formGroupExampleInput4"
                    >{{ $t("views.teros.customers.user.content.mail") }}*</label
                  >
                  <custom-input
                    v-if="newObj"
                    :id="'formGroupExampleInput4'"
                    :regex="'.*'"
                    v-model="user.email"
                  ></custom-input>
                  <custom-input
                    v-else
                    :readonly="true"
                    :id="'formGroupExampleInput4'"
                    :regex="'.*'"
                    v-model="user.email"
                  ></custom-input>
                </div>
              </div>

              <div class="form-group row">
                <div class="col">
                  <label for="formGroupExampleInput5"
                    >{{ $t("views.teros.customers.user.content.contactPhone") }}
                  </label>
                  <input
                    type="text"
                    class="form-control field-copy"
                    id="formGroupExampleInput5"
                    v-model="user.phoneNumber"
                    v-mask="'+#############'"
                  />
                </div>
              </div>

              <div class="form-group" style="text-align: right; display: block">
                <button
                  class="
                    teros_principal__content__column__info__form__combo__salvar
                  "
                >
                  <img src="/static/img/icons/disquete.png" alt="Salvar" />{{
                    $t("views.shared.saveButton")
                  }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http, router } from "@/_helpers";
import LiveSelect from "@/_components/LiveSelect.vue";
import CustomInput from "@/_components/CustomInput.vue";
import moment from 'moment';

export default {
  data() {
    return {
      newObj: true,
      customer: {},
      user: { isActive: true, isAdmin: false },
      cognitoGroups: [],
    };
  },
  components: {
    LiveSelect,
    CustomInput,
  },
  computed: {
    creationDate() {
      if (this.user.creationDate) {
        return moment(String(this.user.creationDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
    creationTime() {
      if (this.user.creationDate) {
        return moment(String(this.user.creationDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("HH:mm");
      }
      return "";
    },
    lastUpdateDate() {
      if (this.user.lastUpdateDate) {
        return moment(String(this.user.lastUpdateDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
    lastUpdateTime() {
      if (this.user.lastUpdateDate) {
        return moment(String(this.user.lastUpdateDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("HH:mm");
      }
      return "";
    },
  },
  mounted() {
    this.$emit("message", this.$route);
    const hash = this.$route.query.hash;
    const customerHash = this.$route.query.customerHash;
    http.get("/api/user/list/groups?startsWith=Customer").then((response) => {
      this.cognitoGroups = response.data;
    });
    if (hash) {
      this.newObj = false;
      http.get("/api/user/get/" + hash).then((response) => {
        this.user = response.data;
        http
          .get("/api/customer/get/" + this.user.customerHash)
          .then((response) => {
            this.customer = response.data;
          });
      });
    } else {
      http.get("/api/customer/get/" + customerHash).then((response) => {
        this.customer = response.data;
        this.user.customerHash = customerHash;
      });
    }
  },
  methods: {
    goBack() {
      router.push({
        name: "userspage",
        query: { hash: this.customer.hash },
      });
    },
    createOrUpdate() {
      if (this.newObj) {
        http.post("/api/user/create/", this.user).then(() => {
          this.goBack();
          this.$emit("success");
        });
      } else {
        http.put("/api/user/update/", this.user).then(() => {
          this.goBack();
          this.$emit("success");
        });
      }
    },
  },
};
</script>