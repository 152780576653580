<template>
  <div class="teros_principal__content container">
    <form @submit.prevent="makeTextSearch">
      <div class="teros_principal__content__table">
        <div class="teros_principal__content__table__header">
          <div class="teros_principal__content__table__header__filters">
            <div class="form-group">
              <div class="search-group">
                <input
                  type="text"
                  class="input-field"
                  :placeholder="$t('views.shared.searchBar.searchLabel')"
                  v-model="textSearchValue"
                />
                <button class="search-button" />
              </div>
            </div>
            <itens-per-page
              :request-pagination="requestPagination"
              :callback="makeTextSearch"
            ></itens-per-page>
          </div>

          <div class="teros_principal__content__table__header__actions">
            <!--<a href="#" title="Download"
              ><img src="/static/img/icons/download.png" alt="Download"
            /></a>
            <a href="#" title="Imprimir"
              ><img src="/static/img/icons/printer.png" alt="Imprimir"
            /></a> -->
          </div>
        </div>

        <div class="teros_principal__content__table__body">
          <table
            id="table"
            class="teros_principal__content__table__body__table"
            data-toggle="table"
            data-height="600"
            data-url=""
          >
            <thead>
              <tr>
                <th data-field="id" data-sortable="true">ID</th>
                <th data-field="a">
                  {{ $t("views.teros.customers.list.table.header.name") }}
                </th>
                <th data-field="c">
                  {{ $t("views.teros.customers.list.table.header.categories") }}
                </th>
                <th data-field="e">
                  {{ $t("views.shared.table.header.createdAt") }}
                </th>
                <th data-field="f">
                  {{ $t("views.shared.table.header.changedAt") }}
                </th>
                <!--<th data-field="g">Contrato</th>-->
                <th data-field="h" style="text-align: center">
                  {{ $t("views.shared.table.header.options") }}
                </th>
                <th data-field="i" style="text-align: center">
                  {{
                    $t(
                      "views.shared.table.header.deactivateActivate"
                    )
                  }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in customers" :key="item.hash">
                <td>{{ index + 1 }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.categoryList | formatCategories }}</td>
                <td>{{ item.creationDate | formatDate }}</td>
                <td>{{ item.lastUpdateDate | formatDate }}</td>
                <!--<td>
                  <pdf-view
                    :file-name="item.contractFileName"
                    :type="'link'"
                  ></pdf-view>
                </td>-->

                <td style="text-align: center; width: 100px">
                  <div
                    class="teros_principal__content__table__body__table__options"
                  >
                    <router-link
                      :to="'/customer?hash=' + item.hash + '#form'"
                      class="teros_principal__content__table__body__table__options__item"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Visualizar/Editar"
                    >
                      <div>
                        <img src="/static/img/icons/editar.svg" alt="" />
                      </div>
                    </router-link>
                    <a
                      @click="prepareRemove(item.hash, index)"
                      class="teros_principal__content__table__body__table__options__item"
                      data-toggle="modal"
                      data-target="#deleteModal"
                      data-placement="top"
                      title="Deletar"
                    >
                      <div>
                        <img src="/static/img/icons/deletar.svg" alt="" />
                      </div>
                    </a>
                  </div>
                </td>
                <td style="text-align: center">
                  <div
                    class="custom-control custom-switch"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Desativar/Ativar"
                  >
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      :id="'customSwitch' + index"
                      v-model="item.active"
                      @change="changeStatus(item.hash)"
                    />
                    <label
                      class="custom-control-label"
                      :for="'customSwitch' + index"
                    ></label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="teros_principal__content__table__footer">
          <div
            v-if="loading"
            class="teros_principal__content__table__footer__loader"
          >
            <img src="/static/img/icons/ajax-loader.gif" alt="" />
            <span>{{ $t("views.shared.loadingResults") }}</span>
          </div>
          <pagination
            :request-pagination="requestPagination"
            :callback="makeTextSearch"
            :current-pagination-info="currentPaginationInfo"
          ></pagination>
          <router-link
            to="/customer"
            title="Novo Cliente"
            class="teros_principal__content__table__footer__add-item"
          >
            <img src="/static/img/icons/plus.png" alt="" />{{
                $t("views.shared.includeButton")
              }}
          </router-link>
        </div>
      </div>
    </form>
    <div
      class="modal fade teros-modal"
      id="deleteModal"
      tabindex="-1"
      aria-labelledby="deleteModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteModalLabel">
              <img src="/static/img/icons/atencao-modal-red.png" alt="" />
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            {{ $t("views.shared.modal.delete.confirmMessage") }}
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              {{ $t("views.shared.modal.delete.cancelButton") }}
            </button>
            <button
              type="button"
              class="btn btn-primary btn-primary--red"
              data-dismiss="modal"
              @click="remove"
            >
              {{ $t("views.shared.modal.delete.deleteButton") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { http } from "@/_helpers";
import PdfView from "@/_components/PdfView.vue";
import Pagination from "@/_components/Pagination.vue";
import ItensPerPage from "@/_components/ItensPerPage.vue";
import moment from 'moment';

export default {
  data() {
    return {
      customers: [],
      customerHash: null,
      index: 0,
      loading: true,
      textSearchValue: "",
      oldTextSearchValue: "",
      currentPaginationInfo: null,
      requestPagination: { page: 1, take: 10 },
    };
  },
  components: {
    PdfView,
    Pagination,
    ItensPerPage,
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
    formatCategories(list) {
      if (list) {
        return list.join(", ");
      }
      return "";
    },
  },
  mounted() {
    this.$emit("message", this.$route);
    http
      .get("/api/customer/search", false)
      .then((response) => {
        this.customers = response.data.items;
        this.currentPaginationInfo = response.data;
      })
      .finally(() => (this.loading = false));
  },
  methods: {
    changeStatus(hash) {
      http.get("/api/customer/get/" + hash).then((response) => {
        let customerEdit = response.data;
        customerEdit.active = !customerEdit.active;
        http.put("/api/customer/update/", customerEdit).then(() => {
          if (customerEdit.active) {
            Vue.$toast.success(
              this.$i18n.t("views.teros.customers.list.modal.activatedMessage")
            );
          } else {
            Vue.$toast.success(
              this.$i18n.t(
                "views.teros.customers.list.modal.deactivatedMessage"
              )
            );
          }
        });
      });
    },
    prepareRemove(hash, index) {
      this.customerHash = hash;
      this.index = index;
    },
    remove() {
      http.remove("/api/customer/delete/" + this.customerHash).then(() => {
        Vue.$toast.success(
          this.$i18n.t("views.teros.customers.list.modal.deletedMessage")
        );
        Vue.delete(this.customers, this.index);
      });
    },
    makeTextSearch(event, isChangePage = false) {
      this.CheckValuesChanged(isChangePage);

      if (this.textSearchValue.trim()) {
        http
          .get(
            `/api/customer/search?FilterProperty=Name&FilterValue=${this.textSearchValue.trim()}&Page=${
              this.requestPagination.page
            }&Take=${this.requestPagination.take}`
          )
          .then((response) => {
            this.customers = response.data.items;
            this.currentPaginationInfo = response.data;
          })
          .finally(() => (this.loading = false));
      } else {
        http
          .get(
            `/api/customer/search?Page=${this.requestPagination.page}&Take=${this.requestPagination.take}`
          )
          .then((response) => {
            this.customers = response.data.items;
            this.currentPaginationInfo = response.data;
          })
          .finally(() => (this.loading = false));
      }
    },
    CheckValuesChanged(isChangePage = false) {
      if (this.textSearchValue != this.oldTextSearchValue) {
        if (isChangePage) {
          this.textSearchValue = this.oldTextSearchValue;
        } else {
          this.requestPagination.page = 1;
          this.oldTextSearchValue = this.textSearchValue;
        }
      }
    },
  },
};
</script>