<template>
  <div>
    <div v-if="!currentUser" class="teros_principal__header__logo">
      <img src="/static/img/logo_teros.png" alt="" />
    </div>
    <hr />
    <div id="swagger"></div>
  </div>
</template>

<script>
import { cognito } from "@/_helpers";

export default {
  data() {
    return {
      currentUser: cognito.currentUserValue,
    };
  },
  mounted() {
    this.$emit("message", this.$route);
    SwaggerUIBundle({
      url: `/static/swagger/spec-${ENV}.yaml`,
      dom_id: "#swagger",
      presets: [SwaggerUIBundle.presets.apis, SwaggerUIStandalonePreset],
    });
  },
};
</script>