var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "teros_principal__content interna container" },
    [
      _c("div", { staticClass: "teros_principal__content__line-1" }, [
        _c(
          "div",
          { staticClass: "teros_principal__content__line-1__highlights" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__line-1__highlights__subject",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__line-1__highlights__subject__breadcrumb",
                  },
                  [
                    _c(
                      "h3",
                      {
                        staticClass:
                          "teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t("views.client.access.general.client")
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "h2",
                      {
                        staticClass:
                          "teros_principal__content__line-1__highlights__subject__breadcrumb__title active",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.currentUser.customerName) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "teros_principal__content__column" }, [
        _c(
          "div",
          { staticClass: "teros_principal__content__column__menu-vertical" },
          [
            _c(
              "ul",
              {
                staticClass:
                  "teros_principal__content__column__menu-vertical__list",
              },
              [
                _c(
                  "li",
                  {
                    staticClass:
                      "teros_principal__content__column__menu-vertical__list__item",
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass:
                          "teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--lupa active",
                        attrs: { href: "#" },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("views.client.access.general.tabTitle"))
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "teros_principal__content__column__info" }, [
          _c("div", { staticClass: "form-group row" }, [
            _c("div", { staticClass: "col" }, [
              _c("label", { attrs: { for: "formGroupExampleInput1" } }, [
                _vm._v(
                  _vm._s(
                    _vm.$t("views.client.access.general.content.standardKey")
                  ) + "\n            "
                ),
                _c("img", {
                  attrs: {
                    src: "/static/img/icons/tooltip.png",
                    "data-toggle": "tooltip",
                    "data-placement": "right",
                    title: _vm.$t(
                      "views.client.access.general.toolTips.gatewayClientId"
                    ),
                  },
                }),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.customer.gatewayClientId,
                    expression: "customer.gatewayClientId",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  id: "formGroupExampleInput1",
                  readonly: "",
                },
                domProps: { value: _vm.customer.gatewayClientId },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.customer,
                      "gatewayClientId",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [
              _c("label", { attrs: { for: "formGroupExampleInput2" } }, [
                _vm._v("Secret Key "),
                _c("img", {
                  attrs: {
                    src: "/static/img/icons/tooltip.png",
                    "data-toggle": "tooltip",
                    "data-placement": "right",
                    title: _vm.$t(
                      "views.client.access.general.toolTips.gatewaySecret"
                    ),
                  },
                }),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.customer.gatewaySecret,
                    expression: "customer.gatewaySecret",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  id: "formGroupExampleInput2",
                  readonly: "",
                },
                domProps: { value: _vm.customer.gatewaySecret },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.customer, "gatewaySecret", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "formGroupExampleInput3" } }, [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "views.client.access.general.content.basic64Credentials"
                  )
                ) + "\n          "
              ),
              _c("img", {
                attrs: {
                  src: "/static/img/icons/tooltip.png",
                  "data-toggle": "tooltip",
                  "data-placement": "right",
                  title: _vm.$t(
                    "views.client.access.general.toolTips.basic64Credentials"
                  ),
                },
              }),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.credentialsBase64,
                  expression: "credentialsBase64",
                },
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                id: "formGroupExampleInput3",
                readonly: "",
              },
              domProps: { value: _vm.credentialsBase64 },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.credentialsBase64 = $event.target.value
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "form-group row",
              staticStyle: { "margin-top": "30px" },
            },
            [
              _c("div", { staticClass: "col" }, [
                _vm.customer.hasClientCertificate
                  ? _c("label", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "views.client.access.general.content.hasCertificate"
                          )
                        ) + " "
                      ),
                    ])
                  : _c("label", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "views.client.access.general.content.noCertificate"
                          )
                        ) + " "
                      ),
                    ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass:
                      "teros_principal__content__column__info__form__combo__btn-contrato",
                    staticStyle: { width: "300px", "margin-top": "8px" },
                    attrs: { href: "javascript:void(null)" },
                    on: { click: _vm.showOrHideCertificateInput },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "views.client.access.general.content.submitCertificate"
                        )
                      ) + "\n          "
                    ),
                  ]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm.showCertFields
            ? _c("div", { staticClass: "form-group" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("label", { attrs: { for: "cert" } }, [
                      _vm._v("Certificate.pem"),
                    ]),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.customer.clientCertificate.certificate,
                          expression: "customer.clientCertificate.certificate",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { id: "cert", cols: "30", rows: "10" },
                      domProps: {
                        value: _vm.customer.clientCertificate.certificate,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.customer.clientCertificate,
                            "certificate",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col" }, [
                    _c("label", { attrs: { for: "cert" } }, [
                      _vm._v("PrivateKey.pem"),
                    ]),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.customer.clientCertificate.privateKey,
                          expression: "customer.clientCertificate.privateKey",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { id: "cert", cols: "30", rows: "10" },
                      domProps: {
                        value: _vm.customer.clientCertificate.privateKey,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.customer.clientCertificate,
                            "privateKey",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "form-group teros_principal__content__column__info__form__combo",
                    staticStyle: {
                      "margin-top": "0px",
                      "margin-bottom": "40px",
                      "text-align": "right",
                    },
                  },
                  [
                    _c("div", [
                      _c(
                        "button",
                        {
                          staticClass:
                            "teros_principal__content__column__info__form__table__plus mb0",
                          on: { click: _vm.submitCert },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "/static/img/icons/disquete.png",
                              alt: "Salvar",
                            },
                          }),
                          _vm._v(
                            _vm._s(_vm.$t("views.shared.saveButton")) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "form-group row",
              staticStyle: { "margin-top": "30px" },
            },
            [
              _c("div", { staticClass: "col" }, [
                _vm.customer.hasJsonKeySet
                  ? _c("label", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "views.client.access.general.content.hasJsonWebKeySet"
                          )
                        ) + " "
                      ),
                    ])
                  : _c("label", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "views.client.access.general.content.noJsonWebKeySet"
                          )
                        ) + " "
                      ),
                    ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass:
                      "teros_principal__content__column__info__form__combo__btn-contrato",
                    staticStyle: { width: "300px", "margin-top": "8px" },
                    attrs: { href: "javascript:void(null)" },
                    on: { click: _vm.showOrHideJwksInput },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "views.client.access.general.content.submitJsonWebKeySet"
                        )
                      ) + "\n          "
                    ),
                  ]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm.showJwksField
            ? _c("div", { staticClass: "form-group" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("label", { attrs: { for: "cert" } }, [_vm._v("JWKS")]),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.customer.jsonKeySet,
                          expression: "customer.jsonKeySet",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { id: "jwks", cols: "30", rows: "10" },
                      domProps: { value: _vm.customer.jsonKeySet },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.customer,
                            "jsonKeySet",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "form-group teros_principal__content__column__info__form__combo",
                    staticStyle: {
                      "margin-top": "0px",
                      "margin-bottom": "40px",
                      "text-align": "right",
                    },
                  },
                  [
                    _c("div", [
                      _c(
                        "button",
                        {
                          staticClass:
                            "teros_principal__content__column__info__form__table__plus mb0",
                          on: { click: _vm.submitJwks },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "/static/img/icons/disquete.png",
                              alt: "Salvar",
                            },
                          }),
                          _vm._v(
                            _vm._s(_vm.$t("views.shared.saveButton")) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }