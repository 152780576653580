var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "teros_principal" }, [
    _c("div", { staticClass: "teros_principal__login" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "teros_principal__login__column teros_principal__login__column--fields",
        },
        [
          _vm._m(1),
          _vm._v(" "),
          _vm._m(2),
          _vm._v(" "),
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.changePassword.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "teros_principal__login__column__form__fields" },
                [
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.oldPassword,
                          expression: "oldPassword",
                        },
                      ],
                      staticClass:
                        "form-control teros_principal__login__column__form__fields__input",
                      attrs: { type: "password", placeholder: "Senha atual" },
                      domProps: { value: _vm.oldPassword },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.oldPassword = $event.target.value
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.newPassword1,
                          expression: "newPassword1",
                        },
                      ],
                      staticClass:
                        "form-control teros_principal__login__column__form__fields__input",
                      attrs: { type: "password", placeholder: "Nova senha" },
                      domProps: { value: _vm.newPassword1 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.newPassword1 = $event.target.value
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.newPassword2,
                          expression: "newPassword2",
                        },
                      ],
                      staticClass:
                        "form-control teros_principal__login__column__form__fields__input",
                      attrs: { type: "password", placeholder: "Confirmação" },
                      domProps: { value: _vm.newPassword2 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.newPassword2 = $event.target.value
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "submit" },
                    },
                    [_vm._v("ALTERAR")]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "teros_principal__login__column" }, [
      _c("img", {
        staticClass: "teros_principal__login__column__logo",
        attrs: { src: "/static/img/logo_login.png", alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "teros_principal__login__column__title" }, [
      _c("h1", { staticClass: "teros_principal__login__column__title__text" }, [
        _vm._v("\n          TROCA DE SENHA\n        "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "teros_principal__login__column__form__highlights" },
      [
        _c(
          "p",
          {
            staticClass:
              "teros_principal__login__column__form__highlights__text",
          },
          [
            _vm._v("\n          Sistema de Governança "),
            _c("br"),
            _vm._v(" "),
            _c("span", [_vm._v("Teros Open Banking")]),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }