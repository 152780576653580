var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "teros_principal__content interna container",
      attrs: { id: "form" },
    },
    [
      _c("div", { staticClass: "teros_principal__content__line-1" }, [
        _c(
          "div",
          { staticClass: "teros_principal__content__line-1__highlights" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__line-1__highlights__subject",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__line-1__highlights__subject__breadcrumb",
                  },
                  [
                    _c(
                      "h3",
                      {
                        staticClass:
                          "teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "views.teros.integrations.integration.title"
                              )
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.newObj
                      ? _c(
                          "h2",
                          {
                            staticClass:
                              "teros_principal__content__line-1__highlights__subject__breadcrumb__title active",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "views.teros.integrations.integration.newObject"
                                  )
                                ) +
                                "\n          "
                            ),
                          ]
                        )
                      : _c(
                          "h2",
                          {
                            staticClass:
                              "teros_principal__content__line-1__highlights__subject__breadcrumb__title active",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.integration.name) +
                                "\n          "
                            ),
                          ]
                        ),
                  ]
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "teros_principal__content__line-1__information" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__line-1__information__time",
              },
              [
                !_vm.newObj
                  ? _c(
                      "p",
                      {
                        staticClass:
                          "teros_principal__content__line-1__information__time__text",
                      },
                      [
                        _c("img", {
                          staticClass:
                            "teros_principal__content__line-1__information__time__text__icon",
                          attrs: {
                            src: "/static/img/icons/relogio.png",
                            alt: "",
                          },
                        }),
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t("views.shared.createdDate", {
                                date: _vm.creationDate,
                                time: _vm.creationTime,
                              })
                            ) +
                            "\n          " +
                            _vm._s(
                              _vm.lastUpdateDate
                                ? "| " +
                                    _vm.$t("views.shared.changedDate", {
                                      date: _vm.lastUpdateDate,
                                      time: _vm.lastUpdateTime,
                                    })
                                : ""
                            ) +
                            "\n        "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "teros_principal__content__line-1__information__time__btn",
                    attrs: { type: "button" },
                    on: { click: _vm.goBack },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "/static/img/icons/seta-esquerda.png",
                        alt: "",
                      },
                    }),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("views.shared.backButton")) +
                        "\n        "
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "teros_principal__content__column" }, [
        _c(
          "div",
          { staticClass: "teros_principal__content__column__menu-vertical" },
          [
            _c(
              "ul",
              {
                staticClass:
                  "teros_principal__content__column__menu-vertical__list",
              },
              [
                _c(
                  "li",
                  {
                    staticClass:
                      "teros_principal__content__column__menu-vertical__list__item",
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass:
                          "teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--lupa active",
                        attrs: { href: "#" },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "views.teros.integrations.integration.tabTitle"
                            )
                          )
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass:
                      "teros_principal__content__column__menu-vertical__list__item",
                  },
                  [
                    _vm.newObj
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--servicos",
                            attrs: { href: "#" },
                            on: { click: _vm.showAlert },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "views.teros.integrations.services.tabTitle"
                                )
                              )
                            ),
                          ]
                        )
                      : _c(
                          "router-link",
                          {
                            staticClass:
                              "teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--servicos",
                            attrs: {
                              to: "/services?hash=" + _vm.integration.hash,
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "views.teros.integrations.services.tabTitle"
                                )
                              )
                            ),
                          ]
                        ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass:
                      "teros_principal__content__column__menu-vertical__list__item",
                  },
                  [
                    _vm.newObj
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--gestao",
                            attrs: { href: "#" },
                            on: { click: _vm.showAlert },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "views.teros.integrations.integrationlogs.tabTitle"
                                )
                              )
                            ),
                          ]
                        )
                      : _c(
                          "router-link",
                          {
                            staticClass:
                              "teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--gestao",
                            attrs: {
                              to:
                                "/integrationlogs?hash=" + _vm.integration.hash,
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "views.teros.integrations.integrationlogs.tabTitle"
                                )
                              )
                            ),
                          ]
                        ),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "teros_principal__content__column__info" }, [
          _c(
            "form",
            {
              attrs: { enctype: "multipart/form-data", method: "post" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.createOrUpdate.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "div",
                { attrs: { id: "accordion" } },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "teros_principal__content__accordion__card mx0",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "teros_principal__content__accordion__card__header",
                          attrs: { id: "headingOne" },
                        },
                        [
                          _c(
                            "h2",
                            {
                              staticClass:
                                "teros_principal__content__accordion__card__header__title",
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "teros_principal__content__accordion__card__header__title__btn",
                                  attrs: {
                                    type: "button",
                                    "data-toggle": "collapse",
                                    "data-target": "#collapseOne",
                                    "aria-expanded": "true",
                                    "aria-controls": "collapseOne",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.$t(
                                          "views.teros.integrations.integration.content.editTab.title"
                                        )
                                      ) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "teros_principal__content__accordion__card__header__collapse collapse show",
                          attrs: { id: "collapseOne" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "teros_principal__content__accordion__card__header__collapse__body",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "teros_principal__content__accordion__card__header__collapse__body__form",
                                },
                                [
                                  _c("div", { staticClass: "form-group" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "custom-control custom-switch",
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.integration.active,
                                              expression: "integration.active",
                                            },
                                          ],
                                          staticClass: "custom-control-input",
                                          attrs: {
                                            type: "checkbox",
                                            checked: "",
                                            id: "customSwitch1",
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.integration.active
                                            )
                                              ? _vm._i(
                                                  _vm.integration.active,
                                                  null
                                                ) > -1
                                              : _vm.integration.active,
                                          },
                                          on: {
                                            change: function ($event) {
                                              var $$a = _vm.integration.active,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.integration,
                                                      "active",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.integration,
                                                      "active",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.integration,
                                                  "active",
                                                  $$c
                                                )
                                              }
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          {
                                            staticClass: "custom-control-label",
                                            attrs: { for: "customSwitch1" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "views.shared.activeSwitch"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "form-group row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col" },
                                      [
                                        _c(
                                          "label",
                                          {
                                            attrs: { for: "IntegrationPage1" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "views.teros.integrations.integration.content.editTab.name"
                                                )
                                              ) + "*"
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("custom-input", {
                                          attrs: {
                                            id: "IntegrationPage1",
                                            regex: "[a-zA-Z0-9\u00C0-\u00ff]",
                                            required: true,
                                          },
                                          model: {
                                            value: _vm.integration.name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.integration,
                                                "name",
                                                $$v
                                              )
                                            },
                                            expression: "integration.name",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col" },
                                      [
                                        _c("live-select", {
                                          attrs: {
                                            title:
                                              _vm.$t(
                                                "views.teros.integrations.integration.content.editTab.category"
                                              ) + "*",
                                            options: _vm.categories,
                                            hash: _vm.integration.categoryHash,
                                          },
                                          on: {
                                            onChange: function ($event) {
                                              _vm.integration.categoryHash =
                                                $event
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "form-group row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col" },
                                      [
                                        _c(
                                          "label",
                                          { attrs: { for: "IntegrationLogo" } },
                                          [
                                            _vm._v(
                                              "\n                        Logo URI\n                      "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("custom-input", {
                                          attrs: {
                                            id: "IntegrationLogo",
                                            regex: ".*",
                                            maxlength: "500",
                                          },
                                          model: {
                                            value: _vm.integration.logoUri,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.integration,
                                                "logoUri",
                                                $$v
                                              )
                                            },
                                            expression: "integration.logoUri",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col" },
                                      [
                                        _c(
                                          "label",
                                          {
                                            attrs: { for: "IntegrationPage4" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "views.teros.integrations.integration.consentURL"
                                                  )
                                                ) +
                                                "\n                        "
                                            ),
                                            _c("img", {
                                              attrs: {
                                                src: "/static/img/icons/tooltip.png",
                                                "data-toggle": "tooltip",
                                                "data-placement": "right",
                                                title: _vm.$t(
                                                  "views.teros.integrations.integration.toolTips.consentURL"
                                                ),
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("custom-input", {
                                          attrs: {
                                            id: "IntegrationPage4",
                                            regex: ".*",
                                            maxlength: "500",
                                          },
                                          model: {
                                            value:
                                              _vm.integration
                                                .consentCreateEndpoint,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.integration,
                                                "consentCreateEndpoint",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "integration.consentCreateEndpoint",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "form-group row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col" },
                                      [
                                        _c(
                                          "label",
                                          {
                                            attrs: { for: "IntegrationPage5" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "views.teros.integrations.integration.paymentConsentCreateEndpoint"
                                                  )
                                                ) +
                                                "\n                        "
                                            ),
                                            _c("img", {
                                              attrs: {
                                                src: "/static/img/icons/tooltip.png",
                                                "data-toggle": "tooltip",
                                                "data-placement": "right",
                                                title: _vm.$t(
                                                  "views.teros.integrations.integration.toolTips.paymentConsentCreateEndpoint"
                                                ),
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("custom-input", {
                                          attrs: {
                                            id: "IntegrationPage5",
                                            regex: ".*",
                                            maxlength: "500",
                                          },
                                          model: {
                                            value:
                                              _vm.integration
                                                .paymentConsentCreateEndpoint,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.integration,
                                                "paymentConsentCreateEndpoint",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "integration.paymentConsentCreateEndpoint",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col" },
                                      [
                                        _c(
                                          "label",
                                          {
                                            attrs: { for: "IntegrationPage4" },
                                          },
                                          [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "views.teros.integrations.integration.pixPaymentCreateEndpoint"
                                                  )
                                                ) +
                                                "\n                        "
                                            ),
                                            _c("img", {
                                              attrs: {
                                                src: "/static/img/icons/tooltip.png",
                                                "data-toggle": "tooltip",
                                                "data-placement": "right",
                                                title: _vm.$t(
                                                  "views.teros.integrations.integration.toolTips.pixPaymentCreateEndpoint"
                                                ),
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("custom-input", {
                                          attrs: {
                                            id: "IntegrationPage6",
                                            regex: ".*",
                                            maxlength: "500",
                                          },
                                          model: {
                                            value:
                                              _vm.integration
                                                .pixPaymentCreateEndpoint,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.integration,
                                                "pixPaymentCreateEndpoint",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "integration.pixPaymentCreateEndpoint",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "teros_principal__content__accordion__card mx0",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "teros_principal__content__accordion__card__header",
                          attrs: { id: "headingOne" },
                        },
                        [
                          _c(
                            "h2",
                            {
                              staticClass:
                                "teros_principal__content__accordion__card__header__title",
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "teros_principal__content__accordion__card__header__title__btn",
                                  attrs: {
                                    type: "button",
                                    "data-toggle": "collapse",
                                    "data-target": "#collapseTwo",
                                    "aria-expanded": "true",
                                    "aria-controls": "collapseTwo",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.$t(
                                          "views.teros.integrations.integration.content.responsibilitiesTab.title"
                                        )
                                      ) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "teros_principal__content__accordion__card__header__collapse collapse show",
                          attrs: { id: "collapseTwo" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "teros_principal__content__accordion__card__header__collapse__body",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "teros_principal__content__accordion__card__header__collapse__body__form",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "form-group" },
                                    [
                                      _c("label", { attrs: { for: "" } }, [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              _vm.$t(
                                                "views.teros.integrations.integration.content.responsibilitiesTab.contract"
                                              )
                                            ) +
                                            "\n                    "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("file-upload", {
                                        attrs: {
                                          "file-name":
                                            _vm.integration.contractFileName,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-group teros_principal__content__column__info__form__combo",
                                    },
                                    [
                                      _c("pdf-view", {
                                        attrs: {
                                          "file-name":
                                            _vm.integration.contractFileName,
                                          type: "button",
                                          title: _vm.$t(
                                            "views.teros.integrations.integration.content.responsibilitiesTab.viewContractButton"
                                          ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("authorization-form", {
                    attrs: {
                      apiAuthentication: _vm.integration.apiAuthentication,
                      showDcrType: _vm.showDcrType,
                      customHeaders: _vm.customHeaders,
                    },
                    on: { onChangeFlow: _vm.onChangeFlowType },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "form-group teros_principal__content__column__info__form__combo",
                  staticStyle: {
                    "margin-top": "0",
                    "margin-bottom": "40px",
                    "text-align": "right",
                  },
                },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "teros_principal__content__column__info__form__combo__salvar",
                      attrs: { title: "Salvar" },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: "/static/img/icons/disquete.png",
                          alt: "Salvar",
                        },
                      }),
                      _vm._v(
                        _vm._s(_vm.$t("views.shared.saveButton")) +
                          "\n          "
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }