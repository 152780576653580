<template>
  <div class="teros_principal__content interna container">
    <div class="teros_principal__content__line-1 line">
      <div class="teros_principal__content__line-1__highlights">
        <div class="teros_principal__content__line-1__highlights__subject">
          <router-link :to="'/services?hash=' + service.integrationHash">
            <div
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb
              "
            >
              <h3
                class="
                  teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle
                "
              >
                {{ $t("views.teros.integrations.integration.title") }}
              </h3>
              <h2
                class="
                  teros_principal__content__line-1__highlights__subject__breadcrumb__title
                "
              >
                {{ service.integrationName }}
              </h2>
            </div>
          </router-link>
          <div class="teros_principal__arrow"></div>
          <router-link :to="'/service?hash=' + service.hash">
            <div
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb
              "
            >
              <h3
                class="
                  teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle
                "
              >
                {{ $t("views.teros.integrations.service.title") }}
              </h3>
              <h2
                class="
                  teros_principal__content__line-1__highlights__subject__breadcrumb__title
                "
              >
                {{ service.name }}
              </h2>
            </div>
          </router-link>
          <div class="teros_principal__arrow"></div>
          <router-link
            :to="
              '/compliance?serviceHash=' +
              service.hash +
              '&hash=' +
              standard.hash
            "
          >
            <div
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb
              "
            >
              <h3
                class="
                  teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle
                "
              >
                {{ $t("views.teros.integrations.compliance.title") }}
              </h3>
              <h2
                class="
                  teros_principal__content__line-1__highlights__subject__breadcrumb__title
                "
              >
                {{ standard.name }}
              </h2>
            </div>
          </router-link>
          <div class="teros_principal__arrow"></div>
          <div
            class="
              teros_principal__content__line-1__highlights__subject__breadcrumb
            "
          >
            <h3
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle
              "
            >
              {{ $t("views.teros.integrations.term.title") }}
            </h3>
            <h2
              v-if="newObj"
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__title
                active
              "
            >
              {{ $t("views.teros.integrations.term.newObject") }}
            </h2>
            <h2
              v-else
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__title
                active
              "
            >
              {{ term.name }}
            </h2>
          </div>
        </div>
      </div>
      <div class="teros_principal__content__line-1__information">
        <div class="teros_principal__content__line-1__information__time">
          <button
            type="button"
            @click="goBack"
            class="teros_principal__content__line-1__information__time__btn"
          >
            <img src="/static/img/icons/seta-esquerda.png" alt="" />
            {{ $t("views.shared.backButton") }}
          </button>
        </div>
      </div>
    </div>

    <div class="teros_principal__content__accordion__description">
      <p
        v-if="!newObj"
        class="teros_principal__content__line-1__information__time__text"
      >
        <img
          class="
            teros_principal__content__line-1__information__time__text__icon
          "
          src="/static/img/icons/relogio.png"
          alt=""
        />
        {{
          $t("views.shared.createdDate", {
            date: creationDate,
            time: creationTime,
          })
        }}
        {{
          lastUpdateDate
            ? "| " +
              $t("views.shared.changedDate", {
                date: lastUpdateDate,
                time: lastUpdateTime,
              })
            : ""
        }}
      </p>
    </div>

    <div id="accordion">
      <div class="teros_principal__content__accordion__card">
        <div
          class="
            teros_principal__content__accordion__card__header
            accordion-off
          "
          id="headingOne"
        >
          <h2 class="teros_principal__content__accordion__card__header__title">
            <button
              class="
                teros_principal__content__accordion__card__header__title__btn
              "
              type="button"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              {{ $t("views.teros.integrations.term.content.edit.title") }}
            </button>
            <hr />
          </h2>
        </div>

        <div
          id="collapseOne"
          class="teros_principal__content__accordion__card__header__collapse"
        >
          <div
            class="
              teros_principal__content__accordion__card__header__collapse__body
            "
          >
            <form
              @submit.prevent="createOrUpdate"
              class="
                teros_principal__content__accordion__card__header__collapse__body__form
              "
              method="post"
            >
              <div class="form-group">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="customSwitch1"
                    v-model="term.serviceStandardTermActive"
                  />
                  <label class="custom-control-label" for="customSwitch1">{{
                    $t("views.shared.activeSwitch")
                  }}</label>
                </div>
              </div>

              <div class="form-group row">
                <div class="col">
                  <label for="formGroupExampleInput"
                    >{{
                      $t(
                        "views.teros.integrations.term.content.edit.orderNumber"
                      )
                    }}*
                    <img
                      src="/static/img/icons/tooltip.png"
                      data-toggle="tooltip"
                      data-placement="right"
                      :title="
                        $t(
                          'views.teros.integrations.term.toolTips.edit.orderNumber'
                        )
                      "
                  /></label>
                  <custom-input
                    :id="'formGroupExampleInput'"
                    :type="'number'"
                    :regex="'[0-9]'"
                    v-model="term.serviceStandardTermOrderNumber"
                  ></custom-input>
                </div>

                <div class="col">
                  <label for="formGroupExampleInput2"
                    >{{
                      $t("views.teros.integrations.term.content.edit.name")
                    }}*
                    <img
                      src="/static/img/icons/tooltip.png"
                      data-toggle="tooltip"
                      data-placement="right"
                      :title="
                        $t('views.teros.integrations.term.toolTips.edit.name')
                      "
                  /></label>
                  <input
                    type="text"
                    required
                    class="form-control"
                    id="formGroupExampleInput2"
                    v-model="term.name"
                  />
                </div>
              </div>

              <div class="form-group row">
                <div class="col">
                  <label for="formGroupExampleInput3"
                    >{{
                      $t(
                        "views.teros.integrations.term.content.edit.editDescription"
                      )
                    }}*</label
                  >
                  <editor api-key="no-api-key" v-model="term.description" />
                </div>
              </div>

              <div class="form-group align-right">
                <button
                  class="
                    teros_principal__content__column__info__form__table__plus
                    mb0
                  "
                >
                  <img src="/static/img/icons/disquete.png" alt="Salvar" />{{
                    $t("views.shared.saveButton")
                  }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http, router } from "@/_helpers";
import Editor from "@tinymce/tinymce-vue";
import CustomInput from "@/_components/CustomInput.vue";
import moment from 'moment';

export default {
  data() {
    return {
      newObj: true,
      service: {},
      standard: {},
      term: { serviceStandardTermActive: true },
    };
  },
  components: {
    Editor,
    CustomInput,
  },
  computed: {
    creationDate() {
      if (this.term.creationDate) {
        return moment(String(this.term.creationDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
    creationTime() {
      if (this.term.creationDate) {
        return moment(String(this.term.creationDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("HH:mm");
      }
      return "";
    },
    lastUpdateDate() {
      if (this.term.lastUpdateDate) {
        return moment(String(this.term.lastUpdateDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
    lastUpdateTime() {
      if (this.term.lastUpdateDate) {
        return moment(String(this.term.lastUpdateDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("HH:mm");
      }
      return "";
    },
  },
  mounted() {
    this.$emit("message", this.$route);
    const hash = this.$route.query.hash;
    const serviceHash = this.$route.query.serviceHash;
    const standardHash = this.$route.query.standardHash;
    http.get("/api/service/get/" + serviceHash).then((response) => {
      this.service = response.data;
    });
    http.get("/api/standard/get/" + standardHash).then((response) => {
      this.standard = response.data;
    });
    if (hash) {
      this.newObj = false;
      http
        .get(
          "/api/service/get/term?serviceHash=" +
            serviceHash +
            "&standardHash=" +
            standardHash +
            "&termHash=" +
            hash
        )
        .then((response) => {
          this.term = response.data;
        });
    }
  },
  methods: {
    goBack() {
      router.push({
        name: "compliancepage",
        query: { hash: this.standard.hash, serviceHash: this.service.hash },
      });
    },
    createOrUpdate() {
      let serviceHash = this.service.hash;
      let standardHash = this.standard.hash;
      let active = this.term.serviceStandardTermActive;
      let orderNumber = this.term.serviceStandardTermOrderNumber;
      if (this.newObj) {
        http.post("/api/term/create/", this.term).then((response) => {
          let termCreated = response.data;
          http
            .post(
              "/api/service/add/term?serviceHash=" +
                serviceHash +
                "&standardHash=" +
                standardHash +
                "&termHash=" +
                termCreated.hash +
                "&active=" +
                active +
                "&orderNumber=" +
                orderNumber
            )
            .then(() => {
              this.goBack();
              this.$emit("success");
            });
        });
      } else {
        http.put("/api/term/update/", this.term).then((response) => {
          let termEdited = response.data;
          http
            .put(
              "/api/service/update/term?serviceHash=" +
                serviceHash +
                "&standardHash=" +
                standardHash +
                "&termHash=" +
                termEdited.hash +
                "&active=" +
                active +
                "&orderNumber=" +
                orderNumber
            )
            .then(() => {
              this.goBack();
              this.$emit("success");
            });
        });
      }
    },
  },
};
</script>