<template>
  <div :class="pageIdentifier">
    <div class="teros_principal clientes">
      <header
        v-if="currentUser"
        class="teros_principal__header container-fluid"
      >
        <div class="teros_principal__header__logo">
          <img src="/static/img/logo_teros.png" alt="" />
        </div>
        <div class="teros_principal__header__menu">
          <div class="teros_principal__header__menu__languages">
            <language-select />
          </div>
          <ul class="teros_principal__header__menu__list">
            <li class="teros_principal__header__menu__list__item">
              <span>{{ currentUser.name }}</span>
            </li>
            <li class="teros_principal__header__menu__list__item">
              <a
                href="#"
                @click="logout"
                class="teros_principal__header__menu__list__item__link"
                >{{ $t("views.shared.exit") }}</a
              >
            </li>
          </ul>
        </div>
      </header>

      <div v-if="currentUser" class="teros_principal__menu container">
        <ul class="teros_principal__menu__list">
          <li class="teros_principal__menu__list__item">
            <router-link
              v-if="menuActive == 'dashboard'"
              to="/"
              class="
                teros_principal__menu__list__item__link
                teros_principal__menu__list__item__link--active-dashboard
              "
            >
              <div class="teros_principal__menu__list__item__link__circle">
                <img src="/static/img/icons/speedometer-active.png" alt="" />
              </div>
              {{ $t("views.shared.menu.dashboard") }}
            </router-link>
            <router-link
              v-else
              to="/"
              class="
                teros_principal__menu__list__item__link
                teros_principal__menu__list__item__link-dashboard
              "
            >
              <div class="teros_principal__menu__list__item__link__circle">
                <img src="/static/img/icons/speedometer.png" alt="" />
              </div>
              {{ $t("views.shared.menu.dashboard") }}
            </router-link>
          </li>

          <li
            v-if="currentUser.isAdmin"
            class="teros_principal__menu__list__item"
          >
            <router-link
              v-if="menuActive == 'integrations'"
              to="/integrations"
              class="
                teros_principal__menu__list__item__link
                teros_principal__menu__list__item__link--active-integracoes
              "
            >
              <div class="teros_principal__menu__list__item__link__circle">
                <img src="/static/img/icons/intersect-active.png" alt="" />
              </div>
              {{ $t("views.shared.menu.integrations") }}
            </router-link>
            <router-link
              v-else
              to="/integrations"
              class="
                teros_principal__menu__list__item__link
                teros_principal__menu__list__item__link-integracoes
              "
            >
              <div class="teros_principal__menu__list__item__link__circle">
                <img src="/static/img/icons/intersect.png" alt="" />
              </div>
              {{ $t("views.shared.menu.integrations") }}
            </router-link>
          </li>

          <li
            v-if="currentUser.isAdmin"
            class="teros_principal__menu__list__item"
          >
            <router-link
              v-if="menuActive == 'customers'"
              to="/customers"
              class="
                teros_principal__menu__list__item__link
                teros_principal__menu__list__item__link--active-clientes
              "
            >
              <div class="teros_principal__menu__list__item__link__circle">
                <img src="/static/img/icons/customer-active.png" />
              </div>
              {{ $t("views.shared.menu.customers") }}
            </router-link>
            <router-link
              v-else
              to="/customers"
              class="
                teros_principal__menu__list__item__link
                teros_principal__menu__list__item__link-clientes
              "
            >
              <div class="teros_principal__menu__list__item__link__circle">
                <img src="/static/img/icons/customer.png" />
              </div>
              {{ $t("views.shared.menu.customers") }}
            </router-link>
          </li>

          <li
            v-if="
              !currentUser.isAdmin &&
              currentUser.groups.includes('CustomerAdmin')
            "
            class="teros_principal__menu__list__item"
          >
            <router-link
              v-if="menuActive == 'reception'"
              to="/receptions"
              class="
                teros_principal__menu__list__item__link
                teros_principal__menu__list__item__link--active-clientes
              "
            >
              <div class="teros_principal__menu__list__item__link__circle">
                <img src="/static/img/icons/customer-active.png" />
              </div>
              {{ $t("views.shared.menu.reception") }}
            </router-link>
            <router-link
              v-else
              to="/receptions"
              class="
                teros_principal__menu__list__item__link
                teros_principal__menu__list__item__link-clientes
              "
            >
              <div class="teros_principal__menu__list__item__link__circle">
                <img src="/static/img/icons/customer.png" />
              </div>
              {{ $t("views.shared.menu.reception") }}
            </router-link>
          </li>

          <li
            v-if="
              !currentUser.isAdmin &&
              currentUser.groups.includes('CustomerAdmin')
            "
            class="teros_principal__menu__list__item"
          >
            <router-link
              v-if="menuActive == 'transmission'"
              to="/transmission"
              class="
                teros_principal__menu__list__item__link
                teros_principal__menu__list__item__link--active-transmissao
              "
            >
              <div class="teros_principal__menu__list__item__link__circle">
                <img src="/static/img/icons/transmission-active.png" />
              </div>
              {{ $t("views.shared.menu.transmission") }}
            </router-link>
            <router-link
              v-else
              to="/transmission"
              class="
                teros_principal__menu__list__item__link
                teros_principal__menu__list__item__link-transmissao
              "
            >
              <div class="teros_principal__menu__list__item__link__circle">
                <img src="/static/img/icons/transmission.png" />
              </div>
              {{ $t("views.shared.menu.transmission") }}
            </router-link>
          </li>

          <li
            v-if="currentUser.isAdmin"
            class="teros_principal__menu__list__item"
          >
            <router-link
              v-if="menuActive == 'authorizations'"
              to="/authorizations"
              class="
                teros_principal__menu__list__item__link
                teros_principal__menu__list__item__link--active-autorizacoes
              "
            >
              <div class="teros_principal__menu__list__item__link__circle">
                <img src="/static/img/icons/paper-active.png" alt="" />
              </div>
              {{ $t("views.shared.menu.authorizations") }}
            </router-link>
            <router-link
              v-else
              to="/authorizations"
              class="
                teros_principal__menu__list__item__link
                teros_principal__menu__list__item__link-autorizacoes
              "
            >
              <div class="teros_principal__menu__list__item__link__circle">
                <img src="/static/img/icons/paper.png" alt="" />
              </div>
              {{ $t("views.shared.menu.authorizations") }}
            </router-link>
          </li>

          <li
            v-if="
              !currentUser.isAdmin &&
              currentUser.groups.includes('CustomerAdmin')
            "
            class="teros_principal__menu__list__item"
          >
            <router-link
              v-if="menuActive == 'access'"
              to="/access"
              class="
                teros_principal__menu__list__item__link
                teros_principal__menu__list__item__link--active-integracoes
              "
            >
              <div class="teros_principal__menu__list__item__link__circle">
                <img src="/static/img/icons/acesso.png" />
              </div>
              {{ $t("views.shared.menu.access") }}
            </router-link>
            <router-link
              v-else
              to="/access"
              class="
                teros_principal__menu__list__item__link
                teros_principal__menu__list__item__link-integracoes
              "
            >
              <div class="teros_principal__menu__list__item__link__circle">
                <img src="/static/img/icons/acesso.png" />
              </div>
              {{ $t("views.shared.menu.access") }}
            </router-link>
          </li>

          <li
            v-if="currentUser.isAdmin"
            class="teros_principal__menu__list__item"
          >
            <router-link
              v-if="menuActive == 'config'"
              to="/categories"
              class="
                teros_principal__menu__list__item__link
                teros_principal__menu__list__item__link--active-autorizacoes
              "
            >
              <div class="teros_principal__menu__list__item__link__circle">
                <img src="/static/img/icons/settings-active.svg" alt="" />
              </div>
              {{ $t("views.shared.menu.config") }}
            </router-link>
            <router-link
              v-else
              to="/categories"
              class="
                teros_principal__menu__list__item__link
                teros_principal__menu__list__item__link-autorizacoes
              "
            >
              <div class="teros_principal__menu__list__item__link__circle">
                <img src="/static/img/icons/settings.svg" alt="" />
              </div>
              {{ $t("views.shared.menu.config") }}
            </router-link>
          </li>
        </ul>
      </div>

      <router-view @message="refreshPage" @success="showMessage"></router-view>
    </div>

    <footer class="teros_principal__footer container">
      <p>Teros Open Banking | {{ $t("views.shared.titleSystem") }} - 2021</p>
    </footer>
  </div>
</template>

<script>
import Vue from "vue";
import { cognito } from "@/_helpers";
import LanguageSelect from "@/_components/LanguageSelect.vue";

export default {
  name: "app",
  data() {
    return {
      currentUser: null,
      pageIdentifier: "",
      menuActive: "dashboard",
    };
  },
  components: {
    LanguageSelect,
  },
  created() {
    cognito.currentUser.subscribe((x) => (this.currentUser = x));
  },
  methods: {
    logout() {
      cognito.logout();
    },
    refreshPage(router) {
      this.pageIdentifier = router.name;
      document.title =
        "Teros Open Banking | " +
        this.$i18n.t("views.shared.menu." + router.meta.menu);
      this.menuActive = router.meta.menu;
    },
    showMessage() {
      Vue.$toast.success(this.$i18n.t("views.shared.modal.success.message"));
    },
  },
};
</script>