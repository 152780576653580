<template>
  <div class="teros_principal__content interna container">
    <div class="teros_principal__content__line-1">
      <div class="teros_principal__content__line-1__highlights">
        <div class="teros_principal__content__line-1__highlights__subject">
          <div class="teros_principal__content__line-1__highlights__subject__breadcrumb">
            <h3 class="teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle">
              {{ $t("views.client.access.general.client") }}
            </h3>

            <h2 class="teros_principal__content__line-1__highlights__subject__breadcrumb__title active">
              {{ currentUser.customerName }}
            </h2>
          </div>
        </div>
      </div>
    </div>

    <div class="teros_principal__content__column">
      <div class="teros_principal__content__column__menu-vertical">
        <ul class="teros_principal__content__column__menu-vertical__list">
          <li class="teros_principal__content__column__menu-vertical__list__item">
            <a href="#" class="teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--lupa active">{{
              $t("views.client.access.general.tabTitle")
            }}</a>
          </li>
        </ul>
      </div>

      <div class="teros_principal__content__column__info">
        <div class="form-group row">
          <div class="col">
            <label for="formGroupExampleInput1"
              >{{ $t("views.client.access.general.content.standardKey") }}
              <img src="/static/img/icons/tooltip.png" data-toggle="tooltip" data-placement="right" :title="$t('views.client.access.general.toolTips.gatewayClientId')"
            /></label>
            <input type="text" class="form-control" id="formGroupExampleInput1" v-model="customer.gatewayClientId" readonly />
          </div>

          <div class="col">
            <label for="formGroupExampleInput2"
              >Secret Key <img src="/static/img/icons/tooltip.png" data-toggle="tooltip" data-placement="right" :title="$t('views.client.access.general.toolTips.gatewaySecret')"
            /></label>
            <input type="text" class="form-control" id="formGroupExampleInput2" v-model="customer.gatewaySecret" readonly />
          </div>
        </div>
        <div class="form-group">
          <label for="formGroupExampleInput3"
            >{{ $t("views.client.access.general.content.basic64Credentials") }}
            <img src="/static/img/icons/tooltip.png" data-toggle="tooltip" data-placement="right" :title="$t('views.client.access.general.toolTips.basic64Credentials')"
          /></label>
          <input type="text" class="form-control" id="formGroupExampleInput3" v-model="credentialsBase64" readonly />
        </div>

        <div class="form-group row" style="margin-top: 30px;">
          <div class="col">
            <label v-if="customer.hasClientCertificate">{{ $t("views.client.access.general.content.hasCertificate") }} </label>
            <label v-else>{{ $t("views.client.access.general.content.noCertificate") }} </label>
            <a
              href="javascript:void(null)"
              @click="showOrHideCertificateInput"
              style="width: 300px; margin-top: 8px;"
              class="
                teros_principal__content__column__info__form__combo__btn-contrato
              "
              >{{ $t("views.client.access.general.content.submitCertificate") }}
            </a>
          </div>
        </div>
        <div class="form-group" v-if="showCertFields">
          <div class="row">
            <div class="col">
              <label for="cert">Certificate.pem</label>
              <textarea id="cert" cols="30" rows="10" v-model="customer.clientCertificate.certificate" class="form-control"></textarea>
            </div>
            <div class="col">
              <label for="cert">PrivateKey.pem</label>
              <textarea id="cert" cols="30" rows="10" v-model="customer.clientCertificate.privateKey" class="form-control"></textarea>
            </div>
          </div>
          <div
            class="
              form-group
              teros_principal__content__column__info__form__combo
            "
            style="margin-top: 0px; margin-bottom: 40px; text-align: right;"
          >
            <div>
              <button
                class="
                  teros_principal__content__column__info__form__table__plus
                  mb0
                "
                @click="submitCert"
              >
                <img src="/static/img/icons/disquete.png" alt="Salvar" />{{ $t("views.shared.saveButton") }}
              </button>
            </div>
          </div>
        </div>

        <div class="form-group row" style="margin-top: 30px;">
          <div class="col">
            <label v-if="customer.hasJsonKeySet">{{ $t("views.client.access.general.content.hasJsonWebKeySet") }} </label>
            <label v-else>{{ $t("views.client.access.general.content.noJsonWebKeySet") }} </label>
            <a
              href="javascript:void(null)"
              @click="showOrHideJwksInput"
              style="width: 300px; margin-top: 8px;"
              class="
                teros_principal__content__column__info__form__combo__btn-contrato
              "
              >{{ $t("views.client.access.general.content.submitJsonWebKeySet") }}
            </a>
          </div>
        </div>
        <div class="form-group" v-if="showJwksField">
          <div class="row">
            <div class="col">
              <label for="cert">JWKS</label>
              <textarea id="jwks" cols="30" rows="10" v-model="customer.jsonKeySet" class="form-control"></textarea>
            </div>
          </div>
          <div
            class="
              form-group
              teros_principal__content__column__info__form__combo
            "
            style="margin-top: 0px; margin-bottom: 40px; text-align: right;"
          >
            <div>
              <button
                class="
                  teros_principal__content__column__info__form__table__plus
                  mb0
                "
                @click="submitJwks"
              >
                <img src="/static/img/icons/disquete.png" alt="Salvar" />{{ $t("views.shared.saveButton") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cognito, http } from "@/_helpers";

export default {
  data() {
    return {
      currentUser: cognito.currentUserValue,
      customer: {
        clientCertificate: {
          privateKey: null,
          certificate: null,
        },
        jsonKeySet: null,
      },
      showCertFields: false,
      showJwksField: false,
    };
  },
  computed: {
    credentialsBase64() {
      return "Basic " + btoa(this.customer.gatewayClientId + ":" + this.customer.gatewaySecret);
    },
  },
  mounted() {
    this.$emit("message", this.$route);
    http.get("/api/customer/get/" + this.currentUser.customerHash).then((response) => {
      this.customer = response.data;
      this.customer.clientCertificate = {};
    });
  },
  methods: {
    showOrHideCertificateInput() {
      this.showCertFields = !this.showCertFields;
    },
    submitCert() {
      this.customer.jsonKeySet = null;
      http.put("/api/customer/update/", this.customer).then(() => {
        this.showCertFields = false;
        this.customer.clientCertificate = {};
        this.customer.hasClientCertificate = true;
        this.$emit("success");
      });
    },
    showOrHideJwksInput() {
      this.showJwksField = !this.showJwksField;
    },
    submitJwks() {
      this.customer.clientCertificate = null;
      http.put("/api/customer/update/", this.customer).then(() => {
        this.showJwksField = false;
        this.customer.clientCertificate = {};
        this.customer.jsonKeySet = null;
        this.customer.hasJsonKeySet = true;
        this.$emit("success");
      });
    },
  },
};
</script>
