var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "teros_principal__content interna container" },
    [
      _c("div", { staticClass: "teros_principal__content__line-1 line" }, [
        _c(
          "div",
          { staticClass: "teros_principal__content__line-1__highlights" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__line-1__highlights__subject",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__line-1__highlights__subject__breadcrumb",
                  },
                  [
                    _c("router-link", { attrs: { to: "/transmission" } }, [
                      _c(
                        "h3",
                        {
                          staticClass:
                            "teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t(
                                  "views.client.transmission.general.client"
                                )
                              ) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "h2",
                        {
                          staticClass:
                            "teros_principal__content__line-1__highlights__subject__breadcrumb__title",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.currentUser.customerName) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "teros_principal__arrow" }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__line-1__highlights__subject__breadcrumb",
                  },
                  [
                    _c(
                      "h3",
                      {
                        staticClass:
                          "teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "views.client.transmission.transmissionconsentiment.title"
                              )
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.newObj
                      ? _c(
                          "h2",
                          {
                            staticClass:
                              "teros_principal__content__line-1__highlights__subject__breadcrumb__title active",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "views.client.transmission.transmissionconsentiment.newObject"
                                  )
                                ) +
                                "\n          "
                            ),
                          ]
                        )
                      : _c(
                          "h2",
                          {
                            staticClass:
                              "teros_principal__content__line-1__highlights__subject__breadcrumb__title active",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.transmissionconsentiment.name) +
                                "\n          "
                            ),
                          ]
                        ),
                  ]
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "teros_principal__content__line-1__information" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__line-1__information__time",
              },
              [
                _c(
                  "button",
                  {
                    staticClass:
                      "teros_principal__content__line-1__information__time__btn",
                    attrs: { type: "button" },
                    on: { click: _vm.goBack },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "/static/img/icons/seta-esquerda.png",
                        alt: "",
                      },
                    }),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("views.shared.backButton")) +
                        "\n        "
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "teros_principal__content__accordion__description" },
        [
          !_vm.newObj
            ? _c(
                "p",
                {
                  staticClass:
                    "teros_principal__content__line-1__information__time__text",
                },
                [
                  _c("img", {
                    staticClass:
                      "teros_principal__content__line-1__information__time__text__icon",
                    attrs: { src: "/static/img/icons/relogio.png", alt: "" },
                  }),
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.$t("views.shared.createdDate", {
                          date: _vm.creationDate,
                          time: _vm.creationTime,
                        })
                      ) +
                      "\n      " +
                      _vm._s(
                        _vm.lastUpdateDate
                          ? "| " +
                              _vm.$t("views.shared.changedDate", {
                                date: _vm.lastUpdateDate,
                                time: _vm.lastUpdateTime,
                              })
                          : ""
                      ) +
                      "\n    "
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("div", { attrs: { id: "accordion" } }, [
        _c(
          "form",
          {
            attrs: { method: "post" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.createOrUpdate.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "teros_principal__content__accordion__card" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__accordion__card__header",
                    attrs: { id: "headingTwo" },
                  },
                  [
                    _c(
                      "h2",
                      {
                        staticClass:
                          "teros_principal__content__accordion__card__header__title",
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "teros_principal__content__accordion__card__header__title__btn",
                            attrs: {
                              type: "button",
                              "data-toggle": "collapse",
                              "data-target": "#collapseTwo",
                              "aria-expanded": "false",
                              "aria-controls": "collapseTwo",
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t(
                                    "views.client.transmission.transmissionconsentiment.general.title"
                                  )
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("hr"),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__accordion__card__header__collapse",
                    attrs: { id: "collapseTwo" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "teros_principal__content__accordion__card__header__collapse__body pt0",
                        staticStyle: { padding: "0 40px" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "teros_principal__content__table" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "form-group",
                                staticStyle: { "padding-top": "40px" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "custom-control custom-switch",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.transmissionconsentiment.active,
                                          expression:
                                            "transmissionconsentiment.active",
                                        },
                                      ],
                                      staticClass: "custom-control-input",
                                      attrs: {
                                        type: "checkbox",
                                        checked: "",
                                        id: "customSwitch1",
                                      },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.transmissionconsentiment.active
                                        )
                                          ? _vm._i(
                                              _vm.transmissionconsentiment
                                                .active,
                                              null
                                            ) > -1
                                          : _vm.transmissionconsentiment.active,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a =
                                              _vm.transmissionconsentiment
                                                .active,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.transmissionconsentiment,
                                                  "active",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.transmissionconsentiment,
                                                  "active",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              _vm.transmissionconsentiment,
                                              "active",
                                              $$c
                                            )
                                          }
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "custom-control-label",
                                        attrs: { for: "customSwitch1" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("views.shared.activeSwitch")
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-group row" }, [
                              _c(
                                "div",
                                { staticClass: "col" },
                                [
                                  _c(
                                    "label",
                                    {
                                      attrs: { for: "formGroupExampleInput3" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "views.client.transmission.transmissionconsentiment.general.content.consentName"
                                          )
                                        ) + "*\n                  "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("custom-input", {
                                    attrs: { id: "formGroupExampleInput3" },
                                    model: {
                                      value: _vm.transmissionconsentiment.name,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.transmissionconsentiment,
                                          "name",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "transmissionconsentiment.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              !_vm.newObj
                                ? _c("div", { staticClass: "col" }, [
                                    _c(
                                      "label",
                                      {
                                        attrs: {
                                          for: "formGroupExampleInput4",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "TransmissionConsentimentHash\n                    "
                                        ),
                                        _c("img", {
                                          attrs: {
                                            src: "/static/img/icons/tooltip.png",
                                            "data-toggle": "tooltip",
                                            "data-placement": "right",
                                            title: _vm.$t(
                                              "views.client.transmission.transmissionconsentiment.toolTip.transmissionconsentimentHash"
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.transmissionconsentiment.hash,
                                          expression:
                                            "transmissionconsentiment.hash",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        id: "formGroupExampleInput4",
                                        readonly: "",
                                      },
                                      domProps: {
                                        value:
                                          _vm.transmissionconsentiment.hash,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.transmissionconsentiment,
                                            "hash",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ])
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            _c("br"),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "teros_principal__content__accordion__card" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__accordion__card__header",
                    attrs: { id: "headingThree" },
                  },
                  [
                    _c(
                      "h2",
                      {
                        staticClass:
                          "teros_principal__content__accordion__card__header__title",
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "teros_principal__content__accordion__card__header__title__btn",
                            attrs: {
                              type: "button",
                              "data-toggle": "collapse",
                              "data-target": "#collapseThree",
                              "aria-expanded": "false",
                              "aria-controls": "collapseThree",
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t(
                                    "views.client.transmission.transmissionconsentiment.consent.title"
                                  )
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("hr"),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__accordion__card__header__collapse",
                    attrs: { id: "collapseThree" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "teros_principal__content__accordion__card__header__collapse__body pt0",
                        staticStyle: { padding: "0 40px" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "teros_principal__content__table" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "form-group row",
                                staticStyle: { "padding-top": "40px" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "col" },
                                  [
                                    _c(
                                      "label",
                                      {
                                        attrs: {
                                          for: "formGroupExampleInput5",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "views.client.transmission.transmissionconsentiment.consent.content.expiration"
                                            )
                                          ) + "*\n                    "
                                        ),
                                        _c("img", {
                                          attrs: {
                                            src: "/static/img/icons/tooltip.png",
                                            "data-toggle": "tooltip",
                                            "data-placement": "right",
                                            title: _vm.$t(
                                              "views.client.transmission.transmissionconsentiment.toolTip.expiration"
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("custom-input", {
                                      attrs: {
                                        id: "formGroupExampleInput5",
                                        type: "number",
                                        regex: "[0-9]",
                                      },
                                      model: {
                                        value:
                                          _vm.transmissionconsentiment
                                            .expiration,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.transmissionconsentiment,
                                            "expiration",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "transmissionconsentiment.expiration",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c("div", { staticClass: "form-group row" }, [
                              _c("div", { staticClass: "col" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "show_tiny teros_principal__content__column__info__form__combo__btn-contrato",
                                    staticStyle: { width: "270px" },
                                    attrs: { href: "javascript:void(null)" },
                                    on: { click: _vm.showOrHideTiny },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.$t(
                                            "views.client.transmission.transmissionconsentiment.consent.content.consentButtons.editConsentLayout"
                                          )
                                        ) +
                                        "\n                  "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _vm.showTiny
                              ? _c("div", { staticClass: "form-group row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col" },
                                    [
                                      _c("editor", {
                                        attrs: { "api-key": "no-api-key" },
                                        model: {
                                          value:
                                            _vm.transmissionconsentiment.layout,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.transmissionconsentiment,
                                              "layout",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "transmissionconsentiment.layout",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.newObj
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "teros_principal__content__table__body",
                                  },
                                  [
                                    _c(
                                      "table",
                                      {
                                        staticClass:
                                          "teros_principal__content__table__body__table",
                                        attrs: {
                                          id: "table",
                                          "data-toggle": "table",
                                          "data-height": "600",
                                          "data-url": "",
                                        },
                                      },
                                      [
                                        _c("thead", [
                                          _c("tr", [
                                            _c(
                                              "th",
                                              {
                                                attrs: {
                                                  "data-field": "id",
                                                  "data-sortable": "true",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "views.client.transmission.transmissionconsentiment.consent.content.table.header.orderNumber"
                                                      )
                                                    ) +
                                                    "\n                      "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "th",
                                              { attrs: { "data-field": "a" } },
                                              [
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "views.client.transmission.transmissionconsentiment.consent.content.table.header.terms"
                                                      )
                                                    ) +
                                                    "\n                      "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "th",
                                              { attrs: { "data-field": "d" } },
                                              [
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "views.shared.table.header.createdAt"
                                                      )
                                                    ) +
                                                    "\n                      "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "th",
                                              { attrs: { "data-field": "e" } },
                                              [
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "views.shared.table.header.changedAt"
                                                      )
                                                    ) +
                                                    "\n                      "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "th",
                                              {
                                                staticStyle: {
                                                  "text-align": "center",
                                                },
                                                attrs: { "data-field": "f" },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "views.shared.table.header.options"
                                                      )
                                                    ) +
                                                    "\n                      "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "tbody",
                                          _vm._l(
                                            _vm.transmissionconsentiment
                                              .termsList,
                                            function (item) {
                                              return _c(
                                                "tr",
                                                { key: item.hash },
                                                [
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(item.orderNumber)
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _vm._v(_vm._s(item.name)),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("formatDate")(
                                                          item.creationDate
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("formatDate")(
                                                          item.lastUpdateDate
                                                        )
                                                      )
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticStyle: {
                                                        "text-align": "center",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "teros_principal__content__table__body__table__options mult-opt",
                                                        },
                                                        [
                                                          _c(
                                                            "router-link",
                                                            {
                                                              staticClass:
                                                                "teros_principal__content__table__body__table__options__item",
                                                              attrs: {
                                                                to:
                                                                  "/transmissionconsentimentterm?transmissionConsentimentHash=" +
                                                                  _vm
                                                                    .transmissionconsentiment
                                                                    .hash +
                                                                  "&hash=" +
                                                                  item.hash,
                                                                "data-toggle":
                                                                  "tooltip",
                                                                "data-placement":
                                                                  "top",
                                                                title:
                                                                  "Visualizar/Editar",
                                                              },
                                                            },
                                                            [
                                                              _c("div", [
                                                                _c("img", {
                                                                  attrs: {
                                                                    src: "/static/img/icons/editar.svg",
                                                                    alt: "",
                                                                  },
                                                                }),
                                                              ]),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "teros_principal__content__table__body__table__options__item",
                                                              attrs: {
                                                                "data-toggle":
                                                                  "modal",
                                                                "data-target":
                                                                  "#deleteModal",
                                                                "data-placement":
                                                                  "top",
                                                                title:
                                                                  "Deletar",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.prepareRemove(
                                                                      item.hash,
                                                                      _vm.index
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._m(0, true)]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.newObj
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "teros_principal__content__table__footer",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "teros_principal__content__table__footer__pagination",
                                      },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "teros_principal__content__table__footer__add-item",
                                            attrs: {
                                              to:
                                                "/transmissionconsentimentterm?transmissionConsentimentHash=" +
                                                _vm.transmissionconsentiment
                                                  .hash,
                                              title: "Novo Termo",
                                            },
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: "/static/img/icons/plus.png",
                                                alt: "",
                                              },
                                            }),
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "views.shared.includeButton"
                                                )
                                              ) + "\n                  "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "form-group row",
                staticStyle: { padding: "0px 40px 40px" },
              },
              [
                _c(
                  "div",
                  { staticClass: "col", staticStyle: { "text-align": "left" } },
                  [
                    _c("div", [
                      _c(
                        "div",
                        [
                          _c("pdf-view", {
                            attrs: {
                              "file-name":
                                _vm.transmissionconsentiment.fileName,
                              type: "button",
                              title: _vm.$t(
                                "views.client.transmission.transmissionconsentiment.consent.content.consentButtons.viewConsent"
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "col",
                    staticStyle: { "text-align": "right" },
                  },
                  [
                    _c("div", [
                      _c("div", [
                        _c(
                          "button",
                          {
                            staticClass:
                              "teros_principal__content__column__info__form__table__plus mb0",
                            staticStyle: { margin: "0" },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "/static/img/icons/disquete.png",
                                alt: "",
                              },
                            }),
                            _vm._v(
                              _vm._s(_vm.$t("views.shared.saveButton")) +
                                "\n              "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticStyle: { clear: "both" } }),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal fade teros-modal",
          attrs: {
            id: "deleteModal",
            tabindex: "-1",
            "aria-labelledby": "deleteModalLabel",
            "aria-hidden": "true",
          },
        },
        [
          _c("div", { staticClass: "modal-dialog" }, [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(1),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("views.shared.modal.delete.confirmMessage")) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button", "data-dismiss": "modal" },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t("views.shared.modal.delete.cancelButton")
                        ) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-primary--red",
                    attrs: { type: "button", "data-dismiss": "modal" },
                    on: { click: _vm.remove },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t("views.shared.modal.delete.deleteButton")
                        ) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", { attrs: { src: "/static/img/icons/deletar.svg", alt: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        { staticClass: "modal-title", attrs: { id: "deleteModalLabel" } },
        [
          _c("img", {
            attrs: { src: "/static/img/icons/atencao-modal-red.png", alt: "" },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }