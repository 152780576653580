var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "teros_principal__content interna container" },
    [
      _c("div", { staticClass: "teros_principal__content__line-1 line" }, [
        _c(
          "div",
          { staticClass: "teros_principal__content__line-1__highlights" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__line-1__highlights__subject",
              },
              [
                _c("router-link", { attrs: { to: "/categories" } }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "teros_principal__content__line-1__highlights__subject__breadcrumb",
                    },
                    [
                      _c(
                        "h3",
                        {
                          staticClass:
                            "teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle",
                        },
                        [
                          _vm._v(
                            "\n              Teros Openbanking\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "h2",
                        {
                          staticClass:
                            "teros_principal__content__line-1__highlights__subject__breadcrumb__title",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("views.shared.menu.config")) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "teros_principal__arrow" }),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: "/resourcetype?hash=" + _vm.resourceType.hash,
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "teros_principal__content__line-1__highlights__subject__breadcrumb",
                      },
                      [
                        _c(
                          "h3",
                          {
                            staticClass:
                              "teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t(
                                    "views.teros.integrations.service.content.general.resourceType"
                                  )
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "h2",
                          {
                            staticClass:
                              "teros_principal__content__line-1__highlights__subject__breadcrumb__title",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.resourceType.name) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "teros_principal__arrow" }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__line-1__highlights__subject__breadcrumb",
                  },
                  [
                    _c(
                      "h3",
                      {
                        staticClass:
                          "teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "views.teros.config.resourceType.content.permissions.title"
                              )
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.newObj
                      ? _c(
                          "h2",
                          {
                            staticClass:
                              "teros_principal__content__line-1__highlights__subject__breadcrumb__title active",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "views.teros.config.permission.newObject"
                                  )
                                ) +
                                "\n          "
                            ),
                          ]
                        )
                      : _c(
                          "h2",
                          {
                            staticClass:
                              "teros_principal__content__line-1__highlights__subject__breadcrumb__title active",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.permission.name) +
                                "\n          "
                            ),
                          ]
                        ),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "teros_principal__content__line-1__information" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__line-1__information__time",
              },
              [
                !_vm.newObj
                  ? _c(
                      "p",
                      {
                        staticClass:
                          "teros_principal__content__line-1__information__time__text",
                      },
                      [
                        _c("img", {
                          staticClass:
                            "teros_principal__content__line-1__information__time__text__icon",
                          attrs: {
                            src: "/static/img/icons/relogio.png",
                            alt: "",
                          },
                        }),
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t("views.shared.createdDate", {
                                date: _vm.creationDate,
                                time: _vm.creationTime,
                              })
                            ) +
                            "\n          " +
                            _vm._s(
                              _vm.lastUpdateDate
                                ? "| " +
                                    _vm.$t("views.shared.changedDate", {
                                      date: _vm.lastUpdateDate,
                                      time: _vm.lastUpdateTime,
                                    })
                                : ""
                            ) +
                            "\n        "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "teros_principal__content__line-1__information__time__btn",
                    attrs: { type: "button" },
                    on: { click: _vm.goBack },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "/static/img/icons/seta-esquerda.png",
                        alt: "",
                      },
                    }),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("views.shared.backButton")) +
                        "\n        "
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { id: "accordion" } }, [
        _c(
          "div",
          { staticClass: "teros_principal__content__accordion__card" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__accordion__card__header accordion-off",
                attrs: { id: "headingOne" },
              },
              [
                _c(
                  "h2",
                  {
                    staticClass:
                      "teros_principal__content__accordion__card__header__title",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "teros_principal__content__accordion__card__header__title__btn",
                        attrs: {
                          type: "button",
                          "aria-expanded": "false",
                          "aria-controls": "collapseOne",
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t("views.teros.config.permission.title")
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("hr"),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__accordion__card__header__collapse",
                attrs: { id: "collapseOne" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__accordion__card__header__collapse__body",
                  },
                  [
                    _c(
                      "form",
                      {
                        staticClass:
                          "teros_principal__content__accordion__card__header__collapse__body__form",
                        attrs: { method: "post" },
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.createOrUpdate.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "label",
                            { attrs: { for: "formGroupExampleInput" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "views.teros.config.permission.content.edit.name"
                                  )
                                ) + "*\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.permission.name,
                                expression: "permission.name",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              required: "",
                              id: "formGroupExampleInput",
                            },
                            domProps: { value: _vm.permission.name },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.permission,
                                  "name",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c(
                          "label",
                          { attrs: { for: "formGroupEndpointInput" } },
                          [_vm._v("\n              Endpoints*\n            ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "action-fields",
                            attrs: { href: "javascript:void(null)" },
                            on: { click: _vm.addPermissionEndpoint },
                          },
                          [_vm._v("+ " + _vm._s(_vm.$t("views.shared.add")))]
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.endpoints, function (endpoint, index) {
                          return _c("div", { staticClass: "form-group row" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.endpoints[index].endpoint,
                                  expression: "endpoints[index].endpoint",
                                },
                              ],
                              staticClass: "form-control col-11 mb-3",
                              attrs: {
                                type: "text",
                                required: "",
                                id: "formGroupEndpontInput",
                              },
                              domProps: {
                                value: _vm.endpoints[index].endpoint,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.endpoints[index],
                                    "endpoint",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-1" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "row display-flex justify-content-center align-items-center",
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "action-fields",
                                      attrs: { href: "javascript:void(null)" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.prepareRemoveEndpointPermission(
                                            index
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: "/static/img/icons/deletar.svg",
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "custom-control custom-switch ml-3",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.endpoints[index].active,
                                            expression:
                                              "endpoints[index].active",
                                          },
                                        ],
                                        staticClass: "custom-control-input",
                                        attrs: {
                                          type: "checkbox",
                                          id: "customSwitch" + index,
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.endpoints[index].active
                                          )
                                            ? _vm._i(
                                                _vm.endpoints[index].active,
                                                null
                                              ) > -1
                                            : _vm.endpoints[index].active,
                                        },
                                        on: {
                                          change: [
                                            function ($event) {
                                              var $$a =
                                                  _vm.endpoints[index].active,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      _vm.endpoints[index],
                                                      "active",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      _vm.endpoints[index],
                                                      "active",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(
                                                  _vm.endpoints[index],
                                                  "active",
                                                  $$c
                                                )
                                              }
                                            },
                                            function ($event) {
                                              return _vm.changeStatusEndpointPermission(
                                                index
                                              )
                                            },
                                          ],
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("label", {
                                        staticClass: "custom-control-label",
                                        attrs: { for: "customSwitch" + index },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ])
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group align-right" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "teros_principal__content__column__info__form__table__plus mb0",
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "/static/img/icons/disquete.png",
                                  alt: "Salvar",
                                },
                              }),
                              _vm._v(
                                _vm._s(_vm.$t("views.shared.saveButton")) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ]),
                      ],
                      2
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }