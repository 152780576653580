<template>
  <div class="teros_principal">
    <div class="teros_principal">
      <div class="teros_principal__login">
        <div
          class="
            teros_principal__login__column
            teros_principal__login__column--fields
          "
        >
          <div class="center">
            <img
              src="/static/img/logo_login.png"
              alt=""
              class="teros_principal__login__column__logo"
            />
          </div>

          <div class="teros_principal__login__column__form__highlights">
            <p class="teros_principal__login__column__form__highlights__text">
              {{ $t("views.shared.titleSystem") }} <br />
              <span>Teros Open Banking</span>
            </p>
          </div>
          <form @submit.prevent="login">
            <div class="teros_principal__login__column__form__fields">
              <div class="form-group">
                <input
                  type="text"
                  placeholder="Login"
                  v-model="username"
                  class="
                    form-control
                    teros_principal__login__column__form__fields__input
                    text-field
                  "
                />
              </div>
              <div class="form-group">
                <input-password
                  :placeholder="$t('views.login.password')"
                  :password="password"
                  @onChange="password = $event"
                ></input-password>
              </div>
              <div class="form-group">
                <div class="form-check">
                  <input
                    type="checkbox"
                    id="gridCheck"
                    class="form-check-input"
                    v-model="keepConected"
                  />
                  <label for="gridCheck" class="form-check-label">
                    {{ $t("views.login.checkboxStayConnected") }}
                  </label>
                </div>
              </div>
              <button type="submit" class="btn btn-primary">
                {{ $t("views.login.sendButton") }}
              </button>

              <hr style="margin: 25px 0" v-if="ssoIssuer" />

              <button
                v-if="ssoIssuer"
                style="width: 300px; margin-top: 0px"
                type="button"
                class="btn btn-primary"
                @click="SSOsignin()"
              >
                Single Sign-On Login
              </button>
            </div>
          </form>
          <router-link
            class="teros_principal__login__column__form__obs"
            to="/forgotpassword"
            >{{ $t("views.login.forgotPwdLink") }}</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cognito, router } from "@/_helpers";
import InputPassword from "@/_components/InputPassword.vue";
import { CognitoUserPool } from 'amazon-cognito-identity-js';

export default {
  data() {
    return {
      username: "",
      password: "",
      keepConected: false,
      returnUrl: "",
      ssoIssuer: null,
    };
  },
  components: {
    InputPassword,
  },
  created() {
    this.returnUrl = this.$route.query.returnUrl || "/";
    this.ssoIssuer =
      this.$route.query.ssoIssuer || this.$cookies.get("ssoIssuer");
    var data = {
      UserPoolId: USERPOOLID,
      ClientId: CLIENTID,
    };
    var userPool = new CognitoUserPool(data);
    var cognitoUser = userPool.getCurrentUser();
    if (cognitoUser != null) {
      return router.push("/");
    }
  },
  methods: {
    login() {
      localStorage.setItem("keepConnected", this.keepConected);
      cognito.authenticate(
        this.username,
        this.password,
        USERPOOLID,
        CLIENTID,
        this.returnUrl
      );
    },
    SSOsignin() {
      localStorage.setItem("keepConnected", this.keepConected);
      this.$cookies.set("ssoIssuer", this.ssoIssuer, "365d");
      cognito.ssoAuthenticationRedirect(this.ssoIssuer);
    },
  },
};
</script>