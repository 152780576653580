<template>
  <div class="teros_principal__content container">
    <form @submit.prevent="makeTextSearch">
      <div class="teros_principal__content__table">
        <div class="teros_principal__content__table__header">
          <div class="teros_principal__content__table__header__filters">
            <div class="form-group">
              <div class="search-group">
                <input
                  type="text"
                  class="input-field"
                  :placeholder="$t('views.shared.searchBar.searchLabel')"
                  maxlength="30"
                  v-model="textSearchValue"
                />
                <button class="search-button" />
              </div>
            </div>
            <itens-per-page
              :request-pagination="requestPagination"
              :callback="makeTextSearch"
            ></itens-per-page>
          </div>

          <div class="teros_principal__content__table__header__actions">
            <!--<a href="#" title="Download"
              ><img src="/static/img/icons/download.png" alt="Download"
            /></a>
            <a href="#" title="Imprimir"
              ><img src="/static/img/icons/printer.png" alt="Imprimir"
            /></a> -->
          </div>
        </div>

        <div class="teros_principal__content__table__body">
          <table
            id="table"
            class="teros_principal__content__table__body__table"
            data-toggle="table"
            data-height="600"
            data-url=""
          >
            <thead>
              <tr>
                <th data-field="id" data-sortable="true">ID</th>
                <th data-field="a">
                  {{
                    $t("views.teros.authorizations.list.table.header.client")
                  }}
                </th>
                <th data-field="b">
                  {{
                    $t("views.teros.authorizations.list.table.header.reception")
                  }}
                </th>
                <th data-field="c">
                  {{
                    $t("views.teros.authorizations.list.table.header.service")
                  }}
                </th>
                <th data-field="d">
                  {{
                    $t("views.teros.authorizations.list.table.header.authDate")
                  }}
                </th>
                <th data-field="e">
                  {{
                    $t(
                      "views.teros.authorizations.list.table.header.changeDate"
                    )
                  }}
                </th>
                <th data-field="g">Status</th>
                <th data-field="h" style="text-align: center">
                  {{
                    $t("views.shared.table.header.options")
                  }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in authorizations" :key="item.hash">
                <td>{{ index + 1 }}</td>
                <td>{{ item.customerName }}</td>
                <td>{{ item.integrationName }}</td>
                <td>{{ item.serviceName }}</td>
                <td>{{ item.responseDate | formatDate }}</td>
                <td>{{ item.lastUpdateDate | formatDate }}</td>
                <td :class="item.status | formatClass">
                  {{ statusTranslated(item.status) }}
                </td>

                <td style="text-align: center; width: 100px">
                  <div
                    class="teros_principal__content__table__body__table__options"
                  >
                    <router-link
                      :to="'/authorization?hash=' + item.hash"
                      class="teros_principal__content__table__body__table__options__item"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Visualizar/Editar"
                    >
                      <div>
                        <img src="/static/img/icons/editar.svg" alt="" />
                      </div>
                    </router-link>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="teros_principal__content__table__footer">
          <div
            v-if="loading"
            class="teros_principal__content__table__footer__loader"
          >
            <img src="/static/img/icons/ajax-loader.gif" alt="" />
            <span>{{ $t("views.shared.loadingResults") }}</span>
          </div>
          <pagination
            :request-pagination="requestPagination"
            :callback="makeTextSearch"
            :current-pagination-info="currentPaginationInfo"
          ></pagination>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { http } from "@/_helpers";
import Pagination from "@/_components/Pagination.vue";
import ItensPerPage from "@/_components/ItensPerPage.vue";
import moment from 'moment';

export default {
  data() {
    return {
      authorizations: [],
      loading: true,
      textSearchValue: "",
      oldTextSearchValue: "",
      currentPaginationInfo: null,
      requestPagination: { page: 1, take: 10 },
    };
  },
  components: {
    Pagination,
    ItensPerPage,
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
    formatClass(value) {
      if (value == 1) {
        return "compativel";
      } else if (value == 2) {
        return "pendente";
      } else if (value == 3) {
        return "nao-compativel";
      }
      return "";
    },
  },
  mounted() {
    this.$emit("message", this.$route);
    http
      .get(
        "/api/authorization/search?OrderByProperty=AuthorizationStatus",
        false
      )
      .then((response) => {
        this.authorizations = response.data.items;
        this.currentPaginationInfo = response.data;
      })
      .finally(() => (this.loading = false));
  },
  methods: {
    statusTranslated(value) {
      if (value == 1) {
        return this.$i18n.t(
          "views.teros.authorizations.list.table.header.status.compatible"
        );
      } else if (value == 2) {
        return this.$i18n.t(
          "views.teros.authorizations.list.table.header.status.pending"
        );
      } else if (value == 3) {
        return this.$i18n.t(
          "views.teros.authorizations.list.table.header.status.notCompatible"
        );
      } else {
        return "";
      }
    },
    makeTextSearch(event, isChangePage = false) {
      this.CheckValuesChanged(isChangePage);

      if (this.textSearchValue.trim()) {
        http
          .get(
            `/api/authorization/search?FilterProperty=Name&FilterValue=${this.textSearchValue.trim()}&Page=${
              this.requestPagination.page
            }&Take=${
              this.requestPagination.take
            }&OrderByProperty=AuthorizationStatus`
          )
          .then((response) => {
            this.authorizations = response.data.items;
            this.currentPaginationInfo = response.data;
          })
          .finally(() => (this.loading = false));
      } else {
        http
          .get(
            `/api/authorization/search?Page=${this.requestPagination.page}&Take=${this.requestPagination.take}&OrderByProperty=AuthorizationStatus`
          )
          .then((response) => {
            this.authorizations = response.data.items;
            this.currentPaginationInfo = response.data;
          })
          .finally(() => (this.loading = false));
      }
    },
    CheckValuesChanged(isChangePage = false) {
      if (this.textSearchValue != this.oldTextSearchValue) {
        if (isChangePage) {
          this.textSearchValue = this.oldTextSearchValue;
        } else {
          this.requestPagination.page = 1;
          this.oldTextSearchValue = this.textSearchValue;
        }
      }
    },
  },
};
</script>