<template>
  <div class="teros_principal__content interna container">
    <div class="teros_principal__content__line-1 line">
      <div class="teros_principal__content__line-1__highlights">
        <div class="teros_principal__content__line-1__highlights__subject">
          <div
            class="
              teros_principal__content__line-1__highlights__subject__breadcrumb
            "
          >
            <router-link to="/receptionlistconsentiments">
              <h3
                class="
                  teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle
                "
              >
                {{ $t("views.client.reception.list.client") }}
              </h3>
              <h2
                class="
                  teros_principal__content__line-1__highlights__subject__breadcrumb__title
                "
              >
                {{ currentUser.customerName }}
              </h2>
            </router-link>
          </div>
          <div class="teros_principal__arrow"></div>
          <div
            class="
              teros_principal__content__line-1__highlights__subject__breadcrumb
            "
          >
            <h3
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle
              "
            >
              {{ $t("views.client.reception.receptionlistconsentiment.title") }}
            </h3>
            <h2
              v-if="newObj"
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__title
                active
              "
            >
              {{ $t("views.client.reception.receptionlistconsentiment.newObject") }}
            </h2>
            <h2
              v-else
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__title
                active
              "
            >
              {{ receptionlistconsentiment.name }}
            </h2>
          </div>
        </div>
      </div>
      <div class="teros_principal__content__line-1__information">
        <div class="teros_principal__content__line-1__information__time">
          <button type="button" @click="goBack" class="teros_principal__content__line-1__information__time__btn">
            <img src="/static/img/icons/seta-esquerda.png" alt="" />
            {{ $t("views.shared.backButton") }}
          </button>
        </div>
      </div>
    </div>

    <div class="teros_principal__content__column">
      <!--Content-->
      <div class="teros_principal__content__column__info">
        <div id="accordion">
          <form @submit.prevent="createOrUpdate" method="post">


            <div class="teros_principal__content__accordion__card">
              <div class="teros_principal__content__accordion__card__header" id="headingTwo">
                <h2 class="teros_principal__content__accordion__card__header__title">
                  <button
                    class="
                    teros_principal__content__accordion__card__header__title__btn
                  "
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    {{ $t("views.client.reception.receptionconsentiment.general.title") }}
                  </button>
                  <hr />
                </h2>
              </div>

              <div id="collapseTwo" class="teros_principal__content__accordion__card__header__collapse">
                <div
                  class="
                  teros_principal__content__accordion__card__header__collapse__body
                  pt0
                "
                  style="padding: 0 40px"
                >

                <div class="teros_principal__content__table">
                  <div class="form-group" style="padding-top: 40px">
                  <div class="custom-control custom-switch">
                    <!-- talvez adicionar checkbox para definir se está ativo ou não v-model="receptionconsentiment.active" -->
                    <input type="checkbox" checked="" class="custom-control-input" id="customSwitch1"  />
                    <label class="custom-control-label" for="customSwitch1">{{ $t("views.shared.activeSwitch") }}</label>
                  </div>
                  </div>
                  
                  <div class="form-group row">
                  <div class="col">
                    <label for="formGroupExampleInput3">{{ $t("views.client.reception.receptionconsentiment.general.content.consentName") }}*</label>
                    <custom-input :id="'formGroupExampleInput3'" ></custom-input>
                    <!-- adicioanr v-model linha de cima -->
                    
                  </div>
                  <div v-if="!newObj" class="col">
                    <label for="formGroupExampleInput4"
                      >ReceptionConsentimentHash
                      <img src="/static/img/icons/tooltip.png" data-toggle="tooltip" data-placement="right" :title="$t('views.client.reception.receptionconsentiment.toolTip.ReceptionConsentHash')" />
                    </label>
                    <input type="text" class="form-control" id="formGroupExampleInput4"  readonly />
                    <!-- v-model="receptionconsentiment.hash" -->
                  </div>
                  </div>
                  <br />
                </div>        
                </div>
              </div>
            </div>
            

            <div class="teros_principal__content__accordion__card">
              <div class="teros_principal__content__accordion__card__header" id="headingThree">
                <h2 class="teros_principal__content__accordion__card__header__title">
                  <button
                    class="
                    teros_principal__content__accordion__card__header__title__btn
                  "
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    {{ $t("views.client.reception.receptionlistconsentiment.consent.title") }}
                  </button>
                  <hr />
                </h2>
              </div>

              <div id="collapseThree" class="teros_principal__content__accordion__card__header__collapse">
                <div
                  class="
                  teros_principal__content__accordion__card__header__collapse__body
                  pt0
                "
                  style="padding: 0 40px"
                >
                    <div class="teros_principal__content__table">
                      <div class="form-group row" style="padding-top: 40px">
                      <div class="col">
                        <!-- <live-select
                          :title="$t('views.client.reception.receptionconsentiment.consent.content.requiredServices')"
                          id="selectRequiredServices"
                          multiple
                          :options="services"
                          :hashs="receptionconsentiment.requiredReceptionServices"
                          @onChange="receptionconsentiment.requiredReceptionServices = $event"
                        ></live-select> -->
                        Placeholder: Select
                      </div>
                      <div class="col">
                        <!-- <live-select
                          :title="$t('views.client.reception.receptionconsentiment.consent.content.optionalServices')"
                          id="selectOptionalServices"
                          multiple
                          :options="services"
                          :hashs="receptionconsentiment.optionalReceptionServices"
                          @onChange="receptionconsentiment.optionalReceptionServices = $event"
                        ></live-select> -->
                        Placeholder: Select 
                      </div>
                      </div>


                      <div class="form-group row" style="padding-top: 40px">
                        <div class="col">
                          <label for="formGroupExampleInput5">
                            {{ $t("views.client.reception.receptionlistconsentiment.consent.content.dataEscrowLimit") }}*
                            <img src="/static/img/icons/tooltip.png" data-toggle="tooltip" data-placement="right" :title="$t('views.client.reception.receptionlistconsentiment.toolTip.dataEscrowLimit')"
                            /></label>
                          <div class="input-group">
                            <!-- custom-input component -->
                            <custom-input :id="'formGroupExampleInput5'" :type="'number'" :regex="'[0-9]'" v-model="receptionlistconsentiment.dataEscrowLimit" style="width: 60%; margin-right: 5px;"></custom-input>
                            <!-- select dropdown -->
                            <select class="custom-select" v-model="unit" @change="handleDurationChange" style="width: 30%; border-radius: 3px;">
                              <option value="days">Dias</option>
                              <option value="months">Meses</option>
                              <option value="years">Anos</option>
                            </select>
                          </div>
                        </div>

                        <div class="col">
                          <label for="formGroupExampleInput6"
                            >{{ $t("views.client.reception.receptionlistconsentiment.consent.content.expiration") }}*
                            <img src="/static/img/icons/tooltip.png" data-toggle="tooltip" data-placement="right" :title="$t('views.client.reception.receptionlistconsentiment.toolTip.Expiration')"
                          /></label>
                          <custom-input :id="'formGroupExampleInput5'" :type="'number'" :regex="'[0-9]'" v-model="receptionlistconsentiment.expiration"></custom-input>
                        </div>
                    </div>


                    <!-- finalidades / purpose-->
                    <div class="form-group row">
                      <div class="col">
                        <label for="formGroupExampleInput7">{{ $t("views.client.reception.receptionlistconsentiment.consent.content.purposes") }}* </label>
                        <a href="javascript:void(null)" @click="addPurpose" class="action-fields">+ {{ $t("views.shared.add") }}</a>
                        <div v-for="(item, counter) in purposes" :key="counter">
                          <input type="text" class="form-control" v-model="item.name" />
                          <a href="javascript:void(null)" @click="removePurpose(counter)" class="action-fields">- {{ $t("views.shared.remove") }}</a>
                        </div>
                      </div>
                    </div>

                    <br />

                    <div class="form-group row">
                      <div class="col">
                        <a
                          href="javascript:void(null)"
                          @click="showOrHideTiny"
                          class="
                          show_tiny
                          teros_principal__content__column__info__form__combo__btn-contrato
                        "
                          style="width: 270px"
                        >
                          {{ $t("views.client.reception.receptionlistconsentiment.consent.content.consentButtons.editConsentLayout") }}
                        </a>
                      </div>
                    </div>
                    <div v-if="showTiny" class="form-group row">
                      <div class="col">
                        <editor api-key="no-api-key" v-model="receptionlistconsentiment.layout" />
                      </div>
                    </div>

                    <div v-if="!newObj" class="teros_principal__content__table__body">
                      <table id="table" class="teros_principal__content__table__body__table" data-toggle="table" data-height="600" data-url="">
                        <thead>
                          <tr>
                            <th data-field="id" data-sortable="true">
                              {{ $t("views.client.reception.receptionlistconsentiment.consent.content.table.header.orderNumber") }}
                            </th>
                            <th data-field="a">
                              {{ $t("views.client.reception.receptionlistconsentiment.consent.content.table.header.terms") }}
                            </th>
                            <th data-field="d">
                              {{ $t("views.shared.table.header.createdAt") }}
                            </th>
                            <th data-field="e">
                              {{ $t("views.shared.table.header.changedAt") }}
                            </th>
                            <th data-field="f" style="text-align: center">
                              {{ $t("views.shared.table.header.options") }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in receptionlistconsentiment.termsList" :key="item.hash">
                            <td>{{ item.orderNumber }}</td>
                            <td>{{ item.name }}</td>

                            <td>{{ item.creationDate | formatDate }}</td>
                            <td>{{ item.lastUpdateDate | formatDate }}</td>

                            <td style="text-align: center">
                              <div
                                class="
                                teros_principal__content__table__body__table__options
                                mult-opt
                              "
                              >
                                <router-link
                                  :to="'/receptionconsentimentterm?receptionconsentimentHash=' + receptionlistconsentiment.hash + '&hash=' + item.hash"
                                  class="
                                  teros_principal__content__table__body__table__options__item
                                "
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Visualizar/Editar"
                                >
                                  <div>
                                    <img src="/static/img/icons/editar.svg" alt="" />
                                  </div>
                                </router-link>

                                <a
                                  @click="prepareRemove(item.hash, index)"
                                  class="
                                  teros_principal__content__table__body__table__options__item
                                "
                                  data-toggle="modal"
                                  data-target="#deleteModal"
                                  data-placement="top"
                                  title="Deletar"
                                >
                                  <div>
                                    <img src="/static/img/icons/deletar.svg" alt="" />
                                  </div>
                                </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div v-if="!newObj" class="teros_principal__content__table__footer">
                      <div class="teros_principal__content__table__footer__pagination">
                        <router-link
                          :to="'/receptionconsentimentterm?receptionconsentimentHash=' + receptionlistconsentiment.hash"
                          title="Novo Termo"
                          class="teros_principal__content__table__footer__add-item"
                        >
                          <img src="/static/img/icons/plus.png" alt="" />{{ $t("views.shared.includeButton") }}
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row" style="padding: 0px 40px 40px">
              <div class="col" style="text-align: left">
                <div>
                  <div>
                    <pdf-view
                      :file-name="receptionlistconsentiment.fileName"
                      :type="'button'"
                      :title="$t('views.client.reception.receptionlistconsentiment.consent.content.consentButtons.viewConsent')"
                    ></pdf-view>
                  </div>
                </div>
              </div>
              <div class="col" style="text-align: right">
                <div>
                  <div>
                    <button
                      style="margin: 0"
                      class="
                      teros_principal__content__table__footer__add-item
                    "
                      v-bind:class="{ disabled: isDisabled }"
                      v-bind:disabled="isDisabled"
                    >
                      <img src="/static/img/icons/disquete.png" alt="" />{{ $t("views.shared.saveButton") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div style="clear: both"></div>
          </form>
        </div>
      </div>
    </div>

    <div class="modal fade teros-modal" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteModalLabel">
              <img src="/static/img/icons/atencao-modal-red.png" alt="" />
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            {{ $t("views.shared.modal.delete.confirmMessage") }}
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              {{ $t("views.shared.modal.delete.cancelButton") }}
            </button>
            <button type="button" class="btn btn-primary btn-primary--red" data-dismiss="modal" @click="remove">
              {{ $t("views.shared.modal.delete.deleteButton") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { cognito, http, router } from "@/_helpers";
import Editor from "@tinymce/tinymce-vue";
import PdfView from "@/_components/PdfView.vue";

import CustomInput from "@/_components/CustomInput.vue";
import moment from "moment";

export default {
  data() {
    return {
      currentUser: cognito.currentUserValue,
      newObj: true,
      receptionlistconsentiment: {
        active: true,
        layout: "<p>{{Termos}} {{Servicos}} {{Finalidades}} {{Validade}} {{PrazoCustodiaDeDados}} </p>",
      },
      termHash: null,
      purposes: [],
      services: [],
      index: 0,
      loading: true,
      showTiny: false,
      isDisabled: false,
      customer: {},
    };
  },
  components: {
    Editor,
    PdfView,
    CustomInput,
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
  },
  computed: {
    creationDate() {
      if (this.receptionlistconsentiment.creationDate) {
        return moment(String(this.receptionlistconsentiment.creationDate)).format("DD/MM/YYYY");
      }
      return "";
    },
    creationTime() {
      if (this.receptionlistconsentiment.creationDate) {
        return moment(String(this.receptionlistconsentiment.creationDate)).format("HH:mm");
      }
      return "";
    },
    lastUpdateDate() {
      if (this.receptionlistconsentiment.lastUpdateDate) {
        return moment(String(this.receptionlistconsentiment.lastUpdateDate)).format("DD/MM/YYYY");
      }
      return "";
    },
    lastUpdateTime() {
      if (this.receptionlistconsentiment.lastUpdateDate) {
        return moment(String(this.receptionlistconsentiment.lastUpdateDate)).format("HH:mm");
      }
      return "";
    },
  },
  mounted() {
    const hash = this.$route.query.hash;
    console.log("hash-->", hash);
    http.get("/api/customer/get/" + this.currentUser.customerHash).then((response) => {
      this.customer = response.data;
      this.customer.clientCertificate = {};
    });

    if (hash) {
      this.newObj = false;
      http
        .get(`/api/receptionconsentiment/generic/get/${hash}`)
        .then((response) => {
          this.receptionlistconsentiment = response.data;
          let purposesList = this.receptionlistconsentiment.purposes.split(",");
          purposesList.forEach((item) => {
            this.purposes.push({
              name: item,
            });
          });
          if (this.receptionlistconsentiment.name === null) {
            router.push({
              name: "receptionlistconsentimentspage",
              //query: { hash: this.receptionlistconsentiment.receptionHash },
            });
          }
        })
        .catch(() => {
          router.push({
            name: "receptionlistconsentimentspage",
            //query: { hash: this.receptionlistconsentiment.receptionHash },
          });
        });
    } else {
      // console.log(this.custumer.gatewayClientId)
      // http
      //   .get(`/api/receptionconsentiment/search/Generic-Consent-${this.custumer.gatewayClientId}`, false)
      //   .then((response) => {
      //     this.consentiments = response.data;
      //     this.currentPaginationInfo = response.data;
      //     if (this.consentiments.name == null) {
      //       this.loading = true;
      //       router.push({
      //         name: "receptionlistconsentimentspage",
      //         //query: { hash: this.receptionlistconsentiment.receptionHash },
      //       });
      //       Vue.$toast.error(this.$i18n.t("já existe um consentimento"));
      //       this.isDisabled = true;
      //     } else {
      //       this.loading = false;
      //       this.isDisabled = false;
      //     }
      //   })
      //   .catch(() => {
      //     this.loading = true;
      //     router.push({
      //       name: "receptionlistconsentimentspage",
      //       //query: { hash: this.receptionlistconsentiment.receptionHash },
      //     });
      //   })
      //   .finally(() => (this.loading = false));

      //this.receptionlistconsentiment.receptionHash = this.currentUser.customerHash;
      //this.receptionlistconsentiment.layout = "{{Termos}} {{Servicos}} {{Validade}}";
    }
  },
  methods: {
    goBack() {
      router.push({
        name: "receptionlistconsentimentspage",
        //query: { hash: this.receptionlistconsentiment.receptionHash },
      });
    },
    addPurpose() {
      this.purposes.push({
        name: "",
      });
    },
    removePurpose(counter) {
      this.purposes.splice(counter, 1);
    },

    createOrUpdate() {
      this.receptionlistconsentiment.purposes = this.purposes
        .filter((e) => e.name != "")
        .map((e) => e.name)
        .join("///");
      this.receptionlistconsentiment.name = this.customer.gatewayClientId;
      this.receptionlistconsentiment.hash ='444add2c-36e8-4fc0-839d-3e4dcfad8dc5'
      if (this.newObj) {
        http.post("/api/receptionconsentiment/generic/create", this.receptionlistconsentiment).then((response) => {
          this.receptionlistconsentiment = response.data;
          this.newObj = false;
          router.push("/receptionlistconsentiments?hash=" + this.customer.gatewayClientId);
          this.$emit("success");
        });
      } else {
        http.put("/api/receptionconsentiment/generic/update/", this.receptionlistconsentiment).then(() => {
          this.goBack();
          this.$emit("success");
        });
      }
    },
    prepareRemove(hash, index) {
      this.termHash = hash;
      this.index = index;
    },
    remove() {
      http.remove("/api/receptionconsentimentterm/generic/delete/" + this.termHash).then(() => {
        Vue.$toast.success(this.$i18n.t("views.client.reception.receptionlistconsentiment.consent.content.table.modal.deletedMessage"));
        Vue.delete(this.receptionlistconsentiment.termsList, this.index);
      });
    },
    showOrHideTiny() {
      this.showTiny = !this.showTiny;
    },
  },
};
</script>
