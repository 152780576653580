import Vue from 'vue';
import axios from 'axios';
import { storage, cognito, i18n } from '@/_helpers';

export const http = {
    get,
    post,
    put,
    remove
}

axios.interceptors.request.use(config => new Promise((resolve, reject) => {
    cognito.refreshToken(function (err, session) {
        if (err) {
            cognito.logout();
            reject(err);
        }
        else {
            storage.setItem('accessToken', session.getAccessToken().getJwtToken());
            storage.setItem('idToken', session.getIdToken().getJwtToken());
            config.headers.Authorization = 'Bearer ' + session.getIdToken().getJwtToken();
            resolve(config);
        }
    });
}));

function get(url, load = true) {
    if (load) {
        let loader = Vue.$loading.show();
        return axios
            .get(APIURL + url)
            .then(response => response)
            .catch(error => {
                handlerErros(error)
            }).finally(() => loader.hide());
    } else {
        return axios
            .get(APIURL + url)
            .then(response => response)
            .catch(error => {
                handlerErros(error)
            });
    }

}

function post(url, obj) {
    let loader = Vue.$loading.show();
    return axios
        .post(APIURL + url, obj)
        .then(response => response)
        .catch(error => {
            handlerErros(error)
        }).finally(() => loader.hide());
}

function put(url, obj) {
    let loader = Vue.$loading.show();
    return axios
        .put(APIURL + url, obj)
        .then(response => response)
        .catch(error => {
            handlerErros(error)
        }).finally(() => loader.hide());
}

function remove(url) {
    let loader = Vue.$loading.show();
    return axios
        .delete(APIURL + url)
        .then(response => response)
        .catch(error => {
            handlerErros(error)
        }).finally(() => loader.hide());
}

function handlerErros(error) {
    if (error.response != null) {
        if (error.response.status != 500 && error.response.data.error) {
            error.response.data.error.forEach((item) => {
                Vue.$toast.error(item)
            });
        }
        else if (error.response.status == 401) {
            cognito.logout();
        }
        else {
            console.log(error.response.data)
            Vue.$toast.error(i18n.t('views.shared.modal.error.internal'))
        }
    }
    else {
        Vue.$toast.error(i18n.t('views.shared.modal.error.connectionFail'))
    }
    throw (error)
}