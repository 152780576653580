<template>
  <div class="teros_principal">
    <div class="teros_principal">
      <div class="teros_principal__login">
        <div
          class="
            teros_principal__login__column
            teros_principal__login__column--fields
          "
        >
          <div class="center">
            <img
              src="/static/img/logo_login.png"
              alt=""
              class="teros_principal__login__column__logo"
            />
          </div>

          <div class="teros_principal__login__column__form__highlights">
            <p class="teros_principal__login__column__form__highlights__text">
              {{ $t("views.shared.titleSystem") }} <br />
              <span>Teros Open Banking</span><br /><br />
              {{ $t("views.forgotpassword.title") }} <br />
            </p>
          </div>
          <form
            v-if="sendEmail"
            @submit.prevent="sendEmailForgotPassword"
            class="teros_principal__login__column__form"
          >
            <div class="teros_principal__login__column__form__fields">
              <div class="form-group">
                <input
                  type="text"
                  class="
                    form-control
                    teros_principal__login__column__form__fields__input
                  "
                  id="email"
                  :placeholder="$t('views.forgotpassword.emailOrUsername')"
                  v-model="email"
                />
              </div>

              <div class="form-group">
                <div class="form-check">
                  <label class="form-check-label" for="gridCheck">
                    {{ $t("views.forgotpassword.instructions1") }}
                  </label>
                </div>
              </div>

              <button type="submit" class="btn btn-primary">
                {{ $t("views.forgotpassword.sendButton") }}
              </button>
            </div>

            <router-link
              class="teros_principal__login__column__form__obs"
              to="/login"
              >Login</router-link
            >
          </form>

          <form
            v-else
            @submit.prevent="confirmPassword"
            class="teros_principal__login__column__form"
            id="form_code"
          >
            <div class="teros_principal__login__column__form__fields">
              <div class="form-group">
                <input
                  type="number"
                  class="
                    form-control
                    teros_principal__login__column__form__fields__input
                  "
                  id="code"
                  :placeholder="$t('views.forgotpassword.code')"
                  v-model.number="code"
                />
              </div>
              <div class="form-group">
                <input-password
                  id="newPassword1"
                  :placeholder="$t('views.forgotpassword.newPassword')"
                  :password="newPassword1"
                  @onChange="newPassword1 = $event"
                ></input-password>
              </div>
              <div class="form-group">
                <input-password
                  id="newPassword2"
                  :placeholder="$t('views.forgotpassword.confirmation')"
                  :password="newPassword2"
                  @onChange="newPassword2 = $event"
                ></input-password>
              </div>

              <button type="submit" class="btn btn-primary">
                {{ $t("views.forgotpassword.resetButton") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { cognito } from "@/_helpers";
import InputPassword from "@/_components/InputPassword.vue";
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';

export default {
  data() {
    return {
      sendEmail: true,
      email: "",
      newPassword1: "",
      newPassword2: "",
      code: null,
      cognitoUser: {},
    };
  },
  components: {
    InputPassword,
  },
  methods: {
    sendEmailForgotPassword() {
      if (this.email != "") {
        var poolData = {
          UserPoolId: USERPOOLID,
          ClientId: CLIENTID,
        };
        var userPool = new CognitoUserPool(poolData);
        var userData = {
          Username: this.email,
          Pool: userPool,
        };
        this.cognitoUser = new CognitoUser(userData);
        this.cognitoUser.forgotPassword({
          inputVerificationCode: (result) => {
            this.sendEmail = false;
          },
          onFailure: (err) => {
            Vue.$toast.error(err.message);
          },
        });
      } else {
        Vue.$toast.error(
          this.$i18n.t("views.shared.modal.error.emailRequired")
        );
      }
    },
    confirmPassword() {
      if (this.code == "") {
        Vue.$toast.error(this.$i18n.t("views.shared.modal.error.codeRequired"));
      } else if (this.newPassword1 == "") {
        Vue.$toast.error(
          this.$i18n.t("views.shared.modal.error.newPasswordRequired")
        );
      } else if (!cognito.isPasswordValid(this.newPassword1)) {
        Vue.$toast.error(
          this.$i18n.t(
            "views.shared.modal.error.passwordPolicyValidationErrors"
          )
        );
      } else if (this.newPassword1 != this.newPassword2) {
        Vue.$toast.error(
          this.$i18n.t("views.shared.modal.error.passwordsDontMatch")
        );
      } else {
        this.cognitoUser.confirmPassword(String(this.code), this.newPassword1, {
          onSuccess: () => {
            cognito.authenticate(
              this.email,
              this.newPassword1,
              USERPOOLID,
              CLIENTID
            );
          },
          onFailure: (err) => {
            if (err.code == "CodeMismatchException") {
              Vue.$toast.error(
                this.$i18n.t("views.shared.modal.error.codeMismatch")
              );
            } else {
              Vue.$toast.error(err.message);
            }
          },
        });
      }
    },
  },
};
</script>>
