<template>
  <div class="teros_principal__content interna container">
    <div class="teros_principal__content__line-1">
      <div class="teros_principal__content__line-1__highlights">
        <div class="teros_principal__content__line-1__highlights__subject">
          <div class="teros_principal__content__line-1__highlights__subject__breadcrumb">
            <router-link to="/reception">
              <h3 class="teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle">
                {{ $t("views.client.reception.list.client") }}
              </h3>
              <h2 class="teros_principal__content__line-1__highlights__subject__breadcrumb__title">
                {{ currentUser.customerName }}
              </h2>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="teros_principal__content__column">
      <div class="teros_principal__content__column__menu-vertical">
        <ul class="teros_principal__content__column__menu-vertical__list">

          <li class="teros_principal__content__column__menu-vertical__list__item">
            <router-link to="/receptions" class="teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--lupa">{{
              $t("views.client.reception.reception.tabTitle")
            }}</router-link>
          </li>

          <!-- <li class="teros_principal__content__column__menu-vertical__list__item">
            <a href="#" class="teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--compliance active">{{
              $t("views.client.reception.receptionlistconsentiments.tabTitle")
            }}</a>
          </li>

          <li class="teros_principal__content__column__menu-vertical__list__item">
            <router-link to="/receptionlistgenericterms" class="teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--registros">{{
              $t("views.client.reception.receptionlistgenericterms.tabTitle")
            }}</router-link>
          </li> -->

        </ul>
      </div>

      <div class="teros_principal__content__column__info">
        <div class="teros_principal__content__table">
          <div class="teros_principal__content__table__body">
            <table id="table" class="teros_principal__content__table__body__table" data-toggle="table" data-height="600" data-url="">
              <thead>
                <tr>
                  <th data-field="id" data-sortable="true">ID</th>
                  <th data-field="a">
                    {{ $t("views.client.reception.receptionconsentiments.table.header.consent") }}
                  </th>

                  <th data-field="f" style="text-align: center">
                    {{ $t("views.shared.table.header.options") }}
                  </th>
                  <!-- <th data-field="e" style="text-align: center">
                    {{ $t("views.shared.table.header.deactivateActivate") }}
                  </th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in consentiments" :key="item.hash">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.name }}</td>

                  <td style="text-align: center">
                    <div class="teros_principal__content__table__body__table__options">
                      <router-link
                        :to="'/receptionlistconsentiment?hash=' + item.hash"
                        class="teros_principal__content__table__body__table__options__item"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Visualizar/Editar"
                      >
                        <div>
                          <img src="/static/img/icons/editar.svg" alt="" />
                        </div>
                      </router-link>

                      <!-- <a
                        @click="prepareRemove(item.hash, index)"
                        class="teros_principal__content__table__body__table__options__item"
                        data-toggle="modal"
                        data-target="#deleteModal"
                        data-placement="top"
                        title="Deletar"
                      >
                        <div>
                          <img src="/static/img/icons/deletar.svg" alt="" />
                        </div>
                      </a> -->
                    </div>
                  </td>
                  <!-- <td style="text-align: center">
                    <div class="custom-control custom-switch" data-toggle="tooltip" data-placement="top" title="Desativar/Ativar">
                      <input type="checkbox" class="custom-control-input" :id="'customSwitch' + index" v-model="item.active" @change="changeStatus(item.hash)" />
                      <label class="custom-control-label" :for="'customSwitch' + index"></label>
                    </div>
                  </td> -->
                </tr>
              </tbody>
            </table>
          </div>

          <div class="teros_principal__content__table__footer">
            <div v-if="loading" class="teros_principal__content__table__footer__loader">
              <img src="/static/img/icons/ajax-loader.gif" alt="" />
              <span>{{ $t("views.shared.loadingResults") }}</span>
            </div>

            <pagination :request-pagination="requestPagination" :callback="makeTextSearch" :current-pagination-info="currentPaginationInfo"></pagination>

            <div class="teros_principal__content__table__footer__pagination">
              <button class="teros_principal__content__table__footer__add-item" style="border:0; padding: 0px 0px" v-bind:class="{ disabled: isDisabled }" v-bind:disabled="isDisabled">
                <a v-if="!isDisabled" @click="goBack" style="color:white; width: 100%; border-radius:5px ;background: #EA6360 ;  padding: 15px 12px;"
                  ><img src="/static/img/icons/plus.png" alt="" />{{ $t("views.shared.includeButton") }}
                </a>
                <span style=" padding: 15px 12px; background-color: #c3c3c3; border-radius:5px ;" v-else><img src="/static/img/icons/plus.png" alt="" />{{ $t("views.shared.includeButton") }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade teros-modal" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteModalLabel">
              <img src="/static/img/icons/atencao-modal-red.png" alt="" />
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            {{ $t("views.shared.modal.delete.confirmMessage") }}
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              {{ $t("views.shared.modal.delete.cancelButton") }}
            </button>
            <button type="button" class="btn btn-primary btn-primary--red" data-dismiss="modal" @click="remove">
              {{ $t("views.shared.modal.delete.deleteButton") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { cognito, http, router } from "@/_helpers";
import Pagination from "@/_components/Pagination.vue";
import ItensPerPage from "@/_components/ItensPerPage.vue";
import moment from "moment";

export default {
  data() {
    return {
      currentUser: cognito.currentUserValue,
      receptionlistconsentiment: {},
      customer: {},
      consentiments: [],
      consentimentHash: null,
      index: 0,
      isDisabled: true,
      loading: true,
      textSearchValue: "",
      oldTextSearchValue: "",
      currentPaginationInfo: null,
      requestPagination: { page: 1, take: 10 },
    };
  },
  components: {
    Pagination,
    ItensPerPage,
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
  },
  computed: {
    creationDate() {
      if (this.receptionlistconsentiment.creationDate) {
        return moment(String(this.receptionlistconsentiment.creationDate)).format("DD/MM/YYYY");
      }
      return "";
    },
    creationTime() {
      if (this.receptionlistconsentiment.creationDate) {
        return moment(String(this.receptionlistconsentiment.creationDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("HH:mm");
      }
      return "";
    },
    lastUpdateDate() {
      if (this.receptionlistconsentiment.lastUpdateDate) {
        return moment(String(this.receptionlistconsentiment.lastUpdateDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
    lastUpdateTime() {
      if (this.receptionlistconsentiment.lastUpdateDate) {
        return moment(String(this.receptionlistconsentiment.lastUpdateDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("HH:mm");
      }
      return "";
    },
  },
  mounted() {
    this.$emit("message", this.$route);

    http
      .get("/api/customer/get/" + this.currentUser.customerHash)
      .then((response) => {
        this.customer = response.data;
        this.customer.clientCertificate = {};
      })
      .then(() => {
        http
          .get(`/api/receptionconsentiment/generic/search`)
          .then((response) => {
            console.log(response.data)
              if (response.data) {
              response.data.items.forEach(element => {
                this.consentiments.push(element);
              })
              this.isDisabled = false;
            } else {
          
              this.consentiments.push(response.data);
              this.isDisabled = true;
              this.loading = false;
            }
      
        })
          
          .catch(() => {
            this.isDisabled = true;
          })
          .finally(() => (this.loading = false));
      });

    // pega a listagem de receptions
    // mock for test
    // const consentimentMock = {
    //   name: "b52a5e16-1087-4f55-a3a7-f04d81760120",
    //   active: true,
    //   layout: "<p>{{Termos}} {{Servicos}} {{Finalidades}} {{Validade}} {{PrazoCustodiaDeDados}} </p>",
    //   expiration: 10,
    //   purposes: "john doe",
    //   dataEscrowLimit: 20,
    // };
    // this.consentiments.push(consentimentMock);
    // console.log(this.consentiments);
    // if (consentimentMock.name == null) {
    //   this.isDisabled = false;
    // } else {
    //   this.consentiments.push(consentimentMock);
    //   this.isDisabled = true;
    //   this.loading = false;
    // }

    // const hash = this.$route.query.hash;
  },
  methods: {
    makeTextSearch(event, isChangePage = false) {
      this.CheckValuesChanged(isChangePage);

      if (this.textSearchValue.trim()) {
        http
          .get(
            `/api/receptionlistconsentiment/search/${this.currentUser.customerHash}?FilterProperty=Name&FilterValue=${this.textSearchValue.trim()}&Page=${this.requestPagination.page}&Take=${
              this.requestPagination.take
            }`
          )
          .then((response) => {
            //this.consentiments = response.data.items;
            this.currentPaginationInfo = response.data;
          })
          .finally(() => (this.loading = false));
      } else {
        http
          .get(`/api/receptionconsentiment/search/${this.currentUser.customerHash}?Page=${this.requestPagination.page}&Take=${this.requestPagination.take}`)
          .then((response) => {
            //this.consentiments = response.data.items;
            this.currentPaginationInfo = response.data;
          })
          .finally(() => (this.loading = false));
      }
    },
    CheckValuesChanged(isChangePage = false) {
      if (this.textSearchValue != this.oldTextSearchValue) {
        if (isChangePage) {
          this.textSearchValue = this.oldTextSearchValue;
        } else {
          this.requestPagination.page = 1;
          this.oldTextSearchValue = this.textSearchValue;
        }
      }
    },
    goBack() {
      router.push({ name: "receptionlistconsentimentpage" });
    },
    changeStatus(hash) {
      http.get("/api/transmissionconsentiment/get/" + hash).then((response) => {
        let consentimentEdit = response.data;
        consentimentEdit.active = !consentimentEdit.active;
        http.put("/api/transmissionconsentiment/update/", consentimentEdit).then(() => {
          if (consentimentEdit.active) {
            Vue.$toast.success(this.$i18n.t("views.client.reception.receptionlistconsentiments.modal.activatedMessage"));
          } else {
            Vue.$toast.success(this.$i18n.t("views.client.reception.receptionlistconsentiments.modal.deactivatedMessage"));
          }
        });
      });
    },
    prepareRemove(hash, index) {
      this.consentimentHash = hash;
      this.index = index;
    },
    remove() {
      http.remove("/api/transmissionconsentiment/delete/" + this.consentimentHash).then(() => {
        Vue.$toast.success(this.$i18n.t("views.client.reception.receptionlistconsentiments.modal.deletedMessage"));
        // Vue.delete(this.consentiments, this.index);
      });
    },
  },
};
</script>
