export const storage = {
    getItem,
    setItem,
    clear
};

function getItem(key) {
    if (JSON.parse(localStorage.getItem("keepConnected"))) {
        return localStorage.getItem(key);
    }
    else {
        return sessionStorage.getItem(key);
    }
}

function setItem(key, value) {
    if (JSON.parse(localStorage.getItem("keepConnected"))) {
        return localStorage.setItem(key, value);
    }
    else {
        return sessionStorage.setItem(key, value);
    }
}

function clear() {
    if (JSON.parse(localStorage.getItem("keepConnected"))) {
        var lang = localStorage.getItem("lang");
        localStorage.clear();
        localStorage.setItem("lang", lang);
    }
    else {
        sessionStorage.clear();
    }
}