var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "select",
    {
      staticClass: "teros_principal__header__menu__languages__select",
      domProps: { value: _vm.currentLang },
      on: { change: _vm.changeLanguage },
    },
    _vm._l(_vm.supportedLanguages, function (lang) {
      return _c(
        "option",
        {
          key: lang.id,
          staticClass:
            "teros_principal__header__menu__languages__select__option",
          domProps: { value: lang.id },
        },
        [_vm._v("\n    " + _vm._s(lang.name) + "\n  ")]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }