<template>
  <div class="teros_principal__content interna container">
    <div class="teros_principal__content__line-1 line">
      <div class="teros_principal__content__line-1__highlights">
        <div class="teros_principal__content__line-1__highlights__subject">
          <router-link to="/categories">
            <div class="
                teros_principal__content__line-1__highlights__subject__breadcrumb
              ">
              <h3 class="
                  teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle
                ">
                Teros Openbanking
              </h3>
              <h2 class="
                  teros_principal__content__line-1__highlights__subject__breadcrumb__title
                ">
                {{ $t("views.shared.menu.config") }}
              </h2>
            </div>
          </router-link>
          <div class="teros_principal__arrow"></div>
          <div class="
              teros_principal__content__line-1__highlights__subject__breadcrumb
            ">
            <h3 class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle
              ">
              {{ $t("views.teros.config.resourceTypes.tabTitle") }}
            </h3>
            <h2 v-if="newObj" class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__title
                active
              ">
              {{ $t("views.teros.config.resourceType.newObject") }}
            </h2>
            <h2 v-else class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__title
                active
              ">
              {{ resourceType.name }}
            </h2>
          </div>
        </div>
      </div>

      <div class="teros_principal__content__line-1__information">
        <div class="teros_principal__content__line-1__information__time">
          <p v-if="!newObj" class="teros_principal__content__line-1__information__time__text">
            <img class="
                teros_principal__content__line-1__information__time__text__icon
              " src="/static/img/icons/relogio.png" alt="" />
            {{
              $t("views.shared.createdDate", {
                date: creationDate,
                time: creationTime,
              })
            }}
            {{
              lastUpdateDate
              ? "| " +
              $t("views.shared.changedDate", {
                date: lastUpdateDate,
                time: lastUpdateTime,
              })
              : ""
            }}
          </p>

          <button type="button" @click="goBack" class="teros_principal__content__line-1__information__time__btn">
            <img src="/static/img/icons/seta-esquerda.png" alt="" />
            {{ $t("views.shared.backButton") }}
          </button>
        </div>
      </div>
    </div>

    <div id="accordion">
      <div class="teros_principal__content__accordion__card">
        <div class="
            teros_principal__content__accordion__card__header
            accordion-off
          " id="headingOne">
          <h2 class="teros_principal__content__accordion__card__header__title">
            <button class="
                teros_principal__content__accordion__card__header__title__btn
              " type="button" aria-expanded="false" aria-controls="collapseOne">
              {{ $t("views.teros.config.resourceType.content.edit.title") }}
            </button>
            <hr />
          </h2>
        </div>

        <div id="collapseOne" class="teros_principal__content__accordion__card__header__collapse">
          <div class="
              teros_principal__content__accordion__card__header__collapse__body
            ">
            <form @submit.prevent="createOrUpdate" class="
                teros_principal__content__accordion__card__header__collapse__body__form
              " method="post">
              <div class="form-group row">
                <div class="col">
                  <label for="formGroupExampleInput">{{
                    $t("views.teros.config.resourceType.content.edit.name")
                  }}*
                    <img src="/static/img/icons/tooltip.png" data-toggle="tooltip" data-placement="right" :title="$t(
                          'views.teros.config.resourceType.toolTip.resourceType'
                        )
                        " />
                  </label>
                  <custom-input :id="'formGroupExampleInput'" :regex="'[a-zA-Z0-9\u00C0-\u00ff - _]'"
                    v-model="resourceType.name"></custom-input>
                </div>
                <div class="col">
                  <label for="formGroupExampleInput2">{{
                    $t("views.teros.config.resourceType.content.edit.scope")
                  }}*
                    <img src="/static/img/icons/tooltip.png" data-toggle="tooltip" data-placement="right" :title="$t(
                          'views.teros.config.resourceType.toolTip.scope'
                        )
                        " />
                  </label>
                  <custom-input :id="'formGroupExampleInput2'" :regex="'[a-zA-Z0-9\u00C0-\u00ff -]'"
                    v-model="resourceType.scope"></custom-input>
                </div>
              </div>

              <div v-if="!newObj" class="accordion" id="accordionGroupings">
                <div class="teros_principal__content__accordion__card">
                  <div class="teros_principal__content__accordion__card__header" id="heardingGroupingOne">
                    <h2 class="teros_principal__content__accordion__card__header__title">
                      <button class="teros_principal__content__accordion__card__header__title__btn" type="button"
                        data-toggle="collapse" data-target="#collapsePermissions" aria-expanded="false"
                        aria-controls="collapsePermissions">
                        Lista de Permissões
                      </button>
                    </h2>
                  </div>

                  <div id="collapsePermissions" class="collapse show" aria-labelledby="heardingPermissions"
                    data-parent="#accordionGroupings">
                    <div class="card-body">
                      <div class="teros_principal__content__table__header">
                        <div class="teros_principal__content__table__header__filters">
                          <div class="form-group">
                            <div class="search-group">
                              <input type="text" class="input-field" :placeholder="$t('views.shared.searchBar.searchLabel')
                                " maxlength="30" onkeydown="return event.keyCode == 13 ? event.preventDefault() : true"
                                v-model="textSearchValue" />
                              <button @click="makeTextSearch()" type="button" class="search-button" />
                            </div>
                          </div>

                          <itens-per-page :request-pagination="requestPagination"
                            :callback="makeTextSearch"></itens-per-page>
                        </div>
                      </div>

                      <div class="teros_principal__content__table__body">
                        <table id="table" class="teros_principal__content__table__body__table" data-toggle="table"
                          data-height="600" data-url="">
                          <thead>
                            <tr>
                              <th data-field="id" data-sortable="true">ID</th>
                              <th data-field="a">
                                {{
                                  $t(
                                    "views.teros.config.resourceType.content.permissions.header.name"
                                  )
                                }}
                              </th>
                              <th data-field="d">
                                {{ $t("views.shared.table.header.createdAt") }}
                              </th>
                              <th data-field="e">
                                {{ $t("views.shared.table.header.changedAt") }}
                              </th>
                              <th data-field="f" style="text-align: center">
                                {{ $t("views.shared.table.header.options") }}
                              </th>
                              <th data-field="g" style="text-align: center">
                                {{
                                  $t("views.shared.table.header.deactivateActivate")
                                }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, index) in permissions" :key="item.hash">
                              <td>{{ index + 1 }}</td>
                              <td>{{ item.name }}</td>
                              <td>{{ item.creationDate | formatDate }}</td>
                              <td>{{ item.lastUpdateDate | formatDate }}</td>

                              <td style="
                                        width: 100px;
                                        padding-left: 0;
                                        padding-right: 0;
                                        text-align: center;
                                        ">
                                <div class="
                                            teros_principal__content__table__body__table__options
                                        ">
                                  <router-link :to="'/permission?resourceTypeHash=' +
                                    resourceType.hash +
                                    '&hash=' +
                                    item.hash
                                    " class="
                                            teros_principal__content__table__body__table__options__item
                                            " data-toggle="tooltip" data-placement="top" title="Visualizar/Editar">
                                    <div>
                                      <img src="/static/img/icons/editar.svg" alt="" />
                                    </div>
                                  </router-link>

                                  <a @click="prepareRemove(item.hash, index)" class="
                                            teros_principal__content__table__body__table__options__item
                                            " data-toggle="modal" data-target="#deleteModal" data-placement="top"
                                    title="Deletar">
                                    <div>
                                      <img src="/static/img/icons/deletar.svg" alt="" />
                                    </div>
                                  </a>
                                </div>
                              </td>
                              <td style="text-align: center">
                                <div class="custom-control custom-switch">
                                  <input type="checkbox" class="custom-control-input" :id="'customSwitch' + index"
                                    v-model="item.active" @change="changeStatus(item.hash)" />
                                  <label class="custom-control-label" :for="'customSwitch' + index"></label>
                                </div>
                              </td>
                            </tr>
                            <tr v-if="!permissions || permissions.length == 0">
                              <td colspan="6" class="text-center">
                                <h4 class="mt-2 mb-2">Não existem permissões a serem listadas</h4>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div class="teros_principal__content__table__footer">
                        <div v-if="loading" class="teros_principal__content__table__footer__loader">
                          <img src="/static/img/icons/ajax-loader.gif" alt="" />
                          <span>{{ $t("views.shared.loadingResults") }}</span>
                        </div>

                        <pagination :request-pagination="requestPagination" :callback="makeTextSearch"
                          :current-pagination-info="currentPaginationInfo"></pagination>
                        <div class="
                        teros_principal__content__table__footer__pagination
                      ">
                          <router-link :to="'/permission?resourceTypeHash=' + resourceType.hash
                            " title="Nova Permissão" class="
                          teros_principal__content__table__footer__add-item
                        ">
                            <img src="/static/img/icons/plus.png" alt="" />{{
                              $t("views.shared.includeButton")
                            }}
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="teros_principal__content__accordion__card">
                  <div class="teros_principal__content__accordion__card__header" id="grouping">
                    <h2 class="teros_principal__content__accordion__card__header__title">
                      <button class="teros_principal__content__accordion__card__header__title__btn" type="button"
                        data-toggle="collapse" data-target="#collapseGroupings" aria-expanded="true"
                        aria-controls="collapseGroupings">
                        Lista de Agrupamentos
                      </button>
                    </h2>
                  </div>

                  <div class="teros_principal__content__accordion__card__body">

                    <div class="accordion" id="accordionGroupingsGroup">
                      <div id="collapseGroupings" class="collapse show" aria-labelledby="heardingGroupingsGroup">
                        <div class="card-body">
                          <div class="card" v-for="(grouping, index) of groupings">
                            <div class="teros_principal__content__accordion__card__header" :id="'grouping' + index">
                              <h2 class="teros_principal__content__accordion__card__header__title">
                                <button class="teros_principal__content__accordion__card__header__title__btn collapsed"
                                  type="button" data-toggle="collapse" :data-target="'#collapseGrouping' + index"
                                  aria-expanded="false" :aria-controls="'collapseGrouping' + index">
                                  {{ grouping.name }}
                                </button>
                              </h2>
                            </div>

                            <div :id="'collapseGrouping' + index" class="collapse" :aria-labelledby="'grouping' + index"
                              data-parent="#accordionGroupingsGroup">
                              <div class="card-body">
                                <div class="teros_principal__content__table__body">
                                  <table id="table" class="teros_principal__content__table__body__table"
                                    data-toggle="table" data-height="600" data-url="">
                                    <thead>
                                      <tr>
                                        <th data-field="id" data-sortable="true">ID</th>
                                        <th data-field="a">
                                          {{
                                            $t(
                                              "views.teros.config.resourceType.content.permissions.header.name"
                                            )
                                          }}
                                        </th>
                                        <th data-field="f" style="text-align: center">
                                          {{ $t("views.shared.table.header.options") }}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-if="!grouping.permissions || grouping.permissions.length == 0">
                                        <td colspan="3" class="text-center">
                                          <h4 class="mt-2 mb-2">Não existem permissões associadas a esse agrupamento</h4>
                                        </td>
                                      </tr>
                                      <tr v-for="(item, index) in grouping.permissions" :key="item.hash"
                                        v-if="grouping.permissions && grouping.permissions.length > 0">
                                        <td>{{ index + 1 }}</td>
                                        <td>{{ item.name }}</td>

                                        <td style="
                                        width: 100px;
                                        padding-left: 0;
                                        padding-right: 0;
                                        text-align: center;
                                        ">
                                          <div class="
                                            teros_principal__content__table__body__table__options
                                        ">
                                            <a @click="prepareUnassociatePermission(grouping.hash, index)" class="
                                            teros_principal__content__table__body__table__options__item
                                            " data-toggle="modal" data-target="#unassociatePermissionModal"
                                              data-placement="top" title="Deletar">
                                              <div>
                                                <img src="/static/img/icons/deletar.svg" alt="" />
                                              </div>
                                            </a>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>

                                <div class="form-group align-right">
                                  <button type="button"
                                    class="mr-4 teros_principal__content__column__info__form__table__plus"
                                    data-toggle="modal" data-target="#removeGroupingModal"
                                    @click="doPrepareRemoveGrouping(grouping)">
                                    Remover Agrupamento
                                  </button>

                                  <button type="button" class="teros_principal__content__column__info__form__table__plus"
                                    data-toggle="modal" data-target="#associatePermissionModal"
                                    @click="onAssociatePermissionClick(grouping)">
                                    <img src="/static/img/icons/intersect.png" alt="Associar" />
                                    Associar Permissão
                                  </button>
                                </div>

                              </div>
                            </div>
                          </div>

                          <div class="form-group align-right">
                            <button type="button" class="teros_principal__content__column__info__form__table__plus mb0"
                              @click="doAddGrouping()">
                              <img src="/static/img/icons/plus.png" alt="Salvar" />
                              Adicionar Agrupamento
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div class="form-group align-right">
                <button class="
                    teros_principal__content__column__info__form__table__plus
                    mb0
                  ">
                  <img src="/static/img/icons/disquete.png" alt="Salvar" />{{
                    $t("views.shared.saveButton")
                  }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade teros-modal" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel"
      aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteModalLabel">
              <img src="/static/img/icons/atencao-modal-red.png" alt="" />
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            {{ $t("views.shared.modal.delete.confirmMessage") }}
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              {{ $t("views.shared.modal.delete.cancelButton") }}
            </button>
            <button type="button" class="btn btn-primary btn-primary--red" data-dismiss="modal"
              v-on:click="removePermission">
              {{ $t("views.shared.modal.delete.deleteButton") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade teros-modal" id="removeGroupingModal" tabindex="-1" aria-labelledby="removeGroupingLabel"
      aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="removeGroupingLabel">
              <img src="/static/img/icons/atencao-modal-red.png" alt="" />
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Tem certeza que deseja remover este item?
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              {{ $t("views.shared.modal.delete.cancelButton") }}
            </button>
            <button type="button" class="btn btn-primary btn-primary--red" data-dismiss="modal"
              v-on:click="doRemoveGrouping">
              Remover
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade teros-modal" id="unassociatePermissionModal" tabindex="-1"
      aria-labelledby="unassociatePermissionLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="unassociatePermissionLabel">
              <img src="/static/img/icons/atencao-modal-red.png" alt="" />
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Tem certeza que deseja desassociar este item?
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              {{ $t("views.shared.modal.delete.cancelButton") }}
            </button>
            <button type="button" class="btn btn-primary btn-primary--red" data-dismiss="modal"
              v-on:click="doSaveGrouping">
              Desassociar
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade teros-modal" id="associatePermissionModal" data-backdrop="static" data-keyboard="false"
      tabindex="-1" aria-labelledby="associatePermissionLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-scrollable" style="min-width: 700px;">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="associatePermissionLabel">Permissões</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">

            <div class="teros_principal__content__table__header__filters">
              <div class="form-group">
                <div class="search-group">
                  <input type="text" class="input-field" :placeholder="$t('views.shared.searchBar.searchLabel')
                    " maxlength="30" onkeydown="return event.keyCode == 13 ? event.preventDefault() : true"
                    v-model="textSearchValue" />
                  <button @click="makeTextSearch()" type="button" class="search-button" />
                </div>
              </div>

              <itens-per-page :request-pagination="requestPagination" :callback="makeTextSearch"></itens-per-page>
            </div>

            <div class="teros_principal__content__table__body">
              <table class="teros_principal__content__table__body__table mt-4">
                <thead v-if="getUnassociadedPermissions() && getUnassociadedPermissions().length > 0">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Permissão</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!getUnassociadedPermissions() || getUnassociadedPermissions().length == 0">
                    <td colspan="3" class="text-center">
                      <h4 class="mt-3 mb-3">Não existem permissões a serem associadas a esse agrupamento</h4>
                    </td>
                  </tr>
                  <tr v-for="(permission, index) of getUnassociadedPermissions()">
                    <td>
                      <input type="checkbox" :name="index" :id="index" @change="onCheckAssociatePermission(permission)">
                    </td>
                    <td>{{ permission.name }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <pagination :request-pagination="requestPagination" :callback="makeTextSearch"
              :current-pagination-info="currentPaginationInfo"></pagination>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
            <button type="button" class="btn btn-primary" data-dismiss="modal"
              :disabled="!getUnassociadedPermissions() || getUnassociadedPermissions().length == 0"
              @click="doSaveGrouping()">Salvar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { http, router } from "@/_helpers";
import CustomInput from "@/_components/CustomInput.vue";
import Pagination from "@/_components/Pagination.vue";
import ItensPerPage from "@/_components/ItensPerPage.vue";
import moment from "moment";

export default {
  data() {
    return {
      newObj: true,
      resourceType: { active: true },
      permissions: [],
      permission: {},
      permissionHash: null,
      groupings: [],
      group: {},
      groupHash: null,
      index: 0,
      loading: true,
      textSearchValue: "",
      oldTextSearchValue: "",
      currentPaginationInfo: null,
      requestPagination: { page: 1, take: 10 },
      selectedGrouping: {},
      groupingToSave: {},
      permissionsToAssociate: [],
      groupingToRemove: {},
    };
  },
  components: {
    CustomInput,
    Pagination,
    ItensPerPage,
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
  },
  computed: {
    creationDate() {
      if (this.resourceType.creationDate) {
        return moment(String(this.resourceType.creationDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
    creationTime() {
      if (this.resourceType.creationDate) {
        return moment(String(this.resourceType.creationDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("HH:mm");
      }
      return "";
    },
    lastUpdateDate() {
      if (this.resourceType.lastUpdateDate) {
        return moment(String(this.resourceType.lastUpdateDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
    lastUpdateTime() {
      if (this.resourceType.lastUpdateDate) {
        return moment(String(this.resourceType.lastUpdateDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("HH:mm");
      }
      return "";
    },
  },
  mounted() {
    this.$emit("message", this.$route);
    const hash = this.$route.query.hash;
    if (hash) {
      this.newObj = false;
      http.get("/api/resourcetype/get/" + hash).then((response) => {
        this.resourceType = response.data;
        this.getGroupingsByResource();
        this.getPermissionsByResource();
      });
    }
  },
  methods: {
    goBack() {
      router.push({
        name: "resourcetypespage",
      });
    },
    createOrUpdate() {
      if (this.newObj) {
        http.post("/api/resourcetype/create/", this.resourceType).then(() => {
          this.goBack();
          this.$emit("success");
        });
      } else {
        http.put("/api/resourcetype/update/", this.resourceType).then(() => {
          this.goBack();
          this.$emit("success");
        });
      }
    },
    changeStatus(hash) {
      http.get("/api/permission/get/" + hash).then((response) => {
        this.permission = response.data;
        this.permission.active = !this.permission.active;
        http.put("/api/permission/update/", this.permission).then(() => {
          if (this.permission.active) {
            Vue.$toast.success(
              this.$i18n.t(
                "views.teros.config.resourceType.content.permissions.modal.activatedMessage"
              )
            );
          } else {
            Vue.$toast.success(
              this.$i18n.t(
                "views.teros.config.resourceType.content.permissions.modal.deactivatedMessage"
              )
            );
          }
        });
      });
    },
    prepareRemove(hash, index) {
      this.permissionHash = hash;
      this.index = index;
    },
    removePermission() {
      http.remove("/api/permission/delete/" + this.permissionHash).then(() => {
        this.$emit(`success`);
        Vue.delete(this.permissions, this.index);
      });
    },
    makeTextSearch(event, isChangePage = false) {
      this.checkValuesChanged(isChangePage);

      if (this.textSearchValue.trim()) {
        http
          .get(
            `/api/permission/search/${this.resourceType.hash
            }?FilterProperty=Name&FilterValue=${this.textSearchValue.trim()}&Page=${this.requestPagination.page
            }&Take=${this.requestPagination.take}`
          )
          .then((response) => {
            this.permissions = response.data.items;
            this.currentPaginationInfo = response.data;
          })
          .finally(() => (this.loading = false));
      } else {
        http
          .get(
            `/api/permission/search/${this.resourceType.hash}?Page=${this.requestPagination.page}&Take=${this.requestPagination.take}`
          )
          .then((response) => {
            this.permissions = response.data.items;
            this.currentPaginationInfo = response.data;
          })
          .finally(() => (this.loading = false));
      }
    },
    checkValuesChanged(isChangePage = false) {
      if (this.textSearchValue != this.oldTextSearchValue) {
        if (isChangePage) {
          this.textSearchValue = this.oldTextSearchValue;
        } else {
          this.requestPagination.page = 1;
          this.oldTextSearchValue = this.textSearchValue;
        }
      }
    },
    getGroupingsByResource() {
      http
        .get("/api/grouping/list/" + this.resourceType.hash, false)
        .then((response) => {
          this.groupings = response.data;
          this.groupings
            .forEach(g => g.resourceTypeHash = this.resourceType.hash);
          // this.currentPaginationInfo = response.data;
        })
        .finally(() => (this.loading = false));
    },
    getPermissionsByResource() {
      http
        .get(`/api/resourceType/${this.resourceType.hash}/permissions/search`, false)
        .then((response) => {
          this.permissions = response.data.items;
          this.currentPaginationInfo = response.data;
        })
        .finally(() => (this.loading = false));
    },
    doAddGrouping() {
      router.push({
        name: 'groupingpage',
        query: {
          resourceTypeHash: this.resourceType.hash
        }
      });
    },
    getUnassociadedPermissions() {
      if (this.permissions && this.selectedGrouping && this.groupings) {
        if (this.selectedGrouping.permissions) {
          let parray = this.selectedGrouping.permissions.map(p => p.hash);
          let results = [];
          this.permissions.forEach(permission => {
            if (!parray.find(pa => pa == permission.hash)) {
              results.push(permission);
            }
          });

          return results;
        } else {
          return this.permissions;
        }
      }
    },
    onAssociatePermissionClick(grouping) {
      this.selectedGrouping = Object.assign({}, grouping);
      this.permissionsToAssociate = [...grouping.permissions];
    },
    onCheckAssociatePermission(permission) {
      if (this.selectedGrouping.permissions) {
        let index = this.permissionsToAssociate.findIndex(p => p.hash == permission.hash);

        if (index == -1) {
          this.permissionsToAssociate.push(permission);
        } else {
          this.permissionsToAssociate.splice(index, 1);
        }
      }
    },
    doSaveGrouping() {
      let grouping = Object.assign({}, this.selectedGrouping);
      grouping.permissions = [...this.permissionsToAssociate];
      this.updateGrouping(grouping);
    },
    updateGrouping(grouping) {
      http.put(`/api/grouping/update`, grouping).then(response => {
        this.$emit("success");
        this.getGroupingsByResource();
        this.permissionsToAssociate = null;
        this.selectedGrouping = null;
        this.groupingToSave = null;
      });
    },
    prepareUnassociatePermission(groupingHash, index) {
      let grouping = Object.assign({}, this.groupings.find(g => g.hash == groupingHash));
      let permitionsArray = [...grouping.permissions];
      permitionsArray.splice(index, 1);

      this.selectedGrouping = Object.assign({}, grouping);
      this.permissionsToAssociate = [...permitionsArray];
    },
    doPrepareRemoveGrouping(grouping) {
      this.groupingToRemove = grouping;
    },
    doRemoveGrouping() {
      http.remove(`/api/grouping/delete/${this.groupingToRemove.hash}`)
        .then(response => {
          this.getGroupingsByResource();
          this.$emit('success');
        });
    }
  },
};
</script>