<template>
  <div class="teros_principal__content interna container">
    <div class="teros_principal__content__line-1">
      <div class="teros_principal__content__line-1__highlights">
        <div class="teros_principal__content__line-1__highlights__subject">
          <div class="teros_principal__content__line-1__highlights__subject__breadcrumb">
            <router-link to="/receptions">
              <h3 class="teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle">
                {{ $t("views.client.reception.list.client") }}
              </h3>
              <h2 class="teros_principal__content__line-1__highlights__subject__breadcrumb__title">{{ reception.customerName }}</h2>
            </router-link>
          </div>
          <div class="teros_principal__arrow"></div>
          <div class="teros_principal__content__line-1__highlights__subject__breadcrumb">
            <h3 class="teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle">
              {{ $t("views.client.reception.reception.title") }}
            </h3>
            <h2 class="teros_principal__content__line-1__highlights__subject__breadcrumb__title active">
              {{ reception.integrationName }}
            </h2>
          </div>
        </div>
      </div>

      <div class="teros_principal__content__line-1__information">
        <div class="teros_principal__content__line-1__information__time">
          <p class="teros_principal__content__line-1__information__time__text">
            <img class="teros_principal__content__line-1__information__time__text__icon" src="/static/img/icons/relogio.png" alt="" />
            {{
              $t("views.shared.createdDate", {
                date: creationDate,
                time: creationTime,
              })
            }}
            {{
              lastUpdateDate
                ? "| " +
                  $t("views.shared.changedDate", {
                    date: lastUpdateDate,
                    time: lastUpdateTime,
                  })
                : ""
            }}
          </p>

          <button type="button" @click="goBack" class="teros_principal__content__line-1__information__time__btn">
            <img src="/static/img/icons/seta-esquerda.png" alt="" />
            {{ $t("views.shared.backButton") }}
          </button>
        </div>
      </div>
    </div>

    <div class="teros_principal__content__column">
      <div class="teros_principal__content__column__menu-vertical">
        <ul class="teros_principal__content__column__menu-vertical__list">
          <li class="teros_principal__content__column__menu-vertical__list__item">
            <router-link
              :to="'/reception?hash=' + reception.hash"
              class="teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--lupa"
              >{{ $t("views.client.reception.reception.tabTitle") }}</router-link
            >
          </li>

          <li class="teros_principal__content__column__menu-vertical__list__item">
            <router-link
              :to="'/receptionservices?hash=' + reception.hash"
              class="teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--servicos"
              >{{ $t("views.client.reception.receptionservices.tabTitle") }}</router-link
            >
          </li>

          <li class="teros_principal__content__column__menu-vertical__list__item">
            <a href="#" class="teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--compliance active">{{
              $t("views.client.reception.receptionconsentiments.tabTitle")
            }}</a>
          </li>

          <li class="teros_principal__content__column__menu-vertical__list__item">
            <router-link
              :to="'/receptionregistries?hash=' + reception.hash"
              class="teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--registros"
              >{{ $t("views.client.reception.registries.tabTitle") }}</router-link
            >
          </li>

          <li class="teros_principal__content__column__menu-vertical__list__item">
            <router-link
              :to="'/receptionlogs?hash=' + reception.hash"
              class="teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--gestao"
              >{{ $t("views.client.reception.receptionlogs.tabTitle") }}</router-link
            >
          </li>
        </ul>
      </div>

      <div class="teros_principal__content__column__info">
        <div class="teros_principal__content__table">
          <div class="teros_principal__content__table__header">
            <div class="teros_principal__content__table__header__filters">
              <div class="form-group">
                <form @submit.prevent="makeTextSearch">
                  <div class="search-group">
                    <input type="text" class="input-field" :placeholder="$t('views.shared.searchBar.searchLabel')" maxlength="30" v-model="textSearchValue" />
                    <button class="search-button" />
                  </div>
                </form>
              </div>

              <itens-per-page :request-pagination="requestPagination" :callback="makeTextSearch"></itens-per-page>
            </div>

            <div class="teros_principal__content__table__header__actions">
              <!--<a href="#" title="Download"
                ><img src="/static/img/icons/download.png" alt="Download"
              /></a>
              <a href="#" title="Imprimir"
                ><img src="/static/img/icons/printer.png" alt="Imprimir"
              /></a> -->
            </div>
          </div>

          <div class="teros_principal__content__table__body">
            <table id="table" class="teros_principal__content__table__body__table" data-toggle="table" data-height="600" data-url="">
              <thead>
                <tr>
                  <th data-field="id" data-sortable="true">ID</th>
                  <th data-field="a">
                    {{ $t("views.client.reception.receptionconsentiments.table.header.consent") }}
                  </th>
                  <th data-field="c">
                    {{ $t("views.shared.table.header.createdAt") }}
                  </th>
                  <th data-field="d">
                    {{ $t("views.shared.table.header.changedAt") }}
                  </th>
                  <th data-field="h" style="text-align: center">
                    {{ $t("views.shared.table.header.options") }}
                  </th>
                  <th data-field="i" style="text-align: center">
                    {{ $t("views.shared.table.header.deactivateActivate") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in consentiments" :key="item.hash">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.creationDate | formatDate }}</td>
                  <td>{{ item.lastUpdateDate | formatDate }}</td>

                  <td style="text-align: center">
                    <div class="teros_principal__content__table__body__table__options">
                      <router-link
                        :to="'/receptionconsentiment?hash=' + item.hash"
                        class="teros_principal__content__table__body__table__options__item"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Visualizar/Editar"
                      >
                        <div>
                          <img src="/static/img/icons/editar.svg" alt="" />
                        </div>
                      </router-link>

                      <a
                        @click="prepareRemove(item.hash, index)"
                        class="teros_principal__content__table__body__table__options__item"
                        data-toggle="modal"
                        data-target="#deleteModal"
                        data-placement="top"
                        title="Deletar"
                      >
                        <div>
                          <img src="/static/img/icons/deletar.svg" alt="" />
                        </div>
                      </a>
                    </div>
                  </td>
                  <td style="text-align: center">
                    <div class="custom-control custom-switch" data-toggle="tooltip" data-placement="top" title="Desativar/Ativar">
                      <input type="checkbox" class="custom-control-input" :id="'customSwitch' + index" v-model="item.active" @change="changeStatus(item.hash)" />
                      <label class="custom-control-label" :for="'customSwitch' + index"></label>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="teros_principal__content__table__footer">
            <div v-if="loading" class="teros_principal__content__table__footer__loader">
              <img src="/static/img/icons/ajax-loader.gif" alt="" />
              <span>{{ $t("views.shared.loadingResults") }}</span>
            </div>

            <pagination :request-pagination="requestPagination" :callback="makeTextSearch" :current-pagination-info="currentPaginationInfo"></pagination>

            <div class="teros_principal__content__table__footer__pagination">
              <router-link :to="'/receptionconsentiment?receptionHash=' + reception.hash" title="Novo Serviço" class="teros_principal__content__table__footer__add-item">
                <img src="/static/img/icons/plus.png" alt="" />{{ $t("views.shared.includeButton") }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade teros-modal" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteModalLabel">
              <img src="/static/img/icons/atencao-modal-red.png" alt="" />
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            {{ $t("views.shared.modal.delete.confirmMessage") }}
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              {{ $t("views.shared.modal.delete.cancelButton") }}
            </button>
            <button type="button" class="btn btn-primary btn-primary--red" data-dismiss="modal" @click="remove">
              {{ $t("views.shared.modal.delete.deleteButton") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { http, router } from "@/_helpers";
import Pagination from "@/_components/Pagination.vue";
import ItensPerPage from "@/_components/ItensPerPage.vue";
import moment from "moment";

export default {
  data() {
    return {
      reception: {},
      consentiments: [],
      consentimentHash: null,
      index: 0,
      loading: true,
      textSearchValue: "",
      oldTextSearchValue: "",
      currentPaginationInfo: null,
      requestPagination: { page: 1, take: 10 },
    };
  },
  components: {
    Pagination,
    ItensPerPage,
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
  },
  computed: {
    creationDate() {
      if (this.reception.creationDate) {
        return moment(String(this.reception.creationDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
    creationTime() {
      if (this.reception.creationDate) {
        return moment(String(this.reception.creationDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("HH:mm");
      }
      return "";
    },
    lastUpdateDate() {
      if (this.reception.lastUpdateDate) {
        return moment(String(this.reception.lastUpdateDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
    lastUpdateTime() {
      if (this.reception.lastUpdateDate) {
        return moment(String(this.reception.lastUpdateDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("HH:mm");
      }
      return "";
    },
  },
  mounted() {
    this.$emit("message", this.$route);
    const hash = this.$route.query.hash;
    http.get("/api/reception/get/" + hash).then((response) => {
      this.reception = response.data;
      http
        .get("/api/receptionconsentiment/search/" + this.reception.hash, false)
        .then((response) => {
          this.consentiments = response.data.items;
          this.currentPaginationInfo = response.data;
        })
        .finally(() => (this.loading = false));
    });
  },
  methods: {
    goBack() {
      router.push({ name: "receptionspage" });
    },
    changeStatus(hash) {
      http.get("/api/receptionconsentiment/get/" + hash).then((response) => {
        let serviceEdit = response.data;
        serviceEdit.active = !serviceEdit.active;
        http.put("/api/receptionconsentiment/update/", serviceEdit).then(() => {
          if (serviceEdit.active) {
            Vue.$toast.success(this.$i18n.t("views.client.reception.receptionconsentiments.modal.activatedMessage"));
          } else {
            Vue.$toast.success(this.$i18n.t("views.client.reception.receptionconsentiments.modal.deactivatedMessage"));
          }
        });
      });
    },
    prepareRemove(hash, index) {
      this.serviceHash = hash;
      this.index = index;
    },
    remove() {
      http.remove("/api/receptionconsentiment/delete/" + this.serviceHash).then(() => {
        Vue.$toast.success(this.$i18n.t("views.client.reception.receptionconsentiments.modal.deletedMessage"));
        Vue.delete(this.consentiments, this.index);
      });
    },
    makeTextSearch(event, isChangePage = false) {
      this.CheckValuesChanged(isChangePage);

      if (this.textSearchValue.trim()) {
        http
          .get(
            `/api/receptionconsentiment/search/${this.reception.hash}?FilterProperty=Name&FilterValue=${this.textSearchValue.trim()}&Page=${this.requestPagination.page}&Take=${
              this.requestPagination.take
            }`
          )
          .then((response) => {
            this.services = response.data.items;
            this.currentPaginationInfo = response.data;
          })
          .finally(() => (this.loading = false));
      } else {
        http
          .get(`/api/receptionconsentiment/search/${this.reception.hash}?Page=${this.requestPagination.page}&Take=${this.requestPagination.take}`)
          .then((response) => {
            this.services = response.data.items;
            this.currentPaginationInfo = response.data;
          })
          .finally(() => (this.loading = false));
      }
    },
    CheckValuesChanged(isChangePage = false) {
      if (this.textSearchValue != this.oldTextSearchValue) {
        if (isChangePage) {
          this.textSearchValue = this.oldTextSearchValue;
        } else {
          this.requestPagination.page = 1;
          this.oldTextSearchValue = this.textSearchValue;
        }
      }
    },
  },
};
</script>
