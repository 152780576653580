var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "teros_principal__content interna container" },
    [
      _c("div", { staticClass: "teros_principal__content__line-1 line" }, [
        _c(
          "div",
          { staticClass: "teros_principal__content__line-1__highlights" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__line-1__highlights__subject",
              },
              [
                _c(
                  "router-link",
                  { attrs: { to: "/customer?hash=" + _vm.customer.hash } },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "teros_principal__content__line-1__highlights__subject__breadcrumb",
                      },
                      [
                        _c(
                          "h3",
                          {
                            staticClass:
                              "teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t("views.teros.customers.customer.title")
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "h2",
                          {
                            staticClass:
                              "teros_principal__content__line-1__highlights__subject__breadcrumb__title",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.customer.name) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "teros_principal__arrow" }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__line-1__highlights__subject__breadcrumb",
                  },
                  [
                    _c(
                      "h3",
                      {
                        staticClass:
                          "teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("views.teros.customers.user.title")) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.newObj
                      ? _c(
                          "h2",
                          {
                            staticClass:
                              "teros_principal__content__line-1__highlights__subject__breadcrumb__title active",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t("views.teros.customers.user.newObject")
                                ) +
                                "\n          "
                            ),
                          ]
                        )
                      : _c(
                          "h2",
                          {
                            staticClass:
                              "teros_principal__content__line-1__highlights__subject__breadcrumb__title active",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.user.username) +
                                "\n          "
                            ),
                          ]
                        ),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "teros_principal__content__line-1__information" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__line-1__information__time",
              },
              [
                !_vm.newObj
                  ? _c(
                      "p",
                      {
                        staticClass:
                          "teros_principal__content__line-1__information__time__text",
                      },
                      [
                        _c("img", {
                          staticClass:
                            "teros_principal__content__line-1__information__time__text__icon",
                          attrs: {
                            src: "/static/img/icons/relogio.png",
                            alt: "",
                          },
                        }),
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t("views.shared.createdDate", {
                                date: _vm.creationDate,
                                time: _vm.creationTime,
                              })
                            ) +
                            "\n          " +
                            _vm._s(
                              _vm.lastUpdateDate
                                ? "| " +
                                    _vm.$t("views.shared.changedDate", {
                                      date: _vm.lastUpdateDate,
                                      time: _vm.lastUpdateTime,
                                    })
                                : ""
                            ) +
                            "\n        "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "teros_principal__content__line-1__information__time__btn",
                    attrs: { type: "button" },
                    on: { click: _vm.goBack },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "/static/img/icons/seta-esquerda.png",
                        alt: "",
                      },
                    }),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("views.shared.backButton")) +
                        "\n        "
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { id: "accordion" } }, [
        _c(
          "div",
          { staticClass: "teros_principal__content__accordion__card" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__accordion__card__header",
                attrs: { id: "headingOne" },
              },
              [
                _c(
                  "h2",
                  {
                    staticClass:
                      "teros_principal__content__accordion__card__header__title",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "teros_principal__content__accordion__card__header__title__btn",
                        attrs: {
                          type: "button",
                          "data-toggle": "collapse",
                          "data-target": "#collapseOne",
                          "aria-expanded": "false",
                          "aria-controls": "collapseOne",
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t("views.teros.customers.user.content.title")
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__accordion__card__header__collapse",
                attrs: { id: "collapseOne" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__accordion__card__header__collapse__body",
                  },
                  [
                    _c(
                      "form",
                      {
                        staticClass:
                          "teros_principal__content__accordion__card__header__collapse__body__form",
                        attrs: { method: "post" },
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.createOrUpdate.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "div",
                            { staticClass: "col" },
                            [
                              _c(
                                "label",
                                { attrs: { for: "formGroupExampleInput1" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "views.teros.customers.user.content.userName"
                                      )
                                    ) + "*\n                  "
                                  ),
                                  _c("img", {
                                    attrs: {
                                      src: "/static/img/icons/tooltip.png",
                                      "data-toggle": "tooltip",
                                      "data-placement": "right",
                                      title: _vm.$t(
                                        "views.teros.customers.user.toolTips.username"
                                      ),
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.newObj
                                ? _c("custom-input", {
                                    attrs: {
                                      id: "formGroupExampleInput1",
                                      regex: "[-a-zA-Z0-9.?$@&!%_]",
                                    },
                                    model: {
                                      value: _vm.user.username,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.user, "username", $$v)
                                      },
                                      expression: "user.username",
                                    },
                                  })
                                : _c("custom-input", {
                                    attrs: {
                                      readonly: true,
                                      id: "formGroupExampleInput1",
                                      regex: "[-a-zA-Z0-9.?$@&!%_]",
                                    },
                                    model: {
                                      value: _vm.user.username,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.user, "username", $$v)
                                      },
                                      expression: "user.username",
                                    },
                                  }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col" },
                            [
                              _c(
                                "label",
                                { attrs: { for: "formGroupExampleInput2" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "views.teros.customers.user.content.fullName"
                                      )
                                    ) + "*\n                  "
                                  ),
                                  _c("img", {
                                    attrs: {
                                      src: "/static/img/icons/tooltip.png",
                                      "data-toggle": "tooltip",
                                      "data-placement": "right",
                                      title: _vm.$t(
                                        "views.teros.customers.user.toolTips.fullname"
                                      ),
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c("custom-input", {
                                attrs: { id: "formGroupExampleInput2" },
                                model: {
                                  value: _vm.user.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.user, "name", $$v)
                                  },
                                  expression: "user.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "div",
                            { staticClass: "col" },
                            [
                              _c("live-select", {
                                attrs: {
                                  title:
                                    _vm.$t(
                                      "views.teros.customers.user.content.accessGroup"
                                    ) + "*",
                                  multiple: "",
                                  options: _vm.cognitoGroups,
                                  hashs: _vm.user.groups,
                                },
                                on: {
                                  onChange: function ($event) {
                                    _vm.user.groups = $event
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col" },
                            [
                              _c(
                                "label",
                                { attrs: { for: "formGroupExampleInput4" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "views.teros.customers.user.content.mail"
                                      )
                                    ) + "*"
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.newObj
                                ? _c("custom-input", {
                                    attrs: {
                                      id: "formGroupExampleInput4",
                                      regex: ".*",
                                    },
                                    model: {
                                      value: _vm.user.email,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.user, "email", $$v)
                                      },
                                      expression: "user.email",
                                    },
                                  })
                                : _c("custom-input", {
                                    attrs: {
                                      readonly: true,
                                      id: "formGroupExampleInput4",
                                      regex: ".*",
                                    },
                                    model: {
                                      value: _vm.user.email,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.user, "email", $$v)
                                      },
                                      expression: "user.email",
                                    },
                                  }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group row" }, [
                          _c("div", { staticClass: "col" }, [
                            _c(
                              "label",
                              { attrs: { for: "formGroupExampleInput5" } },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "views.teros.customers.user.content.contactPhone"
                                    )
                                  ) + "\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.user.phoneNumber,
                                  expression: "user.phoneNumber",
                                },
                                {
                                  name: "mask",
                                  rawName: "v-mask",
                                  value: "+#############",
                                  expression: "'+#############'",
                                },
                              ],
                              staticClass: "form-control field-copy",
                              attrs: {
                                type: "text",
                                id: "formGroupExampleInput5",
                              },
                              domProps: { value: _vm.user.phoneNumber },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.user,
                                    "phoneNumber",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "form-group",
                            staticStyle: {
                              "text-align": "right",
                              display: "block",
                            },
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "teros_principal__content__column__info__form__combo__salvar",
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: "/static/img/icons/disquete.png",
                                    alt: "Salvar",
                                  },
                                }),
                                _vm._v(
                                  _vm._s(_vm.$t("views.shared.saveButton")) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }