<template>
  <div class="teros_principal">
    <div class="teros_principal">
      <div class="teros_principal__login">
        <div
          class="
            teros_principal__login__column
            teros_principal__login__column--fields
          "
        >
          <div class="center">
            <img
              src="/static/img/logo_login.png"
              alt=""
              class="teros_principal__login__column__logo"
            />
          </div>

          <div class="teros_principal__login__column__form__highlights">
            <p class="teros_principal__login__column__form__highlights__text">
              {{ $t("views.shared.titleSystem") }} <br />
              <span>Teros Open Banking</span><br /><br />
              {{ $t("views.newpasswordrequired.title") }} <br />
            </p>
          </div>

          <form @submit.prevent="newPassword">
            <div class="teros_principal__login__column__form__fields">
              <div class="form-group">
                <input-password
                  id="newPassword1"
                  :placeholder = "$t('views.newpasswordrequired.newPassword')"
                  :password="newPassword1"
                  @onChange="newPassword1 = $event"
                ></input-password>
              </div>

              <div class="form-group">
                <input-password
                  id="newPassword2"
                  :placeholder = "$t('views.newpasswordrequired.confirmation')"
                  :password="newPassword2"
                  @onChange="newPassword2 = $event"
                ></input-password>
              </div>

              <button type="submit" class="btn btn-primary">
                {{ $t("views.newpasswordrequired.changeButton") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { cognito } from "@/_helpers";
import InputPassword from "@/_components/InputPassword.vue";
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';

export default {
  data() {
    return {
      username: null,
      session: null,
      newPassword1: "",
      newPassword2: "",
    };
  },
  components: {
    InputPassword,
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    this.username = urlParams.get("username");
    this.session = urlParams.get("session");
  },
  methods: {
    newPassword() {
      if (this.newPassword1 == "") {
        Vue.$toast.error(this.$i18n.t("views.shared.modal.error.newPasswordRequired"));
      } else if (!cognito.isPasswordValid(this.newPassword1)) {
        Vue.$toast.error(this.$i18n.t("views.shared.modal.error.passwordPolicyValidationErrors"));
      } else if (this.newPassword1 != this.newPassword2) {
        Vue.$toast.error(this.$i18n.t("views.shared.modal.error.passwordsDontMatch"));
      } else {
        var poolData = {
          UserPoolId: USERPOOLID,
          ClientId: CLIENTID,
        };
        var userPool = new CognitoUserPool(poolData);
        var userData = {
          Username: this.username,
          Pool: userPool,
        };
        var cognitoUser = new CognitoUser(userData);
        cognitoUser.Session = this.session;
        cognitoUser.completeNewPasswordChallenge(
          this.newPassword1,
          {},
          {
            onSuccess: () => {
              cognito.authenticate(
                this.username,
                this.newPassword1,
                USERPOOLID,
                CLIENTID
              );
            },
            onFailure: (err) => {
              Vue.$toast.error(err.message);
            },
          }
        );
      }
    },
  },
};
</script>