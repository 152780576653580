<template>
  <div class="teros_principal__content interna container">
    <div class="teros_principal__content__line-1 line">
      <div class="teros_principal__content__line-1__highlights">
        <div class="teros_principal__content__line-1__highlights__subject">
          <div
            class="
              teros_principal__content__line-1__highlights__subject__breadcrumb
            "
          >
            <router-link to="/transmission">
              <h3
                class="
                  teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle
                "
              >
                {{ $t("views.client.transmission.general.client") }}
              </h3>
              <h2
                class="
                  teros_principal__content__line-1__highlights__subject__breadcrumb__title
                "
              >
                {{ currentUser.customerName }}
              </h2>
            </router-link>
          </div>
          <div class="teros_principal__arrow"></div>
          <div
            class="
              teros_principal__content__line-1__highlights__subject__breadcrumb
            "
          >
            <h3
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle
              "
            >
              {{ $t("views.client.transmission.transmissionservice.title") }}
            </h3>
            <h2
              v-if="newObj"
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__title
                active
              "
            >
              {{
                $t("views.client.transmission.transmissionservice.newObject")
              }}
            </h2>
            <h2
              v-else
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__title
                active
              "
            >
              {{ transmissionservice.serviceName }}
            </h2>
          </div>
        </div>
      </div>
      <div class="teros_principal__content__line-1__information">
        <div class="teros_principal__content__line-1__information__time">
          <button
            type="button"
            @click="goBack"
            class="teros_principal__content__line-1__information__time__btn"
          >
            <img src="/static/img/icons/seta-esquerda.png" alt="" />
            {{ $t("views.shared.backButton") }}
          </button>
        </div>
      </div>
    </div>

    <div class="teros_principal__content__accordion__description">
      <p
        v-if="!newObj"
        class="teros_principal__content__line-1__information__time__text"
      >
        <img
          class="
            teros_principal__content__line-1__information__time__text__icon
          "
          src="/static/img/icons/relogio.png"
          alt=""
        />
        {{
          $t("views.shared.createdDate", {
            date: creationDate,
            time: creationTime,
          })
        }}
        {{
          lastUpdateDate
            ? "| " +
              $t("views.shared.changedDate", {
                date: lastUpdateDate,
                time: lastUpdateTime,
              })
            : ""
        }}
      </p>
    </div>

    <div id="accordion">
      <form @submit.prevent="createOrUpdate" method="post">
        <div class="teros_principal__content__accordion__card">
          <div
            class="teros_principal__content__accordion__card__header"
            id="headingOne"
          >
            <h2
              class="teros_principal__content__accordion__card__header__title"
            >
              <button
                class="
                  teros_principal__content__accordion__card__header__title__btn
                "
                type="button"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                {{
                  $t(
                    "views.client.transmission.transmissionservice.general.title"
                  )
                }}
              </button>
              <hr />
            </h2>
          </div>

          <div
            id="collapseOne"
            class="teros_principal__content__accordion__card__header__collapse"
          >
            <div
              class="
                teros_principal__content__accordion__card__header__collapse__body
              "
              style="padding-bottom: 0"
            >
              <div
                class="
                  teros_principal__content__accordion__card__header__collapse__body__form
                "
              >
                <div class="form-group">
                  <div class="custom-control custom-switch">
                    <input
                      type="checkbox"
                      checked
                      class="custom-control-input"
                      id="customSwitch1"
                      v-model="transmissionservice.active"
                    />
                    <label class="custom-control-label" for="customSwitch1">{{
                      $t("views.shared.activeSwitch")
                    }}</label>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col">
                    <live-select
                      :id="'resourceType'"
                      :title="
                        $t(
                          'views.teros.integrations.service.content.general.resourceType'
                        ) + '*'
                      "
                      :options="resourceTypes"
                      :hash="transmissionservice.resourceTypeHash"
                      @onChange="getPermissions($event)"
                    ></live-select>
                  </div>
                  <div class="col">
                    <live-select
                      :id="'permissions'"
                      :title="
                        $t(
                          'views.teros.integrations.service.content.general.permissions'
                        ) + '*'
                      "
                      :options="permissions"
                      :multiple="true"
                      :hashs="transmissionservice.permissionHashs"
                      @onChange="transmissionservice.permissionHashs = $event"
                    ></live-select>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col">
                    <label for="formGroupExampleInput3"
                      >{{
                        $t(
                          "views.client.transmission.transmissionservice.general.content.serviceName"
                        )
                      }}*
                      <img
                        src="/static/img/icons/tooltip.png"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Sed vel blandit nisi. Donec nisi magna, pretium sit amet ullamcorper a, sagittis a odio. Duis et tincidunt nunc. Donec dolor enim, hendrerit rutrum sed, dignissim quis velit. Maecenas aliquet tortor ut nisi accumsan, et ullamcorper libero blandit."
                    /></label>
                    <custom-input
                      :id="'formGroupExampleInput3'"
                      v-model="transmissionservice.serviceName"
                    ></custom-input>
                  </div>
                  <div class="col">
                    <label for="formGroupExampleInput4"
                      >URL/Endpoint*
                      <img
                        src="/static/img/icons/tooltip.png"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Sed vel blandit nisi. Donec nisi magna, pretium sit amet ullamcorper a, sagittis a odio. Duis et tincidunt nunc. Donec dolor enim, hendrerit rutrum sed, dignissim quis velit. Maecenas aliquet tortor ut nisi accumsan, et ullamcorper libero blandit."
                    /></label>
                    <custom-input
                      :id="'formGroupExampleInput4'"
                      :regex="'.*'"
                      v-model="transmissionservice.endpointUrl"
                    ></custom-input>
                  </div>
                </div>

                <div class="form-group row">
                  <div v-if="!newObj" class="col">
                    <label for="formGroupExampleInput6"
                      >TransmissionServiceHash
                      <img
                        src="/static/img/icons/tooltip.png"
                        data-toggle="tooltip"
                        data-placement="right"
                        title=""
                      />
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="formGroupExampleInput6"
                      v-model="transmissionservice.hash"
                      readonly
                    />
                  </div>
                  <div class="col">
                    <live-select
                      :id="'api'"
                      :title="'API' + '*'"
                      :options="apis"
                      :hash="transmissionservice.apiHash"
                      @onChange="transmissionservice.apiHash = $event"
                    ></live-select>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col">
                    <label for="formGroupExampleInput5"
                      >{{
                        $t(
                          "views.client.transmission.transmissionservice.general.content.serviceData"
                        )
                      }}*
                    </label>
                    <a
                      href="javascript:void(null)"
                      @click="addServiceDataItem"
                      class="action-fields"
                      >+ {{ $t("views.shared.add") }}</a
                    >
                    <div v-for="(item, counter) in serviceData" :key="counter">
                      <input
                        type="text"
                        class="form-control"
                        v-model="item.name"
                      />
                      <a
                        href="javascript:void(null)"
                        @click="removeServiceDataItem(counter)"
                        class="action-fields"
                        >- {{ $t("views.shared.remove") }}</a
                      >
                    </div>
                  </div>
                  <div class="col form-check form-check-inline">
                    <input
                      class="form-check-input"
                      id="isExternalId"
                      type="radio"
                      v-model="serviceType"
                      value="0"
                    />
                    <label class="form-check-label" for="isExternalId">{{
                      $t(
                        "views.client.transmission.transmissionservice.general.content.isExternal"
                      )
                    }}&nbsp;&nbsp;</label>
                    <input
                      class="form-check-input"
                      id="isInternalId"
                      type="radio"
                      v-model="serviceType"
                      value="1"
                    />
                    <label class="form-check-label" for="isInternalId">{{
                      $t(
                        "views.client.transmission.transmissionservice.general.content.isInternal"
                      )
                    }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group align-right block-out">
          <button
            class="
              teros_principal__content__column__info__form__table__plus
              mb0
            "
          >
            <img src="/static/img/icons/disquete.png" alt="Salvar" />{{
              $t("views.shared.saveButton")
            }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { cognito, http, router } from "@/_helpers";
import LiveSelect from "@/_components/LiveSelect.vue";
import CustomInput from "@/_components/CustomInput.vue";
import moment from "moment";

export default {
  data() {
    return {
      currentUser: cognito.currentUserValue,
      newObj: true,
      transmissionservice: { active: true },
      resourceTypes: [],
      permissions: [],
      apis: [],
      serviceData: [],
      serviceType: 0,
    };
  },
  components: {
    LiveSelect,
    CustomInput,
  },
  computed: {
    creationDate() {
      if (this.transmissionservice.creationDate) {
        return moment(String(this.transmissionservice.creationDate)).format(
          "DD/MM/YYYY"
        );
      }
      return "";
    },
    creationTime() {
      if (this.transmissionservice.creationDate) {
        return moment(String(this.transmissionservice.creationDate)).format(
          "HH:mm"
        );
      }
      return "";
    },
    lastUpdateDate() {
      if (this.transmissionservice.lastUpdateDate) {
        return moment(String(this.transmissionservice.lastUpdateDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
    lastUpdateTime() {
      if (this.transmissionservice.lastUpdateDate) {
        return moment(String(this.transmissionservice.lastUpdateDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("HH:mm");
      }
      return "";
    },
  },
  mounted() {
    this.$emit("message", this.$route);
    const hash = this.$route.query.hash;
    if (hash) {
      this.newObj = false;
      http.get("/api/transmissionservice/get/" + hash).then((response) => {
        this.transmissionservice = response.data;
        this.getPermissions(this.transmissionservice.resourceTypeHash);
        let serviceDataList = this.transmissionservice.serviceData.split("///");
        serviceDataList.forEach((item) => {
          this.serviceData.push({
            name: item,
          });
        });
        if (this.transmissionservice.isExternal) {
          this.serviceType = 0;
        } else {
          this.serviceType = 1;
        }
      });
    } else {
      this.transmissionservice.transmissionHash = this.currentUser.customerHash;
    }
    http.get("/api/resourcetype/list").then((response) => {
      this.resourceTypes = response.data;
    });
    http.get("/api/api/list").then((response) => {
      this.apis = response.data;
    });
  },
  methods: {
    goBack() {
      router.push({
        name: "transmissionservicespage",
        query: { hash: this.transmissionservice.transmissionHash },
      });
    },
    addServiceDataItem() {
      if (this.serviceData.length <= 50) {
        this.serviceData.push({
          name: "",
        });
      }
    },
    removeServiceDataItem(counter) {
      this.serviceData.splice(counter, 1);
    },
    createOrUpdate() {
      this.transmissionservice.serviceData = this.serviceData
        .filter((e) => e.name != "")
        .map((e) => e.name)
        .join("///");
      if (this.serviceType == 0) {
        this.transmissionservice.isExternal = true;
        this.transmissionservice.isInternal = false;
      } else {
        this.transmissionservice.isInternal = true;
        this.transmissionservice.isExternal = false;
      }
      if (this.newObj) {
        http
          .post("/api/transmissionservice/create/", this.transmissionservice)
          .then(() => {
            this.goBack();
            this.$emit("success");
          });
      } else {
        http
          .put("/api/transmissionservice/update/", this.transmissionservice)
          .then(() => {
            this.goBack();
            this.$emit("success");
          });
      }
    },
    getPermissions(resourceTypeHash) {
      this.transmissionservice.resourceTypeHash = resourceTypeHash;
      http.get(`/api/permission/list/${resourceTypeHash}`).then((response) => {
        this.permissions = response.data;
      });
    },
  },
};
</script>