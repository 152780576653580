var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "teros_principal__content interna container" },
    [
      _c("div", { staticClass: "teros_principal__content__line-1" }, [
        _c(
          "div",
          { staticClass: "teros_principal__content__line-1__highlights" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__line-1__highlights__subject",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__line-1__highlights__subject__breadcrumb",
                  },
                  [
                    _c(
                      "h3",
                      {
                        staticClass:
                          "teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t("views.client.reception.list.client")
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "h2",
                      {
                        staticClass:
                          "teros_principal__content__line-1__highlights__subject__breadcrumb__title active",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.currentUser.customerName) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "teros_principal__content__column" }, [
        _c(
          "div",
          { staticClass: "teros_principal__content__column__menu-vertical" },
          [
            _c(
              "ul",
              {
                staticClass:
                  "teros_principal__content__column__menu-vertical__list",
              },
              [
                _c(
                  "li",
                  {
                    staticClass:
                      "teros_principal__content__column__menu-vertical__list__item",
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass:
                          "teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--lupa active",
                        attrs: { href: "/receptions" },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("views.client.reception.reception.tabTitle")
                          )
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "teros_principal__content__column__info" }, [
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.makeTextSearch.apply(null, arguments)
                },
              },
            },
            [
              _c("div", { staticClass: "teros_principal__content__table" }, [
                _c(
                  "div",
                  {
                    staticClass: "teros_principal__content__table__header pt0",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "teros_principal__content__table__header__filters",
                      },
                      [
                        _c("div", { staticClass: "form-group" }, [
                          _c("div", { staticClass: "search-group" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.textSearchValue,
                                  expression: "textSearchValue",
                                },
                              ],
                              staticClass: "input-field",
                              attrs: {
                                type: "text",
                                placeholder: _vm.$t(
                                  "views.shared.searchBar.searchLabel"
                                ),
                                maxlength: "30",
                              },
                              domProps: { value: _vm.textSearchValue },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.textSearchValue = $event.target.value
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("button", { staticClass: "search-button" }),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("itens-per-page", {
                          attrs: {
                            "request-pagination": _vm.requestPagination,
                            callback: _vm.makeTextSearch,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", {
                      staticClass:
                        "teros_principal__content__table__header__actions",
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "teros_principal__content__table__body" },
                  [
                    _c(
                      "table",
                      {
                        staticClass:
                          "teros_principal__content__table__body__table",
                        attrs: {
                          id: "table",
                          "data-toggle": "table",
                          "data-height": "600",
                          "data-url": "",
                        },
                      },
                      [
                        _c("thead", [
                          _c("tr", [
                            _c(
                              "th",
                              {
                                attrs: {
                                  "data-field": "id",
                                  "data-sortable": "true",
                                },
                              },
                              [_vm._v("ID")]
                            ),
                            _vm._v(" "),
                            _c("th", { attrs: { "data-field": "a" } }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.$t(
                                      "views.client.reception.list.table.header.name"
                                    )
                                  ) +
                                  "\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("th", { attrs: { "data-field": "c" } }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.$t(
                                      "views.client.reception.list.table.header.type"
                                    )
                                  ) +
                                  "\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("th", { attrs: { "data-field": "e" } }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.$t(
                                      "views.shared.table.header.createdAt"
                                    )
                                  ) +
                                  "\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("th", { attrs: { "data-field": "f" } }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.$t(
                                      "views.shared.table.header.changedAt"
                                    )
                                  ) +
                                  "\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticStyle: { "text-align": "center" },
                                attrs: { "data-field": "h" },
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm.$t(
                                        "views.shared.table.header.options"
                                      )
                                    ) +
                                    "\n                  "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticStyle: { "text-align": "center" },
                                attrs: { "data-field": "i" },
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm.$t(
                                        "views.shared.table.header.deactivateActivate"
                                      )
                                    ) +
                                    "\n                  "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "tbody",
                          _vm._l(_vm.receptions, function (item, index) {
                            return _c("tr", { key: item.hash }, [
                              _c("td", [_vm._v(_vm._s(index + 1))]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(item.name))]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(item.category))]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatDate")(item.creationDate)
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatDate")(item.lastUpdateDate)
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    width: "102px",
                                    "padding-left": "0",
                                    "padding-right": "0",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "teros_principal__content__table__body__table__options",
                                    },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass:
                                            "teros_principal__content__table__body__table__options__item",
                                          attrs: {
                                            to: "/reception?hash=" + item.hash,
                                            "data-toggle": "tooltip",
                                            "data-placement": "top",
                                            title: "Visualizar/Editar",
                                          },
                                        },
                                        [
                                          _c("div", [
                                            _c("img", {
                                              attrs: {
                                                src: "/static/img/icons/editar.svg",
                                                alt: "",
                                              },
                                            }),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "teros_principal__content__table__body__table__options__item",
                                          attrs: {
                                            "data-toggle": "modal",
                                            "data-target": "#deleteModal",
                                            "data-placement": "top",
                                            title: "Deletar",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.prepareRemove(
                                                item.hash,
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [_vm._m(0, true)]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "custom-control custom-switch",
                                      attrs: {
                                        "data-toggle": "tooltip",
                                        "data-placement": "top",
                                        title: "Desativar/Ativar",
                                      },
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: item.active,
                                            expression: "item.active",
                                          },
                                        ],
                                        staticClass: "custom-control-input",
                                        attrs: {
                                          type: "checkbox",
                                          id: "customSwitch" + index,
                                        },
                                        domProps: {
                                          checked: Array.isArray(item.active)
                                            ? _vm._i(item.active, null) > -1
                                            : item.active,
                                        },
                                        on: {
                                          change: [
                                            function ($event) {
                                              var $$a = item.active,
                                                $$el = $event.target,
                                                $$c = $$el.checked
                                                  ? true
                                                  : false
                                              if (Array.isArray($$a)) {
                                                var $$v = null,
                                                  $$i = _vm._i($$a, $$v)
                                                if ($$el.checked) {
                                                  $$i < 0 &&
                                                    _vm.$set(
                                                      item,
                                                      "active",
                                                      $$a.concat([$$v])
                                                    )
                                                } else {
                                                  $$i > -1 &&
                                                    _vm.$set(
                                                      item,
                                                      "active",
                                                      $$a
                                                        .slice(0, $$i)
                                                        .concat(
                                                          $$a.slice($$i + 1)
                                                        )
                                                    )
                                                }
                                              } else {
                                                _vm.$set(item, "active", $$c)
                                              }
                                            },
                                            function ($event) {
                                              return _vm.changeStatus(item.hash)
                                            },
                                          ],
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("label", {
                                        staticClass: "custom-control-label",
                                        attrs: { for: "customSwitch" + index },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ])
                          }),
                          0
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "teros_principal__content__table__footer" },
                  [
                    _vm.loading
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "teros_principal__content__table__footer__loader",
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "/static/img/icons/ajax-loader.gif",
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.$t("views.shared.loadingResults"))
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("pagination", {
                      attrs: {
                        "request-pagination": _vm.requestPagination,
                        callback: _vm.makeTextSearch,
                        "current-pagination-info": _vm.currentPaginationInfo,
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "teros_principal__content__table__footer__pagination",
                      },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "teros_principal__content__table__footer__add-item",
                            attrs: { to: "/reception", title: "Nova Recepção" },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "/static/img/icons/plus.png",
                                alt: "",
                              },
                            }),
                            _vm._v(
                              _vm._s(_vm.$t("views.shared.includeButton")) +
                                "\n              "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal fade teros-modal",
          attrs: {
            id: "deleteModal",
            tabindex: "-1",
            "aria-labelledby": "deleteModalLabel",
            "aria-hidden": "true",
          },
        },
        [
          _c("div", { staticClass: "modal-dialog" }, [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(1),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("views.shared.modal.delete.confirmMessage")) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button", "data-dismiss": "modal" },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t("views.shared.modal.delete.cancelButton")
                        ) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-primary--red",
                    attrs: { type: "button", "data-dismiss": "modal" },
                    on: { click: _vm.remove },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t("views.shared.modal.delete.deleteButton")
                        ) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", { attrs: { src: "/static/img/icons/deletar.svg", alt: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        { staticClass: "modal-title", attrs: { id: "deleteModalLabel" } },
        [
          _c("img", {
            attrs: { src: "/static/img/icons/atencao-modal-red.png", alt: "" },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }