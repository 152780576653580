<template>
  <div class="teros_principal__content interna container">
    <div class="teros_principal__content__line-1 line">
      <div class="teros_principal__content__line-1__highlights">
        <div class="teros_principal__content__line-1__highlights__subject">
          <div class="
                                      teros_principal__content__line-1__highlights__subject__breadcrumb
                                    ">
            <router-link to="/receptions">
              <h3 class="
                                          teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle
                                        ">
                {{ $t("views.client.reception.list.client") }}
              </h3>
              <h2 class="
                                          teros_principal__content__line-1__highlights__subject__breadcrumb__title
                                        ">
                {{ reception.customerName }}
              </h2>
            </router-link>
          </div>
          <div class="teros_principal__arrow"></div>
          <div class="
                                      teros_principal__content__line-1__highlights__subject__breadcrumb
                                    ">
            <router-link :to="'/reception?hash=' + reception.hash">
              <h3 class="
                                          teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle
                                        ">
                {{ $t("views.client.reception.reception.title") }}
              </h3>
              <h2 class="
                                          teros_principal__content__line-1__highlights__subject__breadcrumb__title
                                        ">
                {{ reception.integrationName }}
              </h2>
            </router-link>
          </div>
          <div class="teros_principal__arrow"></div>
          <div class="
                                      teros_principal__content__line-1__highlights__subject__breadcrumb
                                    ">
            <h3 class="
                                        teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle
                                      ">
              {{ $t("views.client.reception.receptionservice.title") }}
            </h3>
            <h2 v-if="newObj" class="
                                        teros_principal__content__line-1__highlights__subject__breadcrumb__title
                                        active
                                      ">
              {{ $t("views.client.reception.receptionservice.newObject") }}
            </h2>
            <h2 v-else class="
                                        teros_principal__content__line-1__highlights__subject__breadcrumb__title
                                        active
                                      ">
              {{ receptionservice.name }}
            </h2>
          </div>
        </div>
      </div>

      <div class="teros_principal__content__line-1__information">
        <div class="teros_principal__content__line-1__information__time">
          <p v-if="!newObj" class="teros_principal__content__line-1__information__time__text">
            <img class="
                            teros_principal__content__line-1__information__time__text__icon
                        " src="/static/img/icons/relogio.png" alt="" />
            {{
              $t("views.shared.createdDate", {
                date: creationDate,
                time: creationTime,
              })
            }}
            {{
              lastUpdateDate
              ? "| " +
              $t("views.shared.changedDate", {
                date: lastUpdateDate,
                time: lastUpdateTime,
              })
              : ""
            }}
          </p>

          <button type="button" @click="goBack" class="teros_principal__content__line-1__information__time__btn">
            <img src="/static/img/icons/seta-esquerda.png" alt="" />
            {{ $t("views.shared.backButton") }}
          </button>
        </div>
      </div>
    </div>

    <div id="accordion">
      <form @submit.prevent="createOrUpdate" class="
                                  teros_principal__content__accordion__card__header__collapse__body__form
                                " method="post">
        <div class="teros_principal__content__accordion__card">
          <div class="teros_principal__content__accordion__card__header" id="headingOne">
            <h2 class="teros_principal__content__accordion__card__header__title">
              <button class="
                                          teros_principal__content__accordion__card__header__title__btn
                                        " type="button" data-toggle="collapse" data-target="#collapseOne"
                aria-expanded="false" aria-controls="collapseOne">
                {{
                  $t(
                    "views.client.reception.receptionservice.content.general.title"
                  )
                }}
              </button>
              <hr />
            </h2>
          </div>

          <div id="collapseOne" class="teros_principal__content__accordion__card__header__collapse">
            <div class="
                                        teros_principal__content__accordion__card__header__collapse__body
                                      ">
              <div class="
                                          teros_principal__content__accordion__card__header__collapse__body__form
                                        ">
                <div class="form-group row">
                  <div class="col">
                    <div class="custom-control custom-switch">
                      <input type="checkbox" class="custom-control-input" id="customSwitch1"
                        v-model="receptionservice.active" />
                      <label class="custom-control-label" for="customSwitch1">{{
                        $t("views.shared.activeSwitch")
                      }}</label>
                    </div>

                    <div class="
                                                teros_principal__content__accordion__card__header__collapse__body__form__column__fields
                                              ">
                      <div class="form-group">
                        <label v-if="!newObj" for="formGroupExampleInput">{{
                          $t(
                            "views.client.reception.receptionservice.content.general.service"
                          )
                        }}</label>
                        <input v-if="!newObj" type="text" class="
                                                    form-control
                                                    teros_principal__content__column__info__form__input
                                                  " id="formGroupExampleInput" v-model="receptionservice.name"
                          readonly />
                        <live-select v-if="newObj" id="selectServices" :title="$t(
                          'views.client.reception.receptionservice.content.general.service'
                        ) + '*'
                          " :options="services" :hash="receptionservice.serviceHash"
                          @onChange="onChangeService"></live-select>
                      </div>
                      <br />
                      <div class="form-group">
                        <label for="formGroupExampleInput5">{{
                          $t(
                            "views.client.reception.receptionservice.content.general.commercialName"
                          )
                        }}
                        </label>
                        <input type="text" class="form-control" id="formGroupExampleInput5"
                          v-model="receptionservice.commercialName" readonly />
                      </div>
                      <div class="form-group">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input" id="customSwitch2"
                            v-model="receptionservice.store" />
                          <label class="custom-control-label" for="customSwitch2">{{
                            $t(
                              "views.client.reception.receptionservice.content.general.storeSwitch"
                            )
                          }}</label>
                        </div>
                      </div>
                      <div v-if="receptionservice.store" class="form-group">
                        <label for="formGroupExampleInput1">{{
                          $t(
                            "views.client.reception.receptionservice.content.general.storeTime"
                          )
                        }}
                          <img src="/static/img/icons/tooltip.png" data-toggle="tooltip" data-placement="right" :title="$t(
                                'views.client.reception.receptionservice.toolTips.general.storageTimeLimit'
                              )
                              " /></label>
                        <custom-input :id="'formGroupExampleInput1'" :type="'number'" :regex="'[0-9]'"
                          v-model="receptionservice.timetoStore"></custom-input>
                      </div>
                      <div v-if="receptionservice.store" class="form-group">
                        <div class="custom-control custom-switch">
                          <input type="checkbox" class="custom-control-input" id="customSwitch3"
                            v-model="receptionservice.anonymize" />
                          <label class="custom-control-label" for="customSwitch3">{{
                            $t(
                              "views.client.reception.receptionservice.content.general.anonymizationSwitch"
                            )
                          }}</label>
                        </div>
                      </div>
                      <div v-if="receptionservice.store && receptionservice.anonymize
                          " class="form-group">
                        <label for="formGroupExampleInput2">{{
                          $t(
                            "views.client.reception.receptionservice.content.general.anonymizationType"
                          )
                        }}
                          <img src="/static/img/icons/tooltip.png" data-toggle="tooltip" data-placement="right" :title="$t(
                                'views.client.reception.receptionservice.toolTips.general.anonType'
                              )
                              " /></label>
                        <select class="form-control" id="exampleFormControlSelect1"
                          v-model.number="receptionservice.anonymizeType">
                          <option value="1">
                            {{
                              $t(
                                "views.client.reception.receptionservice.content.general.anonymizationTypesDropbox.immediate"
                              )
                            }}
                          </option>
                          <option value="2">
                            {{
                              $t(
                                "views.client.reception.receptionservice.content.general.anonymizationTypesDropbox.obfuscation"
                              )
                            }}
                          </option>
                          <option value="3">
                            {{
                              $t(
                                "views.client.reception.receptionservice.content.general.anonymizationTypesDropbox.scheduled"
                              )
                            }}
                          </option>
                        </select>
                      </div>
                      <div v-if="receptionservice.store &&
                          receptionservice.anonymize &&
                          receptionservice.anonymizeType == 3
                          " class="form-group">
                        <label for="formGroupExampleInput3">{{
                          $t(
                            "views.client.reception.receptionservice.content.general.anonymizationTime"
                          )
                        }}
                          <img src="/static/img/icons/tooltip.png" data-toggle="tooltip" data-placement="right" :title="$t(
                                'views.client.reception.receptionservice.toolTips.general.anonTime'
                              )
                              " /></label>
                        <custom-input :id="'formGroupExampleInput3'" :type="'number'" :regex="'[0-9]'"
                          v-model="receptionservice.timeToAnonymize"></custom-input>
                      </div>

                      <div class="
                                                  teros_principal__content__accordion__card__header__collapse__body__form__column__fields
                                                ">
                        <div class="form-group">
                          <label for="formGroupExampleInput4">URL/Endpoint
                            <img src="/static/img/icons/tooltip.png" data-toggle="tooltip" data-placement="right" :title="$t(
                              'views.client.reception.receptionservice.toolTips.general.urlEndpoint'
                            )
                              " /></label>
                          <input type="text" class="form-control" id="formGroupExampleInput4"
                            v-model="receptionservice.endpointUrl" readonly />
                        </div>
                      </div>

                      <!-- <div class="row" v-if="this.receptionservice.serviceHash">
                        <div class="col">
                          <div class="form-group mt-3">
                            <a href="javascript:void(null)" type="button" data-toggle="modal"
                              data-target="#groupingsModal" class="teros_principal__tags__tag">Permissões de Serviço *</a>
                          </div>
                        </div>
                      </div> -->

                      <!-- <button v-if="endpointPermissionsList && endpointPermissionsList.length > 0" type="button"
                        class="teros_principal__content__column__info__form__table__plus" data-toggle="modal"
                        data-target="#endpointsModal">Endpoints Permitidos</button> -->

                    </div>
                  </div>
                  <div class="col">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                      <li class="nav-item">
                        <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab"
                          aria-controls="home" aria-selected="true">Body Request</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab"
                          aria-controls="profile" aria-selected="false">JSON Schema Response</a>
                      </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                      <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <ace-editor v-model="bodyRequest" ref="bodyRequestEditor" lang="json" theme="iplastic"
                          height="450px" style="fontsize: 15px">
                        </ace-editor>
                        <a href="javascript:void(null)" @click="copyBodyRequest" class="copy-code"><img
                            src="/static/img/icons/copy.png" alt="" /></a>
                      </div>
                      <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                        <div class="teros_principal__tags">
                          <a href="javascript:void(null)" @click="showResponseSuccess" class="
                                                      teros_principal__tags__tag
                                                      teros_principal__tags__tag--green
                                                      active
                                                    ">{{
                                                      $t(
                                                        "views.client.reception.receptionservice.content.general.jsonSchemaMsgs.success"
                                                      )
                                                    }}</a>
                          <a href="javascript:void(null)" @click="showResponseError" class="
                                                      teros_principal__tags__tag
                                                      teros_principal__tags__tag--red
                                                    ">{{
                                                      $t(
                                                        "views.client.reception.receptionservice.content.general.jsonSchemaMsgs.error"
                                                      )
                                                    }}</a>
                        </div>
                        <ace-editor v-if="responseSuccessActive" v-model="receptionservice.jsonSchemaResponseSuccess"
                          ref="jsonSchemaResponseEditor" lang="json" theme="dawn" height="600px" style="fontsize: 15px">
                        </ace-editor>
                        <ace-editor v-else v-model="receptionservice.jsonSchemaResponseError"
                          ref="jsonSchemaResponseEditor" lang="json" theme="iplastic" height="600px"
                          style="fontsize: 15px">
                        </ace-editor>
                        <a href="javascript:void(null)" @click="copyJsonResponse" class="copy-code"><img
                            src="/static/img/icons/copy.png" alt="" /></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="formGroupExampleInput9">{{
                    $t(
                      "views.client.reception.receptionservice.content.general.serviceData"
                    )
                  }}
                  </label>
                  <input type="text" class="form-control" id="formGroupExampleInput9" v-model="serviceData" readonly />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="teros_principal__content__accordion__card" v-if="receptionservice.serviceConsentiment">
          <div class="teros_principal__content__accordion__card__header" id="headingTwo">
            <h2 class="teros_principal__content__accordion__card__header__title">
              <button class="
                                          teros_principal__content__accordion__card__header__title__btn
                                        " type="button" data-toggle="collapse" data-target="#collapseTwo"
                aria-expanded="false" aria-controls="collapseTwo">
                {{
                  $t(
                    "views.client.reception.receptionservice.content.compliance.title"
                  )
                }}
              </button>
              <hr />
            </h2>
          </div>

          <div id="collapseTwo" class="teros_principal__content__accordion__card__header__collapse">
            <div class="
                                        teros_principal__content__accordion__card__header__collapse__body
                                        pt0
                                      " style="padding: 0 40px">
              <div class="teros_principal__content__table">
                <div class="form-group row" style="padding-top: 40px">
                  <div class="col">
                    <div class="custom-control custom-switch">
                      <input type="checkbox" class="custom-control-input" id="customSwitch4"
                        v-model="receptionservice.consentiment" @click="onChangeConsentiment" />
                      <label class="custom-control-label" for="customSwitch4">{{
                        $t(
                          "views.client.reception.receptionservice.content.compliance.consentSwitch"
                        )
                      }}</label>
                    </div>
                  </div>
                </div>

                <div class="form-group row" v-if="receptionservice.consentiment">
                  <div class="col">
                    <label for="formGroupExampleInput6">{{
                      $t(
                        "views.client.reception.receptionservice.content.compliance.validityConsent"
                      )
                    }}
                      <img src="/static/img/icons/tooltip.png" data-toggle="tooltip" data-placement="right" :title="$t(
                            'views.client.reception.receptionservice.toolTips.compliance.consentValidity'
                          )
                          " /></label>
                    <custom-input :id="'formGroupExampleInput6'" :type="'number'" :regex="'[0-9]'"
                      v-model="receptionservice.consentimentExpiration"></custom-input>
                  </div>

                  <div class="col">
                    <label for="formGroupExampleInput7">{{
                      $t(
                        "views.client.reception.receptionservice.content.compliance.purposes"
                      )
                    }}
                    </label>
                    <a href="javascript:void(null)" @click="addPurpose" class="action-fields">+ {{ $t("views.shared.add")
                    }}</a>
                    <div v-for="(item, counter) in purposes" :key="counter">
                      <input type="text" class="form-control" v-model="item.name" />
                      <a href="javascript:void(null)" @click="removePurpose(counter)" class="action-fields">- {{
                        $t("views.shared.remove") }}</a>
                    </div>
                  </div>
                </div>

                <div class="form-group row" v-if="receptionservice.consentiment">
                  <div class="col">
                    <label for="formGroupExampleInput8">{{
                      $t(
                        "views.client.reception.receptionservice.content.compliance.complianceStandards"
                      )
                    }}
                      <img src="/static/img/icons/tooltip.png" data-toggle="tooltip" data-placement="right" :title="$t(
                            'views.client.reception.receptionservice.toolTips.compliance.compliancePattern'
                          )
                          " /></label>

                    <select class="form-control" v-model="receptionservice.standardHash"
                      :disabled="standards == null || standards.length < 1" @change="onChangeStandard($event)">
                      <option v-for="item in standards" :value="item.hash" :key="item.hash">
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div v-if="!newObj && receptionservice.consentiment">
                  <div class="teros_principal__content__table__header">
                    <div class="teros_principal__content__table__header__filters" v-if="showTerms">
                      <div class="form-group">
                        <div class="search-group">
                          <input type="text" class="input-field" :placeholder="$t('views.shared.searchBar.searchLabel')
                            " maxlength="30" onkeydown="return event.keyCode == 13 ? event.preventDefault() : true"
                            v-model="textSearchValue" />
                          <button @click="makeTextSearch()" type="button" class="search-button" />
                        </div>
                      </div>

                      <itens-per-page :request-pagination="requestPagination" :callback="makeTextSearch"></itens-per-page>
                    </div>

                    <div class="teros_principal__content__table__header__actions"></div>
                    <div class="col" v-if="!newObj">
                      <a href="javascript:void(null)" style="width: 300px; margin-left: auto" class="
                                                  show_tiny
                                                  teros_principal__content__column__info__form__combo__btn-contrato
                                                " data-toggle="modal" data-target="#resetModal" data-placement="top">
                        {{
                          $t(
                            "views.client.reception.receptionservice.content.compliance.consentButtons.restoreRecommendedCompliance"
                          )
                        }}
                      </a>
                    </div>
                  </div>

                  <div class="teros_principal__content__table__body" v-if="showTerms">
                    <table id="table" class="teros_principal__content__table__body__table" data-toggle="table"
                      data-height="600" data-url="">
                      <thead>
                        <tr>
                          <th data-field="id" data-sortable="true">
                            {{
                              $t(
                                "views.client.reception.receptionservice.content.compliance.table.header.ordering"
                              )
                            }}
                          </th>
                          <th data-field="a">
                            {{
                              $t(
                                "views.client.reception.receptionservice.content.compliance.table.header.standard"
                              )
                            }}
                          </th>
                          <th data-field="d">
                            {{ $t("views.shared.table.header.createdAt") }}
                          </th>
                          <th data-field="e">
                            {{ $t("views.shared.table.header.changedAt") }}
                          </th>
                          <th data-field="f">Status</th>
                          <th data-field="g" style="text-align: center">
                            {{ $t("views.shared.table.header.options") }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in terms" :key="item.hash">
                          <td>{{ item.orderNumber }}</td>
                          <td>{{ item.name }}</td>
                          <td>{{ item.creationDate | formatDate }}</td>
                          <td>{{ item.lastUpdateDate | formatDate }}</td>
                          <td :class="item.authorizationStatus | formatClass">
                            {{ statusTranslated(item.authorizationStatus) }}
                          </td>

                          <td style="text-align: center">
                            <div class="
                                                        teros_principal__content__table__body__table__options
                                                        mult-opt
                                                      ">
                              <router-link :to="'/servicepartnershipterm?receptionServiceHash=' +
                                receptionservice.hash +
                                '&hash=' +
                                item.hash
                                " class="
                                                          teros_principal__content__table__body__table__options__item
                                                        " data-toggle="tooltip" data-placement="top"
                                title="Visualizar/Editar">
                                <div>
                                  <img src="/static/img/icons/editar.svg" alt="" />
                                </div>
                              </router-link>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="teros_principal__content__table__footer" v-if="showTerms">
                    <div v-if="loading" class="teros_principal__content__table__footer__loader">
                      <img src="/static/img/icons/ajax-loader.gif" alt="" />
                      <span>{{ $t("views.shared.loadingResults") }}</span>
                    </div>

                    <pagination :request-pagination="requestPagination" :callback="makeTextSearch"
                      :current-pagination-info="currentPaginationInfo"></pagination>
                    <div class="
                                                teros_principal__content__table__footer__pagination
                                              ">
                      <router-link :to="'/servicepartnershipterm?receptionServiceHash=' +
                        receptionservice.hash
                        " title="Novo Termo" class="
                                                  teros_principal__content__table__footer__add-item
                                                ">
                        <img src="/static/img/icons/plus.png" alt="" />{{
                          $t("views.shared.includeButton")
                        }}
                      </router-link>
                    </div>
                  </div>
                </div>

                <div class="group-form-group" v-if="receptionservice.standardHash != null &&
                    receptionservice.consentiment
                    ">
                  <div class="form-group row">
                    <div class="col">
                      <a href="javascript:void(null)" @click="showOrHideTiny" style="width: 270px" class="
                                                  show_tiny
                                                  teros_principal__content__column__info__form__combo__btn-contrato
                                                ">
                        {{
                          $t(
                            "views.client.reception.receptionservice.content.compliance.consentButtons.editConsentLayout"
                          )
                        }}
                      </a>
                    </div>

                    <div>
                      <div v-if="receptionservice.consentimentFileName" class="col">
                        <pdf-view :file-name="receptionservice.consentimentFileName" :type="'button'" :title="$t(
                          'views.client.reception.receptionservice.content.compliance.consentButtons.viewConsent'
                        )
                          "></pdf-view>
                      </div>
                      <div v-else-if="receptionservice.hash" class="col">
                        <button type="button" class="
                                                    teros_principal__content__column__info__form__combo__btn-contrato
                                                  " style="width: 270px" @click="getfileUrl">
                          {{
                            $t(
                              "views.client.reception.receptionservice.content.compliance.consentButtons.viewConsent"
                            )
                          }}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div v-if="showTiny" class="form-group row">
                    <div class="col">
                      <editor api-key="no-api-key" v-model="receptionservice.consentimentLayout" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group align-right block-out">
          <button class="
                                      teros_principal__content__column__info__form__table__plus
                                      mb0
                                    ">
            <img src="/static/img/icons/disquete.png" alt="Salvar" />{{
              $t("views.shared.saveButton")
            }}
          </button>
        </div>
      </form>
    </div>

    <div class="modal fade teros-modal" id="resetModal" tabindex="-1" aria-labelledby="resetModalLabel"
      aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="resetModalLabel">
              <img src="/static/img/icons/atencao-modal.png" alt="" />
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            {{
              $t(
                "views.client.reception.receptionservice.modal.restore.confirmMessage"
              )
            }}
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              {{ $t("views.shared.modal.delete.cancelButton") }}
            </button>
            <button type="button" class="btn btn-primary" data-dismiss="modal" v-on:click="termsReset">
              {{
                $t(
                  "views.client.reception.receptionservice.modal.restore.restoreButton"
                )
              }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade teros-modal" id="groupingsModal" tabindex="-1" aria-labelledby="groupingModalLabel"
      aria-hidden="true" data-backdrop="static">
      <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header" id="groupingModalLabel">
            <h5 class="modal-title">Configurações de Permissões</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" style="text-align: start !important;" v-if="permissions.length > 0">
            <div class="row">
              <div class="col-12">
                <div class="form-group" v-for="(rsPermission, index) in receptionServicePermissions" :key="index">
                  <strong v-if="rsPermission[0]">{{ getPermissionNameByPermissionHash(rsPermission[0].permissionHash)
                  }}</strong>

                  <div class="row mt-3" v-for="(item, counter) in receptionServicePermissions[index]" :key="counter">
                    <div class="col-4">
                      <label for="formGroupPermission">
                        Versão

                        <img src="/static/img/icons/tooltip.png" data-toggle="tooltip" data-placement="right"
                          title="Title" />
                      </label>
                      <input type="text" class="form-control" id="formGroupPermission" placeholder="Versão" width="250px"
                        v-model="receptionServicePermissions[index][counter].version"
                        :disabled="receptionServicePermissions[index][counter].disabled" />
                    </div>

                    <div class="col-8">
                      <label for="formGroupPermission">
                        Endpoint Path
                        <img src="/static/img/icons/tooltip.png" data-toggle="tooltip" data-placement="right"
                          title="Title" />
                      </label>
                      <input type="text" class="form-control" id="formGroupPermission" width="250px" placeholder="Path"
                        v-model="receptionServicePermissions[index][counter].path" />
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <a href="javascript:void(null)"
                        @click="addReceptionServicePermission(rsPermission[0].permissionHash, index)"
                        class="action-fields">+ {{
                          $t("views.shared.add") }}</a>
                      <a href="javascript:void(null)" @click="removeReceptionServicePermission(index)"
                        class="action-fields">- {{ $t("views.shared.remove") }}</a>
                    </div>
                  </div>

                  <hr />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              {{ $t("views.shared.modal.delete.cancelButton") }}
            </button>
            <button type="button" class="btn btn-primary" data-dismiss="modal"
              v-on:click="saveReceptionServicePermissions">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade teros-modal" id="endpointsModal" tabindex="-1" aria-labelledby="endpointsModalLabel"
      aria-hidden="true" data-backdrop="static">
      <div class="modal-dialog modal-dialog-scrollable" style="min-width: 700px !important;">
        <div class="modal-content">
          <div class="modal-header" id="endpointsModalLabel">
            <h5 class="modal-title">Configurações de Endpoints</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" style="text-align: start !important;" v-if="endpointPermissionsList.length > 0">
            <div class="row">
              <div class="col-12">
                <div v-for="(endpointPermission, index) of endpointPermissionsList">

                  <p>
                    <a class="action-fields" data-toggle="collapse" :href="'#multiCollapseExample1' + index" role="button"
                      aria-expanded="true" :aria-controls="'multiCollapseExample1' + index">
                      <h5>
                        <strong style="text-transform: lowercase;">
                          {{ getPermissionNameByPermissionHash(endpointPermission.permissionHash) }}</strong>
                      </h5>
                    </a>
                  </p>
                  <div class="row">
                    <div class="col-12">
                      <div class="collapse" :id="'multiCollapseExample1' + index">
                        <div class="card card-body">
                          <div v-if="endpointPermission.endpoints && endpointPermission.endpoints.length > 0">
                            <div class="form-group row"
                              v-for="(endpoint, indexPermission) of endpointPermission.endpoints">
                              <div class="col-9">
                                <label>
                                  Endpoint
                                </label>
                                <input type="text" required class="form-control col-11 mb-3" id="formGroupEndpontInput"
                                  v-model="endpoint.endpoint" disabled>
                              </div>

                              <div class="col-3">
                                <label>
                                  Versão
                                </label>
                                <input type="text" required class="form-control col-11 mb-3" id="formGroupEndpontInput"
                                  v-model="endpoint.version" disabled>
                              </div>

                              <!-- <div class="col-2 d-flex align-items-center justify-content-center">
                                <div class="custom-control custom-switch">
                                  <input type="checkbox" class="custom-control-input" id="customSwitch1"
                                    v-model="endpoint.active"
                                    @click="onChangeStatusServicePermissionEndpoint(endpointPermissionsList[index].endpoints[indexPermission], indexPermission)" />
                                  <label class="custom-control-label" for="customSwitch1">{{
                                    $t("views.shared.activeSwitch")
                                  }}</label>
                                </div>
                              </div> -->

                              <!-- <a href="javascript:void(null)" class="action-fields"
                                @click="addEndpointPermissionVersion(endpoint, index, indexPermission)">
                                <img src="/static/img/icons/plus.png" alt="" />
                                Adicionar Versão
                              </a> -->
                            </div>
                          </div>
                          <div v-else>
                            <h5>Não existem endpoints configurados para essa permissão</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              {{ $t("views.shared.modal.delete.cancelButton") }}
            </button>
            <button type="button" class="btn btn-primary" data-dismiss="modal" v-on:click="">
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { http, router, i18n } from "@/_helpers";
import PdfView from "@/_components/PdfView.vue";
import Editor from "@tinymce/tinymce-vue";
import Pagination from "@/_components/Pagination.vue";
import ItensPerPage from "@/_components/ItensPerPage.vue";
import LiveSelect from "@/_components/LiveSelect.vue";
import Ajv from "ajv";
import JsonSchemaResponseSuccess from "@/../static/json/JsonSchemaResponseSuccess.json";
import AceEditor from "vue2-ace-editor";
import CustomInput from "@/_components/CustomInput.vue";
import moment from 'moment';

require("brace/ext/language_tools");
require("brace/mode/json");
require("brace/theme/dawn");
require("brace/theme/iplastic");

var localize = {
  "en-US": require("ajv-i18n/localize/en"),
  "pt-BR": require("ajv-i18n/localize/pt-BR"),
  "es-ES": require("ajv-i18n/localize/es"),
};
const ajv = new Ajv({ allErrors: true });
const JsonSchemaResponseSuccessValidator = ajv.compile(
  JsonSchemaResponseSuccess
);
const { safeJsonParse } = require("better-error-message-for-json-parse");

export default {
  data() {
    return {
      newObj: true,
      reception: {},
      receptionservice: {
        active: true,
        consentiment: false,
        anonymize: false,
        tracking: false,
        standardHash: null,
      },
      purposes: [],
      responseSuccessActive: true,
      showTiny: false,
      services: [],
      serviceData: "",
      bodyRequest: "",
      standards: [],
      terms: [],
      termHash: null,
      index: 0,
      loading: true,
      textSearchValue: "",
      oldTextSearchValue: "",
      currentPaginationInfo: null,
      requestPagination: { page: 1, take: 10 },
      showTerms: true,
      groupings: [],
      permissions: [],
      receptionServicePermissions: [],
      endpointPermissionsList: [],
      selectedService: {},
    };
  },
  components: {
    PdfView,
    Editor,
    Pagination,
    ItensPerPage,
    LiveSelect,
    AceEditor,
    CustomInput,
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
    formatClass(value) {
      if (value == 1) {
        return "compativel";
      } else if (value == 2) {
        return "pendente";
      } else if (value == 3) {
        return "nao-compativel";
      }
      return "";
    },
  },
  computed: {
    creationDate() {
      if (this.receptionservice.creationDate) {
        return moment(String(this.receptionservice.creationDate)).format(
          "DD/MM/YYYY"
        );
      }
      return "";
    },
    creationTime() {
      if (this.receptionservice.creationDate) {
        return moment(String(this.receptionservice.creationDate)).format(
          "HH:mm"
        );
      }
      return "";
    },
    lastUpdateDate() {
      if (this.receptionservice.lastUpdateDate) {
        return moment(String(this.receptionservice.lastUpdateDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
    lastUpdateTime() {
      if (this.receptionservice.lastUpdateDate) {
        return moment(String(this.receptionservice.lastUpdateDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("HH:mm");
      }
      return "";
    },
  },
  mounted() {
    this.$refs.bodyRequestEditor.editor.setOptions({ readOnly: true });
    this.$refs.jsonSchemaResponseEditor.editor.session.on("change", () =>
      this.prettyFormat()
    );
    this.showTerms = true;
    this.$emit("message", this.$route);
    const hash = this.$route.query.hash;
    const receptionHash = this.$route.query.receptionHash;
    if (hash) {
      this.newObj = false;
      http.get("/api/receptionservice/get/" + hash).then((response) => {
        this.receptionservice = response.data;
        let purposesList = this.receptionservice.purposes.split("///");
        purposesList.forEach((item) => {
          this.purposes.push({
            name: item,
          });
        });
        if (this.receptionservice.serviceData) {
          this.serviceData = this.receptionservice.serviceData
            .split("///")
            .join(", ");
        }
        this.calculateBodyRequest();
        this.prettyFormat();
        http
          .get("/api/reception/get/" + this.receptionservice.receptionHash)
          .then((response) => {
            this.reception = response.data;
          });
        if (this.receptionservice.serviceConsentiment == false) {
          this.receptionservice.consentiment == false;
        }
        if (this.receptionservice.consentiment) {
          http
            .get(
              "/api/service/list/standards/" + this.receptionservice.serviceHash
            )
            .then((response) => {
              this.standards = response.data;
            });
          http
            .get(
              "/api/servicepartnershipterm/search/" +
              this.receptionservice.hash +
              "?OrderByProperty=OrderNumber",
              false
            )
            .then((response) => {
              this.terms = response.data.items;
              this.currentPaginationInfo = response.data;
            })
            .finally(() => (this.loading = false));
        }

        if (this.receptionservice.serviceHash) {
          // this.getPermissionsByServiceHash(this.receptionservice.serviceHash);
        }

      });
    } else {
      http.get("/api/reception/get/" + receptionHash).then((response) => {
        this.reception = response.data;
        this.receptionservice.receptionHash = this.reception.hash;
        http
          .get("/api/service/list/" + this.reception.integrationHash)
          .then((response) => {
            this.services = response.data;
          });
      });
      this.getGroupings();
    }
  },
  methods: {
    goBack() {
      router.push({
        name: "receptionservicespage",
        query: { hash: this.reception.hash },
      });
    },
    showResponseSuccess() {
      this.responseSuccessActive = true;
      this.$refs.jsonSchemaResponseEditor.editor.setOptions({
        readOnly: false,
      });
    },
    showResponseError() {
      this.responseSuccessActive = false;
      this.$refs.jsonSchemaResponseEditor.editor.setOptions({ readOnly: true });
    },
    showOrHideTiny() {
      this.showTiny = !this.showTiny;
    },
    copyBodyRequest() {
      this.copyVarToClipboard(this.$refs.bodyRequestEditor.editor.getValue());
      Vue.$toast.success(
        this.$i18n.t(
          "views.client.reception.receptionservice.modal.copiedJsonMessage"
        )
      );
    },
    copyJsonResponse() {
      this.copyVarToClipboard(
        this.$refs.jsonSchemaResponseEditor.editor.getValue()
      );
      Vue.$toast.success(
        this.$i18n.t(
          "views.client.reception.receptionservice.modal.copiedJsonMessage"
        )
      );
    },
    copyVarToClipboard(text) {
      var dummy = document.createElement("input");
      document.body.appendChild(dummy);
      dummy.value = text;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
    },
    statusTranslated(value) {
      if (value == 1) {
        return this.$i18n.t(
          "views.client.reception.receptionservice.content.compliance.table.header.status.compatible"
        );
      } else if (value == 2) {
        return this.$i18n.t(
          "views.client.reception.receptionservice.content.compliance.table.header.status.pending"
        );
      } else if (value == 3) {
        return this.$i18n.t(
          "views.client.reception.receptionservice.content.compliance.table.header.status.notCompatible"
        );
      } else {
        return "";
      }
    },
    calculateBodyRequest() {
      let bodyJson = { meta: {}, data: {} };
      bodyJson.meta["receptionServiceHash"] = this.receptionservice.hash;
      let jsonSchema = JSON.parse(this.receptionservice.jsonSchemaRequest);
      Object.values(jsonSchema.queryParams).forEach((value) => {
        bodyJson.data[value] = "string";
      });
      Object.values(jsonSchema.body).forEach((value) => {
        var k = bodyJson.data;
        var steps = value.name.split(".");
        var last = steps.pop();
        steps.forEach((e) => (k[e] = k[e] || {}) && (k = k[e]));
        switch (value.type) {
          case "int":
            k[last] = 0;
            break;
          case "bool":
            k[last] = true;
            break;
          case "list":
            k[last] = ["string"];
            break;
          case "object":
            k[last] = {};
            break;
          default:
            k[last] = value.type;
        }
      });
      Object.values(jsonSchema.headers).forEach((value) => {
        bodyJson.data[value] = "string";
      });
      let pathParams = this.receptionservice.endpointUrl.match(/\{.*?\}/g);
      if (pathParams) {
        pathParams
          .map((x) => x.replace(/[{}]/g, ""))
          .forEach((value) => {
            bodyJson.data[value] = "string";
          });
      }
      this.bodyRequest = JSON.stringify(bodyJson, null, 2);
    },
    onChangeService(value) {
      this.receptionservice.serviceHash = value;
      let serviceSelected = this.services.find(
        (s) => s.hash == this.receptionservice.serviceHash
      );
      this.receptionservice.standardHash = null;
      this.receptionservice.name = serviceSelected.name;
      this.receptionservice.jsonSchemaRequest =
        serviceSelected.jsonSchemaRequest;
      this.receptionservice.jsonSchemaResponseSuccess =
        serviceSelected.jsonSchemaResponseSuccess;
      this.receptionservice.jsonSchemaResponseError =
        serviceSelected.jsonSchemaResponseError;
      this.prettyFormat();
      this.receptionservice.endpointUrl = serviceSelected.endpointUrl;
      this.calculateBodyRequest();
      this.receptionservice.serviceConsentiment = serviceSelected.consentiment;
      if (this.receptionservice.serviceConsentiment == false) {
        this.receptionservice.consentiment == false;
      }
      this.receptionservice.commercialName = serviceSelected.commercialName;
      this.receptionservice.resourceType = serviceSelected.resourceType;
      this.serviceData = "";
      // this.getPermissionsByServiceHash(value);
      this.getServiceByHash(value);

      if (serviceSelected.serviceData) {
        this.serviceData = serviceSelected.serviceData.split("///").join(", ");
      }
      http
        .get("/api/service/list/standards/" + serviceSelected.hash)
        .then((response) => {
          this.standards = response.data;
        });
      if (serviceSelected.consentiment) {
        Vue.$toast.info(
          this.$i18n.t(
            "views.client.reception.receptionservice.modal.alertRecommededCompliance",
            { name: serviceSelected.recommendedStandardName }
          )
        );
      }
      this.$forceUpdate();
    },
    getServiceByHash(hash) {
      http.get(`/api/service/get/${hash}`)
      .then((response) => {
        this.selectedService = response.data;
        this.mockServicePermissionEndpoints();
        this.getManyPermissions(this.selectedService.groupingHashs);
      });
    },
    mockServicePermissionEndpoints() {
      const groupingsFiltered = this.groupings.filter((g) => {
        return this.selectedService.groupingHashs.some((gh) => {
          return gh == g.hash;
        });
      });

      let groupingsFilteredPermissions = groupingsFiltered.map(gf => gf.permissions);

      groupingsFilteredPermissions.forEach(gfp => {
        gfp.forEach(g => {
          let index = this.endpointPermissionsList.findIndex(ep => ep.permissionHash == g.hash);

          if (index == -1) {
            this.selectedService.permissionHashs = [...this.selectedService.permissionHashs, g.hash];

            this.endpointPermissionsList.push({
              permissionHash: g.hash,
              permissionName: g.name,
              endpoints: []
            });
          }
        });
      });

      this.endpointPermissionsList.forEach((ep) => {
        this.getEndpointsByPermissionHash(ep.permissionHash);
      });
    },
    getEndpointsByPermissionHash(permissionHash) {
      let index = this.endpointPermissionsList.findIndex(x => x.permissionHash == permissionHash);
      http.get(`/api/permissionEndpoint/get/${permissionHash}`)
        .then((response) => {
          this.endpointPermissionsList[index].endpoints = [
            ...response.data
              .map(x => {
                return {
                  hash: x.hash,
                  endpoint: x.endpoint,
                  version: 1.0,
                  active: true
                };
              })];
        });
    },
    getManyPermissions(groupingHashs) {
      http.get(`/api/permission/listMany/${groupingHashs}`).then((response) => {
        this.permissions = response.data;
      });
    },
    onChangeStandard() {
      http
        .get(
          "/api/service/get/standard?serviceHash=" +
          this.receptionservice.serviceHash +
          "&standardHash=" +
          this.receptionservice.standardHash
        )
        .then((response) => {
          this.receptionservice.standardHash = response.data.hash;
          this.receptionservice.consentimentLayout =
            response.data.consentimentLayout;
        });
      this.showTerms = false;
    },
    onChangeConsentiment() {
      http
        .get("/api/service/list/standards/" + this.receptionservice.serviceHash)
        .then((response) => {
          this.standards = response.data;
        })
        .finally(() => (this.loading = false));
    },
    addPurpose() {
      this.purposes.push({
        name: "",
      });
    },
    removePurpose(counter) {
      this.purposes.splice(counter, 1);
    },
    createOrUpdate() {
      this.receptionservice.purposes = this.purposes
        .filter((e) => e.name != "")
        .map((e) => e.name)
        .join("///");
      if (this.newObj) {
        http
          .post("/api/receptionservice/create/", this.receptionservice)
          .then((response) => {
            this.receptionservice = response.data;
            let serviceSelected = this.services.find(
              (s) => s.hash == this.receptionservice.serviceHash
            );
            this.receptionservice.serviceConsentiment =
              serviceSelected.consentiment;
            this.calculateBodyRequest();
            this.newObj = false;
            this.loading = true;
            http
              .get(
                "/api/servicepartnershipterm/search/" +
                this.receptionservice.hash +
                "?OrderByProperty=OrderNumber",
                false
              )
              .then((response) => {
                this.terms = response.data.items;
                this.showTerms = true;
              })
              .finally(() => (this.loading = false));
            router.push("/receptionservice?hash=" + this.receptionservice.hash);
            this.prettyFormat();
            this.$emit("success");
          });
      } else {
        http
          .put("/api/receptionservice/update/", this.receptionservice)
          .then(() => {
            this.goBack();
            this.$emit("success");
          });
      }
    },
    termsReset() {
      this.loading = true;
      http
        .put("/api/servicepartnershipterm/reset/" + this.receptionservice.hash)
        .then((response) => {
          this.receptionservice.standardHash = response.data.standardHash;
          this.receptionservice.consentimentLayout =
            response.data.consentimentLayout;
          this.receptionservice.consentimentFileName =
            response.data.consentimentFileName;
          http
            .get(
              "/api/servicepartnershipterm/search/" +
              this.receptionservice.hash +
              "?OrderByProperty=OrderNumber",
              false
            )
            .then((response) => {
              this.showTerms = true;
              this.terms = response.data.items;
              this.currentPaginationInfo = response.data;
              Vue.$toast.success(
                this.$i18n.t(
                  "views.client.reception.receptionservice.content.compliance.modal.restoredMessage"
                )
              );
            })
            .finally(() => (this.loading = false));
        })
        .finally(() => (this.loading = false));
    },
    getfileUrl() {
      http
        .get(
          `/api/servicepartnershipterm/get/consentiment/${this.receptionservice.hash}`
        )
        .then((response) => {
          if (response.data) {
            router.push({
              name: "pdfviewpage",
              params: { fileUrl: response.data },
            });
          } else {
            Vue.$toast.info(
              this.$i18n.t("views.pdfView.component.noPdfMessage")
            );
          }
        });
    },
    makeTextSearch(event, isChangePage = false) {
      this.CheckValuesChanged(isChangePage);

      if (this.textSearchValue.trim()) {
        http
          .get(
            `/api/servicepartnershipterm/search/${this.receptionservice.hash
            }?FilterProperty=Name&FilterValue=${this.textSearchValue.trim()}&Page=${this.requestPagination.page
            }&Take=${this.requestPagination.take}`
          )
          .then((response) => {
            this.terms = response.data.items;
            this.currentPaginationInfo = response.data;
          })
          .finally(() => (this.loading = false));
      } else {
        http
          .get(
            `/api/servicepartnershipterm/search/${this.receptionservice.hash}?Page=${this.requestPagination.page}&Take=${this.requestPagination.take}`
          )
          .then((response) => {
            this.terms = response.data.items;
            this.currentPaginationInfo = response.data;
          })
          .finally(() => (this.loading = false));
      }
    },
    CheckValuesChanged(isChangePage = false) {
      if (this.textSearchValue != this.oldTextSearchValue) {
        if (isChangePage) {
          this.textSearchValue = this.oldTextSearchValue;
        } else {
          this.requestPagination.page = 1;
          this.oldTextSearchValue = this.textSearchValue;
        }
      }
    },
    prettyFormat: function () {
      try {
        var jsonSchemaResponseSuccessObj = safeJsonParse(
          this.receptionservice.jsonSchemaResponseSuccess
        );
        let jsonSchemaResponseSuccessValid = JsonSchemaResponseSuccessValidator(
          jsonSchemaResponseSuccessObj
        );
        if (!jsonSchemaResponseSuccessValid) {
          localize[i18n.locale](JsonSchemaResponseSuccessValidator.errors);
          Vue.$toast.clear();
          Vue.$toast.error(
            "'JSON_Response_Success" +
            JsonSchemaResponseSuccessValidator.errors[0].dataPath +
            "' " +
            JsonSchemaResponseSuccessValidator.errors[0].message
          );
        } else {
          Vue.$toast.clear();
        }
      } catch (e) { }
    },
    getGroupings() {
      http.get(`/api/grouping/list`, false)
        .then(response => {
          this.groupings = response.data;
        })
    },
    getPermissionsBySelectedGrouping(groupingHash) {
      http.get(`/api/permission/listMany/${groupingHash}`)
        .then(response => {
          this.permissions = response.data;
        })
    },
    onChangeServiceGrouping(groupingHash) {
      this.getPermissionsBySelectedGrouping(groupingHash);
    },
    getPermissionsByServiceHash(serviceHash) {
      http.get(`/api/service/get/${serviceHash}/permissions`)
        .then((response) => {
          this.permissions = response.data;
          this.setReceptionServicePermissionsToForm();
        });
    },
    setReceptionServicePermissionsToForm() {
      this.permissions.forEach((permission) => {
        let rspArray = [];
        if (this.receptionservice.receptionServicePermissions && this.receptionservice.receptionServicePermissions.length > 0) {
          rspArray = this.receptionservice.receptionServicePermissions
            .map(rspermission => { rspermission.disabled = true; return rspermission; })
            .filter(rspermission => rspermission.permissionHash == permission.hash)
        }

        if (rspArray.length > 0) {
          this.receptionServicePermissions.push(rspArray);
        } else {
          this.receptionServicePermissions.push([
            {
              enabled: false,
              permissionHash: permission.hash,
              version: null,
              path: null,
            }
          ]);
        }
      });
    },
    addReceptionServicePermission(permissionHash, index) {
      let lastIndex = this.receptionServicePermissions[index].length - 1;

      if (
        this.receptionServicePermissions[index][lastIndex].version &&
        this.receptionServicePermissions[index][lastIndex].path) {
        this.receptionServicePermissions[index].push({
          permissionHash: permissionHash,
          version: null,
          path: null
        });
      }
    },
    removeReceptionServicePermission(index) {
      if (this.receptionServicePermissions[index].length > 1) {
        this.receptionServicePermissions[index].splice(this.receptionServicePermissions[index].length - 1, 1);
      }
    },
    getPermissionNameByPermissionHash(permissionHash) {
      if (!this.permissions) {
        return;
      }

      let permission = this.permissions.find(p => p.hash == permissionHash);

      if (permission) {
        return permission.name;
      } else {
        return "Permissão sem nome";
      }
    },
    saveReceptionServicePermissions() {
      let rspArray = [];
      this.receptionservice.receptionServicePermissions = [];

      this.receptionServicePermissions.forEach(rsp => {
        rspArray = [...rspArray, ...rsp];
      });

      this.receptionservice.receptionServicePermissions = rspArray;
    },
  },
};
</script>
