<template>
  <div class="teros_principal__content interna container">
    <div class="teros_principal__content__line-1 line">
      <div class="teros_principal__content__line-1__highlights">
        <div class="teros_principal__content__line-1__highlights__subject">
          <router-link to="/categories">
            <div
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb
              "
            >
              <h3
                class="
                  teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle
                "
              >
                Teros Openbanking
              </h3>
              <h2
                class="
                  teros_principal__content__line-1__highlights__subject__breadcrumb__title
                "
              >
                {{ $t("views.shared.menu.config") }}
              </h2>
            </div>
          </router-link>
          <div class="teros_principal__arrow"></div>
          <div
            class="
              teros_principal__content__line-1__highlights__subject__breadcrumb
            "
          >
            <h3
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle
              "
            >
              {{ $t("views.teros.config.apis.tabTitle") }}
            </h3>
            <h2
              v-if="newObj"
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__title
                active
              "
            >
              {{ $t("views.teros.config.category.newObject") }}
            </h2>
            <h2
              v-else
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__title
                active
              "
            >
              {{ api.name }}
            </h2>
          </div>
        </div>
      </div>

      <div class="teros_principal__content__line-1__information">
        <div class="teros_principal__content__line-1__information__time">
          <p
            v-if="!newObj"
            class="teros_principal__content__line-1__information__time__text"
          >
            <img
              class="
                teros_principal__content__line-1__information__time__text__icon
              "
              src="/static/img/icons/relogio.png"
              alt=""
            />
            {{
              $t("views.shared.createdDate", {
                date: creationDate,
                time: creationTime,
              })
            }}
            {{
              lastUpdateDate
                ? "| " +
                  $t("views.shared.changedDate", {
                    date: lastUpdateDate,
                    time: lastUpdateTime,
                  })
                : ""
            }}
          </p>

          <button
            type="button"
            @click="goBack"
            class="teros_principal__content__line-1__information__time__btn"
          >
            <img src="/static/img/icons/seta-esquerda.png" alt="" />
            {{ $t("views.shared.backButton") }}
          </button>
        </div>
      </div>
    </div>

    <div id="accordion">
      <div class="teros_principal__content__accordion__card">
        <div
          class="
            teros_principal__content__accordion__card__header
            accordion-off
          "
          id="headingOne"
        >
          <h2 class="teros_principal__content__accordion__card__header__title">
            <button
              class="
                teros_principal__content__accordion__card__header__title__btn
              "
              type="button"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              {{ $t("views.teros.config.category.content.edit.title") }}
            </button>
            <hr />
          </h2>
        </div>

        <div
          id="collapseOne"
          class="teros_principal__content__accordion__card__header__collapse"
        >
          <div
            class="
              teros_principal__content__accordion__card__header__collapse__body
            "
          >
            <form
              @submit.prevent="createOrUpdate"
              class="
                teros_principal__content__accordion__card__header__collapse__body__form
              "
              method="post"
            >
              <div class="form-group row">
                <div class="col">
                  <label for="formGroupExampleInput"
                    >{{
                      $t("views.teros.config.category.content.edit.name")
                    }}*</label
                  >
                  <custom-input
                    :id="'formGroupExampleInput'"
                    v-model="api.name"
                  ></custom-input>
                </div>
                <div class="col">
                  <live-select
                    :id="'apiType'"
                    :title="$t('views.teros.config.apis.apiType') + '*'"
                    :options="apiTypes"
                    :hash="api.apiTypeHash"
                    @onChange="api.apiTypeHash = $event"
                  ></live-select>
                </div>
              </div>

              <div class="form-group align-right">
                <button
                  class="
                    teros_principal__content__column__info__form__table__plus
                    mb0
                  "
                >
                  <img src="/static/img/icons/disquete.png" alt="Salvar" />{{
                    $t("views.shared.saveButton")
                  }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http, router } from "@/_helpers";
import CustomInput from "@/_components/CustomInput.vue";
import LiveSelect from "@/_components/LiveSelect.vue";
import moment from "moment";

export default {
  data() {
    return {
      newObj: true,
      api: {},
      apiTypes: [],
    };
  },
  components: {
    LiveSelect,
    CustomInput,
  },
  computed: {
    creationDate() {
      if (this.api.creationDate) {
        return moment(String(this.api.creationDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
    creationTime() {
      if (this.api.creationDate) {
        return moment(String(this.api.creationDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("HH:mm");
      }
      return "";
    },
    lastUpdateDate() {
      if (this.api.lastUpdateDate) {
        return moment(String(this.api.lastUpdateDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
    lastUpdateTime() {
      if (this.api.lastUpdateDate) {
        return moment(String(this.api.lastUpdateDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("HH:mm");
      }
      return "";
    },
  },
  mounted() {
    this.$emit("message", this.$route);
    const hash = this.$route.query.hash;
    if (hash) {
      this.newObj = false;
      http.get("/api/api/get/" + hash).then((response) => {
        this.api = response.data;
      });
    }

    http.get("/api/apitype/list").then((response) => {
      this.apiTypes = response.data;
    });
  },
  methods: {
    goBack() {
      router.push({
        name: "apispage",
      });
    },
    createOrUpdate() {
      if (this.newObj) {
        http.post("/api/api/create/", this.api).then(() => {
          this.goBack();
          this.$emit("success");
        });
      } else {
        http.put("/api/api/update/", this.api).then(() => {
          this.goBack();
          this.$emit("success");
        });
      }
    },
  },
};
</script>