var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "teros_principal__content interna container" },
    [
      _c("div", { staticClass: "teros_principal__content__line-1" }, [
        _c(
          "div",
          { staticClass: "teros_principal__content__line-1__highlights" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__line-1__highlights__subject",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__line-1__highlights__subject__breadcrumb",
                  },
                  [
                    _c(
                      "h3",
                      {
                        staticClass:
                          "teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t("views.teros.customers.customer.title")
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.newObj
                      ? _c(
                          "h2",
                          {
                            staticClass:
                              "teros_principal__content__line-1__highlights__subject__breadcrumb__title active",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "views.teros.customers.customer.newObject"
                                  )
                                ) +
                                "\n          "
                            ),
                          ]
                        )
                      : _c(
                          "h2",
                          {
                            staticClass:
                              "teros_principal__content__line-1__highlights__subject__breadcrumb__title active",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.customer.name) +
                                "\n          "
                            ),
                          ]
                        ),
                  ]
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "teros_principal__content__line-1__information" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__line-1__information__time",
              },
              [
                !_vm.newObj
                  ? _c(
                      "p",
                      {
                        staticClass:
                          "teros_principal__content__line-1__information__time__text",
                      },
                      [
                        _c("img", {
                          staticClass:
                            "teros_principal__content__line-1__information__time__text__icon",
                          attrs: {
                            src: "/static/img/icons/relogio.png",
                            alt: "",
                          },
                        }),
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t("views.shared.createdDate", {
                                date: _vm.creationDate,
                                time: _vm.creationTime,
                              })
                            ) +
                            "\n          " +
                            _vm._s(
                              _vm.lastUpdateDate
                                ? "| " +
                                    _vm.$t("views.shared.changedDate", {
                                      date: _vm.lastUpdateDate,
                                      time: _vm.lastUpdateTime,
                                    })
                                : ""
                            ) +
                            "\n        "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "teros_principal__content__line-1__information__time__btn",
                    attrs: { type: "button" },
                    on: { click: _vm.goBack },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "/static/img/icons/seta-esquerda.png",
                        alt: "",
                      },
                    }),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("views.shared.backButton")) +
                        "\n        "
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "teros_principal__content__column" }, [
        _c(
          "div",
          { staticClass: "teros_principal__content__column__menu-vertical" },
          [
            _c(
              "ul",
              {
                staticClass:
                  "teros_principal__content__column__menu-vertical__list",
              },
              [
                _c(
                  "li",
                  {
                    staticClass:
                      "teros_principal__content__column__menu-vertical__list__item",
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass:
                          "teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--lupa active",
                        attrs: { href: "#" },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("views.teros.customers.customer.tabTitle")
                          )
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass:
                      "teros_principal__content__column__menu-vertical__list__item",
                  },
                  [
                    _vm.newObj
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--usuarios",
                            attrs: { href: "#" },
                            on: { click: _vm.showAlert },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("views.teros.customers.users.tabTitle")
                              )
                            ),
                          ]
                        )
                      : _c(
                          "router-link",
                          {
                            staticClass:
                              "teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--usuarios",
                            attrs: { to: "/users?hash=" + _vm.customer.hash },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("views.teros.customers.users.tabTitle")
                              )
                            ),
                          ]
                        ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass:
                      "teros_principal__content__column__menu-vertical__list__item",
                  },
                  [
                    _vm.newObj
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--gestao",
                            attrs: { href: "#" },
                            on: { click: _vm.showAlert },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "views.teros.customers.customerlogs.tabTitle"
                                )
                              )
                            ),
                          ]
                        )
                      : _c(
                          "router-link",
                          {
                            staticClass:
                              "teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--gestao",
                            attrs: {
                              to: "/customerlogs?hash=" + _vm.customer.hash,
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "views.teros.customers.customerlogs.tabTitle"
                                )
                              )
                            ),
                          ]
                        ),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "form",
          {
            staticClass: "teros_principal__content__column__info",
            attrs: { method: "post" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.createOrUpdate.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "form-group" }, [
              _c("div", { staticClass: "custom-control custom-switch" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.customer.active,
                      expression: "customer.active",
                    },
                  ],
                  staticClass: "custom-control-input",
                  attrs: { type: "checkbox", checked: "", id: "customSwitch1" },
                  domProps: {
                    checked: Array.isArray(_vm.customer.active)
                      ? _vm._i(_vm.customer.active, null) > -1
                      : _vm.customer.active,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.customer.active,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(_vm.customer, "active", $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.customer,
                              "active",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.customer, "active", $$c)
                      }
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "custom-control-label",
                    attrs: { for: "customSwitch1" },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("views.shared.activeSwitch")) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { attrs: { for: "formGroupExampleInput1" } }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("views.teros.customers.customer.content.name")
                    ) + "*\n          "
                  ),
                  _c("img", {
                    attrs: {
                      src: "/static/img/icons/tooltip.png",
                      "data-toggle": "tooltip",
                      "data-placement": "right",
                      title: _vm.$t(
                        "views.teros.customers.customer.toolTips.name"
                      ),
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("custom-input", {
                  attrs: { id: "formGroupExampleInput1", required: true },
                  model: {
                    value: _vm.customer.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.customer, "name", $$v)
                    },
                    expression: "customer.name",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "form-group row" }, [
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("label", { attrs: { for: "formGroupExampleInput2" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "views.teros.customers.customer.content.companyName"
                        )
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("custom-input", {
                    attrs: { id: "formGroupExampleInput2" },
                    model: {
                      value: _vm.customer.companyName,
                      callback: function ($$v) {
                        _vm.$set(_vm.customer, "companyName", $$v)
                      },
                      expression: "customer.companyName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [
                _c("label", { attrs: { for: "formGroupExampleInput3" } }, [
                  _vm._v("CNPJ*"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.customer.cnpj,
                      expression: "customer.cnpj",
                    },
                    {
                      name: "mask",
                      rawName: "v-mask",
                      value: "##.###.###/####-##",
                      expression: "'##.###.###/####-##'",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", id: "formGroupExampleInput3" },
                  domProps: { value: _vm.customer.cnpj },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.customer, "cnpj", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            !_vm.newObj
              ? _c("div", { staticClass: "form-group row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("label", { attrs: { for: "formGroupExampleInput4" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "views.teros.customers.customer.content.standardKey"
                          )
                        ) + "\n            "
                      ),
                      _c("img", {
                        attrs: {
                          src: "/static/img/icons/tooltip.png",
                          "data-toggle": "tooltip",
                          "data-placement": "right",
                          title: _vm.$t(
                            "views.teros.customers.customer.toolTips.standardKey"
                          ),
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.customer.gatewayClientId,
                          expression: "customer.gatewayClientId",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "formGroupExampleInput4",
                        disabled: "",
                      },
                      domProps: { value: _vm.customer.gatewayClientId },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.customer,
                            "gatewayClientId",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col" }, [
                    _c("label", { attrs: { for: "formGroupExampleInput5" } }, [
                      _vm._v("Secret Key "),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.customer.gatewaySecret,
                          expression: "customer.gatewaySecret",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "formGroupExampleInput5",
                        disabled: "",
                      },
                      domProps: { value: _vm.customer.gatewaySecret },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.customer,
                            "gatewaySecret",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "form-group row" }, [
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("live-select", {
                    attrs: {
                      title: _vm.$t(
                        "views.teros.customers.customer.content.categories"
                      ),
                      multiple: "",
                      options: _vm.categories,
                      hashs: _vm.customerCategories,
                    },
                    on: {
                      onChange: function ($event) {
                        _vm.customerCategories = $event
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("label", { attrs: { for: "formGroupExampleInput6" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "views.teros.customers.customer.content.ssoissuer"
                        )
                      ) + "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("custom-input", {
                    attrs: {
                      id: "formGroupExampleInput6",
                      regex: ".*",
                      maxlength: "100",
                    },
                    model: {
                      value: _vm.customer.ssoIssuerURN,
                      callback: function ($$v) {
                        _vm.$set(_vm.customer, "ssoIssuerURN", $$v)
                      },
                      expression: "customer.ssoIssuerURN",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group row" }, [
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("label", { attrs: { for: "formGroupExampleInput8" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "views.teros.customers.customer.content.awsaccount"
                        )
                      ) + "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("custom-input", {
                    attrs: { id: "formGroupExampleInput8", maxlength: "100" },
                    model: {
                      value: _vm.customer.awsAccount,
                      callback: function ($$v) {
                        _vm.$set(_vm.customer, "awsAccount", $$v)
                      },
                      expression: "customer.awsAccount",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group row" }, [
              _c("div", { staticClass: "col" }, [
                _c("label", { attrs: { for: "formGroupExampleInput8" } }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "views.teros.customers.customer.content.defaultWebhookUrl"
                      )
                    ) + "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.customer.defaultWebhookUrl,
                      expression: "customer.defaultWebhookUrl",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", id: "formGroupExampleInput8" },
                  domProps: { value: _vm.customer.defaultWebhookUrl },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.customer,
                        "defaultWebhookUrl",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group row" }, [
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("label", { attrs: { for: "formGroupExampleInput8" } }, [
                    _vm._v("BACEN clientId\n            "),
                    _c("img", {
                      attrs: {
                        src: "/static/img/icons/tooltip.png",
                        "data-toggle": "tooltip",
                        "data-placement": "right",
                        title: _vm.$t(
                          "views.teros.customers.customer.toolTips.bacenClientId"
                        ),
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("custom-input", {
                    attrs: {
                      id: "formGroupExampleInput8",
                      maxlength: "100",
                      regex: ".*",
                    },
                    model: {
                      value: _vm.customer.bacenClientId,
                      callback: function ($$v) {
                        _vm.$set(_vm.customer, "bacenClientId", $$v)
                      },
                      expression: "customer.bacenClientId",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "formGroupExampleInput7" } }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "views.teros.customers.customer.content.observations"
                    )
                  ) + "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.customer.observations,
                    expression: "customer.observations",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  name: "",
                  id: "formGroupExampleInput7",
                  cols: "30",
                  rows: "5",
                  maxlength: "2000",
                },
                domProps: { value: _vm.customer.observations },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.customer, "observations", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { attrs: { for: "" } }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t("views.teros.customers.customer.content.contract")
                    )
                  ),
                ]),
                _vm._v(" "),
                _c("file-upload", {
                  attrs: { "file-name": _vm.customer.contractFileName },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "form-group teros_principal__content__column__info__form__combo",
              },
              [
                _c("pdf-view", {
                  attrs: {
                    "file-name": _vm.customer.contractFileName,
                    type: "button",
                    title: _vm.$t(
                      "views.teros.customers.customer.content.viewContractButton"
                    ),
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "form-group align-right" }, [
              _c(
                "button",
                {
                  staticClass:
                    "teros_principal__content__column__info__form__table__plus",
                },
                [
                  _c("img", {
                    attrs: {
                      src: "/static/img/icons/disquete.png",
                      alt: "Salvar",
                    },
                  }),
                  _vm._v(
                    _vm._s(_vm.$t("views.shared.saveButton")) + "\n        "
                  ),
                ]
              ),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }