<template>
  <div class="teros_principal__content interna container">
    <div class="teros_principal__content__line-1 line">
      <div class="teros_principal__content__line-1__highlights">
        <div class="teros_principal__content__line-1__highlights__subject">
          <router-link to="/financialInstitutions">
            <div
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb
              "
            >
              <h3
                class="
                  teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle
                "
              >
                Teros Openbanking
              </h3>
              <h2
                class="
                  teros_principal__content__line-1__highlights__subject__breadcrumb__title
                "
              >
                {{ $t("views.shared.menu.config") }}
              </h2>
            </div>
          </router-link>
          <div class="teros_principal__arrow"></div>
          <div
            class="
              teros_principal__content__line-1__highlights__subject__breadcrumb
            "
          >
            <h3
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle
              "
            >
              {{ $t("views.teros.config.financialInstitutions.tabTitle") }}
            </h3>
            <h2
              v-if="newObj"
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__title
                active
              "
            >
              {{ $t("views.teros.config.category.newObject") }}
            </h2>
            <h2
              v-else
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__title
                active
              "
            >
              {{ financialInstitution.name }}
            </h2>
          </div>
        </div>
      </div>

      <div class="teros_principal__content__line-1__information">
        <div class="teros_principal__content__line-1__information__time">
          <p
            v-if="!newObj"
            class="teros_principal__content__line-1__information__time__text"
          >
            <img
              class="
                teros_principal__content__line-1__information__time__text__icon
              "
              src="/static/img/icons/relogio.png"
              alt=""
            />
            {{
              $t("views.shared.createdDate", {
                date: creationDate,
                time: creationTime,
              })
            }}
            {{
              lastUpdateDate
                ? "| " +
                  $t("views.shared.changedDate", {
                    date: lastUpdateDate,
                    time: lastUpdateTime,
                  })
                : ""
            }}
          </p>

          <button
            type="button"
            @click="goBack"
            class="teros_principal__content__line-1__information__time__btn"
          >
            <img src="/static/img/icons/seta-esquerda.png" alt="" />
            {{ $t("views.shared.backButton") }}
          </button>
        </div>
      </div>
    </div>

    <div id="accordion">
      <div class="teros_principal__content__accordion__card">
        <div
          class="
            teros_principal__content__accordion__card__header
            accordion-off
          "
          id="headingOne"
        >
          <h2 class="teros_principal__content__accordion__card__header__title">
            <button
              class="
                teros_principal__content__accordion__card__header__title__btn
              "
              type="button"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              {{ $t("views.teros.config.category.content.edit.title") }}
            </button>
            <hr />
          </h2>
        </div>

        <div
          id="collapseOne"
          class="teros_principal__content__accordion__card__header__collapse"
        >
          <div
            class="
              teros_principal__content__accordion__card__header__collapse__body
            "
          >
            <form
              @submit.prevent="createOrUpdate"
              class="
                teros_principal__content__accordion__card__header__collapse__body__form
              "
              method="post"
            >
              <div class="form-group row">
                <div class="col">
                  <label for="formGroupExampleInput"
                    >{{
                      $t("views.teros.config.category.content.edit.name")
                    }}*</label
                  >
                  <custom-input
                    :id="'a'"
                    v-model="financialInstitution.name"
                  ></custom-input>
                </div>
              </div>
              <div class="form-group row">
                <div class="col">
                  <label for="formGroupExampleInput">
                    {{
                      $t(
                        "views.teros.config.financialInstitutions.table.header.financialInstitutionCode"
                      )
                    }}*
                  </label>
                  <custom-input
                    :maxlength="'3'"
                    :id="'b'"
                    :regex="'^[0-9]*$'"
                    v-model="financialInstitution.financialInstitutionCode"
                  ></custom-input>
                </div>
              </div>
              <div class="form-group row">
                <div class="col">
                  <label for="formGroupExampleInput">
                    {{
                      $t(
                        "views.teros.config.financialInstitutions.table.header.shortName"
                      )
                    }}*
                  </label>

                  <custom-input
                    :id="'d'"
                    v-model="financialInstitution.shortName"
                  ></custom-input>
                </div>
              </div>

              <div class="form-group row">
                <div class="col">
                  <div class="form-group">
                    <label for="">
                      {{
                        $t(
                          "views.teros.config.financialInstitutions.table.header.financialInstitutionLogo"
                        )
                      }}</label
                    >
                    <file-upload
                      :file-name="financialInstitution.logoURL"
                      :content-types="['.png', '.jpeg', '.jpg']"
                    ></file-upload>
                  </div>
                </div>
              </div>

              <div class="form-group align-right">
                <button
                  class="
                    teros_principal__content__column__info__form__table__plus
                    mb0
                  "
                >
                  <img src="/static/img/icons/disquete.png" alt="Salvar" />{{
                    $t("views.shared.saveButton")
                  }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http, router } from "@/_helpers";
import CustomInput from "@/_components/CustomInput.vue";
import FileUpload from "@/_components/FileUpload.vue";
const Uuid = require("uuid/v1");
import moment from "moment";

export default {
  data() {
    return {
      newObj: true,
      financialInstitution: {},
    };
  },
  components: {
    CustomInput,
    FileUpload,
  },
  computed: {
    creationDate() {
      if (this.financialInstitution.creationDate) {
        return moment(String(this.financialInstitution.creationDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
    creationTime() {
      if (this.financialInstitution.creationDate) {
        return moment(String(this.financialInstitution.creationDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("HH:mm");
      }
      return "";
    },
    lastUpdateDate() {
      if (this.financialInstitution.lastUpdateDate) {
        return moment(String(this.financialInstitution.lastUpdateDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
    lastUpdateTime() {
      if (this.financialInstitution.lastUpdateDate) {
        return moment(String(this.financialInstitution.lastUpdateDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("HH:mm");
      }
      return "";
    },
  },
  mounted() {
    this.$emit("message", this.$route);
    const hash = this.$route.query.hash;
    if (hash) {
      this.newObj = false;
      http.get("/api/financialInstitution/get/" + hash).then((response) => {
        this.financialInstitution = response.data;
      });
    }
  },
  methods: {
    goBack() {
      router.push({
        name: "financialInstitutionspage",
      });
    },
    createOrUpdate() {
      let uploadFile = new Promise((resolve, reject) => {
        this.bus.$emit(
          "make-file-upload",
          {
            url: "/api/util/upload/base64",
            name: `financialInstitution/logo/${Uuid()}`,
          },
          resolve,
          reject
        );
      });

      uploadFile.then((response) => {
        this.financialInstitution.logoURL = response.data;
        if (this.newObj) {
          http
            .post("/api/financialInstitution/create", this.financialInstitution)
            .then(() => {
              this.goBack();
              this.$emit("success");
            });
        } else {
          http
            .put("/api/financialInstitution/update/", this.financialInstitution)
            .then(() => {
              this.goBack();
              this.$emit("success");
            });
        }
      });
    },
  },
};
</script>