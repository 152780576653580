var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    (_vm.showPassword ? "text" : "password") === "checkbox"
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.passwordValue,
              expression: "passwordValue",
            },
          ],
          staticClass:
            "form-control teros_principal__login__column__form__fields__input",
          attrs: { id: _vm.id, placeholder: _vm.placeholder, type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.passwordValue)
              ? _vm._i(_vm.passwordValue, null) > -1
              : _vm.passwordValue,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.passwordValue,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.passwordValue = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.passwordValue = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.passwordValue = $$c
              }
            },
          },
        })
      : (_vm.showPassword ? "text" : "password") === "radio"
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.passwordValue,
              expression: "passwordValue",
            },
          ],
          staticClass:
            "form-control teros_principal__login__column__form__fields__input",
          attrs: { id: _vm.id, placeholder: _vm.placeholder, type: "radio" },
          domProps: { checked: _vm._q(_vm.passwordValue, null) },
          on: {
            change: function ($event) {
              _vm.passwordValue = null
            },
          },
        })
      : _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.passwordValue,
              expression: "passwordValue",
            },
          ],
          staticClass:
            "form-control teros_principal__login__column__form__fields__input",
          attrs: {
            id: _vm.id,
            placeholder: _vm.placeholder,
            type: _vm.showPassword ? "text" : "password",
          },
          domProps: { value: _vm.passwordValue },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.passwordValue = $event.target.value
            },
          },
        }),
    _vm._v(" "),
    _c("a", {
      class: _vm.showPassword
        ? "teros_principal__login__column__form__fields__view"
        : "teros_principal__login__column__form__fields__slash__view",
      attrs: { for: _vm.id, href: "javascript:void(null)" },
      on: { click: _vm.showPasswordFunction },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }