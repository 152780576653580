<template>
  <select
    class="teros_principal__header__menu__languages__select"
    :value="currentLang"
    @change="changeLanguage"
  >
    <option
      class="teros_principal__header__menu__languages__select__option"
      v-for="lang in supportedLanguages"
      :key="lang.id"
      :value="lang.id"
    >
      {{ lang.name }}
    </option>
  </select>
</template>
<script>
import axios from "axios";
import { i18n } from "@/_helpers";

export default {
  name: "language-select",
  data() {
    return {
      currentLang: "",
      supportedLanguages: [
        { id: "pt-BR", name: "PT" },
        { id: "es-ES", name: "ES" },
        { id: "en-US", name: "EN" },
      ],
    };
  },
  created() {
    this.currentLang = localStorage.getItem('lang') || 'pt-BR';
    this.setlanguage();
  },
  methods: {
    loadLanguageFile(lang) {
      return import(`@/_locales/${lang}.json`);
    },
    setlanguage() {
      this.loadLanguageFile(this.currentLang).then((msgs) => {
        i18n.setLocaleMessage(this.currentLang, msgs.default || msgs);
        i18n.locale = this.currentLang;
        axios.defaults.headers.common["Accept-Language"] = this.currentLang;
        this.$forceUpdate();
      });
    },
    changeLanguage(e) {
      this.currentLang = e.target.value;
      localStorage.setItem('lang', this.currentLang);
      this.setlanguage();
    },
  },
};
</script>
