<template>
  <div class="teros_principal__content__accordion__card mx0">
    <div
      class="teros_principal__content__accordion__card__header"
      id="headingOne"
    >
      <h2 class="teros_principal__content__accordion__card__header__title">
        <button
          class="teros_principal__content__accordion__card__header__title__btn"
          type="button"
          data-toggle="collapse"
          data-target="#collapseThree"
          aria-expanded="true"
          aria-controls="collapseThree"
        >
          {{ $t("views.authorizationForm.title") }}
        </button>
      </h2>
    </div>

    <div
      id="collapseThree"
      class="
        teros_principal__content__accordion__card__header__collapse
        collapse
        show
      "
    >
      <div
        class="
          teros_principal__content__accordion__card__header__collapse__body
        "
      >
        <div
          class="
            teros_principal__content__accordion__card__header__collapse__body__form
          "
        >
          <div class="form-group row" v-if="showDcrType">
            <div class="col">
              <label for="AuthorizationDCR"
                >Dynamic Client Registration (DCR)
                <img
                  src="/static/img/icons/tooltip.png"
                  data-toggle="tooltip"
                  data-placement="right"
                  :title="$t('views.authorizationForm.toolTip.Dcr')"
              /></label>
              <select
                class="form-control"
                id="AuthorizationDCR"
                v-model.number="dcrType"
              >
                <option value="1">{{$t('views.authorizationForm.dcrType.selfManaged')}}</option>
                <option value="2">{{$t('views.authorizationForm.dcrType.autoCreated')}}</option>
                <option value="3">{{$t('views.authorizationForm.dcrType.openBankingBrazil')}}</option>
              </select>
            </div>
          </div>

          <div class="form-group row">
            <div class="col">
              <label for="AuthorizationForm1"
                >Flow Type
                <img
                  src="/static/img/icons/tooltip.png"
                  data-toggle="tooltip"
                  data-placement="right"
                  :title="$t('views.authorizationForm.toolTip.FlowType')"
              /></label>
              <select
                class="form-control"
                id="AuthorizationForm1"
                v-model.number="flowType"
                :disabled="isDisabled"
              >
                <option value="1" v-if="apiAuthentication.dynamicClientRegistrationType == 1">No Auth</option>
                <option value="2" v-if="apiAuthentication.dynamicClientRegistrationType != 3">Client Credentials</option>
                <option value="3" v-if="apiAuthentication.dynamicClientRegistrationType != 3">Authorization Code</option>
                <option value="5" v-if="apiAuthentication.dynamicClientRegistrationType == 1">Password Grant</option>
                <option value="6" v-if="apiAuthentication.dynamicClientRegistrationType == 1">Basic Auth</option>
                <option value="7" v-if="apiAuthentication.dynamicClientRegistrationType == 1">Api Key</option>
                <option value="8" v-if="apiAuthentication.dynamicClientRegistrationType == 1">Bearer Token</option>
                <option value="9">Financial-Grade API</option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <div
              class="col"
              v-if="apiAuthentication.authenticationFlowType == 2 ||
                    apiAuthentication.authenticationFlowType == 3 ||
                    apiAuthentication.authenticationFlowType == 9"
            >
              <label for="AuthorizationForm2"
                >Authorization Server URI*
                <img
                  src="/static/img/icons/tooltip.png"
                  data-toggle="tooltip"
                  data-placement="right"
                  :title="
                    $t('views.authorizationForm.toolTip.AuthorizationServerURI')
                  "
              /></label>
              <custom-input
                :id="'AuthorizationForm2'"
                :regex="'.*'"
                v-model="apiAuthentication.serverUri"
                :maxlength="'200'"
                :readonly="isDisabled"
              ></custom-input>
            </div>
          </div>
          <div class="form-group row">
            <div
              class="col"
              v-if="
                apiAuthentication.authenticationFlowType == 3 ||
                apiAuthentication.authenticationFlowType == 4
              "
            >
              <label for="AuthorizationForm3"
                >Authorization Code Endpoint*
                <img
                  src="/static/img/icons/tooltip.png"
                  data-toggle="tooltip"
                  data-placement="right"
                  :title="
                    $t(
                      'views.authorizationForm.toolTip.AuthorizationCodeEndpoint'
                    )
                  "
              /></label>
              <custom-input
                :id="'AuthorizationForm3'"
                :regex="'.*'"
                v-model="apiAuthentication.authorizationEndpoint"
                :maxlength="'500'"
                :readonly="isDisabled"
              ></custom-input>
            </div>
            <div
              class="col"
              v-if="
                apiAuthentication.authenticationFlowType == 2 ||
                apiAuthentication.authenticationFlowType == 3 ||
                apiAuthentication.authenticationFlowType == 4 ||
                apiAuthentication.authenticationFlowType == 5
              "
            >
              <label for="AuthorizationForm4"
                >Token Endpoint*
                <img
                  src="/static/img/icons/tooltip.png"
                  data-toggle="tooltip"
                  data-placement="right"
                  :title="$t('views.authorizationForm.toolTip.TokenEndpoint')"
              /></label>
              <custom-input
                :id="'AuthorizationForm4'"
                :regex="'.*'"
                v-model="apiAuthentication.tokenEndpoint"
                :maxlength="'500'"
                :readonly="isDisabled"
              ></custom-input>
            </div>
          </div>
          <div class="form-group row">
            <div
              class="col"
              v-if="
                apiAuthentication.authenticationFlowType == 2 ||
                apiAuthentication.authenticationFlowType == 3 ||
                apiAuthentication.authenticationFlowType == 4 ||
                apiAuthentication.authenticationFlowType == 5
              "
            >
              <label for="AuthorizationForm5"
                >Authentication Parameter Type*
                <img
                  src="/static/img/icons/tooltip.png"
                  data-toggle="tooltip"
                  data-placement="right"
                  :title="
                    $t(
                      'views.authorizationForm.toolTip.AuthenticationParameterType'
                    )
                  "
              /></label>
              <select
                class="form-control"
                id="AuthorizationForm5"
                v-model.number="apiAuthentication.authenticationParameterType"
                :disabled="isDisabled"
              >
                <option value="1">Header</option>
                <option
                  v-if="
                    apiAuthentication.authenticationFlowType != 6 &&
                    apiAuthentication.authenticationFlowType != 7 &&
                    apiAuthentication.authenticationFlowType != 8
                  "
                  value="2"
                >
                  Body
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <div
              class="col"
              v-if="
                apiAuthentication.authenticationFlowType == 2 ||
                apiAuthentication.authenticationFlowType == 3 ||
                apiAuthentication.authenticationFlowType == 4 ||
                apiAuthentication.authenticationFlowType == 5 ||
                apiAuthentication.authenticationFlowType == 9
              "
            >
              <label for="AuthorizationForm6"
                >Client Id*
                <img
                  src="/static/img/icons/tooltip.png"
                  data-toggle="tooltip"
                  data-placement="right"
                  :title="$t('views.authorizationForm.toolTip.ClientId')"
              /></label>
              <custom-input
                :id="'AuthorizationForm6'"
                :regex="'.*'"
                v-model="apiAuthentication.clientId"
                :maxlength="'500'"
                :readonly="isDisabled"
              ></custom-input>
            </div>
            <div
              class="col"
              v-if="
                apiAuthentication.authenticationFlowType == 2 ||
                apiAuthentication.authenticationFlowType == 3 ||
                apiAuthentication.authenticationFlowType == 5
              "
            >
              <label for="AuthorizationForm7"
                >Client Secret*
                <img
                  src="/static/img/icons/tooltip.png"
                  data-toggle="tooltip"
                  data-placement="right"
                  :title="$t('views.authorizationForm.toolTip.ClientSecret')"
              /></label>
              <custom-input
                :id="'AuthorizationForm7'"
                :regex="'.*'"
                v-model="apiAuthentication.clientSecret"
                :maxlength="'1000'"
                :readonly="isDisabled"
              ></custom-input>
            </div>
          </div>
          <div
            class="form-group row"
            v-if="
              apiAuthentication.authenticationFlowType == 5 ||
              apiAuthentication.authenticationFlowType == 6
            "
          >
            <div class="col">
              <label for="AuthorizationForm8"
                >Username*
                <img
                  src="/static/img/icons/tooltip.png"
                  data-toggle="tooltip"
                  data-placement="right"
                  :title="$t('views.authorizationForm.toolTip.Username')"
              /></label>
              <custom-input
                :id="'AuthorizationForm8'"
                :regex="'.*'"
                v-model="apiAuthentication.username"
                :maxlength="'1000'"
              ></custom-input>
            </div>
            <div class="col">
              <label for="AuthorizationForm9"
                >Password*
                <img
                  src="/static/img/icons/tooltip.png"
                  data-toggle="tooltip"
                  data-placement="right"
                  :title="$t('views.authorizationForm.toolTip.Password')"
              /></label>
              <custom-input
                :id="'AuthorizationForm9'"
                :regex="'.*'"
                v-model="apiAuthentication.password"
                :maxlength="'500'"
              ></custom-input>
            </div>
          </div>
          <div class="form-group row">
            <div
              class="col"
              v-if="
                apiAuthentication.authenticationFlowType != undefined &&
                apiAuthentication.authenticationFlowType != 1 &&
                apiAuthentication.authenticationFlowType != 9
              "
            >
              <label for="AuthorizationForm10"
                >Authorization Parameter Type*
                <img
                  src="/static/img/icons/tooltip.png"
                  data-toggle="tooltip"
                  data-placement="right"
                  :title="
                    $t(
                      'views.authorizationForm.toolTip.AuthorizationParameterType'
                    )
                  "
              /></label>
              <select
                class="form-control"
                id="AuthorizationForm10"
                v-model.number="apiAuthentication.authorizationParameterType"
                :disabled="isDisabled"
              >
                <option value="1">Header</option>
              </select>
            </div>
            <div
              class="col"
              v-if="apiAuthentication.authenticationFlowType == 8"
            >
              <label for="AuthorizationForm11"
                >Bearer Token*
                <img
                  src="/static/img/icons/tooltip.png"
                  data-toggle="tooltip"
                  data-placement="right"
                  :title="$t('views.authorizationForm.toolTip.BearerToken')"
              /></label>
              <custom-input
                :id="'AuthorizationForm11'"
                :regex="'.*'"
                v-model="apiAuthentication.bearerToken"
                :maxlength="'2500'"
                :readonly="isDisabled"
              ></custom-input>
            </div>
            <div
              class="col"
              v-if="apiAuthentication.authenticationFlowType == 7"
            >
              <label for="AuthorizationForm12"
                >APi Key Value*
                <img
                  src="/static/img/icons/tooltip.png"
                  data-toggle="tooltip"
                  data-placement="right"
                  :title="$t('views.authorizationForm.toolTip.APiKeyValue')"
              /></label>
              <custom-input
                :id="'AuthorizationForm12'"
                :regex="'.*'"
                v-model="apiAuthentication.apiKey"
                :maxlength="'2500'"
                :readonly="isDisabled"
              ></custom-input>
            </div>
          </div>
          <div class="form-group row">
            <div
              class="col"
              v-if="
                apiAuthentication.authenticationFlowType == 2 ||
                apiAuthentication.authenticationFlowType == 3 ||
                apiAuthentication.authenticationFlowType == 5
              "
            >
              <label for="AuthorizationForm13"
                >Scope
                <img
                  src="/static/img/icons/tooltip.png"
                  data-toggle="tooltip"
                  data-placement="right"
                  :title="$t('views.authorizationForm.toolTip.Scope')"
              /></label>
              <custom-input
                :id="'AuthorizationForm13'"
                :regex="'.*'"
                v-model="apiAuthentication.scope"
                :readonly="isDisabled"
              ></custom-input>
            </div>
            <div
              class="col"
              v-if="
                apiAuthentication.authenticationFlowType == 3 ||
                apiAuthentication.authenticationFlowType == 4 ||
                apiAuthentication.authenticationFlowType == 9
              "
            >
              <label for="AuthorizationForm14"
                >Redirect Uri*
                <img
                  src="/static/img/icons/tooltip.png"
                  data-toggle="tooltip"
                  data-placement="right"
                  :title="$t('views.authorizationForm.toolTip.RedirectUri')"
              /></label>
              <custom-input
                :id="'AuthorizationForm14'"
                :regex="'.*'"
                v-model="apiAuthentication.redirectUri"
                :maxlength="'500'"
              ></custom-input>
            </div>
          </div>
          <div class="form-group row">
            <div
              class="col"
              v-if="
                apiAuthentication.authenticationFlowType != undefined &&
                apiAuthentication.authenticationFlowType != 1 &&
                apiAuthentication.authenticationFlowType != 5 &&
                apiAuthentication.authenticationFlowType != 9
              "
            >
              <label for="CustomHeaderField">
                Custom Authorization Header
              </label>
              <div v-for="(item, counter) in customHeaders" :key="counter">
                <input
                  id="CustomHeaderField"
                  type="text"
                  class="
                    form-control
                    teros_principal__content__column__info__form__input
                  "
                  v-model="item.field"
                  :readonly="isDisabled"
                />
                <a
                  v-if="counter == customHeaders.length - 1 && !isDisabled"
                  href="javascript:void(null)"
                  @click="addCustomHeader"
                  class="action-fields"
                  >+ Add Field
                </a>
                <a
                  v-if="customHeaders.length > 1 && !isDisabled"
                  href="javascript:void(null)"
                  @click="removeCustomHeader(counter)"
                  class="action-fields"
                  >- Remove Field
                </a>
              </div>
            </div>

            <div
              class="col"
              v-if="
                apiAuthentication.authenticationFlowType != undefined &&
                apiAuthentication.authenticationFlowType != 1 &&
                apiAuthentication.authenticationFlowType != 5 &&
                apiAuthentication.authenticationFlowType != 9
              "
            >
              <label for="CustomHeaderValue"> Value </label>
              <div v-for="(item, counter) in customHeaders" :key="counter">
                <input
                  id="CustomHeaderValue"
                  type="text"
                  class="
                    form-control
                    teros_principal__content__column__info__form__input
                    margin-bottom-thirty-eight-pixels
                  "
                  v-model="item.value"
                  :readonly="isDisabled"
                />
              </div>
            </div>
          </div>
          <div
            class="form-group row"
            v-if="apiAuthentication.authenticationFlowType == 9"
          >
            <div class="col">
              <label for="AuthorizationForm15"
                >Client Authentication Method*
                <img
                  src="/static/img/icons/tooltip.png"
                  data-toggle="tooltip"
                  data-placement="right"
                  :title="
                    $t(
                      'views.authorizationForm.toolTip.ClientAuthenticationMethod'
                    )
                  "
              /></label>
              <select
                class="form-control"
                id="AuthorizationForm15"
                v-model.number="apiAuthentication.clientAuthenticationMethod"
                :disabled="isDisabled"
              >
                <option value="1">private_key_jwt</option>
                <option value="2">tls_client_auth</option>
              </select>
            </div>
            <div class="col">
              <label for="AuthorizationForm16"
                >Authorization Response Mode*
                <img
                  src="/static/img/icons/tooltip.png"
                  data-toggle="tooltip"
                  data-placement="right"
                  :title="
                    $t(
                      'views.authorizationForm.toolTip.AuthorizationResponseMode'
                    )
                  "
              /></label>
              <select
                class="form-control"
                id="AuthorizationForm16"
                v-model.number="apiAuthentication.authorizationResponseMode"
                :disabled="isDisabled"
              >
                <!-- <option value="3">PLAIN / query</option> -->
                <option value="2">PLAIN / fragment</option>
                <!-- <option value="4">JARM / jwt</option>
                <option value="5">JARM / query.jwt</option> -->
                <option value="6">JARM / fragment.jwt</option>
              </select>
            </div>
          </div>
          <div
            class="form-group row"
            v-if="apiAuthentication.authenticationFlowType == 9"
          >
            <div class="col">
              <label for="AuthorizationForm17"
                >Authentication Context Reference
                <img
                  src="/static/img/icons/tooltip.png"
                  data-toggle="tooltip"
                  data-placement="right"
                  :title="
                    $t(
                      'views.authorizationForm.toolTip.AuthenticationContextReference'
                    )
                  "
              /></label>
              <custom-input
                :id="'AuthorizationForm17'"
                :regex="'.*'"
                v-model="apiAuthentication.authenticationContextReference"
                :maxlength="'200'"
                :readonly="isDisabled"
              ></custom-input>
            </div>
          </div>
          <div
            class="form-group row"
            v-if="apiAuthentication.authenticationFlowType == 9"
          >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomInput from "@/_components/CustomInput.vue";

export default {
  name: "authorization-form",
  props: {
    apiAuthentication: {
      type: Object,
    },
    customHeaders: {},
    showDcrType: false
  },
  components: {
    CustomInput,
  },
  computed: {
    flowType: {
      get: function () {
        return this.apiAuthentication.authenticationFlowType;
      },
      set: function (value) {
        this.$emit("onChangeFlow", value);
      },
    },
    dcrType: {
      get: function () {
        return this.apiAuthentication.dynamicClientRegistrationType;
      },
      set: function (value) {
        this.$emit("onChangeDcr", value);
      },
    },
    isDisabled() {
      return this.apiAuthentication.dynamicClientRegistrationType != 1; //Desabilita edição de alguns componentes se for DCR automático
    },
  },
  methods: {
    addCustomHeader() {
      this.customHeaders.push({
        field: "",
        value: "",
      });
    },
    removeCustomHeader(counter) {
      this.customHeaders.splice(counter, 1);
    },
  },
};
</script>