<template>
  <div class="teros_principal__content interna container">
    <div class="teros_principal__content__line-1 line">
      <div class="teros_principal__content__line-1__highlights">
        <div class="teros_principal__content__line-1__highlights__subject">
          <router-link to="/categories">
            <div class="
                teros_principal__content__line-1__highlights__subject__breadcrumb
              ">
              <h3 class="
                  teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle
                ">
                Teros Openbanking
              </h3>
              <h2 class="
                  teros_principal__content__line-1__highlights__subject__breadcrumb__title
                ">
                {{ $t("views.shared.menu.config") }}
              </h2>
            </div>
          </router-link>
          <div class="teros_principal__arrow"></div>
          <router-link :to="'/resourcetype?hash=' + resourceType.hash">
            <div class="
                teros_principal__content__line-1__highlights__subject__breadcrumb
              ">
              <h3 class="
                  teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle
                ">
                {{
                  $t(
                    "views.teros.integrations.service.content.general.resourceType"
                  )
                }}
              </h3>
              <h2 class="
                  teros_principal__content__line-1__highlights__subject__breadcrumb__title
                ">
                {{ resourceType.name }}
              </h2>
            </div>
          </router-link>
          <div class="teros_principal__arrow"></div>
          <div class="
              teros_principal__content__line-1__highlights__subject__breadcrumb
            ">
            <h3 class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle
              ">
              {{
                $t("views.teros.config.resourceType.content.permissions.title")
              }}
            </h3>
            <h2 v-if="newObj" class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__title
                active
              ">
              {{ $t("views.teros.config.permission.newObject") }}
            </h2>
            <h2 v-else class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__title
                active
              ">
              {{ permission.name }}
            </h2>
          </div>
        </div>
      </div>

      <div class="teros_principal__content__line-1__information">
        <div class="teros_principal__content__line-1__information__time">
          <p v-if="!newObj" class="teros_principal__content__line-1__information__time__text">
            <img class="
                    teros_principal__content__line-1__information__time__text__icon
                " src="/static/img/icons/relogio.png" alt="" />
            {{
              $t("views.shared.createdDate", {
                date: creationDate,
                time: creationTime,
              })
            }}
            {{
              lastUpdateDate
              ? "| " +
              $t("views.shared.changedDate", {
                date: lastUpdateDate,
                time: lastUpdateTime,
              })
              : ""
            }}
          </p>

          <button type="button" @click="goBack" class="teros_principal__content__line-1__information__time__btn">
            <img src="/static/img/icons/seta-esquerda.png" alt="" />
            {{ $t("views.shared.backButton") }}
          </button>
        </div>
      </div>
    </div>

    <div id="accordion">
      <div class="teros_principal__content__accordion__card">
        <div class="
            teros_principal__content__accordion__card__header
            accordion-off
          " id="headingOne">
          <h2 class="teros_principal__content__accordion__card__header__title">
            <button class="
                teros_principal__content__accordion__card__header__title__btn
              " type="button" aria-expanded="false" aria-controls="collapseOne">
              {{ $t("views.teros.config.permission.title") }}
            </button>
            <hr />
          </h2>
        </div>

        <div id="collapseOne" class="teros_principal__content__accordion__card__header__collapse">
          <div class="
              teros_principal__content__accordion__card__header__collapse__body
            ">
            <form @submit.prevent="createOrUpdate" class="
                teros_principal__content__accordion__card__header__collapse__body__form
              " method="post">
              <div class="form-group row">
                <label for="formGroupExampleInput">{{ $t("views.teros.config.permission.content.edit.name") }}*
                </label>
                <input type="text" required class="form-control" id="formGroupExampleInput" v-model="permission.name" />
              </div>

              <label for="formGroupEndpointInput">
                Endpoints*
              </label>

              <a href="javascript:void(null)" @click="addPermissionEndpoint" class="action-fields">+ {{
                $t("views.shared.add") }}</a>

              <div class="form-group row" v-for="(endpoint, index) of endpoints">
                <input type="text" required class="form-control col-11 mb-3" id="formGroupEndpontInput"
                  v-model="endpoints[index].endpoint">

                <div class="col-1">
                  <div class="row display-flex justify-content-center align-items-center">
                    <a href="javascript:void(null)" class="action-fields" @click="prepareRemoveEndpointPermission(index)">
                      <img src="/static/img/icons/deletar.svg" alt="" />
                    </a>

                    <div class="custom-control custom-switch ml-3">
                      <input type="checkbox" class="custom-control-input" :id="'customSwitch' + index"
                        v-model="endpoints[index].active" @change="changeStatusEndpointPermission(index)" />
                      <label class="custom-control-label" :for="'customSwitch' + index"></label>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <a href="javascript:void(null)" @click="removeEndpointPermission" class="action-fields">+ {{
                $t("views.shared.remove") }}</a> -->

              <div class="form-group align-right">
                <button class="
                    teros_principal__content__column__info__form__table__plus
                    mb0
                  ">
                  <img src="/static/img/icons/disquete.png" alt="Salvar" />{{
                    $t("views.shared.saveButton")
                  }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import { http, router } from "@/_helpers";
import moment from "moment";
import Vue from "vue";

export default {
  data() {
    return {
      newObj: true,
      resourceType: {},
      grouping: {},
      permission: { active: true },
      endpoints: []
    };
  },
  computed: {
    creationDate() {
      if (this.permission.creationDate) {
        return moment(String(this.permission.creationDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
    creationTime() {
      if (this.permission.creationDate) {
        return moment(String(this.permission.creationDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("HH:mm");
      }
      return "";
    },
    lastUpdateDate() {
      if (this.permission.lastUpdateDate) {
        return moment(String(this.permission.lastUpdateDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
    lastUpdateTime() {
      if (this.permission.lastUpdateDate) {
        return moment(String(this.permission.lastUpdateDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("HH:mm");
      }
      return "";
    },
  },
  mounted() {
    this.$emit("message", this.$route);
    const hash = this.$route.query.hash;
    const resourceTypeHash = this.$route.query.resourceTypeHash;

    this.getResourceTypeByHash(resourceTypeHash);

    if (hash) {
      this.newObj = false;
      http.get("/api/permission/get/" + hash).then((response) => {
        this.permission = response.data;
        this.getEndpointPermissionByPermissionHash(this.permission.hash);
      });
    } else {
      this.endpoints.push({ endpoint: "", active: true });
    }
  },
  methods: {
    goBack() {
      router.push({
        name: "resourcetypepage",
        query: { hash: this.resourceType.hash },
      });
    },
    createOrUpdate() {
      if (this.newObj) {
        this.permission.resourceTypeHash = this.resourceType.hash;

        http.post("/api/permission/create/", this.permission).then((response) => {
          this.newObj = false;
          this.permission = response.data;
          this.createOrUpdateEndpointPermission();
        });
      } else {
        http.put("/api/permission/update/", this.permission).then((response) => {
          this.permission = response.data;
          this.createOrUpdateEndpointPermission();
        });
      }
    },
    getGroupingByHash(groupingHash) {
      http
        .get("/api/grouping/get/" + groupingHash)
        .then((response) => {
          if (response.data && response.status === 200) {
            this.grouping = response.data;
          }
        })
        .finally(() => this.loading = false);
    },
    getResourceTypeByHash(resourceTypeHash) {
      http
        .get("/api/resourcetype/get/" + resourceTypeHash)
        .then((response) => {
          this.resourceType = response.data;
        });
    },
    addPermissionEndpoint() {
      let lastIndex = this.endpoints.length - 1;

      if (this.endpoints[lastIndex].endpoint != "" && this.endpoints.length <= 50) {
        this.endpoints.push({
          permissionHash: "",
          endpoint: "",
          active: true,
        });
      }
    },
    prepareRemoveEndpointPermission(index) {
      if (this.endpoints[index].hash) {
        this.removeEndpointPermission(this.endpoints[index].hash, index);
      } else {
        this.endpoints.splice(index, 1);
      }
    },
    changeStatusEndpointPermission(index) {
      if (!this.newObj) {
        this.updateEndpointPermission(this.endpoints[index]);
      }
    },
    removeEndpointPermission(endpointsHash, index) {
      http.remove(`/api/permissionEndpoint/delete/${endpointsHash}`)
        .then(() => {
          this.$emit('success');
          Vue.delete(this.endpoints, index);
          if (this.endpoints.length == 0) {
            this.endpoints.push({ endpoint: '', active: true });
          }
        });
    },
    createOrUpdateEndpointPermission() {
      let request = {
        permissionHash: this.permission.hash,
        endpoints: this.endpoints
      };

      http.post(`/api/permissionEndpoint/createBatch`, request)
        .then((response) => {
          this.goBack();
          this.$emit('success');
        });
    },
    updateEndpointPermission(endpointPermission) {
      http.put(`/api/permissionEndpoint/update`, endpointPermission)
        .then((response) => {
          this.$emit('success');
        });
    },
    getEndpointPermissionByPermissionHash(permissionHash) {
      http.get(`/api/permissionEndpoint/get/${permissionHash}`)
        .then((response) => {
          if (!response.data || response.data.length == 0) {
            this.endpoints = [{ endpoint: '', active: true }];
          } else {
            this.endpoints = [...response.data];
          }
        });
    }
  },
};
</script>