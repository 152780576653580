var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "teros_principal__content interna container" },
    [
      _c("div", { staticClass: "teros_principal__content__line-1 line" }, [
        _c(
          "div",
          { staticClass: "teros_principal__content__line-1__highlights" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__line-1__highlights__subject",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__line-1__highlights__subject__breadcrumb",
                  },
                  [
                    _c("router-link", { attrs: { to: "/authorizations" } }, [
                      _c(
                        "h3",
                        {
                          staticClass:
                            "teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t("views.shared.menu.authorizations")
                              ) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "h2",
                        {
                          staticClass:
                            "teros_principal__content__line-1__highlights__subject__breadcrumb__title",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.authorization.customerName) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "teros_principal__arrow" }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__line-1__highlights__subject__breadcrumb",
                  },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: "/authorization?hash=" + _vm.authorization.hash,
                        },
                      },
                      [
                        _c(
                          "h3",
                          {
                            staticClass:
                              "teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t(
                                    "views.teros.authorizations.authorization.title"
                                  )
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "h2",
                          {
                            staticClass:
                              "teros_principal__content__line-1__highlights__subject__breadcrumb__title",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.authorization.integrationName) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "teros_principal__arrow" }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__line-1__highlights__subject__breadcrumb",
                  },
                  [
                    _c(
                      "h3",
                      {
                        staticClass:
                          "teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "views.teros.authorizations.authorizationterm.title"
                              )
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "h2",
                      {
                        staticClass:
                          "teros_principal__content__line-1__highlights__subject__breadcrumb__title active",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.servicepartnershipterm.name) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "teros_principal__content__line-1__information" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__line-1__information__time",
              },
              [
                _c(
                  "button",
                  {
                    staticClass:
                      "teros_principal__content__line-1__information__time__btn",
                    attrs: { type: "button" },
                    on: { click: _vm.goBack },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "/static/img/icons/seta-esquerda.png",
                        alt: "",
                      },
                    }),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("views.shared.backButton")) +
                        "\n        "
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "teros_principal__content__accordion__description" },
        [
          _c(
            "p",
            {
              staticClass:
                "teros_principal__content__line-1__information__time__text",
            },
            [
              _c("img", {
                staticClass:
                  "teros_principal__content__line-1__information__time__text__icon",
                attrs: { src: "/static/img/icons/relogio.png", alt: "" },
              }),
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$t("views.shared.createdDate", {
                      date: _vm.creationDate,
                      time: _vm.creationTime,
                    })
                  ) +
                  "\n      " +
                  _vm._s(
                    _vm.lastUpdateDate
                      ? "| " +
                          _vm.$t("views.shared.changedDate", {
                            date: _vm.lastUpdateDate,
                            time: _vm.lastUpdateTime,
                          })
                      : ""
                  ) +
                  "\n    "
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { attrs: { id: "accordion" } }, [
        _c(
          "div",
          { staticClass: "teros_principal__content__accordion__card" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__accordion__card__header accordion-off",
                attrs: { id: "headingOne" },
              },
              [
                _c(
                  "h2",
                  {
                    staticClass:
                      "teros_principal__content__accordion__card__header__title",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "teros_principal__content__accordion__card__header__title__btn",
                        attrs: {
                          type: "button",
                          "aria-expanded": "false",
                          "aria-controls": "collapseOne",
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "views.teros.authorizations.authorizationterm.content.title"
                              )
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("hr"),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__accordion__card__header__collapse",
                attrs: { id: "collapseOne" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__accordion__card__header__collapse__body",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "teros_principal__content__accordion__card__header__collapse__body__form",
                      },
                      [
                        _vm.servicepartnershipterm.authorizationStatus == 2 &&
                        _vm.servicepartnershiptermorigin.exists
                          ? _c("div", [
                              _c("div", { staticClass: "form-group" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "custom-control custom-switch",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.servicepartnershiptermorigin
                                              .active,
                                          expression:
                                            "servicepartnershiptermorigin.active",
                                        },
                                      ],
                                      staticClass: "custom-control-input",
                                      attrs: {
                                        type: "checkbox",
                                        checked: "",
                                        id: "customSwitch1",
                                        disabled: "",
                                      },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.servicepartnershiptermorigin
                                            .active
                                        )
                                          ? _vm._i(
                                              _vm.servicepartnershiptermorigin
                                                .active,
                                              null
                                            ) > -1
                                          : _vm.servicepartnershiptermorigin
                                              .active,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a =
                                              _vm.servicepartnershiptermorigin
                                                .active,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.servicepartnershiptermorigin,
                                                  "active",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.servicepartnershiptermorigin,
                                                  "active",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              _vm.servicepartnershiptermorigin,
                                              "active",
                                              $$c
                                            )
                                          }
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "custom-control-label",
                                        attrs: { for: "customSwitch1" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "views.teros.authorizations.authorizationterm.content.originalStatus"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "form-group row" }, [
                                _c("div", { staticClass: "col" }, [
                                  _c(
                                    "label",
                                    {
                                      attrs: { for: "formGroupExampleInput1" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "views.teros.authorizations.authorizationterm.content.originalOrdering"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.number",
                                        value:
                                          _vm.servicepartnershiptermorigin
                                            .orderNumber,
                                        expression:
                                          "servicepartnershiptermorigin.orderNumber",
                                        modifiers: { number: true },
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "number",
                                      id: "formGroupExampleInput1",
                                      readonly: "",
                                    },
                                    domProps: {
                                      value:
                                        _vm.servicepartnershiptermorigin
                                          .orderNumber,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.servicepartnershiptermorigin,
                                          "orderNumber",
                                          _vm._n($event.target.value)
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col" }, [
                                  _c(
                                    "label",
                                    {
                                      attrs: { for: "formGroupExampleInput2" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "views.teros.authorizations.authorizationterm.content.originalName"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.servicepartnershiptermorigin.name,
                                        expression:
                                          "servicepartnershiptermorigin.name",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      id: "formGroupExampleInput2",
                                      readonly: "",
                                    },
                                    domProps: {
                                      value:
                                        _vm.servicepartnershiptermorigin.name,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.servicepartnershiptermorigin,
                                          "name",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "form-group row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col" },
                                  [
                                    _c(
                                      "label",
                                      {
                                        attrs: {
                                          for: "formGroupExampleInput3",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "views.teros.authorizations.authorizationterm.content.originalTerm"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("editor", {
                                      attrs: {
                                        id: "originalTerm",
                                        "api-key": "no-api-key",
                                        toolbar: "false",
                                        disabled: "",
                                      },
                                      model: {
                                        value:
                                          _vm.servicepartnershiptermorigin
                                            .description,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.servicepartnershiptermorigin,
                                            "description",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "servicepartnershiptermorigin.description",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c("hr", { staticStyle: { margin: "40px 0" } }),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "div",
                              { staticClass: "custom-control custom-switch" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.servicepartnershipterm.active,
                                      expression:
                                        "servicepartnershipterm.active",
                                    },
                                  ],
                                  staticClass: "custom-control-input",
                                  attrs: {
                                    type: "checkbox",
                                    checked: "",
                                    id: "customSwitch1",
                                    disabled: "",
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.servicepartnershipterm.active
                                    )
                                      ? _vm._i(
                                          _vm.servicepartnershipterm.active,
                                          null
                                        ) > -1
                                      : _vm.servicepartnershipterm.active,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a =
                                          _vm.servicepartnershipterm.active,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.servicepartnershipterm,
                                              "active",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.servicepartnershipterm,
                                              "active",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.servicepartnershipterm,
                                          "active",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass: "custom-control-label",
                                    attrs: { for: "customSwitch1" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "views.teros.authorizations.authorizationterm.content.changedStatus"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group row" }, [
                            _c("div", { staticClass: "col" }, [
                              _c(
                                "label",
                                { attrs: { for: "formGroupExampleInput1" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "views.teros.authorizations.authorizationterm.content.changedOrdering"
                                      )
                                    ) + "\n                    "
                                  ),
                                  _c("img", {
                                    attrs: {
                                      src: "/static/img/icons/tooltip.png",
                                      "data-toggle": "tooltip",
                                      "data-placement": "right",
                                      title: _vm.$t(
                                        "views.teros.authorizations.authorizationterm.toolTips.changedOrdering"
                                      ),
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.number",
                                    value:
                                      _vm.servicepartnershipterm.orderNumber,
                                    expression:
                                      "servicepartnershipterm.orderNumber",
                                    modifiers: { number: true },
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "number",
                                  id: "formGroupExampleInput1",
                                  readonly: "",
                                },
                                domProps: {
                                  value: _vm.servicepartnershipterm.orderNumber,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.servicepartnershipterm,
                                      "orderNumber",
                                      _vm._n($event.target.value)
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col" }, [
                              _c(
                                "label",
                                { attrs: { for: "formGroupExampleInput2" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "views.teros.authorizations.authorizationterm.content.changedName"
                                      )
                                    ) + "\n                    "
                                  ),
                                  _c("img", {
                                    attrs: {
                                      src: "/static/img/icons/tooltip.png",
                                      "data-toggle": "tooltip",
                                      "data-placement": "right",
                                      title: _vm.$t(
                                        "views.teros.authorizations.authorizationterm.toolTips.changedName"
                                      ),
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.servicepartnershipterm.name,
                                    expression: "servicepartnershipterm.name",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "text",
                                  id: "formGroupExampleInput2",
                                  readonly: "",
                                },
                                domProps: {
                                  value: _vm.servicepartnershipterm.name,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.servicepartnershipterm,
                                      "name",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group row" }, [
                            _c(
                              "div",
                              { staticClass: "col" },
                              [
                                _c(
                                  "label",
                                  { attrs: { for: "formGroupExampleInput3" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "views.teros.authorizations.authorizationterm.content.changedTerm"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("editor", {
                                  attrs: {
                                    id: "alteredTerm",
                                    "api-key": "no-api-key",
                                    toolbar: "false",
                                    disabled: "",
                                  },
                                  model: {
                                    value:
                                      _vm.servicepartnershipterm.description,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.servicepartnershipterm,
                                        "description",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "servicepartnershipterm.description",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("hr", { staticStyle: { margin: "40px 0" } }),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }