var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "custom-file" }, [
      _c("input", {
        ref: "files",
        staticClass: "form-control-file custom-file-input",
        attrs: { type: "file", id: "customFile" },
        on: { input: _vm.fileSelected },
      }),
      _vm._v(" "),
      _c(
        "label",
        { staticClass: "custom-file-label", attrs: { for: "customFile" } },
        [_vm._v(_vm._s(_vm.defaultValue))]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticStyle: { "font-size": "10px", "margin-top": "35px" } }, [
      _vm._v(
        "\n    " +
          _vm._s(_vm.$t("views.fileUpload.component.maxFileSize")) +
          "\n  "
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }