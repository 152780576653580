<template>
  <div class="teros_principal__content interna container">
    <div class="teros_principal__content__line-1 line">
      <div class="teros_principal__content__line-1__highlights">
        <div class="teros_principal__content__line-1__highlights__subject">
          <div
            class="
              teros_principal__content__line-1__highlights__subject__breadcrumb
            "
          >
            <router-link to="/transmission">
              <h3
                class="
                  teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle
                "
              >
                {{ $t("views.client.transmission.general.client") }}
              </h3>
              <h2
                class="
                  teros_principal__content__line-1__highlights__subject__breadcrumb__title
                "
              >
                {{ currentUser.customerName }}
              </h2>
            </router-link>
          </div>
          <div class="teros_principal__arrow"></div>
          <div
            class="
              teros_principal__content__line-1__highlights__subject__breadcrumb
            "
          >
            <h3
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle
              "
            >
              {{
                $t("views.client.transmission.transmissionconsentiment.title")
              }}
            </h3>
            <h2
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__title
                active
              "
            >
              {{ transmissionconsentiment.name }}
            </h2>
          </div>
          <div class="teros_principal__arrow"></div>
          <div
            class="
              teros_principal__content__line-1__highlights__subject__breadcrumb
            "
          >
            <h3
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle
              "
            >
              {{
                $t(
                  "views.client.transmission.transmissionconsentimentterm.title"
                )
              }}
            </h3>
            <h2
              v-if="newObj"
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__title
                active
              "
            >
              {{
                $t(
                  "views.client.transmission.transmissionconsentimentterm.newObject"
                )
              }}
            </h2>
            <h2
              v-else
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__title
                active
              "
            >
              {{ transmissionconsentimentterm.name }}
            </h2>
          </div>
        </div>
      </div>
      <div class="teros_principal__content__line-1__information">
        <div class="teros_principal__content__line-1__information__time">
          <button
            type="button"
            @click="goBack"
            class="teros_principal__content__line-1__information__time__btn"
          >
            <img src="/static/img/icons/seta-esquerda.png" alt="" />
            {{ $t("views.shared.backButton") }}
          </button>
        </div>
      </div>
    </div>

    <div class="teros_principal__content__accordion__description">
      <p
        v-if="!newObj"
        class="teros_principal__content__line-1__information__time__text"
      >
        <img
          class="
            teros_principal__content__line-1__information__time__text__icon
          "
          src="/static/img/icons/relogio.png"
          alt=""
        />
        {{
          $t("views.shared.createdDate", {
            date: creationDate,
            time: creationTime,
          })
        }}
        {{
          lastUpdateDate
            ? "| " +
              $t("views.shared.changedDate", {
                date: lastUpdateDate,
                time: lastUpdateTime,
              })
            : ""
        }}
      </p>
    </div>

    <div id="accordion">
      <div class="teros_principal__content__accordion__card">
        <div
          class="
            teros_principal__content__accordion__card__header
            accordion-off
          "
          id="headingOne"
        >
          <h2 class="teros_principal__content__accordion__card__header__title">
            <button
              class="
                teros_principal__content__accordion__card__header__title__btn
              "
              type="button"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              {{
                $t(
                  "views.client.transmission.transmissionconsentimentterm.content.editTab.title"
                )
              }}
            </button>
            <hr />
          </h2>
        </div>

        <div
          id="collapseOne"
          class="teros_principal__content__accordion__card__header__collapse"
        >
          <div
            class="
              teros_principal__content__accordion__card__header__collapse__body
            "
          >
            <form
              @submit.prevent="createOrUpdate"
              method="post"
              class="
                teros_principal__content__accordion__card__header__collapse__body__form
              "
            >
              <div class="form-group">
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    checked
                    class="custom-control-input"
                    id="customSwitch2"
                    v-model="transmissionconsentimentterm.active"
                  />
                  <label class="custom-control-label" for="customSwitch2">{{
                    $t("views.shared.activeSwitch")
                  }}</label>
                </div>
              </div>

              <div class="form-group row">
                <div class="col">
                  <label for="formGroupExampleInput"
                    >{{
                      $t(
                        "views.client.transmission.transmissionconsentimentterm.content.editTab.orderNumber"
                      )
                    }}*
                    <img
                      src="/static/img/icons/tooltip.png"
                      data-toggle="tooltip"
                      data-placement="right"
                      :title="$t('views.client.transmission.transmissionconsentimentterm.toolTip.orderNumber')" 
                  /></label>
                  <custom-input
                    :id="'formGroupExampleInput'"
                    :type="'number'"
                    :regex="'[0-9]'"
                    v-model="transmissionconsentimentterm.orderNumber"
                  ></custom-input>
                </div>

                <div class="col">
                  <label for="formGroupExampleInput2"
                    >{{
                      $t(
                        "views.client.transmission.transmissionconsentimentterm.content.editTab.name"
                      )
                    }}*</label
                  >
                  <custom-input
                    :id="'formGroupExampleInput2'"
                    v-model="transmissionconsentimentterm.name"
                  ></custom-input>
                </div>
              </div>

              <div class="form-group row">
                <div class="col">
                  <label for="formGroupExampleInput3"
                    >{{
                      $t(
                        "views.client.transmission.transmissionconsentimentterm.content.editTab.editDescription"
                      )
                    }}*</label
                  >
                  <editor
                    api-key="no-api-key"
                    v-model="transmissionconsentimentterm.description"
                  />
                </div>
              </div>
              <div class="form-group align-right">
                <button
                  class="
                    teros_principal__content__column__info__form__table__plus
                    mb0
                  "
                >
                  <img src="/static/img/icons/disquete.png" alt="Salvar" />{{
                    $t("views.shared.saveButton")
                  }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cognito, http, router } from "@/_helpers";
import Editor from "@tinymce/tinymce-vue";
import CustomInput from "@/_components/CustomInput.vue";
import moment from "moment";

export default {
  data() {
    return {
      currentUser: cognito.currentUserValue,
      newObj: true,
      transmissionconsentiment: {},
      transmissionconsentimentterm: { active: true },
    };
  },
  components: {
    Editor,
    CustomInput,
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
  },
  computed: {
    creationDate() {
      if (this.transmissionconsentimentterm.creationDate) {
        return moment(
          String(this.transmissionconsentimentterm.creationDate)
        ).format("DD/MM/YYYY");
      }
      return "";
    },
    creationTime() {
      if (this.transmissionconsentimentterm.creationDate) {
        return moment(
          String(this.transmissionconsentimentterm.creationDate)
        ).format("HH:mm");
      }
      return "";
    },
    lastUpdateDate() {
      if (this.transmissionconsentimentterm.lastUpdateDate) {
        return moment(
          String(this.transmissionconsentimentterm.lastUpdateDate)
        ).format("DD/MM/YYYY");
      }
      return "";
    },
    lastUpdateTime() {
      if (this.transmissionconsentimentterm.lastUpdateDate) {
        return moment(
          String(this.transmissionconsentimentterm.lastUpdateDate)
        ).format("HH:mm");
      }
      return "";
    },
  },
  mounted() {
    this.$emit("message", this.$route);
    const hash = this.$route.query.hash;
    const transmissionConsentimentHash =
      this.$route.query.transmissionConsentimentHash;
    http
      .get("/api/transmissionconsentiment/get/" + transmissionConsentimentHash)
      .then((response) => {
        this.transmissionconsentiment = response.data;
      });
    if (hash) {
      this.newObj = false;
      http
        .get("/api/transmissionconsentimentterm/get/" + hash)
        .then((response) => {
          this.transmissionconsentimentterm = response.data;
        });
    } else {
      this.transmissionconsentimentterm.transmissionConsentimentHash =
        transmissionConsentimentHash;
      this.transmissionconsentimentterm.description = "{{Titulo}}";
    }
  },
  methods: {
    goBack() {
      router.push({
        name: "transmissionconsentimentpage",
        query: { hash: this.transmissionconsentiment.hash },
      });
    },
    createOrUpdate() {
      if (this.newObj) {
        http
          .post(
            "/api/transmissionconsentimentterm/create/",
            this.transmissionconsentimentterm
          )
          .then(() => {
            this.goBack();
            this.$emit("success");
          });
      } else {
        http
          .put(
            "/api/transmissionconsentimentterm/update/",
            this.transmissionconsentimentterm
          )
          .then(() => {
            this.goBack();
            this.$emit("success");
          });
      }
    },
  },
};
</script>