var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "teros_principal__content interna container" },
    [
      _c("div", { staticClass: "teros_principal__content__line-1" }, [
        _c(
          "div",
          { staticClass: "teros_principal__content__line-1__highlights" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__line-1__highlights__subject",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__line-1__highlights__subject__breadcrumb",
                  },
                  [
                    _c("a", { attrs: { href: "#" } }, [
                      _c(
                        "h3",
                        {
                          staticClass:
                            "teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t(
                                  "views.client.transmission.general.client"
                                )
                              ) +
                              "\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "h2",
                        {
                          staticClass:
                            "teros_principal__content__line-1__highlights__subject__breadcrumb__title",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.currentUser.customerName) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "teros_principal__content__line-1__information" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__line-1__information__time",
              },
              [
                !_vm.newObj
                  ? _c(
                      "p",
                      {
                        staticClass:
                          "teros_principal__content__line-1__information__time__text",
                      },
                      [
                        _c("img", {
                          staticClass:
                            "teros_principal__content__line-1__information__time__text__icon",
                          attrs: {
                            src: "/static/img/icons/relogio.png",
                            alt: "",
                          },
                        }),
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t("views.shared.createdDate", {
                                date: _vm.creationDate,
                                time: _vm.creationTime,
                              })
                            ) +
                            "\n          " +
                            _vm._s(
                              _vm.lastUpdateDate
                                ? "| " +
                                    _vm.$t("views.shared.changedDate", {
                                      date: _vm.lastUpdateDate,
                                      time: _vm.lastUpdateTime,
                                    })
                                : ""
                            ) +
                            "\n        "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "teros_principal__content__column" }, [
        _c(
          "div",
          { staticClass: "teros_principal__content__column__menu-vertical" },
          [
            _c(
              "ul",
              {
                staticClass:
                  "teros_principal__content__column__menu-vertical__list",
              },
              [
                _c(
                  "li",
                  {
                    staticClass:
                      "teros_principal__content__column__menu-vertical__list__item",
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass:
                          "teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--lupa active",
                        attrs: { href: "#" },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("views.client.transmission.general.tabTitle")
                          )
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass:
                      "teros_principal__content__column__menu-vertical__list__item",
                  },
                  [
                    _vm.newObj
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--servicos",
                            attrs: { href: "#" },
                            on: { click: _vm.showAlert },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "views.client.transmission.transmissionservices.tabTitle"
                                )
                              )
                            ),
                          ]
                        )
                      : _c(
                          "router-link",
                          {
                            staticClass:
                              "teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--servicos",
                            attrs: { to: "/transmissionservices" },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "views.client.transmission.transmissionservices.tabTitle"
                                )
                              )
                            ),
                          ]
                        ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass:
                      "teros_principal__content__column__menu-vertical__list__item",
                  },
                  [
                    _vm.newObj
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--compliance",
                            attrs: { href: "#" },
                            on: { click: _vm.showAlert },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "views.client.transmission.transmissionconsentiments.tabTitle"
                                )
                              )
                            ),
                          ]
                        )
                      : _c(
                          "router-link",
                          {
                            staticClass:
                              "teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--compliance",
                            attrs: { to: "/transmissionconsentiments" },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "views.client.transmission.transmissionconsentiments.tabTitle"
                                )
                              )
                            ),
                          ]
                        ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass:
                      "teros_principal__content__column__menu-vertical__list__item",
                  },
                  [
                    _vm.newObj
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--servicos",
                            attrs: { href: "#" },
                            on: { click: _vm.showAlert },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "views.client.transmission.transmissionapis.tabTitle"
                                )
                              )
                            ),
                          ]
                        )
                      : _c(
                          "router-link",
                          {
                            staticClass:
                              "teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--servicos",
                            attrs: { to: "/transmissionapis" },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "views.client.transmission.transmissionapis.tabTitle"
                                )
                              )
                            ),
                          ]
                        ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass:
                      "teros_principal__content__column__menu-vertical__list__item",
                  },
                  [
                    _vm.newObj
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--registros",
                            attrs: { href: "#" },
                            on: { click: _vm.showAlert },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "views.client.transmission.transmissionregistries.tabTitle"
                                )
                              )
                            ),
                          ]
                        )
                      : _c(
                          "router-link",
                          {
                            staticClass:
                              "teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--registros",
                            attrs: { to: "/transmissionregistries" },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "views.client.transmission.transmissionregistries.tabTitle"
                                )
                              )
                            ),
                          ]
                        ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass:
                      "teros_principal__content__column__menu-vertical__list__item",
                  },
                  [
                    _vm.newObj
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--gestao",
                            attrs: { href: "#" },
                            on: { click: _vm.showAlert },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "views.client.transmission.transmissionlogs.tabTitle"
                                )
                              )
                            ),
                          ]
                        )
                      : _c(
                          "router-link",
                          {
                            staticClass:
                              "teros_principal__content__column__menu-vertical__list__item__link teros_principal__content__column__menu-vertical__list__item__link--gestao",
                            attrs: { to: "/transmissionlogs" },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "views.client.transmission.transmissionlogs.tabTitle"
                                )
                              )
                            ),
                          ]
                        ),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "teros_principal__content__column__info" }, [
          _c("div", { attrs: { id: "accordion" } }, [
            _c(
              "form",
              {
                attrs: { method: "post" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.createOrUpdate.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__accordion__card mx0",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "teros_principal__content__accordion__card__header",
                        attrs: { id: "headingOne" },
                      },
                      [
                        _c(
                          "h2",
                          {
                            staticClass:
                              "teros_principal__content__accordion__card__header__title",
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "teros_principal__content__accordion__card__header__title__btn",
                                attrs: {
                                  type: "button",
                                  "data-toggle": "collapse",
                                  "data-target": "#collapseOne",
                                  "aria-expanded": "false",
                                  "aria-controls": "collapseOne",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t(
                                        "views.client.transmission.general.tabTitle"
                                      )
                                    ) +
                                    "\n                "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "teros_principal__content__accordion__card__header__collapse",
                        attrs: { id: "collapseOne" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "teros_principal__content__accordion__card__header__collapse__body",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "teros_principal__content__accordion__card__header__collapse__body__form",
                              },
                              [
                                _c("div", { staticClass: "form-group" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "custom-control custom-switch",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.transmission.active,
                                            expression: "transmission.active",
                                          },
                                        ],
                                        staticClass: "custom-control-input",
                                        attrs: {
                                          type: "checkbox",
                                          checked: "",
                                          id: "customSwitch1",
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.transmission.active
                                          )
                                            ? _vm._i(
                                                _vm.transmission.active,
                                                null
                                              ) > -1
                                            : _vm.transmission.active,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.transmission.active,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.transmission,
                                                    "active",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.transmission,
                                                    "active",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.transmission,
                                                "active",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        {
                                          staticClass: "custom-control-label",
                                          attrs: { for: "customSwitch1" },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "views.shared.activeSwitch"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "form-group row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          attrs: {
                                            for: "formGroupExampleInput2",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "views.client.transmission.general.content.brand"
                                              )
                                            ) + "*\n                        "
                                          ),
                                          _c("img", {
                                            attrs: {
                                              src: "/static/img/icons/tooltip.png",
                                              "data-toggle": "tooltip",
                                              "data-placement": "right",
                                              title: _vm.$t(
                                                "views.client.transmission.general.toolTips.brand"
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("custom-input", {
                                        attrs: { id: "formGroupExampleInput2" },
                                        model: {
                                          value: _vm.transmission.brand,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.transmission,
                                              "brand",
                                              $$v
                                            )
                                          },
                                          expression: "transmission.brand",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "teros_principal__content__accordion__card",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "teros_principal__content__accordion__card__header",
                                        attrs: { id: "headingSecond2" },
                                      },
                                      [
                                        _c(
                                          "h2",
                                          {
                                            staticClass:
                                              "teros_principal__content__accordion__card__header__title",
                                          },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "teros_principal__content__accordion__card__header__title__btn",
                                                attrs: {
                                                  type: "button",
                                                  "data-toggle": "collapse",
                                                  "data-target": "#fase2",
                                                  "aria-expanded": "true",
                                                  "aria-controls": "fase2",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                          " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "views.client.transmission.general.phase2Config"
                                                      )
                                                    ) +
                                                    "\n                        "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("hr"),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "teros_principal__content__accordion__card__header__collapse",
                                        attrs: { id: "fase2" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "teros_principal__content__accordion__card__header__collapse__body pt0",
                                            staticStyle: { padding: "0 40px" },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "form-group row" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "col" },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: {
                                                          for: "integrationBaseURL",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "views.client.transmission.general.integrationBaseURL"
                                                            )
                                                          ) +
                                                            "*\n                              "
                                                        ),
                                                        _c("img", {
                                                          attrs: {
                                                            src: "/static/img/icons/tooltip.png",
                                                            "data-toggle":
                                                              "tooltip",
                                                            "data-placement":
                                                              "right",
                                                            title: _vm.$t(
                                                              "views.client.transmission.general.toolTips.integrationBaseURL"
                                                            ),
                                                            alt: "",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("custom-input", {
                                                      attrs: {
                                                        id: "integrationBaseURL",
                                                        regex: ".*",
                                                        maxlength: "250",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.transmission
                                                            .integrationBaseURL,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.transmission,
                                                            "integrationBaseURL",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "transmission.integrationBaseURL",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "form-group row" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "col" },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: {
                                                          for: "webhook",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "views.client.transmission.general.webhook"
                                                            )
                                                          ) +
                                                            "*\n                              "
                                                        ),
                                                        _c("img", {
                                                          attrs: {
                                                            src: "/static/img/icons/tooltip.png",
                                                            "data-toggle":
                                                              "tooltip",
                                                            "data-placement":
                                                              "right",
                                                            title: _vm.$t(
                                                              "views.client.transmission.general.toolTips.webhook"
                                                            ),
                                                            alt: "",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("custom-input", {
                                                      attrs: {
                                                        id: "webhook",
                                                        regex: ".*",
                                                        maxlength: "250",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.transmission
                                                            .webhook,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.transmission,
                                                            "webhook",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "transmission.webhook",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "teros_principal__content__accordion__card",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "teros_principal__content__accordion__card__header",
                                        attrs: { id: "headingSecond" },
                                      },
                                      [
                                        _c(
                                          "h2",
                                          {
                                            staticClass:
                                              "teros_principal__content__accordion__card__header__title",
                                          },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "teros_principal__content__accordion__card__header__title__btn",
                                                attrs: {
                                                  type: "button",
                                                  "data-toggle": "collapse",
                                                  "data-target": "#fase3",
                                                  "aria-expanded": "true",
                                                  "aria-controls": "fase3",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                          " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "views.client.transmission.general.phase3Config"
                                                      )
                                                    ) +
                                                    "\n                        "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("hr"),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "teros_principal__content__accordion__card__header__collapse",
                                        attrs: { id: "fase3" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "teros_principal__content__accordion__card__header__collapse__body pt0",
                                            staticStyle: { padding: "0 40px" },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "form-group row" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "col" },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: {
                                                          for: "paymentInitiationURL",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "views.client.transmission.general.paymentInitiationURL"
                                                            )
                                                          ) +
                                                            "*\n                              "
                                                        ),
                                                        _c("img", {
                                                          attrs: {
                                                            src: "/static/img/icons/tooltip.png",
                                                            "data-toggle":
                                                              "tooltip",
                                                            "data-placement":
                                                              "right",
                                                            title: _vm.$t(
                                                              "views.client.transmission.general.toolTips.paymentInitiationURL"
                                                            ),
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("custom-input", {
                                                      attrs: {
                                                        id: "paymentInitiationURL",
                                                        regex: ".*",
                                                        maxlength: "250",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.transmission
                                                            .paymentInitiationURL,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.transmission,
                                                            "paymentInitiationURL",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "transmission.paymentInitiationURL",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "form-group row" },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "col" },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        attrs: {
                                                          for: "paymentInitiationConsultURL",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "views.client.transmission.general.paymentInitiationConsultURL"
                                                            )
                                                          ) +
                                                            "*\n                              "
                                                        ),
                                                        _c("img", {
                                                          attrs: {
                                                            src: "/static/img/icons/tooltip.png",
                                                            "data-toggle":
                                                              "tooltip",
                                                            "data-placement":
                                                              "right",
                                                            title: _vm.$t(
                                                              "views.client.transmission.general.toolTips.paymentInitiationQueryURL"
                                                            ),
                                                            alt: "",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("custom-input", {
                                                      attrs: {
                                                        id: "paymentInitiationConsultURL",
                                                        regex: ".*",
                                                        maxlength: "250",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.transmission
                                                            .paymentInitiationConsultURL,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.transmission,
                                                            "paymentInitiationConsultURL",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "transmission.paymentInitiationConsultURL",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("authorization-form", {
                  attrs: {
                    apiAuthentication: _vm.transmission.apiAuthentication,
                    showDcrType: _vm.showDcrType,
                    customHeaders: _vm.customHeaders,
                  },
                  on: { onChangeFlow: _vm.onChangeFlowType },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "form-group teros_principal__content__column__info__form__combo",
                    staticStyle: {
                      "margin-top": "0",
                      "margin-bottom": "40px",
                      "text-align": "right",
                    },
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "teros_principal__content__column__info__form__combo__salvar",
                        attrs: { title: "Salvar" },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: "/static/img/icons/disquete.png",
                            alt: "Salvar",
                          },
                        }),
                        _vm._v(
                          _vm._s(_vm.$t("views.shared.saveButton")) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }