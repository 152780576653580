<template>
  <div class="teros_principal__content interna container">
    <div class="teros_principal__content__line-1 line">
      <div class="teros_principal__content__line-1__highlights">
        <div class="teros_principal__content__line-1__highlights__subject">
          <router-link v-if="newObj" :to="'/services?hash=' + integration.hash">
            <div class="
                teros_principal__content__line-1__highlights__subject__breadcrumb
              ">
              <h3 class="
                  teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle
                ">
                {{ $t("views.teros.integrations.integration.title") }}
              </h3>
              <h2 class="
                  teros_principal__content__line-1__highlights__subject__breadcrumb__title
                ">
                {{ integration.name }}
              </h2>
            </div>
          </router-link>
          <router-link v-else :to="'/services?hash=' + service.integrationHash">
            <div class="
                teros_principal__content__line-1__highlights__subject__breadcrumb
              ">
              <h3 class="
                  teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle
                ">
                {{ $t("views.teros.integrations.integration.title") }}
              </h3>
              <h2 class="
                  teros_principal__content__line-1__highlights__subject__breadcrumb__title
                ">
                {{ service.integrationName }}
              </h2>
            </div>
          </router-link>
          <div class="teros_principal__arrow"></div>
          <div v-if="newObj" class="
              teros_principal__content__line-1__highlights__subject__breadcrumb
            ">
            <h3 class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle
              ">
              {{ $t("views.teros.integrations.service.title") }}
            </h3>
            <h2 class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__title
                active
              ">
              {{ $t("views.teros.integrations.service.newObject") }}
            </h2>
          </div>
          <div v-else class="
              teros_principal__content__line-1__highlights__subject__breadcrumb
            ">
            <h3 class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle
              ">
              {{ $t("views.teros.integrations.service.title") }}
            </h3>
            <h2 class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__title
                active
              ">
              {{ service.name }}
            </h2>
          </div>
        </div>
      </div>
      <div class="teros_principal__content__line-1__information">
        <div class="teros_principal__content__line-1__information__time">
          <button type="button" @click="goBack" class="teros_principal__content__line-1__information__time__btn">
            <img src="/static/img/icons/seta-esquerda.png" alt="" />
            {{ $t("views.shared.backButton") }}
          </button>
        </div>
      </div>
    </div>

    <div v-if="!newObj" class="teros_principal__content__accordion__description">
      <p class="teros_principal__content__line-1__information__time__text">
        <img class="
            teros_principal__content__line-1__information__time__text__icon
          " src="/static/img/icons/relogio.png" alt="" />
        {{
          $t("views.shared.createdDate", {
            date: creationDate,
            time: creationTime,
          })
        }}
        {{
          lastUpdateDate
          ? "| " +
          $t("views.shared.changedDate", {
            date: lastUpdateDate,
            time: lastUpdateTime,
          })
          : ""
        }}
      </p>
    </div>

    <div id="accordion">
      <form @submit.prevent="createOrUpdate" class="
          teros_principal__content__accordion__card__header__collapse__body__form
        " method="post">
        <div class="teros_principal__content__accordion__card">
          <div class="teros_principal__content__accordion__card__header" id="headingOne">
            <h2 class="teros_principal__content__accordion__card__header__title">
              <button class="
                  teros_principal__content__accordion__card__header__title__btn
                " type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false"
                aria-controls="collapseOne">
                {{
                  $t("views.teros.integrations.service.content.general.title")
                }}
              </button>
              <hr />
            </h2>
          </div>

          <div id="collapseOne" class="teros_principal__content__accordion__card__header__collapse">
            <div class="
                teros_principal__content__accordion__card__header__collapse__body
              ">
              <div class="
                  teros_principal__content__accordion__card__header__collapse__body__form
                ">
                <div class="form-group row">
                  <div class="col">
                    <div class="custom-control custom-switch">
                      <input type="checkbox" class="custom-control-input" id="customSwitch" v-model="service.active" />
                      <label class="custom-control-label" for="customSwitch">{{
                        $t("views.shared.activeSwitch")
                      }}</label>
                    </div>

                    <div class="
                        teros_principal__content__accordion__card__header__collapse__body__form__column__fields
                      ">
                      <div class="form-group">
                        <label for="formGroupExampleInput">{{
                          $t(
                            "views.teros.integrations.service.content.general.serviceName"
                          )
                        }}*
                          <img src="/static/img/icons/tooltip.png" data-toggle="tooltip" data-placement="right" :title="$t(
                                'views.teros.integrations.service.toolTips.general.serviceName'
                              )
                              " /></label>
                        <custom-input :id="'formGroupExampleInput'" v-model="service.name"></custom-input>
                      </div>
                      <div class="form-group">
                        <label for="formGroupExampleInput1">{{
                          $t(
                            "views.teros.integrations.service.content.general.commercialName"
                          )
                        }}
                        </label>
                        <custom-input :id="'formGroupExampleInput1'" v-model="service.commercialName"></custom-input>
                      </div>
                      <div class="form-group">
                        <live-select :title="$t(
                          'views.teros.integrations.service.content.general.resourceType'
                        ) + '*'
                          " :options="resourceTypes" :hash="service.resourceTypeHash"
                          @onChange="onChangeResourceTypeSelect($event)"></live-select>
                      </div>

                      <div class="form-group">
                        <live-select :id="'groupings'" :title="$t(
                          'views.teros.integrations.service.content.general.grouping'
                        ) + '*'
                          " :options="groupings" :multiple="true" :hashs="service.groupingHashs"
                          @onChange="$event => onChangeGroupingsSelect($event)"></live-select>
                      </div>
                      <!-- <div class="form-group">
                        <live-select
                          :id="'permissions'"
                          :title="
                            $t(
                              'views.teros.integrations.service.content.general.permissions'
                            ) + '*'
                          "
                          :options="permissions"
                          :multiple="true"
                          :disabled="true"
                          :hashs="service.permissionHashs"
                          @onChange="$event => onChangePermissionsSelect($event)"
                        ></live-select>
                      </div> -->
                      <div class="
                          teros_principal__content__accordion__card__header__collapse__body__form__column__fields
                        " style="padding-top: 0">
                        <div class="form-group">
                          <label for="formGroupExampleInput2">URL*
                            <img src="/static/img/icons/tooltip.png" data-toggle="tooltip" data-placement="right" :title="$t(
                              'views.teros.integrations.service.toolTips.general.urlEndpoint'
                            )
                              " /></label>
                          <custom-input :id="'formGroupExampleInput2'" :regex="'.*'"
                            v-model="service.endpointUrl"></custom-input>
                        </div>
                        <div class="form-group">
                          <label for="formGroupExampleInput4">{{
                            $t(
                              "views.teros.integrations.service.content.general.serviceData"
                            )
                          }}
                          </label>
                          <a href="javascript:void(null)" @click="addServiceDataItem" class="action-fields">+ {{
                            $t("views.shared.add") }}</a>
                          <div v-for="(item, counter) in serviceData" :key="counter">
                            <input type="text" class="form-control" v-model="item.name" />
                            <a href="javascript:void(null)" @click="removeServiceDataItem(counter)"
                              class="action-fields">- {{ $t("views.shared.remove") }}</a>
                          </div>
                        </div>
                      </div>

                      <!-- <button v-if="endpointPermissionsList && endpointPermissionsList.length > 0" type="button"
                        class="teros_principal__content__column__info__form__table__plus" data-toggle="modal"
                        data-target="#endpointsModal">Configurar Endpoints</button> -->
                    </div>
                  </div>
                  <div class="col">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                      <li class="nav-item">
                        <a class="nav-link active" id="jsonSchemaRequest-tab" data-toggle="tab" href="#jsonSchemaRequest"
                          role="tab" aria-controls="jsonSchemaRequest" aria-selected="true">JSON Schema Request</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" id="jsonSchemaResponse-tab" data-toggle="tab" href="#jsonSchemaResponse"
                          role="tab" aria-controls="jsonSchemaResponse" aria-selected="false">JSON Schema Response</a>
                      </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                      <div class="tab-pane fade show active" id="jsonSchemaRequest" role="tabpanel"
                        aria-labelledby="jsonSchemaRequest-tab">
                        <ace-editor v-model="service.jsonSchemaRequest" ref="jsonSchemaRequestEditor" lang="json"
                          theme="dawn" height="450px" style="fontsize: 15px">
                        </ace-editor>
                        <a href="javascript:void(null)" @click="copyJsonRequest" class="copy-code"><img
                            src="/static/img/icons/copy.png" alt="" /></a>
                      </div>
                      <div class="tab-pane fade" id="jsonSchemaResponse" role="tabpanel"
                        aria-labelledby="jsonSchemaResponse-tab">
                        <div class="teros_principal__tags">
                          <a href="javascript:void(null)" @click="showResponseSuccess" class="
                              teros_principal__tags__tag
                              teros_principal__tags__tag--green
                              active
                            ">{{
                              $t(
                                "views.teros.integrations.service.content.general.jsonSchemaMsgs.success"
                              )
                            }}</a>
                          <a href="javascript:void(null)" @click="showResponseError" class="
                              teros_principal__tags__tag
                              teros_principal__tags__tag--red
                            ">{{
                              $t(
                                "views.teros.integrations.service.content.general.jsonSchemaMsgs.error"
                              )
                            }}</a>
                        </div>
                        <ace-editor v-if="responseSuccessActive" v-model="service.jsonSchemaResponseSuccess"
                          ref="jsonSchemaResponseEditor" lang="json" theme="dawn" height="450px" style="fontsize: 15px">
                        </ace-editor>
                        <ace-editor v-else v-model="service.jsonSchemaResponseError" ref="jsonSchemaResponseEditor"
                          lang="json" theme="iplastic" height="450px" style="fontsize: 15px">
                        </ace-editor>
                        <a href="javascript:void(null)" @click="copyJsonResponse" class="copy-code"><img
                            src="/static/img/icons/copy.png" alt="" /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="!newObj" class="teros_principal__content__accordion__card">
          <div class="teros_principal__content__accordion__card__header" id="headingTwo">
            <h2 class="teros_principal__content__accordion__card__header__title">
              <button class="
                  teros_principal__content__accordion__card__header__title__btn
                " type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
                aria-controls="collapseTwo">
                {{
                  $t(
                    "views.teros.integrations.service.content.compliance.title"
                  )
                }}
              </button>
              <hr />
            </h2>
          </div>

          <div id="collapseTwo" class="teros_principal__content__accordion__card__header__collapse">
            <div class="
                teros_principal__content__accordion__card__header__collapse__body
                pt0
              " style="padding: 0 40px">
              <div class="custom-control custom-switch" style="margin-bottom: 20px">
                <input type="checkbox" class="custom-control-input" checked id="customSwitchConsent"
                  v-model="service.consentiment" />
                <label class="custom-control-label" for="customSwitchConsent">{{
                  $t(
                    "views.teros.integrations.service.content.compliance.content.consentSwitch"
                  )
                }}</label>
              </div>
              <div class="form-group">
                <live-select :title="$t(
                      'views.teros.integrations.service.content.compliance.content.complianceStandard'
                    )
                    " :id="'complianceStandard'" :options="standards" :hash="service.recommendedStandardHash"
                  @onChange="service.recommendedStandardHash = $event"></live-select>
              </div>

              <div class="
                  teros_principal__content__accordion__card__header__collapse__body
                  pt0
                " style="padding: 0 40px">
                <div class="teros_principal__content__table">
                  <div class="teros_principal__content__table__header">
                    <div class="teros_principal__content__table__header__filters">
                      <div class="form-group">
                        <div class="search-group">
                          <input type="text" class="input-field" :placeholder="$t('views.shared.searchBar.searchLabel')
                            " maxlength="30" onkeydown="return event.keyCode == 13 ? event.preventDefault() : true"
                            v-model="textSearchValue" />
                          <button @click="makeTextSearch()" type="button" class="search-button" />
                        </div>
                      </div>

                      <itens-per-page :request-pagination="requestPagination" :callback="makeTextSearch"></itens-per-page>
                    </div>

                    <div class="teros_principal__content__table__header__actions">
                      <!--<a href="#" title="Download"
                        ><img
                          src="/static/img/icons/download.png"
                          alt="Download"
                      /></a>
                      <a href="#" title="Imprimir"
                        ><img
                          src="/static/img/icons/printer.png"
                          alt="Imprimir"
                      /></a> -->
                    </div>
                  </div>

                  <div class="teros_principal__content__table__body">
                    <table id="table" class="teros_principal__content__table__body__table" data-toggle="table"
                      data-height="600" data-url="">
                      <thead>
                        <tr>
                          <th data-field="id" data-sortable="true">ID</th>
                          <th data-field="a">
                            {{
                              $t(
                                "views.teros.integrations.service.content.compliance.content.table.header.standard"
                              )
                            }}
                          </th>
                          <th data-field="d">
                            {{ $t("views.shared.table.header.createdAt") }}
                          </th>
                          <th data-field="e">
                            {{ $t("views.shared.table.header.changedAt") }}
                          </th>
                          <th data-field="f" style="text-align: center">
                            {{ $t("views.shared.table.header.options") }}
                          </th>
                          <th data-field="g" style="text-align: center">
                            {{
                              $t("views.shared.table.header.deactivateActivate")
                            }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in standards" :key="item.hash">
                          <td>{{ index + 1 }}</td>
                          <td>{{ item.name }}</td>
                          <td>{{ item.creationDate | formatDate }}</td>
                          <td>{{ item.lastUpdateDate | formatDate }}</td>

                          <td style="
                              width: 100px;
                              padding-left: 0;
                              padding-right: 0;
                              text-align: center;
                            ">
                            <div class="
                                teros_principal__content__table__body__table__options
                              ">
                              <router-link :to="'/compliance?serviceHash=' +
                                service.hash +
                                '&hash=' +
                                item.hash
                                " class="
                                  teros_principal__content__table__body__table__options__item
                                " data-toggle="tooltip" data-placement="top" title="Visualizar/Editar">
                                <div>
                                  <img src="/static/img/icons/editar.svg" alt="" />
                                </div>
                              </router-link>

                              <a @click="prepareRemove(item.hash, index)" class="
                                  teros_principal__content__table__body__table__options__item
                                " data-toggle="modal" data-target="#deleteModal" data-placement="top" title="Deletar">
                                <div>
                                  <img src="/static/img/icons/deletar.svg" alt="" />
                                </div>
                              </a>
                            </div>
                          </td>
                          <td style="text-align: center">
                            <div class="custom-control custom-switch">
                              <input type="checkbox" class="custom-control-input" :id="'customSwitch' + index"
                                v-model="item.serviceStandardActive" @change="
                                  changeStatus(
                                    item.hash,
                                    item.serviceStandardActive
                                  )
                                  " />
                              <label class="custom-control-label" :for="'customSwitch' + index"></label>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="teros_principal__content__table__footer">
                    <div v-if="loading" class="teros_principal__content__table__footer__loader">
                      <img src="/static/img/icons/ajax-loader.gif" alt="" />
                      <span>{{ $t("views.shared.loadingResults") }}</span>
                    </div>

                    <pagination :request-pagination="requestPagination" :callback="makeTextSearch"
                      :current-pagination-info="currentPaginationInfo"></pagination>
                    <div class="
                        teros_principal__content__table__footer__pagination
                      ">
                      <router-link :to="'/compliance?serviceHash=' + service.hash" title="Novo Nível" class="
                          teros_principal__content__table__footer__add-item
                        ">
                        <img src="/static/img/icons/plus.png" alt="" />{{
                          $t("views.shared.includeButton")
                        }}
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group align-right" style="margin: 40px 80px 40px 40px">
          <button class="
              teros_principal__content__column__info__form__table__plus
              mb0
            " style="margin-top: 0">
            <img src="/static/img/icons/disquete.png" alt="Salvar" />{{
              $t("views.shared.saveButton")
            }}
          </button>
        </div>
      </form>
    </div>

    <div class="modal fade teros-modal" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel"
      aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteModalLabel">
              <img src="/static/img/icons/atencao-modal-red.png" alt="" />
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            {{ $t("views.shared.modal.delete.confirmMessage") }}
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              {{ $t("views.shared.modal.delete.cancelButton") }}
            </button>
            <button type="button" class="btn btn-primary btn-primary--red" data-dismiss="modal" v-on:click="remove">
              {{ $t("views.shared.modal.delete.deleteButton") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade teros-modal" id="endpointsModal" tabindex="-1" aria-labelledby="endpointsModalLabel"
      aria-hidden="true" data-backdrop="static">
      <div class="modal-dialog modal-dialog-scrollable" style="min-width: 700px !important;">
        <div class="modal-content">
          <div class="modal-header" id="endpointsModalLabel">
            <h5 class="modal-title">Configurações de Endpoints</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" style="text-align: start !important;" v-if="endpointPermissionsList.length > 0">
            <div class="row">
              <div class="col-12">
                <div v-for="(endpointPermission, index) of endpointPermissionsList">

                  <p>
                    <a class="action-fields" data-toggle="collapse" :href="'#multiCollapseExample1' + index" role="button"
                      aria-expanded="true" :aria-controls="'multiCollapseExample1' + index">
                      <h5>
                        <strong style="text-transform: lowercase;">
                          {{ getPermissionNameByPermissionHash(endpointPermission.permissionHash) }}</strong>
                      </h5>
                    </a>
                  </p>
                  <div class="row">
                    <div class="col-12">
                      <div class="collapse" :id="'multiCollapseExample1' + index">
                        <div class="card card-body">
                          <div v-if="endpointPermission.endpoints && endpointPermission.endpoints.length > 0">
                            <div class="form-group row"
                              v-for="(endpoint, indexPermission) of endpointPermission.endpoints">
                              <div class="col-9">
                                <label>
                                  Endpoint
                                </label>
                                <input type="text" required class="form-control col-11 mb-3" id="formGroupEndpontInput"
                                  v-model="endpoint.endpoint" disabled>
                              </div>

                              <div class="col-3">
                                <label>
                                  Versão
                                </label>
                                <input type="text" required class="form-control col-11 mb-3" id="formGroupEndpontInput"
                                  v-model="endpoint.version">
                              </div>

                              <!-- <div class="col-2 d-flex align-items-center justify-content-center">
                                <div class="custom-control custom-switch">
                                  <input type="checkbox" class="custom-control-input" id="customSwitch1"
                                    v-model="endpoint.active"
                                    @click="onChangeStatusServicePermissionEndpoint(endpointPermissionsList[index].endpoints[indexPermission], indexPermission)" />
                                  <label class="custom-control-label" for="customSwitch1">{{
                                    $t("views.shared.activeSwitch")
                                  }}</label>
                                </div>
                              </div> -->

                              <a href="javascript:void(null)" class="action-fields"
                                @click="addEndpointPermissionVersion(endpoint, index, indexPermission)">
                                <img src="/static/img/icons/plus.png" alt="" />
                                Adicionar Versão
                              </a>
                            </div>
                          </div>
                          <div v-else>
                            <h5>Não existem endpoints configurados para essa permissão</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <!-- <h5 class="mb2"><strong style="text-transform: lowercase;">
                      {{ endpointPermission.permissionName }}</strong></h5> -->

                  <!-- <div v-if="endpointPermission.endpoints && endpointPermission.endpoints.length > 0">
                    <div class="form-group row" v-for="endpoint of endpointPermission.endpoints">
                      <div class="col-8">
                        <label>
                          Endpoint
                        </label>
                        <input type="text" required class="form-control col-11 mb-3" id="formGroupEndpontInput"
                          v-model="endpoint.endpoint" disabled>
                      </div>

                      <div class="col-4">
                        <label>
                          Versão
                        </label>
                        <input type="text" required class="form-control col-11 mb-3" id="formGroupEndpontInput"
                          v-model="endpoint.version">
                      </div>

                      <a href="javascript:void(null)" class="action-fields" @click="">
                        <img src="/static/img/icons/plus.png" alt="" />
                        Adicionar Versão
                      </a>
                    </div>
                  </div>

                  <hr /> -->
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              {{ $t("views.shared.modal.delete.cancelButton") }}
            </button>
            <button type="button" class="btn btn-primary" data-dismiss="modal" v-on:click="onSaveEndpointPermissions">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { http, router, i18n } from "@/_helpers";
import Pagination from "@/_components/Pagination.vue";
import ItensPerPage from "@/_components/ItensPerPage.vue";
import LiveSelect from "@/_components/LiveSelect.vue";
import Ajv from "ajv";
import JsonSchemaRequest from "@/../static/json/JsonSchemaRequest.json";
import JsonSchemaResponseError from "@/../static/json/JsonSchemaResponseError.json";
import JsonSchemaResponseSuccess from "@/../static/json/JsonSchemaResponseSuccess.json";
import AceEditor from "vue2-ace-editor";
import CustomInput from "@/_components/CustomInput.vue";
import moment from 'moment';

require("brace/ext/language_tools");
require("brace/mode/json");
require("brace/theme/dawn");
require("brace/theme/iplastic");

var localize = {
  "en-US": require("ajv-i18n/localize/en"),
  "pt-BR": require("ajv-i18n/localize/pt-BR"),
  "es-ES": require("ajv-i18n/localize/es"),
};
const ajv = new Ajv({ allErrors: true });
const JsonSchemaRequestValidator = ajv.compile(JsonSchemaRequest);
const JsonSchemaResponseErrorValidator = ajv.compile(JsonSchemaResponseError);
const JsonSchemaResponseSuccessValidator = ajv.compile(
  JsonSchemaResponseSuccess
);
const { safeJsonParse } = require("better-error-message-for-json-parse");

export default {
  data() {
    return {
      newObj: true,
      integration: {},
      service: { active: true, consentiment: false },
      responseSuccessActive: true,
      showTiny: false,
      standards: [],
      standardHash: null,
      index: 0,
      resourceTypes: [],
      permissions: [],
      permissionsToList: [],
      cachedPermissions: [],
      groupings: [],
      groupingHashs: [],
      serviceData: [],
      loading: true,
      textSearchValue: "",
      oldTextSearchValue: "",
      currentPaginationInfo: null,
      requestPagination: { page: 1, take: 10 },
      endpoints: [],
      endpointPermissionsList: [],
    };
  },
  components: {
    Pagination,
    ItensPerPage,
    LiveSelect,
    AceEditor,
    CustomInput,
  },
  filters: {
    formatDate(value) {
      if (value) {
        return moment(String(value))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
  },
  computed: {
    creationDate() {
      if (this.service.creationDate) {
        return moment(String(this.service.creationDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
    creationTime() {
      if (this.service.creationDate) {
        return moment(String(this.service.creationDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("HH:mm");
      }
      return "";
    },
    lastUpdateDate() {
      if (this.service.lastUpdateDate) {
        return moment(String(this.service.lastUpdateDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
    lastUpdateTime() {
      if (this.service.lastUpdateDate) {
        return moment(String(this.service.lastUpdateDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("HH:mm");
      }
      return "";
    },
  },
  mounted() {
    this.$refs.jsonSchemaRequestEditor.editor.session.on("change", () =>
      this.prettyFormat()
    );
    this.$refs.jsonSchemaResponseEditor.editor.session.on("change", () =>
      this.prettyFormat()
    );
    this.$emit("message", this.$route);
    const hash = this.$route.query.hash;
    const integrationHash = this.$route.query.integrationHash;
    if (hash) {
      this.newObj = false;

      http.get(`/api/service/get/${hash}`).then((response) => {
        this.service = response.data;

        this.getAllResourceTypes();
        this.mockEndpointPermissionsToView();

        if (this.service.resourceTypeHash &&
          this.service.resourceTypeHash != '00000000-0000-0000-0000-000000000000'
        ) {
          this.getGroupings(this.service.resourceTypeHash);
        }

        if (this.service.groupingHashs) {
          this.getManyPermissions(this.service.groupingHashs);
        }

        if (this.service.serviceData) {
          let serviceDataList = this.service.serviceData.split("///");
          serviceDataList.forEach((item) => {
            this.serviceData.push({
              name: item,
            });
          });
        }

        this.getServiceStandards();
      });
    } else {
      http.get("/api/integration/get/" + integrationHash).then((response) => {
        this.integration = response.data;

        this.getAllResourceTypes();
        this.mockServicePermissionEndpoints();

        this.service.integrationHash = this.integration.hash;
        this.service.jsonSchemaRequest =
          '{ "queryParams": { }, "body": { } , "headers": { }, "method": "get" }';
        this.service.jsonSchemaResponseSuccess =
          '{ "body.value": { "blindingRule": ".*" } }';
        this.service.jsonSchemaResponseError = "{ }";
        this.prettyFormat();
      });
    }
  },
  methods: {
    goBack() {
      router.push({
        name: "servicespage",
        query: { hash: this.service.integrationHash },
      });
    },
    addServiceDataItem() {
      if (this.serviceData.length <= 50) {
        this.serviceData.push({
          name: "",
        });
      }
    },
    removeServiceDataItem(counter) {
      this.serviceData.splice(counter, 1);
    },
    showResponseSuccess() {
      this.responseSuccessActive = true;
    },
    showResponseError() {
      this.responseSuccessActive = false;
    },
    copyJsonRequest() {
      this.copyVarToClipboard(
        this.$refs.jsonSchemaRequestEditor.editor.getValue()
      );
      Vue.$toast.success(
        this.$i18n.t("views.teros.integrations.service.modal.copiedJsonMessage")
      );
    },
    copyJsonResponse() {
      this.copyVarToClipboard(
        this.$refs.jsonSchemaResponseEditor.editor.getValue()
      );
      Vue.$toast.success(
        this.$i18n.t("views.teros.integrations.service.modal.copiedJsonMessage")
      );
    },
    copyVarToClipboard(text) {
      var dummy = document.createElement("input");
      document.body.appendChild(dummy);
      dummy.value = text;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
    },
    createOrUpdate() {
      this.service.serviceData = this.serviceData
        .filter((e) => e.name != "")
        .map((e) => e.name)
        .join("///");

      if (this.newObj) {
        http.post("/api/service/create/", this.service).then((response) => {
          this.service = response.data;
          this.newObj = false;
          this.loading = false;
          router.push("/service?hash=" + this.service.hash);
          this.$emit("success");
        });
      } else {
        http.put("/api/service/update/", this.service).then(() => {
          this.goBack();
          this.$emit("success");
        });
      }
    },
    changeStatus(hash, active) {
      http
        .get(
          "/api/service/get/standard?serviceHash=" +
          this.service.hash +
          "&standardHash=" +
          hash
        )
        .then((response) => {
          this.standard = response.data;
          this.standard.servicehash = this.service.hash;
          this.standard.standardhash = this.standard.hash;
          this.standard.active = !this.standard.serviceStandardActive;
          http.put("/api/service/update/standard/", this.standard).then(() => {
            if (active) {
              Vue.$toast.success(
                this.$i18n.t(
                  "views.teros.integrations.services.content.compliance.content.modal.activatedMessage"
                )
              );
            } else {
              Vue.$toast.success(
                this.$i18n.t(
                  "views.teros.integrations.services.content.compliance.content.modal.deactivatedMessage"
                )
              );
            }
          });
        });
    },
    prepareRemove(hash, index) {
      this.standardHash = hash;
      this.index = index;
    },
    remove() {
      http
        .remove(
          "/api/service/delete/standard?serviceHash=" +
          this.service.hash +
          "&StandardHash=" +
          this.standardHash
        )
        .then(() => {
          Vue.$toast.success(
            this.$i18n.t(
              "views.teros.integrations.services.content.compliance.content.modal.deletedMessage"
            )
          );
          Vue.delete(this.standards, this.index);
        });
    },
    makeTextSearch(event, isChangePage = false) {
      this.CheckValuesChanged(isChangePage);

      if (this.textSearchValue.trim()) {
        http
          .get(
            `/api/service/search/standards/${this.service.hash
            }?FilterProperty=Name&FilterValue=${this.textSearchValue.trim()}&Page=${this.requestPagination.page
            }&Take=${this.requestPagination.take}`
          )
          .then((response) => {
            this.standards = response.data.items;
            this.currentPaginationInfo = response.data;
          })
          .finally(() => (this.loading = false));
      } else {
        http
          .get(
            `/api/service/search/standards/${this.service.hash}?Page=${this.requestPagination.page}&Take=${this.requestPagination.take}`
          )
          .then((response) => {
            this.standards = response.data.items;
            this.currentPaginationInfo = response.data;
          })
          .finally(() => (this.loading = false));
      }
    },
    CheckValuesChanged(isChangePage = false) {
      if (this.textSearchValue != this.oldTextSearchValue) {
        if (isChangePage) {
          this.textSearchValue = this.oldTextSearchValue;
        } else {
          this.requestPagination.page = 1;
          this.oldTextSearchValue = this.textSearchValue;
        }
      }
    },
    prettyFormat: function () {
      try {
        var jsonSchemaRequestObj = safeJsonParse(
          this.service.jsonSchemaRequest
        );
        let jsonSchemaRequestValid =
          JsonSchemaRequestValidator(jsonSchemaRequestObj);
        var jsonSchemaResponseErrorObj = safeJsonParse(
          this.service.jsonSchemaResponseError
        );
        let jsonSchemaResponseErrorValid = JsonSchemaResponseErrorValidator(
          jsonSchemaResponseErrorObj
        );
        var jsonSchemaResponseSuccessObj = safeJsonParse(
          this.service.jsonSchemaResponseSuccess
        );
        let jsonSchemaResponseSuccessValid = JsonSchemaResponseSuccessValidator(
          jsonSchemaResponseSuccessObj
        );
        if (!jsonSchemaRequestValid) {
          localize[i18n.locale](JsonSchemaRequestValidator.errors);
          Vue.$toast.clear();
          Vue.$toast.error(
            "'JSON_Request" +
            JsonSchemaRequestValidator.errors[0].dataPath +
            "' " +
            JsonSchemaRequestValidator.errors[0].message
          );
        }
        if (!jsonSchemaResponseErrorValid) {
          localize[i18n.locale](JsonSchemaResponseErrorValidator.errors);
          Vue.$toast.clear();
          Vue.$toast.error(
            "'JSON_Response_Error" +
            JsonSchemaResponseErrorValidator.errors[0].dataPath +
            "' " +
            JsonSchemaResponseErrorValidator.errors[0].message
          );
        }
        if (!jsonSchemaResponseSuccessValid) {
          localize[i18n.locale](JsonSchemaResponseSuccessValidator.errors);
          Vue.$toast.clear();
          Vue.$toast.error(
            "'JSON_Response_Success" +
            JsonSchemaResponseSuccessValidator.errors[0].dataPath +
            "' " +
            JsonSchemaResponseSuccessValidator.errors[0].message
          );
        }
        if (
          jsonSchemaRequestValid &&
          jsonSchemaResponseErrorValid &&
          jsonSchemaResponseSuccessValid
        ) {
          Vue.$toast.clear();
        }
      } catch (e) { }
    },
    onChangeResourceTypeSelect(resourceTypeHash) {
      this.service.resourceTypeHash = resourceTypeHash;
      this.service.groupingHashs = [];
      this.service.permissionHashs = [];

      if (resourceTypeHash) {
        this.getGroupings(resourceTypeHash);
      }
    },
    getAllResourceTypes() {
      http.get("/api/resourcetype/list").then((response) => {
        this.resourceTypes = response.data;
      });
    },
    getGroupings(resourceTypeHash) {
      http.get(`/api/grouping/list/${resourceTypeHash}`).then((response) => {
        this.groupings = response.data;
        this.permissions = [];
        this.permissionsToList = [];
      });
    },
    onChangeGroupingsSelect(groupingHashsArray) {
      this.service.groupingHashs = groupingHashsArray;

      this.permissions = [];
      this.permissionsToList = [];

      this.groupingHashs = groupingHashsArray;

      this.getManyPermissions(this.groupingHashs);

      // this.mockServicePermissionEndpoints();

    },
    mockServicePermissionEndpoints() {
      const groupingsFiltered = this.groupings.filter((g) => {
        return this.service.groupingHashs.some((gh) => {
          return gh == g.hash;
        });
      });

      let groupingsFilteredPermissions = groupingsFiltered.map(gf => gf.permissions);

      groupingsFilteredPermissions.forEach(gfp => {
        gfp.forEach(g => {
          let index = this.endpointPermissionsList.findIndex(ep => ep.permissionHash == g.hash);

          if (index == -1) {
            this.service.permissionHashs = [...this.service.permissionHashs, g.hash];

            this.endpointPermissionsList.push({
              permissionHash: g.hash,
              permissionName: g.name,
              endpoints: []
            });
          }
        });
      });

      this.endpointPermissionsList.forEach((ep) => {
        this.getEndpointsByPermissionHash(ep.permissionHash);
      });
    },
    onChangePermissionsSelect(permissionHashs) {
      this.service.permissionHashs = permissionHashs;
    },
    getPermissions(groupingHash) {
      if (!this.cachedPermissions.find(p => p.groupingHash == groupingHash)) {
        http.get(`/api/permission/search/${groupingHash}`).then((response) => {
          response.data.items
            .map(i => {
              i.groupingHash = groupingHash;
              this.permissions.push(i);
              this.cachedPermissions.push(i);
              this.service.permissionHashs.push(i.hash);
            });
        });
      } else {
        this.permissions = [
          ...this.permissions,
          ...this.cachedPermissions.filter(permission => permission.groupingHash == groupingHash)
        ];

        this.service.permissionHashs = this.permissions.map(p => p.hash);
      }
    },
    getManyPermissions(groupingHashs) {
      let notPermissionCacheds = this.getNoCachedPermissions(groupingHashs);

      // if (notPermissionCacheds.length == 1) {
      //   http.get(`/api/permission/search/${notPermissionCacheds}`).then((response) => {
      //     this.permissions = response.data.items;
      //     this.service.permissionHashs = response.data.items.map(i => i.hash);
      //   });
      // } else 

      if (notPermissionCacheds.length > 0) {
        http.get(`/api/permission/listMany/${notPermissionCacheds}`).then((response) => {
          this.permissions = response.data;
          this.permissionsToList = response.data;
          this.service.permissionHashs = response.data.map(p => p.hash);
        });
      } else {
        this.permissions = [
          ...this.permissions,
          ...this.getCachedPermissions(groupingHashs)
        ];
        this.permissionsToList = [
          ...this.permissionsToList,
          ...this.getCachedPermissions(groupingHashs)
        ];
      }
    },
    getServiceStandards() {
      http.get("/api/service/search/standards/" + this.service.hash, false)
        .then((response) => {
          this.standards = response.data.items;
          this.currentPaginationInfo = response.data;
        })
        .finally(() => this.loading = false);
    },
    getCachedPermissions(groupingHashs) {
      let cachedPermissions = [];

      groupingHashs.forEach(groupingHash => {
        let cachedPermission = this.cachedPermissions.find(p => p.hash == groupingHash);
        if (cachedPermission) {
          cachedPermission.push(cachedPermission);
        }
      });

      return cachedPermissions;
    },
    getNoCachedPermissions(groupingHashs) {
      let notCachedPermissions = [];

      groupingHashs.forEach(groupingHash => {
        let cachedPermission = this.cachedPermissions.find(p => p.hash == groupingHash);
        if (!cachedPermission) {
          notCachedPermissions.push(groupingHash);
        }
      });

      return notCachedPermissions;
    },
    changePermissions() {
      this.groupings.forEach(grouping => {
        this.permissions = [...grouping.permissions];
      });
    },
    getGroupingNameByHash(hash) {
      let grouping = this.groupings.find(g => g.hash == hash);

      if (grouping) {
        return grouping.name;
      } else {
        return '';
      }
    },
    getPermissionsByGroupingHash(groupingHash) {
      let grouping = this.groupings.find(g => g.hash == groupingHash);

      if (grouping) {
        return grouping.permissions;
      } else {
        return [];
      }
    },
    getEndpointsByPermissionHash(permissionHash) {
      let index = this.endpointPermissionsList.findIndex(x => x.permissionHash == permissionHash);
      http.get(`/api/permissionEndpoint/get/${permissionHash}`)
        .then((response) => {
          this.endpointPermissionsList[index].endpoints = [
            ...response.data
              .map(x => {
                return {
                  hash: x.hash,
                  endpoint: x.endpoint,
                  version: 1.0,
                  active: true
                };
              })];
        });
    },
    onSaveEndpointPermissions() {
      this.service.servicePermissionEndpoints = [];

      this.endpointPermissionsList.forEach(ep => {
        ep.endpoints.forEach(e => {
          this.service.servicePermissionEndpoints.push({
            permissionEndpointHash: e.hash,
            permissionHash: ep.permissionHash,
            version: e.version,
            active: e.active,
          });
        });
      });
    },
    mockEndpointPermissionsToView() {
      this.service.permissionHashs.forEach(ph => {
        this.endpointPermissionsList.push(
          {
            permissionHash: ph,
            permissionName: ph,
            endpoints: this.service.servicePermissionEndpoints.filter(spe => spe.permissionHash == ph),
          }
        );
      });
    },
    getPermissionNameByPermissionHash(permissionHash) {
      let permission = this.permissions.find(p => p.hash == permissionHash);

      if (permission) {
        return permission.name;
      } else {
        return '';
      }
    },
    addEndpointPermissionVersion(endpoint, index, indexPermission) {
      if (
        this.endpointPermissionsList[index].endpoints[indexPermission].version == "" ||
        !this.endpointPermissionsList[index].endpoints[indexPermission].version) {
        this.$emit('error', "Precisa preencher a versão anterior!");
        return;
      }

      this.endpointPermissionsList[index].endpoints
        .splice(indexPermission + 1, 0, {
          hash: endpoint.hash,
          endpoint: endpoint.endpoint,
          version: "",
          active: true
        });
    },
    onChangeStatusServicePermissionEndpoint(endpoint, index) {
      console.log(endpoint, index);
    }
  },
};
</script>
