<template>
  <div>
    <div class="custom-file">
      <input
        type="file"
        class="form-control-file custom-file-input"
        id="customFile"
        ref="files"
        v-on:input="fileSelected"
      />
      <label class="custom-file-label" for="customFile">{{
        defaultValue
      }}</label>
    </div>
    <div style="font-size: 10px; margin-top: 35px">
      {{ $t("views.fileUpload.component.maxFileSize") }}
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { http, i18n } from "@/_helpers";

export default {
  name: "file-upload",
  props: {
    fileName: {
      type: String,
      default: "",
    },
    contentTypes: {
      type: Array,
      default: [".pdf"],
    },
  },
  data() {
    return {
      file: "",
    };
  },
  computed: {
    defaultValue: function () {
      var formatedContentTypes = "";
      this.contentTypes.forEach((e) => {
        formatedContentTypes += e + " ";
      });

      return this.fileName
        ? this.fileName.length > 60
          ? this.fileName.substring(0, 60) + "..."
          : this.fileName
        : i18n.t("views.fileUpload.component.filename") +
            " " +
            formatedContentTypes;
    },
  },
  mounted() {
    this.bus.$on("make-file-upload", (data, resolve, reject) => {
      let reader = new FileReader();
      if (this.file) {
        reader.readAsBinaryString(this.file);
        let fileName = data.name + "_" + this.fileName;
        let type = this.file.type;
        let fileType = fileName.split(".").pop();
        if (!this.contentTypes.includes("." + fileType)) {
          Vue.$toast.error(i18n.t("views.fileUpload.component.typeError"));
        } else {
          reader.onload = function () {
            let file = btoa(reader.result);
            http
              .post(data.url, { fileName, base64: file, contentType: type })
              .then((response) => {
                resolve(response);
              })
              .catch((error) => {
                reject(error);
              });
          };
          reader.onerror = function () {
            Vue.$toast.error(i18n.t("views.fileUpload.component.uploadError"));
          };
        }
      } else {
        resolve({ data: this.fileName });
      }
    });
  },
  beforeDestroy() {
    this.bus.$off("make-file-upload");
  },
  methods: {
    fileSelected(e) {
      this.file = e.target.files[0];
      this.fileName =
        this.file.name.length > 60
          ? this.file.name.substring(0, 60) + "..."
          : this.file.name;
    },
  },
};
</script>