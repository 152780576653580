var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "teros_principal__content interna container" },
    [
      _c("div", { staticClass: "dashboard", attrs: { id: "accordion" } }, [
        _c(
          "div",
          { staticClass: "teros_principal__content__accordion__card mx0" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__accordion__card__header",
                attrs: { id: "headingOne" },
              },
              [
                _c(
                  "h2",
                  {
                    staticClass:
                      "teros_principal__content__accordion__card__header__title",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "teros_principal__content__accordion__card__header__title__btn",
                        attrs: {
                          type: "button",
                          "data-toggle": "collapse",
                          "data-target": "#collapseOne",
                          "aria-expanded": "false",
                          "aria-controls": "collapseOne",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.loadPanel(0)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t("views.shared.dashboard.lateralTab.KPIs")
                            ) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__accordion__card__header__collapse collapse",
                attrs: { id: "collapseOne" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__accordion__card__header__collapse__body",
                  },
                  [
                    _c("div", { staticClass: "dashboard-page" }, [
                      _c("iframe", {
                        attrs: {
                          src: _vm.generalPanelUrl,
                          width: "1200",
                          height: "300",
                        },
                      }),
                    ]),
                  ]
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "teros_principal__content__accordion__card mx0" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__accordion__card__header",
                attrs: { id: "headingOne" },
              },
              [
                _c(
                  "h2",
                  {
                    staticClass:
                      "teros_principal__content__accordion__card__header__title",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "teros_principal__content__accordion__card__header__title__btn collapsed",
                        attrs: {
                          type: "button",
                          "data-toggle": "collapse",
                          "data-target": "#collapseTwo",
                          "aria-expanded": "false",
                          "aria-controls": "collapseTwo",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.loadPanel(1)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t(
                                "views.shared.dashboard.lateralTab.transactions"
                              )
                            ) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__accordion__card__header__collapse collapse",
                attrs: { id: "collapseTwo" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__accordion__card__header__collapse__body",
                  },
                  [
                    _c("div", { staticClass: "dashboard-page" }, [
                      _c("iframe", {
                        attrs: {
                          src: _vm.servicesPanelUrl,
                          width: "1200",
                          height: "1900",
                        },
                      }),
                    ]),
                  ]
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "teros_principal__content__accordion__card mx0" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__accordion__card__header",
                attrs: { id: "headingOne" },
              },
              [
                _c(
                  "h2",
                  {
                    staticClass:
                      "teros_principal__content__accordion__card__header__title",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "teros_principal__content__accordion__card__header__title__btn collapsed",
                        attrs: {
                          type: "button",
                          "data-toggle": "collapse",
                          "data-target": "#collapseTwo2",
                          "aria-expanded": "false",
                          "aria-controls": "collapseTwo2",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.loadPanel(2)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t(
                                "views.shared.dashboard.lateralTab.consents"
                              )
                            ) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__accordion__card__header__collapse collapse",
                attrs: { id: "collapseTwo2" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__accordion__card__header__collapse__body",
                  },
                  [
                    _c("div", { staticClass: "dashboard-page" }, [
                      _c("iframe", {
                        attrs: {
                          src: _vm.receptionPanelUrl,
                          width: "1200",
                          height: "3000",
                        },
                      }),
                    ]),
                  ]
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "teros_principal__content__accordion__card mx0" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__accordion__card__header",
                attrs: { id: "headingOne" },
              },
              [
                _c(
                  "h2",
                  {
                    staticClass:
                      "teros_principal__content__accordion__card__header__title",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "teros_principal__content__accordion__card__header__title__btn collapsed",
                        attrs: {
                          type: "button",
                          "data-toggle": "collapse",
                          "data-target": "#collapseThree",
                          "aria-expanded": "false",
                          "aria-controls": "collapseThree",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.loadPanel(3)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n            \n              " +
                            _vm._s(
                              _vm.$t(
                                "views.shared.dashboard.lateralTab.techIndicators"
                              )
                            ) +
                            "\n            "
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__accordion__card__header__collapse collapse",
                attrs: { id: "collapseThree" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__accordion__card__header__collapse__body",
                  },
                  [
                    _c("div", { staticClass: "dashboard-page" }, [
                      _c("iframe", {
                        attrs: {
                          src: _vm.responsibilityManagementPanelUrl,
                          width: "1200",
                          height: "1300",
                        },
                      }),
                    ]),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }