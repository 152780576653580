var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "teros_principal" }, [
    _c("div", { staticClass: "teros_principal" }, [
      _c("div", { staticClass: "teros_principal__login" }, [
        _c(
          "div",
          {
            staticClass:
              "teros_principal__login__column teros_principal__login__column--fields",
          },
          [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "teros_principal__login__column__form__highlights",
              },
              [
                _c(
                  "p",
                  {
                    staticClass:
                      "teros_principal__login__column__form__highlights__text",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("views.shared.titleSystem")) +
                        " "
                    ),
                    _c("br"),
                    _vm._v(" "),
                    _c("span", [_vm._v("Teros Open Banking")]),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("views.forgotpassword.title")) +
                        " "
                    ),
                    _c("br"),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _vm.sendEmail
              ? _c(
                  "form",
                  {
                    staticClass: "teros_principal__login__column__form",
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.sendEmailForgotPassword.apply(
                          null,
                          arguments
                        )
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "teros_principal__login__column__form__fields",
                      },
                      [
                        _c("div", { staticClass: "form-group" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.email,
                                expression: "email",
                              },
                            ],
                            staticClass:
                              "form-control teros_principal__login__column__form__fields__input",
                            attrs: {
                              type: "text",
                              id: "email",
                              placeholder: _vm.$t(
                                "views.forgotpassword.emailOrUsername"
                              ),
                            },
                            domProps: { value: _vm.email },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.email = $event.target.value
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _c("div", { staticClass: "form-check" }, [
                            _c(
                              "label",
                              {
                                staticClass: "form-check-label",
                                attrs: { for: "gridCheck" },
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t(
                                        "views.forgotpassword.instructions1"
                                      )
                                    ) +
                                    "\n                "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            attrs: { type: "submit" },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t("views.forgotpassword.sendButton")
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "teros_principal__login__column__form__obs",
                        attrs: { to: "/login" },
                      },
                      [_vm._v("Login")]
                    ),
                  ],
                  1
                )
              : _c(
                  "form",
                  {
                    staticClass: "teros_principal__login__column__form",
                    attrs: { id: "form_code" },
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.confirmPassword.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "teros_principal__login__column__form__fields",
                      },
                      [
                        _c("div", { staticClass: "form-group" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.number",
                                value: _vm.code,
                                expression: "code",
                                modifiers: { number: true },
                              },
                            ],
                            staticClass:
                              "form-control teros_principal__login__column__form__fields__input",
                            attrs: {
                              type: "number",
                              id: "code",
                              placeholder: _vm.$t("views.forgotpassword.code"),
                            },
                            domProps: { value: _vm.code },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.code = _vm._n($event.target.value)
                              },
                              blur: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("input-password", {
                              attrs: {
                                id: "newPassword1",
                                placeholder: _vm.$t(
                                  "views.forgotpassword.newPassword"
                                ),
                                password: _vm.newPassword1,
                              },
                              on: {
                                onChange: function ($event) {
                                  _vm.newPassword1 = $event
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("input-password", {
                              attrs: {
                                id: "newPassword2",
                                placeholder: _vm.$t(
                                  "views.forgotpassword.confirmation"
                                ),
                                password: _vm.newPassword2,
                              },
                              on: {
                                onChange: function ($event) {
                                  _vm.newPassword2 = $event
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            attrs: { type: "submit" },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t("views.forgotpassword.resetButton")
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "center" }, [
      _c("img", {
        staticClass: "teros_principal__login__column__logo",
        attrs: { src: "/static/img/logo_login.png", alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }