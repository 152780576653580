<template>
  <div class="teros_principal__content interna container">
    <div class="teros_principal__content__line-1 line mb0">
      <div class="teros_principal__content__line-1__highlights">
        <div class="teros_principal__content__line-1__highlights__subject">
          <div class="teros_principal__content__line-1__highlights__subject__breadcrumb">
            <h3 class="teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle">
              {{ $t("views.pdfView.title") }}
            </h3>
            <h2 class="teros_principal__content__line-1__highlights__subject__breadcrumb__title active">
              {{ $t("views.pdfView.document") }}
            </h2>
          </div>
        </div>
      </div>
      <div class="teros_principal__content__line-1__information">
        <div class="teros_principal__content__line-1__information__time">
          <button type="button" @click="backToIntegrationPage" class="teros_principal__content__line-1__information__time__btn">
            <img src="/static/img/icons/seta-esquerda.png" alt="" />
            {{ $t("views.shared.backButton") }}
          </button>
        </div>
      </div>
    </div>
    <embed :src="fileUrl" type="application/pdf" width="100%" height="600" scale="tofit" />
  </div>
</template>

<script>
import { router } from "@/_helpers";

export default {
  props: {
    fileUrl: {
      type: String,
      default: "",
    },
  },
  methods: {
    backToIntegrationPage() {
      router.go(-1);
    },
  },
};
</script>
