<template>
  <div class="teros_principal__content interna container">
    <div class="teros_principal__content__line-1">
      <div class="teros_principal__content__line-1__highlights">
        <div class="teros_principal__content__line-1__highlights__subject">
          <div
            class="
              teros_principal__content__line-1__highlights__subject__breadcrumb
            "
          >
            <router-link to="/receptions">
              <h3
                class="
                  teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle
                "
              >
                {{ $t("views.client.reception.list.client") }}
              </h3>
              <h2
                v-if="newObj"
                class="
                  teros_principal__content__line-1__highlights__subject__breadcrumb__title
                "
              >
                {{ currentUser.customerName }}
              </h2>
              <h2
                v-else
                class="
                  teros_principal__content__line-1__highlights__subject__breadcrumb__title
                "
              >
                {{ reception.customerName }}
              </h2>
            </router-link>
          </div>
          <div class="teros_principal__arrow"></div>
          <div
            class="
              teros_principal__content__line-1__highlights__subject__breadcrumb
            "
          >
            <h3
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle
              "
            >
              {{ $t("views.client.reception.reception.title") }}
            </h3>
            <h2
              v-if="newObj"
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__title
                active
              "
            >
              {{ $t("views.client.reception.reception.newObject") }}
            </h2>
            <h2
              v-else
              class="
                teros_principal__content__line-1__highlights__subject__breadcrumb__title
                active
              "
            >
              {{ reception.integrationName }}
            </h2>
          </div>
        </div>
      </div>

      <div class="teros_principal__content__line-1__information">
        <div class="teros_principal__content__line-1__information__time">
          <p v-if="!newObj" class="teros_principal__content__line-1__information__time__text">
            <img
              class="
                teros_principal__content__line-1__information__time__text__icon
              "
              src="/static/img/icons/relogio.png"
              alt=""
            />
            {{
              $t("views.shared.createdDate", {
                date: creationDate,
                time: creationTime,
              })
            }}
            {{
              lastUpdateDate
                ? "| " +
                  $t("views.shared.changedDate", {
                    date: lastUpdateDate,
                    time: lastUpdateTime,
                  })
                : ""
            }}
          </p>

          <button type="button" @click="goBack" class="teros_principal__content__line-1__information__time__btn">
            <img src="/static/img/icons/seta-esquerda.png" alt="" />
            {{ $t("views.shared.backButton") }}
          </button>
        </div>
      </div>
    </div>

    <div class="teros_principal__content__column">
      <div class="teros_principal__content__column__menu-vertical">
        <ul class="teros_principal__content__column__menu-vertical__list">
          <li class="teros_principal__content__column__menu-vertical__list__item">
            <a
              href="#"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--lupa
                active
              "
              >{{ $t("views.client.reception.reception.tabTitle") }}</a
            >
          </li>

          <li class="teros_principal__content__column__menu-vertical__list__item">
            <a
              v-if="newObj"
              href="#"
              @click="showAlert"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--servicos
              "
              >{{ $t("views.client.reception.receptionservices.tabTitle") }}</a
            >
            <router-link
              v-else
              :to="'/receptionservices?hash=' + reception.hash"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--servicos
              "
              >{{ $t("views.client.reception.receptionservices.tabTitle") }}</router-link
            >
          </li>

          <li class="teros_principal__content__column__menu-vertical__list__item">
            <a
              v-if="newObj"
              href="#"
              @click="showAlert"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--compliance
              "
              >{{ $t("views.client.reception.receptionconsentiments.tabTitle") }}</a
            >
            <router-link
              v-else
              :to="'/receptionconsentiments?hash=' + reception.hash"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--compliance
              "
              >{{ $t("views.client.reception.receptionconsentiments.tabTitle") }}</router-link
            >
          </li>

          <li class="teros_principal__content__column__menu-vertical__list__item">
            <a
              v-if="newObj"
              href="#"
              @click="showAlert"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--registros
              "
              >{{ $t("views.client.reception.registries.tabTitle") }}</a
            >
            <router-link
              v-else
              :to="'/receptionregistries?hash=' + reception.hash"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--registros
              "
              >{{ $t("views.client.reception.registries.tabTitle") }}</router-link
            >
          </li>

          <li class="teros_principal__content__column__menu-vertical__list__item">
            <a
              v-if="newObj"
              href="#"
              @click="showAlert"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--gestao
              "
              >{{ $t("views.client.reception.receptionlogs.tabTitle") }}</a
            >
            <router-link
              v-else
              :to="'/receptionlogs?hash=' + reception.hash"
              class="
                teros_principal__content__column__menu-vertical__list__item__link
                teros_principal__content__column__menu-vertical__list__item__link--gestao
              "
              >{{ $t("views.client.reception.receptionlogs.tabTitle") }}</router-link
            >
          </li>
        </ul>
      </div>
      <div class="teros_principal__content__column__info">
        <div id="accordion">
          <form @submit.prevent="createOrUpdate" enctype="multipart/form-data" method="post">
            <div class="teros_principal__content__accordion__card mx0">
              <div class="teros_principal__content__accordion__card__header" id="headingOne">
                <h2
                  class="
                    teros_principal__content__accordion__card__header__title
                  "
                >
                  <button
                    class="
                      teros_principal__content__accordion__card__header__title__btn
                    "
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    {{ $t("views.client.reception.reception.content.editTab.title") }}
                  </button>
                </h2>
              </div>

              <div
                id="collapseOne"
                class="
                  teros_principal__content__accordion__card__header__collapse
                  collapse
                  show
                "
              >
                <div
                  class="
                    teros_principal__content__accordion__card__header__collapse__body
                  "
                >
                  <div
                    class="
                      teros_principal__content__accordion__card__header__collapse__body__form
                    "
                  >
                    <div class="form-group">
                      <div class="custom-control custom-switch">
                        <input type="checkbox" checked class="custom-control-input" id="customSwitch1" v-model="reception.active" />
                        <label class="custom-control-label" for="customSwitch1">{{ $t("views.client.reception.reception.content.editTab.activate") }}</label>
                      </div>
                    </div>

                    <div class="form-group">
                      <label v-if="!newObj" for="formGroupExampleInput1">{{ $t("views.client.reception.reception.content.editTab.integration") }}</label>
                      <input
                        v-if="!newObj"
                        type="text"
                        class="
                          form-control
                          teros_principal__content__column__info__form__input
                        "
                        id="formGroupExampleInput1"
                        v-model="reception.integrationName"
                        readonly
                      />
                      <live-select
                        v-if="newObj"
                        :title="$t('views.client.reception.reception.content.editTab.integration') + '*'"
                        :options="integrations"
                        :hash="reception.integrationHash"
                        @onChange="onChangeIntegration"
                      ></live-select>
                    </div>

                    <div class="form-group row">
                      <div class="col">
                        <label for="formGroupExampleInput3">Webhook Url</label>
                        <input
                          type="text"
                          class="
                            form-control
                            teros_principal__content__column__info__form__input
                          "
                          id="formGroupExampleInput3"
                          v-model="reception.webhookUrl"
                        />
                      </div>
                      <div class="col" v-if="!newObj">
                        <label for="formGroupExampleInput4"
                          >ReceptionHash
                          <img src="/static/img/icons/tooltip.png" data-toggle="tooltip" data-placement="right" :title="$t('views.client.reception.toolTip.ReceptionHash')" />
                        </label>
                        <input
                          type="text"
                          class="
                            form-control
                            teros_principal__content__column__info__form__input
                          "
                          id="formGroupExampleInput4"
                          v-model="reception.hash"
                          readonly
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="teros_principal__content__accordion__card mx0">
              <div class="teros_principal__content__accordion__card__header" id="headingOne">
                <h2
                  class="
                    teros_principal__content__accordion__card__header__title
                  "
                >
                  <button
                    class="
                      teros_principal__content__accordion__card__header__title__btn
                    "
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="true"
                    aria-controls="collapseTwo"
                  >
                    {{ $t("views.client.reception.reception.content.responsibilitiesTab.title") }}
                  </button>
                </h2>
              </div>

              <div
                id="collapseTwo"
                class="
                  teros_principal__content__accordion__card__header__collapse
                  collapse
                  show
                "
              >
                <div
                  class="
                    teros_principal__content__accordion__card__header__collapse__body
                  "
                >
                  <div
                    class="
                      teros_principal__content__accordion__card__header__collapse__body__form
                    "
                  >
                    <div class="form-group">
                      <label for="">{{ $t("views.client.reception.reception.content.responsibilitiesTab.contract") }}</label>
                      <file-upload :file-name="reception.contractFileName"></file-upload>
                    </div>

                    <div
                      class="
                        form-group
                        teros_principal__content__column__info__form__combo
                      "
                    >
                      <pdf-view
                        v-if="!newObj"
                        :file-name="reception.contractFileName"
                        :type="'button'"
                        :title="$t('views.client.reception.reception.content.responsibilitiesTab.seeContractButton')"
                      ></pdf-view>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <authorization-form :apiAuthentication="reception.apiAuthentication" :showDcrType="showDcrType" :customHeaders="customHeaders" @onChangeFlow="onChangeFlow" @onChangeDcr="onChangeDcr" />

            <div
              class="
                form-group
                teros_principal__content__column__info__form__combo
              "
              style="margin-top: 0; margin-bottom: 40px; text-align: right"
            >
              <button
                class="
                  teros_principal__content__column__info__form__combo__salvar
                "
                title="Salvar"
              >
                <img src="/static/img/icons/disquete.png" alt="Salvar" />{{ $t("views.shared.saveButton") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { cognito, http, router } from "@/_helpers";
import FileUpload from "@/_components/FileUpload.vue";
import PdfView from "@/_components/PdfView.vue";
import LiveSelect from "@/_components/LiveSelect.vue";
import AuthorizationForm from "@/_components/AuthorizationForm.vue";
import moment from "moment";

export default {
  data() {
    return {
      currentUser: cognito.currentUserValue,
      newObj: true,
      reception: {
        active: true,
        apiAuthentication: {
          dynamicClientRegistrationType: 1,
          authenticationFlowType: 1,
        },
      },
      selectedIntegration: null,
      integrations: [],
      customHeaders: [],
      showDcrType: true,
    };
  },
  components: {
    FileUpload,
    PdfView,
    LiveSelect,
    AuthorizationForm,
  },
  computed: {
    creationDate() {
      if (this.reception.creationDate) {
        return moment(String(this.reception.creationDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
    creationTime() {
      if (this.reception.creationDate) {
        return moment(String(this.reception.creationDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("HH:mm");
      }
      return "";
    },
    lastUpdateDate() {
      if (this.reception.lastUpdateDate) {
        return moment(String(this.reception.lastUpdateDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("DD/MM/YYYY");
      }
      return "";
    },
    lastUpdateTime() {
      if (this.reception.lastUpdateDate) {
        return moment(String(this.reception.lastUpdateDate))
          .subtract(new Date().getTimezoneOffset(), "m")
          .format("HH:mm");
      }
      return "";
    },
  },
  mounted() {
    this.$emit("message", this.$route);
    const hash = this.$route.query.hash;
    if (hash) {
      this.newObj = false;
      this.showDcrType = false;
      http.get("/api/reception/get/" + hash).then((response) => {
        this.reception = response.data;
        if (this.reception.apiAuthentication == null) {
          this.reception.apiAuthentication = {
            dynamicClientRegistrationType: 1,
            authenticationFlowType: 1,
            authorizationParameterType: 1,
            authenticationParameterType: 1,
            tokenType: 1,
            codeChallengeMethod: 1,
          };
        }
        if (this.reception.apiAuthentication.customHeaderFields != null) {
          this.customHeaders = this.customHeaders.concat(JSON.parse(this.reception.apiAuthentication.customHeaderFields));
        }
        this.customHeaders.push({ field: "", value: "" });
      });
    } else {
      this.reception.customerHash = this.currentUser.customerHash;
      this.reception.webhookUrl = this.currentUser.customerWebhookUrl ? this.currentUser.customerWebhookUrl : '';
      this.customHeaders.push({ field: "", value: "" });
      http.get("/api/integration/list/" + this.currentUser.customerHash).then((response) => {
        this.integrations = response.data;
      });
    }
  },
  methods: {
    goBack() {
      router.push({ name: "receptionspage" });
    },
    showAlert() {
      Vue.$toast.info(this.$i18n.t("views.client.reception.reception.alert.saveBefore"));
    },
    onChangeIntegration(value) {
      this.reception.integrationHash = value;
      let integration = this.integrations.find((s) => s.hash == this.reception.integrationHash);
      this.selectedIntegration = JSON.stringify(integration);
      this.reception.integrationName = integration.name;
      this.reception.apiAuthentication = integration.apiAuthentication;
      this.customHeaders = [];
      if (this.reception.apiAuthentication.customHeaderFields != null) {
        this.customHeaders = this.customHeaders.concat(JSON.parse(this.reception.apiAuthentication.customHeaderFields));
      }
      this.customHeaders.push({ field: "", value: "" });
      this.$forceUpdate();
    },
    onChangeDcr(value) {
      this.reception.apiAuthentication = JSON.parse(this.selectedIntegration).apiAuthentication;
      this.reception.apiAuthentication.dynamicClientRegistrationType = value;
      if (this.reception.apiAuthentication.dynamicClientRegistrationType == 3) {
        //If open banking brazil, set FAPI flow
        this.reception.apiAuthentication.authenticationFlowType = 9;
      }
      this.customHeaders = [];
      this.customHeaders.push({ field: "", value: "" });
    },
    onChangeFlow(value) {
      let dcrType = this.reception.apiAuthentication.dynamicClientRegistrationType;
      this.reception.apiAuthentication = JSON.parse(this.selectedIntegration).apiAuthentication;
      this.reception.apiAuthentication.dynamicClientRegistrationType = dcrType;
      this.reception.apiAuthentication.authenticationFlowType = value;
      this.customHeaders = [];
      this.customHeaders.push({ field: "", value: "" });
    },
    createOrUpdate() {
      let uploadFile = new Promise((resolve, reject) => {
        this.bus.$emit(
          "make-file-upload",
          {
            url: "/api/util/upload/base64",
            name: `reception/${this.currentUser.customerName}/${this.currentUser.customerHash}`,
          },
          resolve,
          reject
        );
      });

      let invalidCustomHeaders = this.customHeaders.filter((x) => (x.field == "" && x.value != "") || (x.field != "" && x.value == ""));
      if (invalidCustomHeaders.length > 0) {
        Vue.$toast.error(this.$i18n.t("views.authorizationForm.error.customHeaderInvalid"));
        return;
      }

      let customHeadersCleaned = this.customHeaders.filter((x) => x.field != "" && x.value != "");

      this.reception.apiAuthentication.customHeaderFields = JSON.stringify(customHeadersCleaned);

      uploadFile.then((response) => {
        this.reception.contractFileName = response.data;
        if (this.newObj) {
          http.post("/api/reception/create/", this.reception).then((response) => {
            this.reception = response.data;
            this.showDcrType = false;
            this.newObj = false;
            router.push("/reception?hash=" + this.reception.hash);
            this.$emit("success");
            this.$forceUpdate();
          });
        } else {
          http.put("/api/reception/update/", this.reception).then(() => {
            this.goBack();
            this.$emit("success");
          });
        }
      });
    },
  },
};
</script>
