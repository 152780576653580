var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "teros_principal__content interna container" },
    [
      _c("div", { staticClass: "teros_principal__content__line-1 line" }, [
        _c(
          "div",
          { staticClass: "teros_principal__content__line-1__highlights" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__line-1__highlights__subject",
              },
              [
                _vm.newObj
                  ? _c(
                      "router-link",
                      {
                        attrs: { to: "/services?hash=" + _vm.integration.hash },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "teros_principal__content__line-1__highlights__subject__breadcrumb",
                          },
                          [
                            _c(
                              "h3",
                              {
                                staticClass:
                                  "teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "views.teros.integrations.integration.title"
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "h2",
                              {
                                staticClass:
                                  "teros_principal__content__line-1__highlights__subject__breadcrumb__title",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.integration.name) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    )
                  : _c(
                      "router-link",
                      {
                        attrs: {
                          to: "/services?hash=" + _vm.service.integrationHash,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "teros_principal__content__line-1__highlights__subject__breadcrumb",
                          },
                          [
                            _c(
                              "h3",
                              {
                                staticClass:
                                  "teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "views.teros.integrations.integration.title"
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "h2",
                              {
                                staticClass:
                                  "teros_principal__content__line-1__highlights__subject__breadcrumb__title",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.service.integrationName) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                _vm._v(" "),
                _c("div", { staticClass: "teros_principal__arrow" }),
                _vm._v(" "),
                _vm.newObj
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "teros_principal__content__line-1__highlights__subject__breadcrumb",
                      },
                      [
                        _c(
                          "h3",
                          {
                            staticClass:
                              "teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "views.teros.integrations.service.title"
                                  )
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "h2",
                          {
                            staticClass:
                              "teros_principal__content__line-1__highlights__subject__breadcrumb__title active",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "views.teros.integrations.service.newObject"
                                  )
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    )
                  : _c(
                      "div",
                      {
                        staticClass:
                          "teros_principal__content__line-1__highlights__subject__breadcrumb",
                      },
                      [
                        _c(
                          "h3",
                          {
                            staticClass:
                              "teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "views.teros.integrations.service.title"
                                  )
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "h2",
                          {
                            staticClass:
                              "teros_principal__content__line-1__highlights__subject__breadcrumb__title active",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.service.name) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    ),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "teros_principal__content__line-1__information" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__line-1__information__time",
              },
              [
                _c(
                  "button",
                  {
                    staticClass:
                      "teros_principal__content__line-1__information__time__btn",
                    attrs: { type: "button" },
                    on: { click: _vm.goBack },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "/static/img/icons/seta-esquerda.png",
                        alt: "",
                      },
                    }),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("views.shared.backButton")) +
                        "\n        "
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      !_vm.newObj
        ? _c(
            "div",
            { staticClass: "teros_principal__content__accordion__description" },
            [
              _c(
                "p",
                {
                  staticClass:
                    "teros_principal__content__line-1__information__time__text",
                },
                [
                  _c("img", {
                    staticClass:
                      "teros_principal__content__line-1__information__time__text__icon",
                    attrs: { src: "/static/img/icons/relogio.png", alt: "" },
                  }),
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.$t("views.shared.createdDate", {
                          date: _vm.creationDate,
                          time: _vm.creationTime,
                        })
                      ) +
                      "\n      " +
                      _vm._s(
                        _vm.lastUpdateDate
                          ? "| " +
                              _vm.$t("views.shared.changedDate", {
                                date: _vm.lastUpdateDate,
                                time: _vm.lastUpdateTime,
                              })
                          : ""
                      ) +
                      "\n    "
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { attrs: { id: "accordion" } }, [
        _c(
          "form",
          {
            staticClass:
              "teros_principal__content__accordion__card__header__collapse__body__form",
            attrs: { method: "post" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.createOrUpdate.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "teros_principal__content__accordion__card" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__accordion__card__header",
                    attrs: { id: "headingOne" },
                  },
                  [
                    _c(
                      "h2",
                      {
                        staticClass:
                          "teros_principal__content__accordion__card__header__title",
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "teros_principal__content__accordion__card__header__title__btn",
                            attrs: {
                              type: "button",
                              "data-toggle": "collapse",
                              "data-target": "#collapseOne",
                              "aria-expanded": "false",
                              "aria-controls": "collapseOne",
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t(
                                    "views.teros.integrations.service.content.general.title"
                                  )
                                ) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("hr"),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__accordion__card__header__collapse",
                    attrs: { id: "collapseOne" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "teros_principal__content__accordion__card__header__collapse__body",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "teros_principal__content__accordion__card__header__collapse__body__form",
                          },
                          [
                            _c("div", { staticClass: "form-group row" }, [
                              _c("div", { staticClass: "col" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "custom-control custom-switch",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.service.active,
                                          expression: "service.active",
                                        },
                                      ],
                                      staticClass: "custom-control-input",
                                      attrs: {
                                        type: "checkbox",
                                        id: "customSwitch",
                                      },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.service.active
                                        )
                                          ? _vm._i(_vm.service.active, null) >
                                            -1
                                          : _vm.service.active,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.service.active,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.service,
                                                  "active",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.service,
                                                  "active",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(_vm.service, "active", $$c)
                                          }
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "custom-control-label",
                                        attrs: { for: "customSwitch" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("views.shared.activeSwitch")
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "teros_principal__content__accordion__card__header__collapse__body__form__column__fields",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "form-group" },
                                      [
                                        _c(
                                          "label",
                                          {
                                            attrs: {
                                              for: "formGroupExampleInput",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "views.teros.integrations.service.content.general.serviceName"
                                                )
                                              ) + "*\n                        "
                                            ),
                                            _c("img", {
                                              attrs: {
                                                src: "/static/img/icons/tooltip.png",
                                                "data-toggle": "tooltip",
                                                "data-placement": "right",
                                                title: _vm.$t(
                                                  "views.teros.integrations.service.toolTips.general.serviceName"
                                                ),
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("custom-input", {
                                          attrs: {
                                            id: "formGroupExampleInput",
                                          },
                                          model: {
                                            value: _vm.service.name,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.service, "name", $$v)
                                            },
                                            expression: "service.name",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "form-group" },
                                      [
                                        _c(
                                          "label",
                                          {
                                            attrs: {
                                              for: "formGroupExampleInput1",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "views.teros.integrations.service.content.general.commercialName"
                                                )
                                              ) + "\n                      "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("custom-input", {
                                          attrs: {
                                            id: "formGroupExampleInput1",
                                          },
                                          model: {
                                            value: _vm.service.commercialName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.service,
                                                "commercialName",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "service.commercialName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "form-group" },
                                      [
                                        _c("live-select", {
                                          attrs: {
                                            title:
                                              _vm.$t(
                                                "views.teros.integrations.service.content.general.resourceType"
                                              ) + "*",
                                            options: _vm.resourceTypes,
                                            hash: _vm.service.resourceTypeHash,
                                          },
                                          on: {
                                            onChange: function ($event) {
                                              return _vm.onChangeResourceTypeSelect(
                                                $event
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "form-group" },
                                      [
                                        _c("live-select", {
                                          attrs: {
                                            id: "groupings",
                                            title:
                                              _vm.$t(
                                                "views.teros.integrations.service.content.general.grouping"
                                              ) + "*",
                                            options: _vm.groupings,
                                            multiple: true,
                                            hashs: _vm.service.groupingHashs,
                                          },
                                          on: {
                                            onChange: ($event) =>
                                              _vm.onChangeGroupingsSelect(
                                                $event
                                              ),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "teros_principal__content__accordion__card__header__collapse__body__form__column__fields",
                                        staticStyle: { "padding-top": "0" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "form-group" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                attrs: {
                                                  for: "formGroupExampleInput2",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "URL*\n                          "
                                                ),
                                                _c("img", {
                                                  attrs: {
                                                    src: "/static/img/icons/tooltip.png",
                                                    "data-toggle": "tooltip",
                                                    "data-placement": "right",
                                                    title: _vm.$t(
                                                      "views.teros.integrations.service.toolTips.general.urlEndpoint"
                                                    ),
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("custom-input", {
                                              attrs: {
                                                id: "formGroupExampleInput2",
                                                regex: ".*",
                                              },
                                              model: {
                                                value: _vm.service.endpointUrl,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.service,
                                                    "endpointUrl",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "service.endpointUrl",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "form-group" },
                                          [
                                            _c(
                                              "label",
                                              {
                                                attrs: {
                                                  for: "formGroupExampleInput4",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "views.teros.integrations.service.content.general.serviceData"
                                                    )
                                                  ) +
                                                    "\n                        "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                staticClass: "action-fields",
                                                attrs: {
                                                  href: "javascript:void(null)",
                                                },
                                                on: {
                                                  click: _vm.addServiceDataItem,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "+ " +
                                                    _vm._s(
                                                      _vm.$t("views.shared.add")
                                                    )
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm._l(
                                              _vm.serviceData,
                                              function (item, counter) {
                                                return _c(
                                                  "div",
                                                  { key: counter },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value: item.name,
                                                          expression:
                                                            "item.name",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      attrs: { type: "text" },
                                                      domProps: {
                                                        value: item.name,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            item,
                                                            "name",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "action-fields",
                                                        attrs: {
                                                          href: "javascript:void(null)",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.removeServiceDataItem(
                                                              counter
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "- " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "views.shared.remove"
                                                              )
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col" }, [
                                _vm._m(0),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "tab-content",
                                    attrs: { id: "myTabContent" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "tab-pane fade show active",
                                        attrs: {
                                          id: "jsonSchemaRequest",
                                          role: "tabpanel",
                                          "aria-labelledby":
                                            "jsonSchemaRequest-tab",
                                        },
                                      },
                                      [
                                        _c("ace-editor", {
                                          ref: "jsonSchemaRequestEditor",
                                          staticStyle: { fontsize: "15px" },
                                          attrs: {
                                            lang: "json",
                                            theme: "dawn",
                                            height: "450px",
                                          },
                                          model: {
                                            value:
                                              _vm.service.jsonSchemaRequest,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.service,
                                                "jsonSchemaRequest",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "service.jsonSchemaRequest",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "a",
                                          {
                                            staticClass: "copy-code",
                                            attrs: {
                                              href: "javascript:void(null)",
                                            },
                                            on: { click: _vm.copyJsonRequest },
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: "/static/img/icons/copy.png",
                                                alt: "",
                                              },
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "tab-pane fade",
                                        attrs: {
                                          id: "jsonSchemaResponse",
                                          role: "tabpanel",
                                          "aria-labelledby":
                                            "jsonSchemaResponse-tab",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "teros_principal__tags",
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "teros_principal__tags__tag teros_principal__tags__tag--green active",
                                                attrs: {
                                                  href: "javascript:void(null)",
                                                },
                                                on: {
                                                  click:
                                                    _vm.showResponseSuccess,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "views.teros.integrations.service.content.general.jsonSchemaMsgs.success"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "teros_principal__tags__tag teros_principal__tags__tag--red",
                                                attrs: {
                                                  href: "javascript:void(null)",
                                                },
                                                on: {
                                                  click: _vm.showResponseError,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "views.teros.integrations.service.content.general.jsonSchemaMsgs.error"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.responseSuccessActive
                                          ? _c("ace-editor", {
                                              ref: "jsonSchemaResponseEditor",
                                              staticStyle: { fontsize: "15px" },
                                              attrs: {
                                                lang: "json",
                                                theme: "dawn",
                                                height: "450px",
                                              },
                                              model: {
                                                value:
                                                  _vm.service
                                                    .jsonSchemaResponseSuccess,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.service,
                                                    "jsonSchemaResponseSuccess",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "service.jsonSchemaResponseSuccess",
                                              },
                                            })
                                          : _c("ace-editor", {
                                              ref: "jsonSchemaResponseEditor",
                                              staticStyle: { fontsize: "15px" },
                                              attrs: {
                                                lang: "json",
                                                theme: "iplastic",
                                                height: "450px",
                                              },
                                              model: {
                                                value:
                                                  _vm.service
                                                    .jsonSchemaResponseError,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.service,
                                                    "jsonSchemaResponseError",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "service.jsonSchemaResponseError",
                                              },
                                            }),
                                        _vm._v(" "),
                                        _c(
                                          "a",
                                          {
                                            staticClass: "copy-code",
                                            attrs: {
                                              href: "javascript:void(null)",
                                            },
                                            on: { click: _vm.copyJsonResponse },
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: "/static/img/icons/copy.png",
                                                alt: "",
                                              },
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            !_vm.newObj
              ? _c(
                  "div",
                  { staticClass: "teros_principal__content__accordion__card" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "teros_principal__content__accordion__card__header",
                        attrs: { id: "headingTwo" },
                      },
                      [
                        _c(
                          "h2",
                          {
                            staticClass:
                              "teros_principal__content__accordion__card__header__title",
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "teros_principal__content__accordion__card__header__title__btn",
                                attrs: {
                                  type: "button",
                                  "data-toggle": "collapse",
                                  "data-target": "#collapseTwo",
                                  "aria-expanded": "false",
                                  "aria-controls": "collapseTwo",
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "views.teros.integrations.service.content.compliance.title"
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("hr"),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "teros_principal__content__accordion__card__header__collapse",
                        attrs: { id: "collapseTwo" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "teros_principal__content__accordion__card__header__collapse__body pt0",
                            staticStyle: { padding: "0 40px" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "custom-control custom-switch",
                                staticStyle: { "margin-bottom": "20px" },
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.service.consentiment,
                                      expression: "service.consentiment",
                                    },
                                  ],
                                  staticClass: "custom-control-input",
                                  attrs: {
                                    type: "checkbox",
                                    checked: "",
                                    id: "customSwitchConsent",
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.service.consentiment
                                    )
                                      ? _vm._i(_vm.service.consentiment, null) >
                                        -1
                                      : _vm.service.consentiment,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.service.consentiment,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.service,
                                              "consentiment",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.service,
                                              "consentiment",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.service,
                                          "consentiment",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  {
                                    staticClass: "custom-control-label",
                                    attrs: { for: "customSwitchConsent" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "views.teros.integrations.service.content.compliance.content.consentSwitch"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("live-select", {
                                  attrs: {
                                    title: _vm.$t(
                                      "views.teros.integrations.service.content.compliance.content.complianceStandard"
                                    ),
                                    id: "complianceStandard",
                                    options: _vm.standards,
                                    hash: _vm.service.recommendedStandardHash,
                                  },
                                  on: {
                                    onChange: function ($event) {
                                      _vm.service.recommendedStandardHash =
                                        $event
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "teros_principal__content__accordion__card__header__collapse__body pt0",
                                staticStyle: { padding: "0 40px" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "teros_principal__content__table",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "teros_principal__content__table__header",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "teros_principal__content__table__header__filters",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "form-group" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "search-group",
                                                  },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.textSearchValue,
                                                          expression:
                                                            "textSearchValue",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "input-field",
                                                      attrs: {
                                                        type: "text",
                                                        placeholder: _vm.$t(
                                                          "views.shared.searchBar.searchLabel"
                                                        ),
                                                        maxlength: "30",
                                                        onkeydown:
                                                          "return event.keyCode == 13 ? event.preventDefault() : true",
                                                      },
                                                      domProps: {
                                                        value:
                                                          _vm.textSearchValue,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.textSearchValue =
                                                            $event.target.value
                                                        },
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("button", {
                                                      staticClass:
                                                        "search-button",
                                                      attrs: { type: "button" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.makeTextSearch()
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("itens-per-page", {
                                              attrs: {
                                                "request-pagination":
                                                  _vm.requestPagination,
                                                callback: _vm.makeTextSearch,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("div", {
                                          staticClass:
                                            "teros_principal__content__table__header__actions",
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "teros_principal__content__table__body",
                                      },
                                      [
                                        _c(
                                          "table",
                                          {
                                            staticClass:
                                              "teros_principal__content__table__body__table",
                                            attrs: {
                                              id: "table",
                                              "data-toggle": "table",
                                              "data-height": "600",
                                              "data-url": "",
                                            },
                                          },
                                          [
                                            _c("thead", [
                                              _c("tr", [
                                                _c(
                                                  "th",
                                                  {
                                                    attrs: {
                                                      "data-field": "id",
                                                      "data-sortable": "true",
                                                    },
                                                  },
                                                  [_vm._v("ID")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "th",
                                                  {
                                                    attrs: {
                                                      "data-field": "a",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                          " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "views.teros.integrations.service.content.compliance.content.table.header.standard"
                                                          )
                                                        ) +
                                                        "\n                        "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "th",
                                                  {
                                                    attrs: {
                                                      "data-field": "d",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                          " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "views.shared.table.header.createdAt"
                                                          )
                                                        ) +
                                                        "\n                        "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "th",
                                                  {
                                                    attrs: {
                                                      "data-field": "e",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                          " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "views.shared.table.header.changedAt"
                                                          )
                                                        ) +
                                                        "\n                        "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "th",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "center",
                                                    },
                                                    attrs: {
                                                      "data-field": "f",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                          " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "views.shared.table.header.options"
                                                          )
                                                        ) +
                                                        "\n                        "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "th",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "center",
                                                    },
                                                    attrs: {
                                                      "data-field": "g",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                          " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "views.shared.table.header.deactivateActivate"
                                                          )
                                                        ) +
                                                        "\n                        "
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "tbody",
                                              _vm._l(
                                                _vm.standards,
                                                function (item, index) {
                                                  return _c(
                                                    "tr",
                                                    { key: item.hash },
                                                    [
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(index + 1)
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(item.name)
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f(
                                                              "formatDate"
                                                            )(item.creationDate)
                                                          )
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f(
                                                              "formatDate"
                                                            )(
                                                              item.lastUpdateDate
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            width: "100px",
                                                            "padding-left": "0",
                                                            "padding-right":
                                                              "0",
                                                            "text-align":
                                                              "center",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "teros_principal__content__table__body__table__options",
                                                            },
                                                            [
                                                              _c(
                                                                "router-link",
                                                                {
                                                                  staticClass:
                                                                    "teros_principal__content__table__body__table__options__item",
                                                                  attrs: {
                                                                    to:
                                                                      "/compliance?serviceHash=" +
                                                                      _vm
                                                                        .service
                                                                        .hash +
                                                                      "&hash=" +
                                                                      item.hash,
                                                                    "data-toggle":
                                                                      "tooltip",
                                                                    "data-placement":
                                                                      "top",
                                                                    title:
                                                                      "Visualizar/Editar",
                                                                  },
                                                                },
                                                                [
                                                                  _c("div", [
                                                                    _c("img", {
                                                                      attrs: {
                                                                        src: "/static/img/icons/editar.svg",
                                                                        alt: "",
                                                                      },
                                                                    }),
                                                                  ]),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "a",
                                                                {
                                                                  staticClass:
                                                                    "teros_principal__content__table__body__table__options__item",
                                                                  attrs: {
                                                                    "data-toggle":
                                                                      "modal",
                                                                    "data-target":
                                                                      "#deleteModal",
                                                                    "data-placement":
                                                                      "top",
                                                                    title:
                                                                      "Deletar",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.prepareRemove(
                                                                          item.hash,
                                                                          index
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._m(
                                                                    1,
                                                                    true
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            "text-align":
                                                              "center",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "custom-control custom-switch",
                                                            },
                                                            [
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      item.serviceStandardActive,
                                                                    expression:
                                                                      "item.serviceStandardActive",
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "custom-control-input",
                                                                attrs: {
                                                                  type: "checkbox",
                                                                  id:
                                                                    "customSwitch" +
                                                                    index,
                                                                },
                                                                domProps: {
                                                                  checked:
                                                                    Array.isArray(
                                                                      item.serviceStandardActive
                                                                    )
                                                                      ? _vm._i(
                                                                          item.serviceStandardActive,
                                                                          null
                                                                        ) > -1
                                                                      : item.serviceStandardActive,
                                                                },
                                                                on: {
                                                                  change: [
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      var $$a =
                                                                          item.serviceStandardActive,
                                                                        $$el =
                                                                          $event.target,
                                                                        $$c =
                                                                          $$el.checked
                                                                            ? true
                                                                            : false
                                                                      if (
                                                                        Array.isArray(
                                                                          $$a
                                                                        )
                                                                      ) {
                                                                        var $$v =
                                                                            null,
                                                                          $$i =
                                                                            _vm._i(
                                                                              $$a,
                                                                              $$v
                                                                            )
                                                                        if (
                                                                          $$el.checked
                                                                        ) {
                                                                          $$i <
                                                                            0 &&
                                                                            _vm.$set(
                                                                              item,
                                                                              "serviceStandardActive",
                                                                              $$a.concat(
                                                                                [
                                                                                  $$v,
                                                                                ]
                                                                              )
                                                                            )
                                                                        } else {
                                                                          $$i >
                                                                            -1 &&
                                                                            _vm.$set(
                                                                              item,
                                                                              "serviceStandardActive",
                                                                              $$a
                                                                                .slice(
                                                                                  0,
                                                                                  $$i
                                                                                )
                                                                                .concat(
                                                                                  $$a.slice(
                                                                                    $$i +
                                                                                      1
                                                                                  )
                                                                                )
                                                                            )
                                                                        }
                                                                      } else {
                                                                        _vm.$set(
                                                                          item,
                                                                          "serviceStandardActive",
                                                                          $$c
                                                                        )
                                                                      }
                                                                    },
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.changeStatus(
                                                                        item.hash,
                                                                        item.serviceStandardActive
                                                                      )
                                                                    },
                                                                  ],
                                                                },
                                                              }),
                                                              _vm._v(" "),
                                                              _c("label", {
                                                                staticClass:
                                                                  "custom-control-label",
                                                                attrs: {
                                                                  for:
                                                                    "customSwitch" +
                                                                    index,
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "teros_principal__content__table__footer",
                                      },
                                      [
                                        _vm.loading
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "teros_principal__content__table__footer__loader",
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: "/static/img/icons/ajax-loader.gif",
                                                    alt: "",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "views.shared.loadingResults"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("pagination", {
                                          attrs: {
                                            "request-pagination":
                                              _vm.requestPagination,
                                            callback: _vm.makeTextSearch,
                                            "current-pagination-info":
                                              _vm.currentPaginationInfo,
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "teros_principal__content__table__footer__pagination",
                                          },
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                staticClass:
                                                  "teros_principal__content__table__footer__add-item",
                                                attrs: {
                                                  to:
                                                    "/compliance?serviceHash=" +
                                                    _vm.service.hash,
                                                  title: "Novo Nível",
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: "/static/img/icons/plus.png",
                                                    alt: "",
                                                  },
                                                }),
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "views.shared.includeButton"
                                                    )
                                                  ) + "\n                    "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "form-group align-right",
                staticStyle: { margin: "40px 80px 40px 40px" },
              },
              [
                _c(
                  "button",
                  {
                    staticClass:
                      "teros_principal__content__column__info__form__table__plus mb0",
                    staticStyle: { "margin-top": "0" },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "/static/img/icons/disquete.png",
                        alt: "Salvar",
                      },
                    }),
                    _vm._v(
                      _vm._s(_vm.$t("views.shared.saveButton")) + "\n        "
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal fade teros-modal",
          attrs: {
            id: "deleteModal",
            tabindex: "-1",
            "aria-labelledby": "deleteModalLabel",
            "aria-hidden": "true",
          },
        },
        [
          _c("div", { staticClass: "modal-dialog" }, [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(2),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("views.shared.modal.delete.confirmMessage")) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button", "data-dismiss": "modal" },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t("views.shared.modal.delete.cancelButton")
                        ) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-primary--red",
                    attrs: { type: "button", "data-dismiss": "modal" },
                    on: { click: _vm.remove },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t("views.shared.modal.delete.deleteButton")
                        ) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal fade teros-modal",
          attrs: {
            id: "endpointsModal",
            tabindex: "-1",
            "aria-labelledby": "endpointsModalLabel",
            "aria-hidden": "true",
            "data-backdrop": "static",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-dialog modal-dialog-scrollable",
              staticStyle: { "min-width": "700px !important" },
            },
            [
              _c("div", { staticClass: "modal-content" }, [
                _vm._m(3),
                _vm._v(" "),
                _vm.endpointPermissionsList.length > 0
                  ? _c(
                      "div",
                      {
                        staticClass: "modal-body",
                        staticStyle: { "text-align": "start !important" },
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            _vm._l(
                              _vm.endpointPermissionsList,
                              function (endpointPermission, index) {
                                return _c("div", [
                                  _c("p", [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "action-fields",
                                        attrs: {
                                          "data-toggle": "collapse",
                                          href:
                                            "#multiCollapseExample1" + index,
                                          role: "button",
                                          "aria-expanded": "true",
                                          "aria-controls":
                                            "multiCollapseExample1" + index,
                                        },
                                      },
                                      [
                                        _c("h5", [
                                          _c(
                                            "strong",
                                            {
                                              staticStyle: {
                                                "text-transform": "lowercase",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(
                                                    _vm.getPermissionNameByPermissionHash(
                                                      endpointPermission.permissionHash
                                                    )
                                                  )
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "row" }, [
                                    _c("div", { staticClass: "col-12" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "collapse",
                                          attrs: {
                                            id: "multiCollapseExample1" + index,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "card card-body" },
                                            [
                                              endpointPermission.endpoints &&
                                              endpointPermission.endpoints
                                                .length > 0
                                                ? _c(
                                                    "div",
                                                    _vm._l(
                                                      endpointPermission.endpoints,
                                                      function (
                                                        endpoint,
                                                        indexPermission
                                                      ) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-group row",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-9",
                                                              },
                                                              [
                                                                _c("label", [
                                                                  _vm._v(
                                                                    "\n                                Endpoint\n                              "
                                                                  ),
                                                                ]),
                                                                _vm._v(" "),
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        endpoint.endpoint,
                                                                      expression:
                                                                        "endpoint.endpoint",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "form-control col-11 mb-3",
                                                                  attrs: {
                                                                    type: "text",
                                                                    required:
                                                                      "",
                                                                    id: "formGroupEndpontInput",
                                                                    disabled:
                                                                      "",
                                                                  },
                                                                  domProps: {
                                                                    value:
                                                                      endpoint.endpoint,
                                                                  },
                                                                  on: {
                                                                    input:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          $event
                                                                            .target
                                                                            .composing
                                                                        )
                                                                          return
                                                                        _vm.$set(
                                                                          endpoint,
                                                                          "endpoint",
                                                                          $event
                                                                            .target
                                                                            .value
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "col-3",
                                                              },
                                                              [
                                                                _c("label", [
                                                                  _vm._v(
                                                                    "\n                                Versão\n                              "
                                                                  ),
                                                                ]),
                                                                _vm._v(" "),
                                                                _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        endpoint.version,
                                                                      expression:
                                                                        "endpoint.version",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "form-control col-11 mb-3",
                                                                  attrs: {
                                                                    type: "text",
                                                                    required:
                                                                      "",
                                                                    id: "formGroupEndpontInput",
                                                                  },
                                                                  domProps: {
                                                                    value:
                                                                      endpoint.version,
                                                                  },
                                                                  on: {
                                                                    input:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          $event
                                                                            .target
                                                                            .composing
                                                                        )
                                                                          return
                                                                        _vm.$set(
                                                                          endpoint,
                                                                          "version",
                                                                          $event
                                                                            .target
                                                                            .value
                                                                        )
                                                                      },
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "action-fields",
                                                                attrs: {
                                                                  href: "javascript:void(null)",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.addEndpointPermissionVersion(
                                                                        endpoint,
                                                                        index,
                                                                        indexPermission
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("img", {
                                                                  attrs: {
                                                                    src: "/static/img/icons/plus.png",
                                                                    alt: "",
                                                                  },
                                                                }),
                                                                _vm._v(
                                                                  "\n                              Adicionar Versão\n                            "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                : _c("div", [
                                                    _c("h5", [
                                                      _vm._v(
                                                        "Não existem endpoints configurados para essa permissão"
                                                      ),
                                                    ]),
                                                  ]),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ])
                              }
                            ),
                            0
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "modal-footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button", "data-dismiss": "modal" },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t("views.shared.modal.delete.cancelButton")
                          ) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button", "data-dismiss": "modal" },
                      on: { click: _vm.onSaveEndpointPermissions },
                    },
                    [_vm._v("\n            Salvar\n          ")]
                  ),
                ]),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "ul",
      { staticClass: "nav nav-tabs", attrs: { id: "myTab", role: "tablist" } },
      [
        _c("li", { staticClass: "nav-item" }, [
          _c(
            "a",
            {
              staticClass: "nav-link active",
              attrs: {
                id: "jsonSchemaRequest-tab",
                "data-toggle": "tab",
                href: "#jsonSchemaRequest",
                role: "tab",
                "aria-controls": "jsonSchemaRequest",
                "aria-selected": "true",
              },
            },
            [_vm._v("JSON Schema Request")]
          ),
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "nav-item" }, [
          _c(
            "a",
            {
              staticClass: "nav-link",
              attrs: {
                id: "jsonSchemaResponse-tab",
                "data-toggle": "tab",
                href: "#jsonSchemaResponse",
                role: "tab",
                "aria-controls": "jsonSchemaResponse",
                "aria-selected": "false",
              },
            },
            [_vm._v("JSON Schema Response")]
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", { attrs: { src: "/static/img/icons/deletar.svg", alt: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        { staticClass: "modal-title", attrs: { id: "deleteModalLabel" } },
        [
          _c("img", {
            attrs: { src: "/static/img/icons/atencao-modal-red.png", alt: "" },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "modal-header", attrs: { id: "endpointsModalLabel" } },
      [
        _c("h5", { staticClass: "modal-title" }, [
          _vm._v("Configurações de Endpoints"),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "close",
            attrs: {
              type: "button",
              "data-dismiss": "modal",
              "aria-label": "Close",
            },
          },
          [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }