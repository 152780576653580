import Vue from 'vue';
import Vuelidate from 'vuelidate';
import VueToast from 'vue-toast-notification';
import VueMask from 'v-mask';
import Loading from 'vue-loading-overlay';
import VueCookies from 'vue-cookies';

import { router, i18n } from './_helpers';
import App from './app/App';

Vue.use(Vuelidate);

Vue.use(VueCookies);

Vue.use(VueToast, {
    position: 'top',
    duration: 9000
})

Vue.use(VueMask);

Vue.use(Loading, {
    color: '#5ea3ac'
});

Vue.prototype.bus = new Vue();

new Vue({
    el: '#app',
    router,
    i18n,
    render: h => h(App)
});