var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "teros_principal__content interna container" },
    [
      _c("div", { staticClass: "teros_principal__content__line-1 line" }, [
        _c(
          "div",
          { staticClass: "teros_principal__content__line-1__highlights" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__line-1__highlights__subject",
              },
              [
                _c("router-link", { attrs: { to: "/categories" } }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "teros_principal__content__line-1__highlights__subject__breadcrumb",
                    },
                    [
                      _c(
                        "h3",
                        {
                          staticClass:
                            "teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle",
                        },
                        [
                          _vm._v(
                            "\n              Teros Openbanking\n            "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "h2",
                        {
                          staticClass:
                            "teros_principal__content__line-1__highlights__subject__breadcrumb__title",
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("views.shared.menu.config")) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "teros_principal__arrow" }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__line-1__highlights__subject__breadcrumb",
                  },
                  [
                    _c(
                      "h3",
                      {
                        staticClass:
                          "teros_principal__content__line-1__highlights__subject__breadcrumb__subtitle",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "views.teros.config.resourceTypes.tabTitle"
                              )
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.newObj
                      ? _c(
                          "h2",
                          {
                            staticClass:
                              "teros_principal__content__line-1__highlights__subject__breadcrumb__title active",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "views.teros.config.resourceType.newObject"
                                  )
                                ) +
                                "\n          "
                            ),
                          ]
                        )
                      : _c(
                          "h2",
                          {
                            staticClass:
                              "teros_principal__content__line-1__highlights__subject__breadcrumb__title active",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.resourceType.name) +
                                "\n          "
                            ),
                          ]
                        ),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "teros_principal__content__line-1__information" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__line-1__information__time",
              },
              [
                !_vm.newObj
                  ? _c(
                      "p",
                      {
                        staticClass:
                          "teros_principal__content__line-1__information__time__text",
                      },
                      [
                        _c("img", {
                          staticClass:
                            "teros_principal__content__line-1__information__time__text__icon",
                          attrs: {
                            src: "/static/img/icons/relogio.png",
                            alt: "",
                          },
                        }),
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t("views.shared.createdDate", {
                                date: _vm.creationDate,
                                time: _vm.creationTime,
                              })
                            ) +
                            "\n          " +
                            _vm._s(
                              _vm.lastUpdateDate
                                ? "| " +
                                    _vm.$t("views.shared.changedDate", {
                                      date: _vm.lastUpdateDate,
                                      time: _vm.lastUpdateTime,
                                    })
                                : ""
                            ) +
                            "\n        "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "teros_principal__content__line-1__information__time__btn",
                    attrs: { type: "button" },
                    on: { click: _vm.goBack },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "/static/img/icons/seta-esquerda.png",
                        alt: "",
                      },
                    }),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("views.shared.backButton")) +
                        "\n        "
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { id: "accordion" } }, [
        _c(
          "div",
          { staticClass: "teros_principal__content__accordion__card" },
          [
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__accordion__card__header accordion-off",
                attrs: { id: "headingOne" },
              },
              [
                _c(
                  "h2",
                  {
                    staticClass:
                      "teros_principal__content__accordion__card__header__title",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "teros_principal__content__accordion__card__header__title__btn",
                        attrs: {
                          type: "button",
                          "aria-expanded": "false",
                          "aria-controls": "collapseOne",
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "views.teros.config.resourceType.content.edit.title"
                              )
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("hr"),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "teros_principal__content__accordion__card__header__collapse",
                attrs: { id: "collapseOne" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "teros_principal__content__accordion__card__header__collapse__body",
                  },
                  [
                    _c(
                      "form",
                      {
                        staticClass:
                          "teros_principal__content__accordion__card__header__collapse__body__form",
                        attrs: { method: "post" },
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.createOrUpdate.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "form-group row" }, [
                          _c(
                            "div",
                            { staticClass: "col" },
                            [
                              _c(
                                "label",
                                { attrs: { for: "formGroupExampleInput" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "views.teros.config.resourceType.content.edit.name"
                                      )
                                    ) + "*\n                  "
                                  ),
                                  _c("img", {
                                    attrs: {
                                      src: "/static/img/icons/tooltip.png",
                                      "data-toggle": "tooltip",
                                      "data-placement": "right",
                                      title: _vm.$t(
                                        "views.teros.config.resourceType.toolTip.resourceType"
                                      ),
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c("custom-input", {
                                attrs: {
                                  id: "formGroupExampleInput",
                                  regex: "[a-zA-Z0-9\u00C0-\u00ff - _]",
                                },
                                model: {
                                  value: _vm.resourceType.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.resourceType, "name", $$v)
                                  },
                                  expression: "resourceType.name",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col" },
                            [
                              _c(
                                "label",
                                { attrs: { for: "formGroupExampleInput2" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "views.teros.config.resourceType.content.edit.scope"
                                      )
                                    ) + "*\n                  "
                                  ),
                                  _c("img", {
                                    attrs: {
                                      src: "/static/img/icons/tooltip.png",
                                      "data-toggle": "tooltip",
                                      "data-placement": "right",
                                      title: _vm.$t(
                                        "views.teros.config.resourceType.toolTip.scope"
                                      ),
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c("custom-input", {
                                attrs: {
                                  id: "formGroupExampleInput2",
                                  regex: "[a-zA-Z0-9\u00C0-\u00ff -]",
                                },
                                model: {
                                  value: _vm.resourceType.scope,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.resourceType, "scope", $$v)
                                  },
                                  expression: "resourceType.scope",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        !_vm.newObj
                          ? _c(
                              "div",
                              {
                                staticClass: "accordion",
                                attrs: { id: "accordionGroupings" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "teros_principal__content__accordion__card",
                                  },
                                  [
                                    _vm._m(0),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "collapse show",
                                        attrs: {
                                          id: "collapsePermissions",
                                          "aria-labelledby":
                                            "heardingPermissions",
                                          "data-parent": "#accordionGroupings",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "card-body" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "teros_principal__content__table__header",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "teros_principal__content__table__header__filters",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "search-group",
                                                          },
                                                          [
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.textSearchValue,
                                                                  expression:
                                                                    "textSearchValue",
                                                                },
                                                              ],
                                                              staticClass:
                                                                "input-field",
                                                              attrs: {
                                                                type: "text",
                                                                placeholder:
                                                                  _vm.$t(
                                                                    "views.shared.searchBar.searchLabel"
                                                                  ),
                                                                maxlength: "30",
                                                                onkeydown:
                                                                  "return event.keyCode == 13 ? event.preventDefault() : true",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  _vm.textSearchValue,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.textSearchValue =
                                                                      $event.target.value
                                                                  },
                                                              },
                                                            }),
                                                            _vm._v(" "),
                                                            _c("button", {
                                                              staticClass:
                                                                "search-button",
                                                              attrs: {
                                                                type: "button",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.makeTextSearch()
                                                                  },
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("itens-per-page", {
                                                      attrs: {
                                                        "request-pagination":
                                                          _vm.requestPagination,
                                                        callback:
                                                          _vm.makeTextSearch,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "teros_principal__content__table__body",
                                              },
                                              [
                                                _c(
                                                  "table",
                                                  {
                                                    staticClass:
                                                      "teros_principal__content__table__body__table",
                                                    attrs: {
                                                      id: "table",
                                                      "data-toggle": "table",
                                                      "data-height": "600",
                                                      "data-url": "",
                                                    },
                                                  },
                                                  [
                                                    _c("thead", [
                                                      _c("tr", [
                                                        _c(
                                                          "th",
                                                          {
                                                            attrs: {
                                                              "data-field":
                                                                "id",
                                                              "data-sortable":
                                                                "true",
                                                            },
                                                          },
                                                          [_vm._v("ID")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "th",
                                                          {
                                                            attrs: {
                                                              "data-field": "a",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                              " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "views.teros.config.resourceType.content.permissions.header.name"
                                                                  )
                                                                ) +
                                                                "\n                            "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "th",
                                                          {
                                                            attrs: {
                                                              "data-field": "d",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                              " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "views.shared.table.header.createdAt"
                                                                  )
                                                                ) +
                                                                "\n                            "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "th",
                                                          {
                                                            attrs: {
                                                              "data-field": "e",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                              " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "views.shared.table.header.changedAt"
                                                                  )
                                                                ) +
                                                                "\n                            "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "th",
                                                          {
                                                            staticStyle: {
                                                              "text-align":
                                                                "center",
                                                            },
                                                            attrs: {
                                                              "data-field": "f",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                              " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "views.shared.table.header.options"
                                                                  )
                                                                ) +
                                                                "\n                            "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "th",
                                                          {
                                                            staticStyle: {
                                                              "text-align":
                                                                "center",
                                                            },
                                                            attrs: {
                                                              "data-field": "g",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                              " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "views.shared.table.header.deactivateActivate"
                                                                  )
                                                                ) +
                                                                "\n                            "
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "tbody",
                                                      [
                                                        _vm._l(
                                                          _vm.permissions,
                                                          function (
                                                            item,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "tr",
                                                              {
                                                                key: item.hash,
                                                              },
                                                              [
                                                                _c("td", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      index + 1
                                                                    )
                                                                  ),
                                                                ]),
                                                                _vm._v(" "),
                                                                _c("td", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.name
                                                                    )
                                                                  ),
                                                                ]),
                                                                _vm._v(" "),
                                                                _c("td", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "formatDate"
                                                                      )(
                                                                        item.creationDate
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                                _vm._v(" "),
                                                                _c("td", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "formatDate"
                                                                      )(
                                                                        item.lastUpdateDate
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "td",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        width:
                                                                          "100px",
                                                                        "padding-left":
                                                                          "0",
                                                                        "padding-right":
                                                                          "0",
                                                                        "text-align":
                                                                          "center",
                                                                      },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "teros_principal__content__table__body__table__options",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "router-link",
                                                                          {
                                                                            staticClass:
                                                                              "teros_principal__content__table__body__table__options__item",
                                                                            attrs:
                                                                              {
                                                                                to:
                                                                                  "/permission?resourceTypeHash=" +
                                                                                  _vm
                                                                                    .resourceType
                                                                                    .hash +
                                                                                  "&hash=" +
                                                                                  item.hash,
                                                                                "data-toggle":
                                                                                  "tooltip",
                                                                                "data-placement":
                                                                                  "top",
                                                                                title:
                                                                                  "Visualizar/Editar",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              [
                                                                                _c(
                                                                                  "img",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        src: "/static/img/icons/editar.svg",
                                                                                        alt: "",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "a",
                                                                          {
                                                                            staticClass:
                                                                              "teros_principal__content__table__body__table__options__item",
                                                                            attrs:
                                                                              {
                                                                                "data-toggle":
                                                                                  "modal",
                                                                                "data-target":
                                                                                  "#deleteModal",
                                                                                "data-placement":
                                                                                  "top",
                                                                                title:
                                                                                  "Deletar",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.prepareRemove(
                                                                                    item.hash,
                                                                                    index
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._m(
                                                                              1,
                                                                              true
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "td",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "text-align":
                                                                          "center",
                                                                      },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "custom-control custom-switch",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "input",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "model",
                                                                                  rawName:
                                                                                    "v-model",
                                                                                  value:
                                                                                    item.active,
                                                                                  expression:
                                                                                    "item.active",
                                                                                },
                                                                              ],
                                                                            staticClass:
                                                                              "custom-control-input",
                                                                            attrs:
                                                                              {
                                                                                type: "checkbox",
                                                                                id:
                                                                                  "customSwitch" +
                                                                                  index,
                                                                              },
                                                                            domProps:
                                                                              {
                                                                                checked:
                                                                                  Array.isArray(
                                                                                    item.active
                                                                                  )
                                                                                    ? _vm._i(
                                                                                        item.active,
                                                                                        null
                                                                                      ) >
                                                                                      -1
                                                                                    : item.active,
                                                                              },
                                                                            on: {
                                                                              change:
                                                                                [
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    var $$a =
                                                                                        item.active,
                                                                                      $$el =
                                                                                        $event.target,
                                                                                      $$c =
                                                                                        $$el.checked
                                                                                          ? true
                                                                                          : false
                                                                                    if (
                                                                                      Array.isArray(
                                                                                        $$a
                                                                                      )
                                                                                    ) {
                                                                                      var $$v =
                                                                                          null,
                                                                                        $$i =
                                                                                          _vm._i(
                                                                                            $$a,
                                                                                            $$v
                                                                                          )
                                                                                      if (
                                                                                        $$el.checked
                                                                                      ) {
                                                                                        $$i <
                                                                                          0 &&
                                                                                          _vm.$set(
                                                                                            item,
                                                                                            "active",
                                                                                            $$a.concat(
                                                                                              [
                                                                                                $$v,
                                                                                              ]
                                                                                            )
                                                                                          )
                                                                                      } else {
                                                                                        $$i >
                                                                                          -1 &&
                                                                                          _vm.$set(
                                                                                            item,
                                                                                            "active",
                                                                                            $$a
                                                                                              .slice(
                                                                                                0,
                                                                                                $$i
                                                                                              )
                                                                                              .concat(
                                                                                                $$a.slice(
                                                                                                  $$i +
                                                                                                    1
                                                                                                )
                                                                                              )
                                                                                          )
                                                                                      }
                                                                                    } else {
                                                                                      _vm.$set(
                                                                                        item,
                                                                                        "active",
                                                                                        $$c
                                                                                      )
                                                                                    }
                                                                                  },
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.changeStatus(
                                                                                      item.hash
                                                                                    )
                                                                                  },
                                                                                ],
                                                                            },
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "label",
                                                                          {
                                                                            staticClass:
                                                                              "custom-control-label",
                                                                            attrs:
                                                                              {
                                                                                for:
                                                                                  "customSwitch" +
                                                                                  index,
                                                                              },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        _vm._v(" "),
                                                        !_vm.permissions ||
                                                        _vm.permissions
                                                          .length == 0
                                                          ? _c("tr", [
                                                              _vm._m(2),
                                                            ])
                                                          : _vm._e(),
                                                      ],
                                                      2
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "teros_principal__content__table__footer",
                                              },
                                              [
                                                _vm.loading
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "teros_principal__content__table__footer__loader",
                                                      },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            src: "/static/img/icons/ajax-loader.gif",
                                                            alt: "",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "views.shared.loadingResults"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c("pagination", {
                                                  attrs: {
                                                    "request-pagination":
                                                      _vm.requestPagination,
                                                    callback:
                                                      _vm.makeTextSearch,
                                                    "current-pagination-info":
                                                      _vm.currentPaginationInfo,
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "teros_principal__content__table__footer__pagination",
                                                  },
                                                  [
                                                    _c(
                                                      "router-link",
                                                      {
                                                        staticClass:
                                                          "teros_principal__content__table__footer__add-item",
                                                        attrs: {
                                                          to:
                                                            "/permission?resourceTypeHash=" +
                                                            _vm.resourceType
                                                              .hash,
                                                          title:
                                                            "Nova Permissão",
                                                        },
                                                      },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            src: "/static/img/icons/plus.png",
                                                            alt: "",
                                                          },
                                                        }),
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "views.shared.includeButton"
                                                            )
                                                          ) +
                                                            "\n                        "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "teros_principal__content__accordion__card",
                                  },
                                  [
                                    _vm._m(3),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "teros_principal__content__accordion__card__body",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "accordion",
                                            attrs: {
                                              id: "accordionGroupingsGroup",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "collapse show",
                                                attrs: {
                                                  id: "collapseGroupings",
                                                  "aria-labelledby":
                                                    "heardingGroupingsGroup",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "card-body" },
                                                  [
                                                    _vm._l(
                                                      _vm.groupings,
                                                      function (
                                                        grouping,
                                                        index
                                                      ) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            staticClass: "card",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "teros_principal__content__accordion__card__header",
                                                                attrs: {
                                                                  id:
                                                                    "grouping" +
                                                                    index,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "h2",
                                                                  {
                                                                    staticClass:
                                                                      "teros_principal__content__accordion__card__header__title",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "button",
                                                                      {
                                                                        staticClass:
                                                                          "teros_principal__content__accordion__card__header__title__btn collapsed",
                                                                        attrs: {
                                                                          type: "button",
                                                                          "data-toggle":
                                                                            "collapse",
                                                                          "data-target":
                                                                            "#collapseGrouping" +
                                                                            index,
                                                                          "aria-expanded":
                                                                            "false",
                                                                          "aria-controls":
                                                                            "collapseGrouping" +
                                                                            index,
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                " +
                                                                            _vm._s(
                                                                              grouping.name
                                                                            ) +
                                                                            "\n                              "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "collapse",
                                                                attrs: {
                                                                  id:
                                                                    "collapseGrouping" +
                                                                    index,
                                                                  "aria-labelledby":
                                                                    "grouping" +
                                                                    index,
                                                                  "data-parent":
                                                                    "#accordionGroupingsGroup",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "card-body",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "teros_principal__content__table__body",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "table",
                                                                          {
                                                                            staticClass:
                                                                              "teros_principal__content__table__body__table",
                                                                            attrs:
                                                                              {
                                                                                id: "table",
                                                                                "data-toggle":
                                                                                  "table",
                                                                                "data-height":
                                                                                  "600",
                                                                                "data-url":
                                                                                  "",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "thead",
                                                                              [
                                                                                _c(
                                                                                  "tr",
                                                                                  [
                                                                                    _c(
                                                                                      "th",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            "data-field":
                                                                                              "id",
                                                                                            "data-sortable":
                                                                                              "true",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "ID"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "th",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            "data-field":
                                                                                              "a",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                        " +
                                                                                            _vm._s(
                                                                                              _vm.$t(
                                                                                                "views.teros.config.resourceType.content.permissions.header.name"
                                                                                              )
                                                                                            ) +
                                                                                            "\n                                      "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    _c(
                                                                                      "th",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            "text-align":
                                                                                              "center",
                                                                                          },
                                                                                        attrs:
                                                                                          {
                                                                                            "data-field":
                                                                                              "f",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "\n                                        " +
                                                                                            _vm._s(
                                                                                              _vm.$t(
                                                                                                "views.shared.table.header.options"
                                                                                              )
                                                                                            ) +
                                                                                            "\n                                      "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "tbody",
                                                                              [
                                                                                !grouping.permissions ||
                                                                                grouping
                                                                                  .permissions
                                                                                  .length ==
                                                                                  0
                                                                                  ? _c(
                                                                                      "tr",
                                                                                      [
                                                                                        _vm._m(
                                                                                          4,
                                                                                          true
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _vm._l(
                                                                                  grouping.permissions,
                                                                                  function (
                                                                                    item,
                                                                                    index
                                                                                  ) {
                                                                                    return grouping.permissions &&
                                                                                      grouping
                                                                                        .permissions
                                                                                        .length >
                                                                                        0
                                                                                      ? _c(
                                                                                          "tr",
                                                                                          {
                                                                                            key: item.hash,
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "td",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  _vm._s(
                                                                                                    index +
                                                                                                      1
                                                                                                  )
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _vm._v(
                                                                                              " "
                                                                                            ),
                                                                                            _c(
                                                                                              "td",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  _vm._s(
                                                                                                    item.name
                                                                                                  )
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _vm._v(
                                                                                              " "
                                                                                            ),
                                                                                            _c(
                                                                                              "td",
                                                                                              {
                                                                                                staticStyle:
                                                                                                  {
                                                                                                    width:
                                                                                                      "100px",
                                                                                                    "padding-left":
                                                                                                      "0",
                                                                                                    "padding-right":
                                                                                                      "0",
                                                                                                    "text-align":
                                                                                                      "center",
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "div",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "teros_principal__content__table__body__table__options",
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "a",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "teros_principal__content__table__body__table__options__item",
                                                                                                        attrs:
                                                                                                          {
                                                                                                            "data-toggle":
                                                                                                              "modal",
                                                                                                            "data-target":
                                                                                                              "#unassociatePermissionModal",
                                                                                                            "data-placement":
                                                                                                              "top",
                                                                                                            title:
                                                                                                              "Deletar",
                                                                                                          },
                                                                                                        on: {
                                                                                                          click:
                                                                                                            function (
                                                                                                              $event
                                                                                                            ) {
                                                                                                              return _vm.prepareUnassociatePermission(
                                                                                                                grouping.hash,
                                                                                                                index
                                                                                                              )
                                                                                                            },
                                                                                                        },
                                                                                                      },
                                                                                                      [
                                                                                                        _vm._m(
                                                                                                          5,
                                                                                                          true
                                                                                                        ),
                                                                                                      ]
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e()
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              2
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "form-group align-right",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "button",
                                                                          {
                                                                            staticClass:
                                                                              "mr-4 teros_principal__content__column__info__form__table__plus",
                                                                            attrs:
                                                                              {
                                                                                type: "button",
                                                                                "data-toggle":
                                                                                  "modal",
                                                                                "data-target":
                                                                                  "#removeGroupingModal",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.doPrepareRemoveGrouping(
                                                                                    grouping
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                                  Remover Agrupamento\n                                "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "button",
                                                                          {
                                                                            staticClass:
                                                                              "teros_principal__content__column__info__form__table__plus",
                                                                            attrs:
                                                                              {
                                                                                type: "button",
                                                                                "data-toggle":
                                                                                  "modal",
                                                                                "data-target":
                                                                                  "#associatePermissionModal",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.onAssociatePermissionClick(
                                                                                    grouping
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "img",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    src: "/static/img/icons/intersect.png",
                                                                                    alt: "Associar",
                                                                                  },
                                                                              }
                                                                            ),
                                                                            _vm._v(
                                                                              "\n                                  Associar Permissão\n                                "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group align-right",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "teros_principal__content__column__info__form__table__plus mb0",
                                                            attrs: {
                                                              type: "button",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.doAddGrouping()
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("img", {
                                                              attrs: {
                                                                src: "/static/img/icons/plus.png",
                                                                alt: "Salvar",
                                                              },
                                                            }),
                                                            _vm._v(
                                                              "\n                            Adicionar Agrupamento\n                          "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group align-right" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "teros_principal__content__column__info__form__table__plus mb0",
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: "/static/img/icons/disquete.png",
                                  alt: "Salvar",
                                },
                              }),
                              _vm._v(
                                _vm._s(_vm.$t("views.shared.saveButton")) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal fade teros-modal",
          attrs: {
            id: "deleteModal",
            tabindex: "-1",
            "aria-labelledby": "deleteModalLabel",
            "aria-hidden": "true",
          },
        },
        [
          _c("div", { staticClass: "modal-dialog" }, [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(6),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("views.shared.modal.delete.confirmMessage")) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button", "data-dismiss": "modal" },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t("views.shared.modal.delete.cancelButton")
                        ) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-primary--red",
                    attrs: { type: "button", "data-dismiss": "modal" },
                    on: { click: _vm.removePermission },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t("views.shared.modal.delete.deleteButton")
                        ) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal fade teros-modal",
          attrs: {
            id: "removeGroupingModal",
            tabindex: "-1",
            "aria-labelledby": "removeGroupingLabel",
            "aria-hidden": "true",
          },
        },
        [
          _c("div", { staticClass: "modal-dialog" }, [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(7),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _vm._v(
                  "\n          Tem certeza que deseja remover este item?\n        "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button", "data-dismiss": "modal" },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t("views.shared.modal.delete.cancelButton")
                        ) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-primary--red",
                    attrs: { type: "button", "data-dismiss": "modal" },
                    on: { click: _vm.doRemoveGrouping },
                  },
                  [_vm._v("\n            Remover\n          ")]
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal fade teros-modal",
          attrs: {
            id: "unassociatePermissionModal",
            tabindex: "-1",
            "aria-labelledby": "unassociatePermissionLabel",
            "aria-hidden": "true",
          },
        },
        [
          _c("div", { staticClass: "modal-dialog" }, [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(8),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _vm._v(
                  "\n          Tem certeza que deseja desassociar este item?\n        "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button", "data-dismiss": "modal" },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.$t("views.shared.modal.delete.cancelButton")
                        ) +
                        "\n          "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary btn-primary--red",
                    attrs: { type: "button", "data-dismiss": "modal" },
                    on: { click: _vm.doSaveGrouping },
                  },
                  [_vm._v("\n            Desassociar\n          ")]
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal fade teros-modal",
          attrs: {
            id: "associatePermissionModal",
            "data-backdrop": "static",
            "data-keyboard": "false",
            tabindex: "-1",
            "aria-labelledby": "associatePermissionLabel",
            "aria-hidden": "true",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-dialog modal-dialog-scrollable",
              staticStyle: { "min-width": "700px" },
            },
            [
              _c("div", { staticClass: "modal-content" }, [
                _vm._m(9),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "modal-body" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "teros_principal__content__table__header__filters",
                      },
                      [
                        _c("div", { staticClass: "form-group" }, [
                          _c("div", { staticClass: "search-group" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.textSearchValue,
                                  expression: "textSearchValue",
                                },
                              ],
                              staticClass: "input-field",
                              attrs: {
                                type: "text",
                                placeholder: _vm.$t(
                                  "views.shared.searchBar.searchLabel"
                                ),
                                maxlength: "30",
                                onkeydown:
                                  "return event.keyCode == 13 ? event.preventDefault() : true",
                              },
                              domProps: { value: _vm.textSearchValue },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.textSearchValue = $event.target.value
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("button", {
                              staticClass: "search-button",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.makeTextSearch()
                                },
                              },
                            }),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("itens-per-page", {
                          attrs: {
                            "request-pagination": _vm.requestPagination,
                            callback: _vm.makeTextSearch,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "teros_principal__content__table__body" },
                      [
                        _c(
                          "table",
                          {
                            staticClass:
                              "teros_principal__content__table__body__table mt-4",
                          },
                          [
                            _vm.getUnassociadedPermissions() &&
                            _vm.getUnassociadedPermissions().length > 0
                              ? _c("thead", [_vm._m(10)])
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "tbody",
                              [
                                !_vm.getUnassociadedPermissions() ||
                                _vm.getUnassociadedPermissions().length == 0
                                  ? _c("tr", [_vm._m(11)])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.getUnassociadedPermissions(),
                                  function (permission, index) {
                                    return _c("tr", [
                                      _c("td", [
                                        _c("input", {
                                          attrs: {
                                            type: "checkbox",
                                            name: index,
                                            id: index,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.onCheckAssociatePermission(
                                                permission
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(_vm._s(permission.name)),
                                      ]),
                                    ])
                                  }
                                ),
                              ],
                              2
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("pagination", {
                      attrs: {
                        "request-pagination": _vm.requestPagination,
                        callback: _vm.makeTextSearch,
                        "current-pagination-info": _vm.currentPaginationInfo,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "modal-footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button", "data-dismiss": "modal" },
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: {
                        type: "button",
                        "data-dismiss": "modal",
                        disabled:
                          !_vm.getUnassociadedPermissions() ||
                          _vm.getUnassociadedPermissions().length == 0,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.doSaveGrouping()
                        },
                      },
                    },
                    [_vm._v("Salvar")]
                  ),
                ]),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "teros_principal__content__accordion__card__header",
        attrs: { id: "heardingGroupingOne" },
      },
      [
        _c(
          "h2",
          {
            staticClass:
              "teros_principal__content__accordion__card__header__title",
          },
          [
            _c(
              "button",
              {
                staticClass:
                  "teros_principal__content__accordion__card__header__title__btn",
                attrs: {
                  type: "button",
                  "data-toggle": "collapse",
                  "data-target": "#collapsePermissions",
                  "aria-expanded": "false",
                  "aria-controls": "collapsePermissions",
                },
              },
              [
                _vm._v(
                  "\n                      Lista de Permissões\n                    "
                ),
              ]
            ),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", { attrs: { src: "/static/img/icons/deletar.svg", alt: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "text-center", attrs: { colspan: "6" } }, [
      _c("h4", { staticClass: "mt-2 mb-2" }, [
        _vm._v("Não existem permissões a serem listadas"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "teros_principal__content__accordion__card__header",
        attrs: { id: "grouping" },
      },
      [
        _c(
          "h2",
          {
            staticClass:
              "teros_principal__content__accordion__card__header__title",
          },
          [
            _c(
              "button",
              {
                staticClass:
                  "teros_principal__content__accordion__card__header__title__btn",
                attrs: {
                  type: "button",
                  "data-toggle": "collapse",
                  "data-target": "#collapseGroupings",
                  "aria-expanded": "true",
                  "aria-controls": "collapseGroupings",
                },
              },
              [
                _vm._v(
                  "\n                      Lista de Agrupamentos\n                    "
                ),
              ]
            ),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "text-center", attrs: { colspan: "3" } }, [
      _c("h4", { staticClass: "mt-2 mb-2" }, [
        _vm._v("Não existem permissões associadas a esse agrupamento"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", { attrs: { src: "/static/img/icons/deletar.svg", alt: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        { staticClass: "modal-title", attrs: { id: "deleteModalLabel" } },
        [
          _c("img", {
            attrs: { src: "/static/img/icons/atencao-modal-red.png", alt: "" },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        { staticClass: "modal-title", attrs: { id: "removeGroupingLabel" } },
        [
          _c("img", {
            attrs: { src: "/static/img/icons/atencao-modal-red.png", alt: "" },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        {
          staticClass: "modal-title",
          attrs: { id: "unassociatePermissionLabel" },
        },
        [
          _c("img", {
            attrs: { src: "/static/img/icons/atencao-modal-red.png", alt: "" },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        {
          staticClass: "modal-title",
          attrs: { id: "associatePermissionLabel" },
        },
        [_vm._v("Permissões")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", { attrs: { scope: "col" } }, [_vm._v("#")]),
      _vm._v(" "),
      _c("th", { attrs: { scope: "col" } }, [_vm._v("Permissão")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("td", { staticClass: "text-center", attrs: { colspan: "3" } }, [
      _c("h4", { staticClass: "mt-3 mb-3" }, [
        _vm._v("Não existem permissões a serem associadas a esse agrupamento"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }