<template>
  <div>
    <input
      :type="type"
      :required="required"
      :readonly="readonly"
      class="form-control teros_principal__content__column__info__form__input"
      :id="id"
      v-bind:value="value"
      v-on:input="OnInput($event)"
      v-on:keypress="OnKeyPress($event)"
      :maxlength="maxlength"
    />
    <span v-show="type == 'text'"
      style="font-size: 10px"
      v-text="value ? value.length + '/' + maxlength : '0' + '/' + maxlength"
    ></span>
  </div>
</template>

<script>
export default {
  name: "custom-input",
  props: {
    id: String,
    type: {
      type: String,
      default: "text",
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    value: [String, Number],
    regex: {
      type: String,
      default: "[a-zA-Z0-9\u00C0-\u00ff ]", //latin letters characteres, numbers and spaces
    },
    maxlength: {
      type: String,
      default: "250",
    },
  },
  methods: {
    OnKeyPress(e) {
      let char = String.fromCharCode(e.keyCode);
      let re = new RegExp(this.regex, "gu");
      if (re.test(char)) {
        return true;
      } else {
        e.preventDefault();
      }
    },
    OnInput(e) {
      let re = new RegExp(this.regex, "gu");
      let array = e.target.value.match(re);
      if (array != null) {
        let joinedString = array.join("");
        let response = this.type == "text" ? joinedString : parseInt(joinedString, 10);
        this.$emit("input", response);
      } else {
        e.preventDefault();
        e.target.value = "";
        let response = this.type == "text" ? "" : 0;
        this.$emit("input", response);
      }
    },
  },
};
</script>
