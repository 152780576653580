var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("input", {
      staticClass:
        "form-control teros_principal__content__column__info__form__input",
      attrs: {
        type: _vm.type,
        required: _vm.required,
        readonly: _vm.readonly,
        id: _vm.id,
        maxlength: _vm.maxlength,
      },
      domProps: { value: _vm.value },
      on: {
        input: function ($event) {
          return _vm.OnInput($event)
        },
        keypress: function ($event) {
          return _vm.OnKeyPress($event)
        },
      },
    }),
    _vm._v(" "),
    _c("span", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.type == "text",
          expression: "type == 'text'",
        },
      ],
      staticStyle: { "font-size": "10px" },
      domProps: {
        textContent: _vm._s(
          _vm.value
            ? _vm.value.length + "/" + _vm.maxlength
            : "0" + "/" + _vm.maxlength
        ),
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }